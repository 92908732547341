import Dashboard from 'views/pages/dashboards/Dashboard.js';
import { ListOrder } from 'views/pages/OrderManagement';
import { AddOrder } from 'views/pages/OrderManagement';
import { UpdateOrder } from 'views/pages/OrderManagement';
import { PlanPendingCreate } from 'views/pages/PlanManagement';
import PlanPendingApply from 'views/pages/PlanManagement/PlanPendingApply';
import AccountManage from 'views/pages/AccountManage';
import RoleManage from 'views/pages/RoleManage';
import LoginPage from 'views/pages/LoginPage';
import ForgotPasswordPage from 'views/pages/ForgotPasswordPage';
import ResetPasswordPage from 'views/pages/ResetPasswordPage';
import AccountInfo from 'views/pages/AccountInfo';
import CustomerManage from 'views/pages/CustomerManage';
import ProducerManage from 'views/pages/ProducerManage';
import { UpdateMeasurePage } from 'views/pages/MeasureManagement';
import DetailOrder from 'views/pages/OrderManagement/ListOrder/DetailOrder';
import NplManage from 'views/pages/NplManage';
import NplTypes from 'views/pages/NplManage/NplTypes';
import AddNPL from 'views/pages/NplManage/AddNpl';
import { AggregatePlan } from 'views/pages/PlanManagement';
import { CensorshipConversion } from 'views/pages/MeasureManagement';
import { CensorshipResult } from 'views/pages/MeasureManagement';
import { AggregateMeasure } from 'views/pages/MeasureManagement';
import { ListMeasurementStandards } from 'views/pages/MeasurementStandards';
import { AddMeasurementStandard } from 'views/pages/MeasurementStandards';
import { TypeProduct } from 'views/pages/ProductManagement';
import { ListProduct } from 'views/pages/ProductManagement';
import { AddProduct } from 'views/pages/ProductManagement';
import { NormMaterials } from 'views/pages/MaterialsManage';
import { Materials } from 'views/pages/MaterialsManage';
import FormMaterialNorm from 'views/pages/MaterialsManage/FormMaterialNorm';
import DetailMaterialNorm from 'views/pages/MaterialsManage/DetailMaterialNorm';
import { ListProductionCommand } from 'views/pages/ProductionCommand';
import { CreateProductionCommand } from 'views/pages/ProductionCommand';
import { UpdateProductionCommand } from 'views/pages/ProductionCommand';
import { DetailProductionCommand } from 'views/pages/ProductionCommand';
import { ProductionCommandError } from 'views/pages/ProductionCommand';
import ReceiveGC from 'views/pages/DeliveryManage/ReceiveGC';
import ReceiveError from 'views/pages/DeliveryManage/ReceiveError';
import FormReceiveGC from 'views/pages/DeliveryManage/FormReceiveGC';
import FormReceiveErrorGC from 'views/pages/DeliveryManage/FormReceiveErrorGC';
import QC from 'views/pages/QCManage/QC';
import FormQCQualified from 'views/pages/QCManage/FormQCQualified';
import FormQCUnqualified from 'views/pages/QCManage/FormQCUnqualified';
import QCProductError from 'views/pages/QCManage/QCProductError';
import CompleteStage from 'views/pages/FinishProduct/CompleteStage';
import TransferQC from 'views/pages/FinishProduct/TransferQC';
import TransferCustomer from 'views/pages/FinishProduct/TransferCustomer';
import CompletePaking from 'views/pages/FinishProduct/CompletePaking';
import CompleteProductError from 'views/pages/FinishProduct/CompleteProductError';
import ManagePayingCustomer from 'views/pages/ManagePayingCustomer';
import SyntheticForwardingProcessing from 'views/pages/Statistical/SyntheticForwardingProcessing';
import DetailSyntheticForwardingProcessing from 'views/pages/Statistical/SyntheticForwardingProcessing/DetailSyntheticForwardingProcessing';
import DetailSyntheticInventory from 'views/pages/Statistical/SyntheticInventory/DetailSyntheticInventory';
import DetailSyntheticProducer from 'views/pages/Statistical/SyntheticProducer/DetailSyntheticProducer';
import DetailSyntheticComplete from 'views/pages/Statistical/SyntheticComplete/DetailSyntheticComplete';
// DetailSyntheticComplete
import SyntheticComplete from 'views/pages/Statistical/SyntheticComplete';
import SyntheticProducer from 'views/pages/Statistical/SyntheticProducer';
import SyntheticInventory from 'views/pages/Statistical/SyntheticInventory';
import DetailAggregatePlan from 'views/pages/PlanManagement/AggregatePlan/Detail';

const routes = [
  {
    children: true,
    path: 'detail-aggrement-plan/:orderPlanId',
    name: 'Chi tiết kế hoạch triển khai sản xuất cho đơn hàng',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailAggregatePlan,
    layout: '/',
    roles: ['get_orderPlan'],
  },
  {
    children: true,
    path: 'form-transfer-customer',
    name: 'Chuyển trả khách',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: TransferCustomer,
    layout: '/',
    roles: ['manage_transportTK'],
  },
  {
    children: true,
    path: 'form-transfer-qc/:tab',
    name: 'Chuyển QC SP',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: TransferQC,
    layout: '/',
    roles: ['manage_transportHT'],
  },
  {
    children: true,
    path: 'customers/:id',
    name: 'Khách hàng - Danh sách',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: CustomerManage,
    layout: '/',
    roles: ['get_customerOrganization'],
  },
  {
    children: true,
    path: 'form-qc-qualified',
    name: 'QC SP đạt',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: FormQCQualified,
    layout: '/',
    roles: ['manage_transportQC'],
  },
  {
    children: true,
    path: 'form-qc-unqualified',
    name: 'QC SP không đạt',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: FormQCUnqualified,
    layout: '/',
    roles: ['manage_transportQC'],
  },
  {
    children: true,
    path: 'form-receive-error-gc',
    name: 'Tiếp nhận sản phẩm lỗi khách trả',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: FormReceiveErrorGC,
    layout: '/',
    roles: ['manage_transportGN'],
  },
  {
    children: true,
    path: 'form-receive-gc',
    name: 'Tiếp nhận sản phẩm từ nhà GC',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: FormReceiveGC,
    layout: '/',
    roles: ['manage_transportGN'],
  },
  {
    children: true,
    path: 'detail-production-command/:productionCommandId',
    name: 'Chi tiết lệnh sản xuất',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailProductionCommand,
    layout: '/',
    roles: ['get_productionCommand'],
  },
  {
    children: true,
    path: 'update-production-command/:productionCommandId',
    name: 'Cập nhật lệnh sản xuất',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: UpdateProductionCommand,
    layout: '/',
    roles: ['manage_productionCommand'],
  },
  {
    children: true,
    path: 'norm-materials/detail/:orderId',
    name: 'Chi tiết định mức npl',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailMaterialNorm,
    layout: '/',
    roles: ['get_materialNorm'],
  },
  {
    children: true,
    path: 'norm-materials/update/:orderId',
    name: 'Cập nhật định mức npl',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: FormMaterialNorm,
    layout: '/',
    roles: ['manage_materialNorm'],
  },
  {
    children: true,
    path: 'add-order/customer/:orderId/:customerOrganizationId',
    name: 'Thêm mới',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: AddOrder,
    layout: '/',
    roles: ['manage_order'],
  },
  {
    children: true,
    path: 'update-order/:orderId',
    name: 'Cập nhật thông tin đơn hàng',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: UpdateOrder,
    layout: '/',
    roles: ['manage_order'],
  },
  {
    children: true,
    path: 'update-order/customer/:orderId',
    name: 'Cập nhật thông tin khách hàng của đơn hàng',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: UpdateOrder,
    layout: '/',
    roles: ['manage_order'],
  },
  {
    children: true,
    path: 'order-infor/:id',
    name: 'Chi tiết đơn hàng',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailOrder,
    layout: '/',
    roles: ['get_order'],
  },
  {
    children: true,
    path: 'account-info/:id/:tab',
    name: 'Thông tin tài khoản',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: AccountInfo,
    layout: '/',
    roles: [],
  },
  {
    children: true,
    path: 'synthetic-forwarding-processing/:id',
    name: 'Báo cáo thống kê - Báo cáo chi tiết giao nhận',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailSyntheticForwardingProcessing,
    layout: '/',
    roles: ['get_order'],
  },
  {
    children: true,
    path: 'synthetic-inventory/:id',
    name: 'Báo cáo thống kê - Báo cáo chi tiết QC',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailSyntheticInventory,
    layout: '/',
    roles: ['get_order'],
  },
  {
    children: true,
    path: 'synthetic-producer/:id',
    name: 'Báo cáo thống kê - Báo cáo chi tiết sản xuất',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailSyntheticProducer,
    layout: '/',
    roles: ['get_order'],
  },
  {
    children: true,
    path: 'synthetic-complete/:id',
    name: 'Báo cáo thống kê - Báo cáo chi tiết hoàn thiện',
    svg: ``,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: DetailSyntheticComplete,
    layout: '/',
    roles: ['get_order'],
  },
  {
    name: 'Tổng quan',
    icon: 'ni ni-shop text-primary',
    big: true,
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <polyline points="0.5 8.5 7 2 13.5 8.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polyline>
    <polyline points="2.5 6.5 2.5 13.5 11.5 13.5 11.5 6.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polyline>
    <line x1="7" y1="13.5" x2="7" y2="10.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <circle cx="7" cy="6.75" r="1.25" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
  </g></g></svg>`,
    state: 'dashboard',
    path: 'dashboard',
    component: Dashboard,
    layout: '/',
    roles: ['get_dashboardQT'],
  },
  {
    collapse: true,
    name: 'Quản lý đơn hàng',
    icon: 'ni ni-ungroup text-orange',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <path d="M9.5,1.5H11a1,1,0,0,1,1,1v10a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1H4.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
    <rect x="4.5" y="0.5" width="5" height="2.5" rx="1" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></rect>
    <line x1="4.5" y1="5.5" x2="9.5" y2="5.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="4.5" y1="8" x2="9.5" y2="8" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="4.5" y1="10.5" x2="9.5" y2="10.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
  </g></g></svg>`,
    state: 'order',
    roles: ['get_order', 'manage_order'],
    views: [
      {
        path: 'order-manage',
        name: 'Danh sách',
        miniName: 'OM',
        component: ListOrder,
        layout: '/',
        roles: ['get_order'],
      },
      {
        path: 'add-order',
        name: 'Thêm mới',
        miniName: 'AO',
        component: AddOrder,
        layout: '/',
        roles: ['manage_order'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý kế hoạch',
    icon: 'ni ni-ui-04 text-info',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <path d="M1.5,2.5a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h11a1,1,0,0,0,1-1v-9a1,1,0,0,0-1-1h-2" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
    <line x1="3.5" y1="0.5" x2="3.5" y2="4.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="10.5" y1="0.5" x2="10.5" y2="4.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="3.5" y1="2.5" x2="8.5" y2="2.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <circle cx="3.5" cy="7.5" r="0.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
    <circle cx="7" cy="7.5" r="0.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
    <circle cx="10.5" cy="7.5" r="0.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
    <circle cx="3.5" cy="10.5" r="0.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
  </g></g></svg>`,
    state: 'order-plan',
    roles: ['get_orderPlan'],
    views: [
      {
        path: 'plan-pending-create',
        name: 'Khởi tạo kế hoạch SX',
        miniName: 'PPC',
        component: PlanPendingCreate,
        layout: '/',
        roles: ['get_orderPlan'],
      },
      {
        path: 'plan-pending-apply',
        name: 'Kế hoạch chờ duyệt',
        miniName: 'PPA',
        component: PlanPendingApply,
        layout: '/',
        roles: ['get_orderPlan'],
      },
      {
        path: 'aggrement-plan',
        name: 'Tổng hợp kế hoạch',
        miniName: 'AP',
        component: AggregatePlan,
        layout: '/',
        roles: ['get_orderPlan'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý số đo',
    icon: 'ni ni-single-copy-04 text-pink',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
		<g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
				<rect x="0.64" y="4.17" width="12.73" height="5.66" transform="translate(-2.9 7) rotate(-45)" style="fill: none;stroke: #000000;stroke-linecap: round;stroke-linejoin: round"></rect>
				<line x1="7.5" y1="2.5" x2="9" y2="4" style="fill: none;stroke: #000000;stroke-linecap: round;stroke-linejoin: round"></line>
				<line x1="5" y1="5" x2="6.5" y2="6.5" style="fill: none;stroke: #000000;stroke-linecap: round;stroke-linejoin: round"></line>
				<line x1="2.5" y1="7.5" x2="4" y2="9" style="fill: none;stroke: #000000;stroke-linecap: round;stroke-linejoin: round"></line>
			</g></g></svg>`,
    state: 'size-manage',
    roles: ['get_customerInOrder'],
    views: [
      {
        path: 'measure',
        name: 'Cập nhật kết quả đo',
        miniName: 'M',
        component: UpdateMeasurePage,
        layout: '/',
        roles: ['get_customerInOrder'],
      },
      {
        path: 'censorship-measure',
        name: 'Kiểm duyệt chuyển đối SĐ',
        miniName: 'CM',
        component: CensorshipConversion,
        layout: '/',
        roles: ['get_customerInOrder'],
      },
      {
        path: 'censorship-result',
        name: 'Kiểm duyệt kết quả đo',
        miniName: 'CR',
        component: CensorshipResult,
        layout: '/',
        roles: ['get_customerInOrder'],
      },
      {
        path: 'aggregate-measure',
        name: 'Danh sách tổng hợp',
        miniName: 'AM',
        component: AggregateMeasure,
        layout: '/',
        roles: ['get_customerInOrder'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý nguyên phụ liệu',
    icon: 'ni ni-align-left-2 text-default',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <rect x="0.5" y="1" width="5" height="5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></rect>
    <line x1="13.25" y1="13.5" x2="8.25" y2="13.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="8.25" y1="8.5" x2="13.25" y2="8.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <line x1="8.25" y1="11" x2="13.25" y2="11" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
    <polygon points="13.5 6 8 6 10.75 0.5 13.5 6" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polygon>
    <circle cx="3" cy="11" r="2.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
  </g></g></svg>`,
    state: 'npl-manage',
    roles: ['get_materialNorm'],
    views: [
      {
        path: 'norm-materials',
        name: 'Định mức NPL',
        miniName: 'NM',
        component: NormMaterials,
        layout: '/',
        roles: ['get_materialNorm'],
      },
      {
        path: 'materials',
        name: 'Danh sách NPL',
        miniName: 'M',
        component: Materials,
        layout: '/',
        roles: ['get_materialNorm'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý lệnh sản xuất',
    icon: 'ni ni-map-big text-primary',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <rect x="3.5" y="0.5" width="10" height="10" rx="1" transform="translate(17 11) rotate(180)" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></rect>
    <path d="M10.5,13.5h-9a1,1,0,0,1-1-1v-9" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
    <polyline points="7.5 3.5 10.5 3.5 10.5 6.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polyline>
    <line x1="10.5" y1="3.5" x2="6.5" y2="7.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
  </g></g></svg>`,
    state: 'production-command',
    roles: ['get_productionCommand'],
    views: [
      {
        path: 'list-product-command',
        name: 'Danh sách gia công SP mới',
        miniName: 'LPC',
        component: ListProductionCommand,
        layout: '/',
        roles: ['get_productionCommand'],
      },
      {
        path: 'list-product-command-error',
        name: 'Danh sách gia công SP lỗi',
        miniName: 'LPCE',
        component: ProductionCommandError,
        layout: '/',
        roles: ['get_productionCommand'],
      },
      {
        path: 'create-product-command',
        name: 'Tạo mới lệnh sx',
        miniName: 'CPC',
        component: CreateProductionCommand,
        layout: '/',
        roles: ['manage_productionCommand'],
      },
      {
        path: 'synthetic-producer',
        name: 'Báo cáo tổng hợp sản xuất',
        miniName: 'CPE',
        component: SyntheticProducer,
        layout: '/',
        roles: [],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý giao nhận',
    icon: 'ni ni-map-big text-primary',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <rect x="0.5" y="0.5" width="13" height="13" rx="3" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></rect>
    <polyline points="6 10.5 4 8.5 10 8.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polyline>
    <polyline points="8 3.5 10 5.5 4 5.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></polyline>
  </g></g></svg>`,
    state: 'delivery-manage',
    roles: ['get_transportGN'],
    views: [
      {
        path: 'receive-gc',
        name: 'Nhận SP từ nhà GC',
        miniName: 'RG',
        component: ReceiveGC,
        layout: '/',
        roles: ['get_transportGN'],
      },
      {
        path: 'receive-error',
        name: 'Nhận SP lỗi khách trả',
        miniName: 'RE',
        component: ReceiveError,
        layout: '/',
        roles: ['get_transportGN'],
      },
      {
        path: 'synthetic-forwarding-processing',
        name: 'Báo cáo giao nhận gia công',
        miniName: 'SFP',
        component: SyntheticForwardingProcessing,
        layout: '/',
        roles: [],
      },
    ],
  },
  {
    collapse: true,
    name: 'Quản lý chất lượng (QC)',
    icon: 'ni ni-map-big text-primary',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <rect x="0.5" y="0.5" width="13" height="13" rx="3" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></rect>
    <path d="M4,8,6.05,9.64a.48.48,0,0,0,.4.1.5.5,0,0,0,.34-.24L10,4" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
    </g></g></svg>`,
    state: 'qc-manage',
    roles: ['get_transportQC'],
    views: [
      {
        path: 'pendding-qc',
        name: 'Danh sách chờ QC',
        miniName: 'PQC',
        component: QC,
        layout: '/',
        roles: ['get_transportQC'],
      },
      {
        path: 'products-error',
        name: 'Danh sách sản phẩm lỗi',
        miniName: 'PE',
        component: QCProductError,
        layout: '/',
        roles: ['get_transportQC'],
      },
      {
        path: 'synthetic-inventory',
        name: 'Báo cáo tổng hợp QC',
        miniName: 'SI',
        component: SyntheticInventory,
        layout: '/',
        roles: [],
      },
    ],
  },
  {
    collapse: true,
    name: 'Hoàn thiện sản phẩm',
    icon: 'ni ni-map-big text-primary',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
  
    <g transform="matrix(3.4285714285714284,0,0,3.4285714285714284,0,0)"><g>
        <line x1="7" y1="0.5" x2="7" y2="4.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
        <line x1="8.5" y1="11" x2="11" y2="11" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></line>
        <path d="M.5,4.5h13a0,0,0,0,1,0,0v8a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-8A0,0,0,0,1,.5,4.5Z" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
        <path d="M.5,4.5,2,1.61A2,2,0,0,1,3.74.5h6.52a2,2,0,0,1,1.79,1.11L13.5,4.5" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
      </g></g></svg>`,
    state: 'finish-product',
    roles: ['get_transportHT'],
    views: [
      {
        path: 'complete-stage',
        name: 'Hoàn thiện công đoạn',
        miniName: 'CS',
        component: CompleteStage,
        layout: '/',
        roles: ['get_transportHT'],
      },
      {
        path: 'complete-packing',
        name: 'Hoàn thiện đóng gói',
        miniName: 'CP',
        component: CompletePaking,
        layout: '/',
        roles: ['get_transportHT'],
      },
      {
        path: 'complete-product-error',
        name: 'Hoàn thiện SP lỗi',
        miniName: 'CPE',
        component: CompleteProductError,
        layout: '/',
        roles: ['get_transportHT'],
      },
      {
        path: 'synthetic-complete',
        name: 'Báo cáo tổng hợp hoàn thiện',
        miniName: 'CPE',
        component: SyntheticComplete,
        layout: '/',
        roles: [],
      },
    ],
  },
  {
    path: 'manage-paying-customer',
    name: 'Quản lý hàng trả khách',
    svg: `<svg style='width: 20px;height: 20px' class="mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  
<g transform="matrix(1.4285714285714286,0,0,1.4285714285714286,0,0)"><g>
    <circle cx="5" cy="2.75" r="2.25" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></circle>
    <path d="M6,6.61A4.49,4.49,0,0,0,.5,11v1.5H6" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
    <path d="M10.67,13.47h0a.5.5,0,0,1-.34,0h0A4.48,4.48,0,0,1,7.5,9.31V8A.47.47,0,0,1,8,7.5H13A.47.47,0,0,1,13.5,8V9.31A4.48,4.48,0,0,1,10.67,13.47Z" style="fill: none;stroke: #00305C;stroke-linecap: round;stroke-linejoin: round"></path>
  </g></g></svg>`,
    icon: 'ni ni-calendar-grid-58 text-red',
    component: ManagePayingCustomer,
    layout: '/',
    roles: ['get_transportTK'],
  },
  {
    collapse: true,
    redirect: true,
    name: 'Auth',
    icon: 'ni ni-ungroup text-orange',
    state: 'auth',
    roles: [],
    views: [
      {
        path: '/login',
        name: 'LoginPage',
        miniName: 'AL',
        component: LoginPage,
        layout: '/auth',
        roles: [],
      },
      {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        miniName: 'AF',
        component: ForgotPasswordPage,
        layout: '/auth',
        roles: [],
      },
      {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        miniName: 'AR',
        component: ResetPasswordPage,
        layout: '/auth',
        roles: [],
      },
    ],
  },
];
export default routes;
export const routeAdmin = [
  {
    collapse: true,
    name: 'Tài khoản người dùng',
    icon: 'ni ni-circle-08',
    state: 'account',
    roles: ['get_user', 'get_role'],
    views: [
      {
        path: 'accounts',
        name: 'Quản lý tài khoản',
        miniName: 'A',
        component: AccountManage,
        layout: '/',
        roles: ['get_user'],
      },
      {
        path: 'roles',
        name: 'Quản lý phân quyền',
        miniName: 'R',
        component: RoleManage,
        layout: '/',
        roles: ['get_role'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Khách hàng',
    icon: 'ni ni-badge',
    state: 'customer',
    roles: ['get_customerOrganization'],
    views: [
      {
        path: 'customers',
        name: 'Danh sách',
        miniName: 'C',
        component: CustomerManage,
        layout: '/',
        roles: ['get_customerOrganization'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Nhà sản xuất GC',
    icon: 'ni ni-building',
    state: 'producer',
    roles: ['get_producer'],
    views: [
      {
        path: 'producers',
        name: 'Danh sách',
        miniName: 'P',
        component: ProducerManage,
        layout: '/',
        roles: ['get_producer'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Kho hàng NPL',
    icon: 'ni ni-box-2',
    state: 'warehouse-npl',
    roles: ['get_materialType', 'get_material', 'manage_material'],
    views: [
      {
        path: 'npl-types',
        name: 'Thể loại',
        miniName: 'NPLT',
        component: NplTypes,
        layout: '/',
        roles: ['get_materialType'],
      },
      {
        path: 'npls',
        name: 'Danh sách',
        miniName: 'NPLS',
        component: NplManage,
        layout: '/',
        roles: ['get_material'],
      },
      {
        path: 'add-npl',
        name: 'Thêm mới',
        miniName: 'ANPL',
        component: AddNPL,
        layout: '/',
        roles: ['manage_material'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Tiêu chuẩn số đo',
    icon: 'ni ni-ruler-pencil',
    state: 'standard-size',
    roles: ['get_standardSize', 'manage_standardSize'],
    views: [
      {
        path: 'list-standards',
        name: 'Danh sách',
        miniName: 'LS',
        component: ListMeasurementStandards,
        layout: '/',
        roles: ['get_standardSize'],
      },
      {
        path: 'add-standards',
        name: 'Tạo mới',
        miniName: 'AS',
        component: AddMeasurementStandard,
        layout: '/',
        roles: ['manage_standardSize'],
      },
    ],
  },
  {
    collapse: true,
    name: 'Danh mục sản phẩm',
    icon: 'ni ni-cart',
    state: 'product-category',
    roles: ['get_productType', 'manage_productType'],
    views: [
      {
        path: 'type-product',
        name: 'Dòng sản phẩm',
        miniName: 'TP',
        component: TypeProduct,
        layout: '/',
        roles: ['get_productType'],
      },
      {
        path: 'list-product',
        name: 'Danh sách sản phẩm',
        miniName: 'LP',
        component: ListProduct,
        layout: '/',
        roles: ['get_productType'],
      },
      {
        path: 'add-product',
        name: 'Tạo mới',
        miniName: 'AP',
        component: AddProduct,
        layout: '/',
        roles: ['manage_productType'],
      },
    ],
  },
];
