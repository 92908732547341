import TypeActions from "../TypeActions";

const initialState = {
	qualities: {
		results: [],
	},
	productsError: {
		results: [],
	},
	qualityById: {},
	customerByBarcodeQuality: {},
	isGetQualities: false,
	isGetQualityById: false,
	isCreateQuality: false,
	isUpdateQuality: false,
	isDeleteQuality: false,
	isGetCustomerByBarcodeQuality: false,
	isqcProductAchieved: false,
	isQcProductUnachieved: false,
	isGetProductsError: false,
	isQcCompleteProductsError: false,
	isReturnProducer: false,
	errors: {
		getQualities: "",
		getQualityById: "",
		createQuality: "",
		updateQuality: "",
		deleteQuality: "",
		getCustomerByBarcodeQuality: "",
		qcProductAchieved: "",
		qcProductUnachieved: "",
		getProductsError: "",
		qcCompleteProductsError: "",
		returnProducer: "",
	},
};

export const qualityReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case TypeActions.GET_QUALITIES_REQUEST:
			return {
				...state,
				// qualities: { results: [] },
				isGetQualities: true,
				errors: { ...state.errors, getQualities: "" },
			};
		case TypeActions.GET_QUALITIES_SUCCESS:
			return {
				...state,
				qualities: actions.data || { results: [] },
				isGetQualities: false,
				errors: { ...state.errors, getQualities: "" },
			};
		case TypeActions.GET_QUALITIES_FAILED:
			return {
				...state,
				qualities: { results: [] },
				isGetQualities: false,
				errors: { ...state.errors, getQualities: actions.error },
			};

		case TypeActions.GET_QUALITY_BY_ID_REQUEST:
			return {
				...state,
				// qualityById: {},
				isGetQualityById: true,
				errors: { ...state.errors, getQualityById: "" },
			};
		case TypeActions.GET_QUALITY_BY_ID_SUCCESS:
			return {
				...state,
				qualityById: actions.data || {},
				isGetQualityById: false,
				errors: { ...state.errors, getQualityById: "" },
			};
		case TypeActions.GET_QUALITY_BY_ID_FAILED:
			return {
				...state,
				qualityById: {},
				isGetQualityById: false,
				errors: { ...state.errors, getQualityById: actions.error },
			};

		case TypeActions.CREATE_QUALITY_REQUEST:
			return {
				...state,
				isCreateQuality: true,
				errors: { ...state.errors, createQuality: "" },
			};
		case TypeActions.CREATE_QUALITY_SUCCESS:
			return {
				...state,
				isCreateQuality: false,
				errors: { ...state.errors, createQuality: "" },
			};
		case TypeActions.CREATE_QUALITY_FAILED:
			return {
				...state,
				isCreateQuality: false,
				errors: { ...state.errors, createQuality: actions.error },
			};

		case TypeActions.UPDATE_QUALITY_REQUEST:
			return {
				...state,
				isUpdateQuality: true,
				errors: { ...state.errors, updateQuality: "" },
			};
		case TypeActions.UPDATE_QUALITY_SUCCESS:
			return {
				...state,
				isUpdateQuality: false,
				errors: { ...state.errors, updateQuality: "" },
			};
		case TypeActions.UPDATE_QUALITY_FAILED:
			return {
				...state,
				isUpdateQuality: false,
				errors: { ...state.errors, updateQuality: actions.error },
			};

		case TypeActions.DELETE_QUALITY_REQUEST:
			return {
				...state,
				isDeleteQuality: true,
				errors: { ...state.errors, deleteQuality: "" },
			};
		case TypeActions.DELETE_QUALITY_SUCCESS:
			return {
				...state,
				isDeleteQuality: false,
				errors: { ...state.errors, deleteQuality: "" },
			};
		case TypeActions.DELETE_QUALITY_FAILED:
			return {
				...state,
				isDeleteQuality: false,
				errors: { ...state.errors, deleteQuality: actions.error },
			};

		case TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_REQUEST:
			return {
				...state,
				// customerByBarcodeQuality: {},
				isGetCustomerByBarcodeQuality: true,
				errors: { ...state.errors, getCustomerByBarcodeQuality: "" },
			};
		case TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_SUCCESS:
			return {
				...state,
				customerByBarcodeQuality: actions.data || {},
				isGetCustomerByBarcodeQuality: false,
				errors: { ...state.errors, getCustomerByBarcodeQuality: "" },
			};
		case TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_FAILED:
			return {
				...state,
				customerByBarcodeQuality: {},
				isGetCustomerByBarcodeQuality: false,
				errors: { ...state.errors, getCustomerByBarcodeQuality: actions.error },
			};

		case TypeActions.QC_PRODUCT_ACHIEVED_REQUEST:
			return {
				...state,
				isqcProductAchieved: true,

				errors: { ...state.errors, qcProductAchieved: "" },
			};
		case TypeActions.QC_PRODUCT_ACHIEVED_SUCCESS:
			return {
				...state,
				isqcProductAchieved: false,
				customerByBarcodeQuality: {},
				errors: { ...state.errors, qcProductAchieved: "" },
			};
		case TypeActions.QC_PRODUCT_ACHIEVED_FAILED:
			return {
				...state,
				isqcProductAchieved: false,
				customerByBarcodeQuality: {},
				errors: { ...state.errors, qcProductAchieved: actions.error },
			};

		case TypeActions.QC_PRODUCT_UNACHIEVED_REQUEST:
			return {
				...state,
				isQcProductUnachieved: true,
				errors: { ...state.errors, qcProductUnachieved: "" },
			};
		case TypeActions.QC_PRODUCT_UNACHIEVED_SUCCESS:
			return {
				...state,
				isQcProductUnachieved: false,
				customerByBarcodeQuality: {},
				errors: { ...state.errors, qcProductUnachieved: "" },
			};
		case TypeActions.QC_PRODUCT_UNACHIEVED_FAILED:
			return {
				...state,
				isQcProductUnachieved: false,
				customerByBarcodeQuality: {},
				errors: { ...state.errors, qcProductUnachieved: actions.error },
			};

		case TypeActions.GET_PRODUCTS_ERROR_QC_REQUEST:
			return {
				...state,
				// productsError: { results: [] },
				isGetProductsError: true,
				errors: { ...state.errors, getProductsError: "" },
			};
		case TypeActions.GET_PRODUCTS_ERROR_QC_SUCCESS:
			return {
				...state,
				productsError: actions.data || { results: [] },
				isGetProductsError: false,
				errors: { ...state.errors, getProductsError: "" },
			};
		case TypeActions.GET_PRODUCTS_ERROR_QC_FAILED:
			return {
				...state,
				productsError: { results: [] },
				isGetProductsError: false,
				errors: { ...state.errors, getProductsError: actions.error },
			};

		case TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_REQUEST:
			return {
				...state,
				isQcCompleteProductsError: true,
				errors: { ...state.errors, qcCompleteProductsError: "" },
			};
		case TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_SUCCESS:
			return {
				...state,
				isQcCompleteProductsError: false,
				errors: { ...state.errors, qcCompleteProductsError: "" },
			};
		case TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_FAILED:
			return {
				...state,
				isQcCompleteProductsError: false,
				errors: { ...state.errors, qcCompleteProductsError: actions.error },
			};

		case TypeActions.RETURN_PRODUCER_REQUEST:
			return {
				...state,
				isReturnProducer: true,
				errors: { ...state.errors, returnProducer: "" },
			};
		case TypeActions.RETURN_PRODUCER_SUCCESS:
			return {
				...state,
				isReturnProducer: false,
				errors: { ...state.errors, returnProducer: "" },
			};
		case TypeActions.RETURN_PRODUCER_FAILED:
			return {
				...state,
				isReturnProducer: false,
				errors: { ...state.errors, returnProducer: actions.error },
			};

		default:
			return { ...state };
	}
};

export default qualityReducer;
