import TypeActions from '../TypeActions';

const initialState = {
  productionCommands: {
    results: [],
  },
  productionCommandsError: {
    results: [],
  },
  productionCommandById: {},
  maxProduct: {},
  isGetProductionCommands: false,
  isGetProductionCommandsError: false,
  isGetProductionCommandById: false,
  isCreateProductionCommand: false,
  isUpdateProductionCommand: false,
  isDeleteProductionCommand: false,
  isExcludeCustomerCommand: false,
  isExportProductCommand: false,
  isGetMaxProduct: false,
  errors: {
    getProductionCommands: '',
    getProductionCommandsError: '',
    getProductionCommandById: '',
    createProductionCommand: '',
    updateProductionCommand: '',
    deleteProductionCommand: '',
    excludeCustomerCommand: '',
    exportProductCommand: '',
    getMaxProduct: '',
  },
};

export const productionCommandReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_PRODUCTION_COMMANDS_REQUEST:
      return {
        ...state,
        isGetProductionCommands: true,
        productionCommands: { results: [] },
        errors: {
          ...state.errors,
          getProductionCommands: '',
        },
      };
    case TypeActions.GET_PRODUCTION_COMMANDS_SUCCESS:
      return {
        ...state,
        isGetProductionCommands: false,
        productionCommands: actions.data || { results: [] },
        errors: {
          ...state.errors,
          getProductionCommands: '',
        },
      };
    case TypeActions.GET_PRODUCTION_COMMANDS_FAILED:
      return {
        ...state,
        isGetProductionCommands: false,
        productionCommands: { results: [] },
        errors: {
          ...state.errors,
          getProductionCommands: actions.error,
        },
      };

    case TypeActions.GET_PRODUCTION_COMMAND_BY_ID_REQUEST:
      return {
        ...state,
        isGetProductionCommandById: true,
        // productionCommandById: {},
        errors: {
          ...state.errors,
          getProductionCommandById: '',
        },
      };

    case TypeActions.GET_PRODUCTION_COMMAND_BY_ID_SUCCESS:
      return {
        ...state,
        isGetProductionCommandById: false,
        productionCommandById: actions.data || {},
        errors: {
          ...state.errors,
          getProductionCommandById: '',
        },
      };
    case TypeActions.GET_PRODUCTION_COMMAND_BY_ID_FAILED:
      return {
        ...state,
        isGetProductionCommandById: false,
        productionCommandById: {},
        errors: {
          ...state.errors,
          getProductionCommandById: actions.error,
        },
      };

    case TypeActions.CREATE_PRODUCTION_COMMAND_REQUEST:
      return {
        ...state,
        isCreateProductionCommand: true,
        errors: {
          ...state.errors,
          createProductionCommand: '',
        },
      };
    case TypeActions.CREATE_PRODUCTION_COMMAND_SUCCESS:
      return {
        ...state,
        isCreateProductionCommand: false,
        errors: {
          ...state.errors,
          createProductionCommand: '',
        },
      };
    case TypeActions.CREATE_PRODUCTION_COMMAND_FAILED:
      return {
        ...state,
        isCreateProductionCommand: false,
        errors: {
          ...state.errors,
          createProductionCommand: actions.error,
        },
      };

    case TypeActions.DELETE_PRODUCTION_COMMAND_REQUEST:
      return {
        ...state,
        isDeleteProductionCommand: true,
        errors: {
          ...state.errors,
          deleteProductionCommand: '',
        },
      };
    case TypeActions.DELETE_PRODUCTION_COMMAND_SUCCESS:
      return {
        ...state,
        isDeleteProductionCommand: false,
        errors: {
          ...state.errors,
          deleteProductionCommand: '',
        },
      };
    case TypeActions.DELETE_PRODUCTION_COMMAND_FAILED:
      return {
        ...state,
        isDeleteProductionCommand: false,
        errors: {
          ...state.errors,
          deleteProductionCommand: actions.error,
        },
      };

    case TypeActions.UPDATE_PRODUCTION_COMMAND_REQUEST:
      return {
        ...state,
        isUpdateProductionCommand: true,
        // productionCommandById: actions.body,
        errors: {
          ...state.errors,
          updateProductionCommand: '',
        },
      };
    case TypeActions.UPDATE_PRODUCTION_COMMAND_SUCCESS:
      return {
        ...state,
        isUpdateProductionCommand: false,

        errors: {
          ...state.errors,
          updateProductionCommand: '',
        },
      };
    case TypeActions.UPDATE_PRODUCTION_COMMAND_FAILED:
      return {
        ...state,
        isUpdateProductionCommand: false,
        errors: {
          ...state.errors,
          updateProductionCommand: actions.error,
        },
      };

    case TypeActions.EXCLUDE_CUSTOMER_COMMAND_REQUEST:
      return {
        ...state,
        isExcludeCustomerCommand: true,
        errors: {
          ...state.errors,
          excludeCustomerCommand: '',
        },
      };
    case TypeActions.EXCLUDE_CUSTOMER_COMMAND_SUCCESS:
      return {
        ...state,
        isExcludeCustomerCommand: false,
        errors: {
          ...state.errors,
          excludeCustomerCommand: '',
        },
      };
    case TypeActions.EXCLUDE_CUSTOMER_COMMAND_FAILED:
      return {
        ...state,
        isExcludeCustomerCommand: false,
        errors: {
          ...state.errors,
          excludeCustomerCommand: actions.error,
        },
      };

    case TypeActions.GET_PRODUCTION_COMMANDS_ERROR_REQUEST:
      return {
        ...state,
        isGetProductionCommandsError: true,
        // productionCommandsError: { results: [] },
        errors: {
          ...state.errors,
          getProductionCommandsError: '',
        },
      };
    case TypeActions.GET_PRODUCTION_COMMANDS_ERROR_SUCCESS:
      return {
        ...state,
        isGetProductionCommandsError: false,
        productionCommandsError: actions.data || { results: [] },
        errors: {
          ...state.errors,
          getProductionCommandsError: '',
        },
      };
    case TypeActions.GET_PRODUCTION_COMMANDS_ERROR_FAILED:
      return {
        ...state,
        isGetProductionCommandsError: false,
        productionCommandsError: { results: [] },
        errors: {
          ...state.errors,
          getProductionCommandsError: actions.error,
        },
      };

    case TypeActions.GET_MAX_PRODUCT_REQUEST:
      return {
        ...state,
        isGetMaxProduct: true,
        errors: {
          ...state.errors,
          getMaxProduct: '',
        },
      };

    case TypeActions.GET_MAX_PRODUCT_SUCCESS:
      return {
        ...state,
        isGetMaxProduct: false,
        maxProduct: actions.data || {},
        errors: {
          ...state.errors,
          getMaxProduct: '',
        },
      };
    case TypeActions.GET_MAX_PRODUCT_FAILED:
      return {
        ...state,
        isGetMaxProduct: false,
        maxProduct: {},
        errors: {
          ...state.errors,
          getMaxProduct: actions.error,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default productionCommandReducer;
