import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import orderActions from 'Redux/Actions/orderActions';
import { measureSizeActions } from 'Redux/Actions';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import FileDownload from 'js-file-download';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { checkRole } from 'common';
function Header({
  name,
  parentName,
  toggle,
  toggleFormMeasure,
  toggleDialogExtractModal,
  setCurrentOrders,
  isListResult,
  options,
}) {
  const notificationAlertRef = useRef();
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const [orderSearch, setOrderSearch] = useState('');
  const [orderValue, setOrderValue] = useState(null);
  const { orders } = useSelector((state) => state.orderReducer);
  const { isExportMeasureSize, isGetTemplateMeasureSize } = useSelector(
    (state) => state.measureSizeReducer
  );
  const [firstLoading, setFirstLoading] = useState(true);
  const [queryOrders, setQueryOrders] = useState({
    page: 1,
    limit: 10,
    // orderPlanStatus: "0",
    sortBy: 'createdAt:desc',
  });
  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(
        orderActions.getOrders(queryString.stringify(queryOrders), {
          success: (data) => {
            if (firstLoading && data.results.length > 0) {
              // setOrderValue({
              //   value: data.results[0].id,
              //   label: data.results[0].name,
              // });
              // setCurrentOrders(data.results[0].id);
              setFirstLoading(false);
            }
          },
          failed: (err) => {},
        })
      );
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrders, name: orderSearch }),
          {
            success: (data) => {
              if (firstLoading && data.results.length > 0) {
                // setOrderValue({
                //   value: data.results[0].id,
                //   label: data.results[0].name,
                // });
                setFirstLoading(false);
              }
            },
            failed: (err) => {},
          }
        )
      );
    }
  };
  const handleExtractMeasure = () => {
    dispatch(
      measureSizeActions.extractMeasureSize(orderValue.value, '', {
        success: async (data) => {
          FileDownload(data, 'danhsachsodo.xlsx');
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Tải xuống danh sách số đo thành công!`
          );
        },
        failed: (err) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải xuống danh sách số đo thất bại. Lỗi: ${err}!`
          );
        },
      })
    );
  };

  const handleGetTemplateMeasureSize = () => {
    dispatch(
      measureSizeActions.getTemplateMeasureSize(orderValue.value, {
        success: async (data) => {
          const filename = `DANH_SACH_MAU_DON_HANG_${orderValue.label.toUpperCase()}.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Tải xuống danh sách mẫu thành công!`
          );
        },
        failed: (err) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải xuống danh sách mẫu thất bại. Lỗi: ${err}!`
          );
        },
      })
    );
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderSearch]);
  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-end py-4">
              <Col md="5">
                <Row className="align-items-center">
                  <Col md="12">
                    <p className="text-info mb-2 font-weight-500">
                      Vui lòng chọn đơn hàng, sau đó cập nhật số đo cho đơn hàng
                      đó
                    </p>
                  </Col>
                  <Col md="4">
                    <h5 className="text-info">Đơn hàng đang chọn:</h5>
                  </Col>
                  <Col md="8">
                    <ReactSelect
                      className="select-custom"
                      size="sm"
                      placeholder="Chọn đơn hàng"
                      isClearable={true}
                      value={orderValue}
                      onChange={(e) => {
                        setOrderValue(e);
                        setCurrentOrders(e?.value ?? '');
                      }}
                      options={orders.results.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      onInputChange={(value) => {
                        setOrderSearch(value);
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      noOptionsMessage={() => null}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right pl-0" md="7">
                {isListResult &&
                  checkRole(currentAccount, {
                    roles: ['manage_customerInOrder'],
                  }) && (
                    <>
                      {/* <LoadingButtonCustom
                        outline={true}
                        className="text-white mr-1 px-1"
                        size="md"
                        loading={isGetTemplateMeasureSize}
                        onClick={() => {
                          handleGetTemplateMeasureSize();
                        }}
                      >
                        Xuất danh sách mẫu
                      </LoadingButtonCustom> */}
                      <Button
                        outline={true}
                        className="text-white"
                        size="md"
                        onClick={toggleFormMeasure}
                      >
                        Nhập danh sách số đo
                      </Button>
                      <LoadingButtonCustom
                        outline={true}
                        className="text-white"
                        size="md"
                        onClick={() => {
                          // toggleDialogExtractModal();
                          handleExtractMeasure();
                        }}
                        loading={isExportMeasureSize}
                      >
                        Xuất danh sách số đo
                      </LoadingButtonCustom>
                    </>
                  )}

                <Button
                  onClick={toggle}
                  className="btn-neutral"
                  color="default"
                  size="md"
                  // style={{ fontSize: 16 }}
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
            {_.isEmpty(options) ? (
              <></>
            ) : (
              <>
                <Row className="mb-3">
                  <Col xs={12}>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {options.status && (
                      <span className="font-weight-600 text-info">
                        &ensp;Trạng thái: {options.status};
                      </span>
                    )}
                    {options.name && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tên: {options.name};
                      </span>
                    )}
                    {options.year && (
                      <span className="font-weight-600 text-info">
                        &ensp;Năm: {options.year.label};
                      </span>
                    )}
                    {options.month && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tháng: {options.month};
                      </span>
                    )}
                    {options.customerOrganizationId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Khách hàng: {options.customerOrganizationId.label}
                        ;
                      </span>
                    )}
                    {options.provinceId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Thành phố: {options.provinceId.label};
                      </span>
                    )}
                    {options.productTypeId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Sản phẩm: {options.productTypeId.label};
                      </span>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Header;
