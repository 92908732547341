import TypeActions from "../TypeActions";

export const getQualities = (query, callback) => {
  return {
    type: TypeActions.GET_QUALITIES_REQUEST,
    query,
    callback,
  };
};
export const getQualityById = (params, query, callback) => {
  return {
    type: TypeActions.GET_QUALITY_BY_ID_REQUEST,
    params,
    query,
    callback,
  };
};

export const createQuality = (body, callback) => {
  return {
    type: TypeActions.CREATE_QUALITY_REQUEST,
    body,
    callback,
  };
};
export const updateQuality = (body, params, callback) => {
  return {
    type: TypeActions.UPDATE_QUALITY_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteQuality = (params, callback) => {
  return {
    type: TypeActions.DELETE_QUALITY_REQUEST,
    params,
    callback,
  };
};

export const getCustomerByBarcodeQuality = (query, callback) => {
  return {
    type: TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_REQUEST,
    query,
    callback,
  };
};

export const qcProductAchieved = (body, callback) => {
  return {
    type: TypeActions.QC_PRODUCT_ACHIEVED_REQUEST,
    body,
    callback,
  };
};

export const qcProductUnachieved = (body, callback) => {
  return {
    type: TypeActions.QC_PRODUCT_UNACHIEVED_REQUEST,
    body,
    callback,
  };
};

export const getProductsError = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_ERROR_QC_REQUEST,
    query,
    callback,
  };
};

export const qcCompleteProductsError = (body, callback) => {
  return {
    type: TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_REQUEST,
    body,
    callback,
  };
};

export const returnProducerError = (body, callback) => {
  return {
    type: TypeActions.RETURN_PRODUCER_REQUEST,
    body,
    callback,
  };
};

export default {
  getQualities,
  createQuality,
  updateQuality,
  deleteQuality,
  getQualityById,
  getCustomerByBarcodeQuality,
  qcProductAchieved,
  qcProductUnachieved,
  getProductsError,
  qcCompleteProductsError,
  returnProducerError,
};
