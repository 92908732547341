import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  CardFooter,
} from 'reactstrap';
import makeAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import DatePicker from 'reactstrap-date-picker';
import {
  customerActions,
  orderActions,
  producerActions,
  productActions,
  productionCommandActions,
} from 'Redux/Actions';
import Select from 'react-select';
import _ from 'lodash';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
const animatedComponents = makeAnimated();
const Filter = ({
  openFilter,
  filterValues,
  setFilterValues,
  handleOpenFilter,
  isGetting = false,
  isFilterByArrangeDate = false,
}) => {
  const initialValues = {
    year: null,
    orderId: null,
    producerProductionIds: [],
    customerOrgId: null,
    productId: null,
    fromDate: null,
    toDate: null,
  };
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orderReducer);
  const { producers } = useSelector((state) => state.producerReducer);
  const { products } = useSelector((state) => state.productReducer);
  const { customers } = useSelector((state) => state.customerReducer);
  const { productionCommands } = useSelector(
    (state) => state.productionCommandReducer
  );
  const [optionFilter, setOptionFilter] = useState(initialValues);

  const [orderSearch, setOrderSearch] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');
  const [customerSearch, setCustometSearch] = useState('');
  const [productionCommandSearch, setProductionCommandSearch] = useState('');

  const clearFilter = () => {
    if (isGetting) return;
    setOptionFilter(initialValues);
    setFilterValues(initialValues);
    handleOpenFilter(false);
  };

  const handleGetOrders = () => {
    if (orderSearch === '' && !optionFilter.year) {
      dispatch(orderActions.getOrders(queryString.stringify({})));
    } else {
      const query = {};
      if (!!optionFilter.year) {
        query.year = optionFilter.year.value;
        // setOptionFilter({
        // 	...optionFilter,
        // })
      }
      if (orderSearch !== '') {
        query.name = orderSearch;
      }
      dispatch(orderActions.getOrders(queryString.stringify(query)));
    }
  };
  const handleGetProducers = () => {
    if (producerSearch === '') {
      dispatch(producerActions.getProducers(queryString.stringify({})));
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ name: producerSearch })
        )
      );
    }
  };
  const handleGetProducts = () => {
    if (productSearch === '') {
      dispatch(productActions.getProducts(queryString.stringify({})));
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({ name: productSearch })
        )
      );
    }
  };

  const handleGetCustomers = () => {
    if (customerSearch === '') {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({ level: 1, status: 'active' })
        )
      );
    } else {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({
            name: customerSearch,
            level: 1,
            status: 'active',
          })
        )
      );
    }
  };

  const handleGetProductionCommands = () => {
    if (_.isEmpty(optionFilter.orderId)) return;
    if (productionCommandSearch === '') {
      dispatch(
        productionCommandActions.getProductionCommands(
          queryString.stringify({ orderId: optionFilter.orderId.value })
        )
      );
    } else {
      dispatch(
        productionCommandActions.getProductionCommands(
          queryString.stringify({
            name: productionCommandSearch,
            orderId: optionFilter.orderId.value,
          })
        )
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderSearch, optionFilter.year]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetProducers();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [producerSearch]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetProducts();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [productSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCustomers();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [customerSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetProductionCommands();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [productionCommandSearch, optionFilter]);

  useEffect(() => {
    openFilter && setOptionFilter({ ...filterValues });
  }, [openFilter]);
  return (
    <>
      <Card className={`filter ${openFilter && 'show'} transition-filter`}>
        <CardHeader>
          <span
            style={{ cursor: 'pointer' }}
            className="text-danger font-weight-bold"
            onClick={() => {
              if (isGetting) return;
              handleOpenFilter(false);
            }}
          >
            Đóng
          </span>
        </CardHeader>

        <CardBody style={{ height: '78vh', overflowY: 'auto' }}>
          {!isFilterByArrangeDate ? (
            <FormGroup className="row">
              <Label
                className="form-control-label text-sm"
                htmlFor="example-text-input"
                md="5"
              >
                Lọc theo năm
              </Label>
              <Col md="7">
                <Select
                  placeholder="Chọn năm"
                  isClearable={true}
                  value={optionFilter.year}
                  onChange={(e) => {
                    setOptionFilter({
                      ...optionFilter,
                      year: !!e ? e : null,
                      orderId: null,
                      producerProductionIds: [],
                      customerOrgId: null,
                      productId: null,
                      fromDate: null,
                      toDate: null,
                    });
                  }}
                  options={Array.apply(null, Array(5)).map((item, index) => ({
                    label: new Date().getFullYear() - index + '',
                    value: new Date().getFullYear() - index + '',
                  }))}
                />
              </Col>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label className="form-control-label text-sm">
                Chọn khoảng ngày
              </Label>
              <div className="d-flex mb-3 align-items-center">
                <Label
                  className="form-control-label text-sm"
                  style={{ width: '100px' }}
                >
                  Từ ngày
                </Label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  calendarPlacement="bottom"
                  value={optionFilter.fromDate}
                  onChange={(v, f) => {
                    setOptionFilter({
                      ...optionFilter,
                      fromDate: v,
                      year: null,
                      orderId: null,
                      producerProductionIds: [],
                      customerOrgId: null,
                      productId: null,
                    });
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <Label
                  className="form-control-label text-sm"
                  style={{ width: '100px' }}
                >
                  Đến ngày
                </Label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  calendarPlacement="bottom"
                  value={optionFilter.toDate}
                  onChange={(v, f) => {
                    setOptionFilter({
                      ...optionFilter,
                      toDate: v,
                      year: null,
                      orderId: null,
                      producerProductionIds: [],
                      customerOrgId: null,
                      productId: null,
                    });
                  }}
                />
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Label className="form-control-label text-sm">Chọn đơn hàng</Label>
            <Select
              // isDisabled={_.isEmpty(optionFilter.year)}
              placeholder="Nhập để tìm kiếm"
              isClearable={false}
              value={optionFilter.orderId}
              onChange={(e) => {
                setOptionFilter({
                  ...optionFilter,
                  orderId: e,
                  producerProductionIds: [],
                  productId: null,
                  customerOrgId: null,
                });
              }}
              options={orders.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setOrderSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">
              Chọn lệnh sản xuất
            </Label>
            <Select
              isDisabled={_.isEmpty(optionFilter.orderId)}
              placeholder="Nhập để tìm kiếm"
              isClearable={false}
              value={optionFilter.productionCommandId}
              onChange={(e) => {
                setOptionFilter({
                  ...optionFilter,
                  productionCommandId: e,
                });
              }}
              options={productionCommands.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setProductionCommandSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">
              Chọn nhà gia công
            </Label>
            <Select
              className="select-muti"
              isDisabled={_.isEmpty(optionFilter.orderId)}
              placeholder="Nhập để tìm kiếm"
              closeMenuOnSelect={false}
              isClearable={false}
              components={animatedComponents}
              isMulti
              value={optionFilter.producerProductionIds}
              onChange={(e, remove) => {
                if (_.isEmpty(remove?.removedValue)) {
                  setOptionFilter({
                    ...optionFilter,
                    producerProductionIds: e,
                  });
                } else {
                  const temp = optionFilter.producerProductionIds.filter(
                    (item) => item.value !== remove.removedValue.value
                  );
                  setOptionFilter({
                    ...optionFilter,
                    producerProductionIds: temp,
                  });
                }
              }}
              options={producers.results.map((item) => ({
                label: item.name,
                value: item.id,
                code: item.code,
              }))}
              onInputChange={(value) => {
                setProducerSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">Chọn sản phẩm</Label>
            <Select
              isDisabled={_.isEmpty(optionFilter.orderId)}
              placeholder="Nhập để tìm kiếm"
              isClearable={true}
              value={optionFilter.productId}
              onChange={(e) => {
                setOptionFilter({ ...optionFilter, productId: e });
              }}
              options={products.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setProductSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">
              Lọc theo chi nhánh
            </Label>
            <Select
              isDisabled={_.isEmpty(optionFilter.orderId)}
              placeholder="Nhập để tìm kiếm"
              isClearable={true}
              value={optionFilter.customerOrgId}
              onChange={(e) => {
                setOptionFilter({ ...optionFilter, customerOrgId: e });
              }}
              options={customers.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setCustometSearch(value);
              }}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <button
            className="btn btn-secondary btn-md text-sm btn-block"
            onClick={clearFilter}
          >
            Xóa bộ lọc
          </button>
          <LoadingButtonCustom
            className="btn-md text-sm ml-0 text-white"
            onClick={() => {
              // if (_.isEmpty(optionFilter.orderId)) {
              //   return;
              // }
              setFilterValues({ ...optionFilter });
            }}
            loading={isGetting}
            outline={false}
            block={true}
            style={{ background: '#5e72e4' }}
          >
            Áp dụng bộ lọc
          </LoadingButtonCustom>
        </CardFooter>
      </Card>
    </>
  );
};

export default Filter;
