import React, { useState, useEffect, useRef } from 'react';
import { AddSVG } from 'assets/svg';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import InputCustom from 'views/pages/components/InputCustom';
import Select from 'react-select';
import {
  customerActions,
  productActions,
  orderActions,
  accountActions,
} from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import ReactNotificationAlert from 'react-notification-alert';
import { notify } from 'common';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import Error from 'views/pages/components/Error';
import RequireCustom from 'views/pages/components/RequireCustom';
import _ from 'lodash';
import { BinSVG } from 'assets/svg';
import DatePicker from 'reactstrap-date-picker';
import DialogAddProduct from '../DialogAddProduct';

const OrderInfor = ({ orderById, location }) => {
  const orderSchema = yup.object().shape({
    code: yup.string().required('Mã đơn hàng không được để trống!'),
    name: yup.string().required('Tên đơn hàng không được để trống!'),
    manager: yup.string().required('Vui lòng chọn chủ nhiệm quản lý!'),
    totalProducts: yup
      .number()
      .min(0, 'Tổng số lượng sản phẩm theo hợp đồng phải lớn hơn 0!')
      .nullable(),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { accounts } = useSelector((state) => state.accountReducer);
  const { products } = useSelector((state) => state.productReducer);
  const { customers } = useSelector((state) => state.customerReducer);

  const [arrProduct, setArrProduct] = useState([]);
  const [productIds, setProductIds] = useState(['']);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerValue, setCustomerValue] = useState(null);
  const [productTypesSearch, setProductSearch] = useState('');
  const notificationAlertRef = useRef(null);
  const [accountValue, setAccountValue] = useState(null);
  const [accountSearch, setAccountSearch] = useState('');
  const [errorProducts, setErrorProducts] = useState(false);
  const [openDialogAddProduct, setOpenDialogAddProduct] = useState(false);
  const [orderInfo, setOrderInfo] = useState({
    code: '',
    name: '',
    manager: '',
    products: [
      {
        productValue: null,
        productTypeId: '',
        amount: '',
      },
    ],
    startDate: moment().toISOString(),
    endDate: moment().toISOString(),
    guaranteeDate: moment().toISOString(),
    actualDate: moment().toISOString(),
    notes: '',
    totalProducts: 0,
  });
  const [changed, setChanged] = useState({
    manager: false,
  });
  const handleGetAccounts = () => {
    if (accountSearch === '') {
      dispatch(
        accountActions.getAccounts(queryString.stringify({ status: 'active' }))
      );
    } else {
      dispatch(
        accountActions.getAccounts(
          queryString.stringify({ status: 'active', name: accountSearch })
        )
      );
    }
  };

  const handleGetProducts = () => {
    if (productTypesSearch === '') {
      dispatch(
        productActions.getProducts(queryString.stringify({ status: 'active' }))
      );
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({
            status: 'active',
            name: productTypesSearch,
          })
        )
      );
    }
  };

  const handleGetCustomers = () => {
    if (customerSearch === '') {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({ status: 'active' })
        )
      );
    } else {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({
            status: 'active',
            name: customerSearch,
          })
        )
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetAccounts();
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [accountSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetProducts();
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [productTypesSearch]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetCustomers();
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [customerSearch]);

  useEffect(() => {
    if (!_.isEmpty(productIds)) {
      const temp = products.results.filter(
        (item) => !productIds.includes(item.id)
      );
      setArrProduct(temp);
    }
  }, [productIds, products]);

  const clearData = () => {
    setOrderInfo({
      code: '',
      name: '',
      manager: '',
      products: [
        {
          productValue: null,
          productTypeId: '',
          amount: '',
        },
      ],
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
      guaranteeDate: moment().toISOString(),
      actualDate: moment().toISOString(),
      notes: '',
      totalProducts: 0,
    });
    setAccountValue(null);
    setArrProduct(products.results);
    setProductIds(['']);
  };

  const onSubmit = (values) => {
    let check = false;
    values.products.forEach((item) => {
      if (
        item.productTypeId === '' ||
        item.amount === '' ||
        // item.price === "" ||
        item.amount < 0
        // ||
        // item.price < 0
      ) {
        check = true;
      }
    });
    if (check) {
      setErrorProducts(true);
      return;
    }
    delete values.customerOrganizationId;
    dispatch(
      orderActions.updateOrder(
        {
          ...values,
          products: values.products.map((item) => ({
            productTypeId: item.productTypeId,
            amount: item.amount,
            price: item.price === '' ? 0 : item.price,
          })),
        },
        orderById.id,
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật đơn hàng thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật đơn hàng thất bại. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    if (!_.isEmpty(orderById)) {
      // const tempOrderDetails = [];
      // orderById.orderDetails.forEach((item) => {
      //   const tempListProduct = item.listProduct.map((val) => ({
      //     amount: val?.amount,
      //     productTypeId: val?.productTypeId?.id,
      //   }));
      //   tempOrderDetails.push({
      //     customerOrganizationId: item?.customerOrganizationId,
      //     listProduct: tempListProduct,
      //   });
      // });
      setOrderInfo({
        code: orderById?.code,
        name: orderById?.name,
        manager: orderById?.manager?.id,
        products: orderById.products.map((item) => ({
          productValue: {
            label: item?.productTypeId?.name,
            value: item?.productTypeId?.id,
          },
          productTypeId: item?.productTypeId?.id,
          amount: item?.amount,
          price: item?.price,
        })),
        startDate: moment(orderById?.startDate).toISOString(),
        endDate: moment(orderById?.endDate).toISOString(),
        guaranteeDate: moment(orderById?.guaranteeDate).toISOString(),
        actualDate: moment(orderById?.actualDate).toISOString(),
        notes: orderById?.notes,
        customerOrganizationId: orderById?.customerOrganizationId?.id,
        totalProducts: orderById?.totalProducts ?? 0,
        // orderDetails: tempOrderDetails,
        customerIds: orderById?.customerIds,
      });
      setProductIds(
        !_.isEmpty(orderById.products)
          ? orderById.products.map((item) => item?.productTypeId?.id)
          : ['']
      );
      setCustomerValue({
        label: orderById?.customerOrganizationId?.name,
        value: orderById?.customerOrganizationId?.id,
      });
      setAccountValue({
        value: orderById?.manager?.id,
        label: orderById?.manager?.name,
      });
    }
  }, [orderById]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Formik
        initialValues={orderInfo}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={orderSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => {
          return (
            <>
              <Row>
                <Col xs={7} style={{ borderRight: '1px solid #BABCBE' }}>
                  <Row>
                    <Col xs={3} className="h4 font-weight-600">
                      Mã đơn hàng
                      <RequireCustom />
                    </Col>
                    <Col xs={9}>
                      <FormGroup className="mb-3">
                        <InputCustom
                          className="max-height-input-custom"
                          // style={{ maxWidth: 300 }}
                          placeholder="AD123456"
                          type="text"
                          name="code"
                          id="code"
                          onBlur={handleBlur}
                          invalid={touched.code && errors.code}
                          onChange={(e) => {
                            setFieldValue('code', e.target.value);
                          }}
                          messageInvalid={errors.code}
                          value={values.code}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3} className="h4 font-weight-600">
                      Tên đơn hàng
                      <RequireCustom />
                    </Col>
                    <Col xs={9}>
                      <FormGroup className="mb-3">
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập tên"
                          type="text"
                          name="name"
                          id="name"
                          onBlur={handleBlur}
                          invalid={errors.name && touched.name}
                          onChange={(e) => {
                            // setOrderInfo({
                            //   ...orderInfo,
                            //   name: e.target.value,
                            // });
                            setFieldValue('name', e.target.value);
                          }}
                          messageInvalid={errors.name}
                          value={values.name}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col
                      xs={3}
                      style={{ whiteSpace: 'nowrap' }}
                      className="h4 font-weight-600"
                    >
                      Chủ nhiệm quản lý
                      <RequireCustom />
                    </Col>
                    <Col xs={9}>
                      <Select
                        placeholder="Nhập tên tìm kiếm"
                        isClearable={true}
                        value={accountValue}
                        onChange={(e) => {
                          setAccountValue(e);
                          setFieldValue('manager', e ? e.value : '');
                        }}
                        options={accounts.results.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        onInputChange={(value) => {
                          setAccountSearch(value);
                        }}
                        onFocus={() => {
                          setChanged({
                            ...changed,
                            manager: true,
                          });
                        }}
                      />
                      {changed.manager && errors.manager && (
                        <Error messageInvalid={errors.manager} />
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3} className="h4 font-weight-600">
                      Đơn vị
                      <RequireCustom />
                    </Col>
                    <Col xs={9}>
                      <Select
                        isDisabled={true}
                        placeholder="Nhập tên tìm kiếm"
                        isClearable={true}
                        value={customerValue}
                        onChange={(e) => {
                          setCustomerValue(e);
                          setFieldValue(
                            'customerOrganizationId',
                            e ? e.value : ''
                          );
                        }}
                        options={customers.results.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        onInputChange={(value) => {
                          setCustomerSearch(value);
                        }}
                        onFocus={() => {
                          setChanged({
                            ...changed,
                            customerOrganizationId: true,
                          });
                        }}
                      />
                      {changed.customerOrganizationId &&
                        errors.customerOrganizationId && (
                          <Error
                            messageInvalid={errors.customerOrganizationId}
                          />
                        )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3} className="h4 font-weight-600">
                      Ghi chú
                    </Col>
                    <Col md={9}>
                      <FormGroup className="mb-3">
                        <InputCustom
                          placeholder="Nhập ghi chú"
                          type="textarea"
                          rows="4"
                          name="notes"
                          id="notes"
                          onBlur={handleBlur}
                          invalid={errors.notes && touched.notes}
                          onChange={(e) => {
                            setFieldValue('notes', e.target.value);
                          }}
                          messageInvalid={errors.notes}
                          value={values.notes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={3} className="h4 font-weight-600">
                      Tổng SL sản phẩm
                      <RequireCustom />
                    </Col>
                    <Col xs={9}>
                      <FormGroup className="mb-3">
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập số lượng trên hợp hợp đồng"
                          type="number"
                          name="totalProducts"
                          id="totalProducts"
                          onBlur={handleBlur}
                          invalid={
                            errors.totalProducts && touched.totalProducts
                          }
                          onChange={handleChange}
                          messageInvalid={errors.totalProducts}
                          value={values.totalProducts}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-end pb-3" md={12}>
                      <Button
                        onClick={() => setOpenDialogAddProduct(true)}
                        size="sm"
                        className="d-flex align-items-center"
                      >
                        <AddSVG />
                        {'  '}Thêm mới sản phẩm
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <label className="form-control-label">
                        Chọn sản phẩm
                        <RequireCustom />
                      </label>
                    </Col>
                    <Col md={3}>
                      <label className="form-control-label">Đơn giá</label>
                    </Col>
                    {/* <Col md={2}>
                      <label style={{whiteSpace: 'nowrap'}} className="form-control-label">
                        Số lượng may<RequireCustom />
                      </label>
                    </Col> */}
                    <Col md={3}>
                      <label
                        style={{ whiteSpace: 'nowrap' }}
                        className="form-control-label"
                      >
                        Số lượng may/người
                        <RequireCustom />
                      </label>
                    </Col>
                    <Col md={1}></Col>
                  </Row>
                  {values.products.map((item, index) => {
                    return (
                      <Row key={index}>
                        <Col md={12}>
                          <Row>
                            <Col xs={5}>
                              {/* <label className="form-control-label">
                                Chọn sản phẩm
                                <RequireCustom />
                              </label> */}
                              <Select
                                placeholder="Lựa chọn"
                                isClearable={true}
                                value={item.productValue}
                                onChange={(e) => {
                                  setFieldValue('products', [
                                    ...values.products.slice(0, index),
                                    {
                                      ...values.products[index],
                                      productTypeId: !!e ? e.value : '',
                                      productValue: e,
                                    },
                                    ...values.products.slice(index + 1),
                                  ]);
                                  setProductIds([
                                    ...productIds.slice(0, index),
                                    !!e ? e.value : '',
                                    ...productIds.slice(index + 1),
                                  ]);
                                }}
                                options={arrProduct.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                                onInputChange={(value) => {
                                  setProductSearch(value);
                                }}
                              />
                            </Col>
                            <Col xs={3}>
                              <Row className="mx-0 ">
                                <FormGroup className="mb-3">
                                  <InputCustom
                                    className="max-height-input-custom"
                                    placeholder="Nhập"
                                    // label="Đơn giá"
                                    type="number"
                                    // invalid={
                                    //   item.price < 0 || item.price === ""
                                    // }
                                    onChange={(e) => {
                                      setFieldValue('products', [
                                        ...values.products.slice(0, index),
                                        {
                                          ...values.products[index],
                                          price:
                                            e.target.value === ''
                                              ? ''
                                              : Number(e.target.value) < 0
                                              ? ''
                                              : Number(e.target.value),
                                        },
                                        ...values.products.slice(index + 1),
                                      ]);
                                    }}
                                    value={item.price}
                                    style={{ maxWidth: 80 }}
                                  />
                                </FormGroup>
                              </Row>
                            </Col>
                            <Col xs={3}>
                              <Row
                                className="mx-0 "
                                style={{
                                  alignItems: 'center',
                                }}
                              >
                                <FormGroup className="mb-3">
                                  <InputCustom
                                    className="max-height-input-custom"
                                    placeholder="Nhập"
                                    // label="Số lượng may"
                                    required={<RequireCustom />}
                                    type="number"
                                    invalid={
                                      item.amount < 0 || item.amount === ''
                                    }
                                    onChange={(e) => {
                                      setFieldValue('products', [
                                        ...values.products.slice(0, index),
                                        {
                                          ...values.products[index],
                                          amount:
                                            e.target.value === ''
                                              ? ''
                                              : Number(e.target.value) < 0
                                              ? ''
                                              : Number(e.target.value),
                                        },
                                        ...values.products.slice(index + 1),
                                      ]);
                                    }}
                                    value={item.amount}
                                    style={{ maxWidth: 80 }}
                                  />
                                </FormGroup>
                              </Row>
                            </Col>
                            {/* <Col xs={3}>
                              <Row
                                className="mx-0 "
                                style={{
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <FormGroup className="mb-3">
                                  <InputCustom
                                    className="max-height-input-custom"
                                    placeholder="Nhập"
                                    type="number"
                                    invalid={
                                      item.amount < 0 || item.amount === ""
                                    }
                                    onChange={(e) => {
                                      setFieldValue("products", [
                                        ...values.products.slice(0, index),
                                        {
                                          ...values.products[index],
                                          amount:
                                            e.target.value === ""
                                              ? ""
                                              : Number(e.target.value) < 0
                                              ? ""
                                              : Number(e.target.value),
                                        },
                                        ...values.products.slice(index + 1),
                                      ]);
                                    }}
                                    value={""}
                                  />
                                </FormGroup>
                              </Row>
                            </Col> */}
                            <Col
                              className="d-flex align-items-center justify-content-end"
                              xs={1}
                            >
                              <div
                                // className="ml-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setFieldValue('products', [
                                    ...values.products.slice(0, index),
                                    ...values.products.slice(index + 1),
                                  ]);
                                }}
                              >
                                {values.products.length !== 1 && <BinSVG />}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="pr-3" style={{ justifyContent: 'flex-end' }}>
                    <span
                      onClick={() => {
                        setFieldValue('products', [
                          ...values.products,
                          {
                            productValue: null,
                            productTypeId: '',
                            amount: '',
                          },
                        ]);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <AddSVG />
                    </span>
                    <Col md="12">
                      {errorProducts && (
                        <Error messageInvalid="Vui lòng nhập đầy đủ thông tin dòng sản phẩm!" />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  <p className=" h5 text-muted text-uppercase">Thời gian</p>
                  <Row className="mb-3">
                    <Col xs={6}>
                      <p className="h5 font-weight-600">
                        Thời gian bắt đầu đi đo
                      </p>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        calendarPlacement="bottom"
                        value={values.startDate}
                        onChange={(v, f) => {
                          setFieldValue('startDate', v);
                        }}
                      />
                    </Col>
                    <Col xs={6}>
                      <p className="h5 font-weight-600">Thời gian trả hàng</p>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        calendarPlacement="bottom"
                        value={values.endDate}
                        onChange={(v, f) => {
                          setFieldValue('endDate', v);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <p className="h5 font-weight-600">Thời gian bảo hành</p>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        calendarPlacement="bottom"
                        value={values.guaranteeDate}
                        onChange={(v, f) => {
                          setFieldValue('guaranteeDate', v);
                        }}
                      />
                    </Col>
                    <Col xs={6}>
                      <p className="h5 font-weight-600">
                        Thời gian hoàn thành chỉnh sửa
                      </p>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        calendarPlacement="bottom"
                        value={values.actualDate}
                        onChange={(v, f) => {
                          setFieldValue('actualDate', v);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="text-md-right mt-3">
                <Button
                  onClick={() => {
                    history.push('/order-manage', { ...location?.state });
                  }}
                >
                  Quay lại
                </Button>
                <Button
                  onClick={() => {
                    setChanged({
                      manager: true,
                    });

                    let check = false;
                    values.products.forEach((item) => {
                      if (
                        item.productTypeId === '' ||
                        item.amount === '' ||
                        // item.price === "" ||
                        item.amount < 0
                        // ||
                        // item.price < 0
                      ) {
                        check = true;
                      }
                    });
                    if (check) {
                      setErrorProducts(true);
                    }

                    handleSubmit();
                  }}
                  color="primary"
                >
                  Lưu lại
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
      {openDialogAddProduct && (
        <DialogAddProduct
          open={openDialogAddProduct}
          setOpen={setOpenDialogAddProduct}
        />
      )}
    </>
  );
};

export default OrderInfor;
