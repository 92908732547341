import React, { useState, useEffect, useRef } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  UncontrolledTooltip,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Style } from '../style';
import BootstrapTable from 'react-bootstrap-table-next';
import { EditSVG } from 'assets/svg';
import { ViewSVG } from 'assets/svg';
import { BinSVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { nplOrderActions, orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import FormNplOrder from '../FormNplOrder';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import Select from 'react-select';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import { notify } from 'common';
import NplOrderDetail from '../NplOrderDetail';
import FormNplOrderDetail from '../NplOrderDetail';
import CONSTANTS from 'constant';
import { checkRole } from 'common';

const Materials = () => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const { isGetNplOrders, nplOrders, isDeleteNplOrder } = useSelector(
    (state) => state.nplOrderReducer
  );
  const dispatch = useDispatch();

  const notificationAlertRef = useRef(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [formModalDetail, setFormModalDetail] = useState(false);
  const [isFormAdd, setIsFormAdd] = useState(false);
  const [nplOrder, setNplOrder] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstCalled, setFirstCalled] = useState(false);
  const [searchValues, setSearchValues] = useState({
    code: '',
    name: '',
  });
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    sortBy: 'createdAt:desc',
    populate:
      'orderId, createdBy, updatedBy, orderPlanId.orderId,suggestDetails.materialId.unitId',
  });
  const [queryOrders, setQueryOrders] = useState({
    page: 1,
    sortBy: 'createdAt:desc',
    limit: 10,
    // orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.Accept,
  });
  const [orderSearch, setOrderSearch] = useState('');
  const [orderValue, setOrderValue] = useState(null);
  const [orders, setOrders] = useState({ results: [] });

  const boxAction = (cell, row, id) => {
    return (
      <>
        <button
          id={'view' + id}
          onClick={() => {
            setFormModalDetail(true);
            setNplOrder(row);
          }}
          className="btn-none"
        >
          <ViewSVG />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
          Xem chi tiết
        </UncontrolledTooltip>
        {checkRole(currentAccount, { roles: ['manage_material'] }) && (
          <>
            <button
              id={'edit' + id}
              onClick={() => {
                // history.push(`/norm-materials/update/${row.id}`);
                setFormModal(true);
                setIsFormAdd(false);
                setNplOrder(row);
              }}
              className="btn-none"
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Cập nhật đề xuất NPL
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccount, { roles: ['delete_material'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                setNplOrder(row);
                setNotificationModal(true);
              }}
              id={'delete' + id}
            >
              <BinSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + id}
            >
              Xóa đề xuất
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: 'code',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.code}
            onChange={(e) =>
              setSearchValues({ ...searchValues, code: e.target.value })
            }
            placeholder="Mã đề xuất"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.name}
            onChange={(e) =>
              setSearchValues({ ...searchValues, name: e.target.value })
            }
            placeholder="Tên đề xuất"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Ngày cập nhật',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'suggestDate',
      text: 'Ngày đề xuất',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actualDate',
      text: 'Ngày thu mua thực tế',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'suggestExportDate',
      text: 'Ngày xuất mẫu',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'createdBy.name',
      text: 'Người đề xuất',
      formatter: (cell) => {
        return cell ? cell : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: nplOrders?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > nplOrders.totalResults
              ? !isNaN(nplOrders?.totalResults)
                ? nplOrders.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(nplOrders?.totalResults) ? nplOrders.totalResults : 0} bản
            ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetNplOrders = () => {
    const tmpQ = _.cloneDeep(query);
    if (_.isEmpty(tmpQ.orderId)) {
      delete tmpQ.orderId;
    }
    dispatch(
      nplOrderActions.getNplOrders(queryString.stringify({ ...tmpQ }), {
        success: () => {
          setFirstCalled(true);
        },
      })
    );
  };

  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(
        orderActions.getOrders(queryString.stringify(queryOrders), {
          success: (data) => {
            setOrders(data);
          },
        })
      );
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrders, name: orderSearch }),
          {
            success: (data) => {
              setOrders(data);
            },
          }
        )
      );
    }
  };

  const handleDelete = () => {
    if (_.isEmpty(nplOrder)) return;
    dispatch(
      nplOrderActions.deleteNplOrder(nplOrder.id, {
        success: () => {
          setNotificationModal(false);
          setNplOrder({});
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa đề xuất thành công!`
          );
          handleGetNplOrders();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa đề xuất thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  useEffect(() => {
    handleGetNplOrders();
  }, [query]);

  useEffect(() => {
    // if (!_.isEmpty(orderValue)) {
    setQuery({
      ...query,
      orderId: !_.isEmpty(orderValue) ? orderValue.value : '',
    });
    // }
  }, [orderValue]);

  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  const handleSearch = () => {
    const tempSearch = { ...searchValues };
    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      nplOrderActions.getNplOrders(
        queryString.stringify({ ...query, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <Style>
      {formModal && (
        <FormNplOrder
          open={formModal}
          setFormModal={setFormModal}
          isFormAdd={isFormAdd}
          nplOrder={nplOrder}
          notificationAlertRef={notificationAlertRef}
          handleGetNplOrders={handleGetNplOrders}
        />
      )}
      {formModalDetail && (
        <FormNplOrderDetail
          open={formModalDetail}
          setFormModalDetail={setFormModalDetail}
          nplOrder={nplOrder}
          notificationAlertRef={notificationAlertRef}
          handleGetNplOrders={handleGetNplOrders}
        />
      )}
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="đề xuất"
          func={handleDelete}
          isDelete={isDeleteNplOrder}
        />
      )}
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <Row className="align-items-center">
                  <Col md="12">
                    <p className="text-info mb-2 font-weight-500">
                      Vui lòng chọn đơn hàng, sau đó thao tác với đề xuất nguyên
                      phụ liệu của đơn hàng đó
                    </p>
                  </Col>
                  <Col md={3} className="pr-0">
                    <h5 className="text-info mb-0">Đơn hàng đang chọn: </h5>
                  </Col>
                  <Col md={9}>
                    <div>
                      <Select
                        className="select-custom"
                        size="sm"
                        placeholder="Chọn đơn hàng"
                        isClearable={true}
                        value={orderValue}
                        onChange={(e) => {
                          setOrderValue(e);
                        }}
                        options={orders.results.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onInputChange={(value) => {
                          setOrderSearch(value);
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() => null}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                {!!orderValue && (
                  <Button
                    onClick={() => {
                      setIsFormAdd(true);
                      setFormModal(true);
                    }}
                    className="btn-neutral"
                    color="default"
                    size="sm"
                  >
                    Đề xuất NPL
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="pb-3">
              <ToolkitProvider
                data={nplOrders.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">
                            Danh sách nguyên phụ liệu cho đơn hàng
                          </h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      filter={filterFactory()}
                      pagination={pagination}
                      bordered={false}
                      hover
                      remote
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </Style>
  );
};

export default Materials;
