import TypeActions from "../TypeActions";

export const getProductionCommands = (params, callback) => {
  return {
    type: TypeActions.GET_PRODUCTION_COMMANDS_REQUEST,
    params,
    callback,
  };
};

export const getProductionCommandsError = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTION_COMMANDS_ERROR_REQUEST,
    query,
    callback,
  };
};

export const getProductionCommandById = (params, query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTION_COMMAND_BY_ID_REQUEST,
    params,
    query,
    callback,
  };
};

export const createProductionCommand = (body, callback) => {
  return {
    type: TypeActions.CREATE_PRODUCTION_COMMAND_REQUEST,
    body,
    callback,
  };
};
export const updateProductionCommand = (body, params, callback) => {
  return {
    type: TypeActions.UPDATE_PRODUCTION_COMMAND_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteProductionCommand = (params, callback) => {
  return {
    type: TypeActions.DELETE_PRODUCTION_COMMAND_REQUEST,
    params,
    callback,
  };
};

export const excludeCustomerCommand = (params, body, callback) => {
  return {
    type: TypeActions.EXCLUDE_CUSTOMER_COMMAND_REQUEST,
    params,
    body,
    callback,
  };
};
export const exportProductionCommand = (params, callback) => {
  return {
    type: TypeActions.EXPORT_PRODUCTION_COMMAND_REQUEST,
    params,
    callback,
  };
};

export const getMaxProduct = (query, callback) => {
  return {
    type: TypeActions.GET_MAX_PRODUCT_REQUEST,
    query,
    callback,
  };
};

export default {
  getProductionCommands,
  createProductionCommand,
  updateProductionCommand,
  deleteProductionCommand,
  getProductionCommandById,
  excludeCustomerCommand,
  getProductionCommandsError,
  getMaxProduct,
};
