import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Modal,
	Label,
	Button,
	Container,
} from "reactstrap";
import LoadingButtonCustom from "views/pages/components/LoadingButtonCustom";
import Select from "react-select";
import _ from "lodash";
import { notify } from "common";
import CONSTANTS from "constant";
import { deliveryActions } from "Redux/Actions";
import { useSelector, useDispatch } from "react-redux";

const ModalCoordinator = ({
	setIsOpen,
	isOpen,
	selectItems,
	setSelectItems,
	notificationAlertRef,
	barcodes,
	setBarcodes,
	handleGetDeliveries,
}) => {
	const { isCoordinatorDelivery } = useSelector(
		(state) => state.deliveryReducer
	);
	const dispatch = useDispatch();
	const [commandValue, setCommandValue] = useState({
		label: "Kiểm tra chất lượng (QC)",
		value: CONSTANTS.TRANSPORT_STATUS.QC,
	});
	const [commands, setCommands] = useState([
		{
			label: "Kiểm tra chất lượng (QC)",
			value: CONSTANTS.TRANSPORT_STATUS.QC,
		},
		{
			label: "Hoàn thiện công đoạn",
			value: CONSTANTS.TRANSPORT_STATUS.STAGE,
		},
		{
			label: "Điều phối trả khách",
			value: CONSTANTS.TRANSPORT_STATUS.DELIVERING,
		},
	]);

	useEffect(() => {
		setCommands(
			commands.map((item) => ({
				label: `${item.label} (${selectItems.length})`,
				value: item.value,
			}))
		);
		setCommandValue({
			label: `Kiểm tra chất lượng (QC) (${selectItems.length})`,
			value: CONSTANTS.TRANSPORT_STATUS.QC,
		});
	}, [selectItems]);

	const handleCoordinator = () => {
		if (selectItems.length === 0) {
			notify(
				notificationAlertRef,
				"warning",
				"Thông báo",
				`Vui lòng chọn sản phẩm để điều phối!`
			);
			return;
		}
		dispatch(
			deliveryActions.coordinatorDelivery(
				{
					barcodes: barcodes,
					transportStatus: commandValue.value,
				},
				{
					success: () => {
						notify(
							notificationAlertRef,
							"success",
							"Thông báo",
							`Điều phối sản phẩm thành công!`
						);
						setBarcodes([]);
						setSelectItems([]);
						handleGetDeliveries();
						setIsOpen(false);
					},
					failed: (mess) => {
						notify(
							notificationAlertRef,
							"danger",
							"Thông báo",
							`Điều phối sản phẩm thất bại. Lỗi: ${mess}!`
						);
					},
				}
			)
		);
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				size="md"
				isOpen={isOpen}
				toggle={() => {
					setIsOpen(false);
				}}
			>
				<div className="modal-body p-0">
					{/* <Container> */}
					<Card className="border-0 mb-0">
						<CardHeader className="bg-transparent pb-2">
							<h2 className="mb-0">Điều phối</h2>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="4">
									<Label className="form-control-label text-sm">
										Lựa chọn lệnh
									</Label>
								</Col>
								<Col md="8">
									<Select
										placeholder="Chọn lệnh"
										isClearable={false}
										value={commandValue}
										onChange={(e) => {
											setCommandValue(e);
										}}
										options={commands}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-5">
								<Button
									onClick={() => {
										setIsOpen(false);
									}}
								>
									Hủy
								</Button>
								<LoadingButtonCustom
									size="md"
									onClick={handleCoordinator}
									loading={isCoordinatorDelivery}
									color="primary"
								>
									Xác nhận
								</LoadingButtonCustom>
							</Row>
						</CardBody>
					</Card>
					{/* </Container> */}
				</div>
			</Modal>

			{/* </Container> */}
		</>
	);
};

export default ModalCoordinator;
