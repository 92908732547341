import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  DropdownItem,
  CardBody,
  Card,
} from "reactstrap";
import UploadFileCustom from "views/pages/components/UploadFileCustom";
import _ from "lodash";

const DetailProduct = ({ open, toggle, data }) => {
  return (
    <Modal size="lg" isOpen={open} toggle={toggle}>
      <ModalHeader className="pb-0">
        <p className="h3 text-uppercase">Chi tiết sản phẩm</p>
      </ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Row>
          <Col xs={7}>
            <Row>
              <Col md={3} className="h3 text-sm mt-3 font-weight-500">
                Mã sản phẩm
              </Col>
              <Col md={9} className="text-sm mt-3">
                : {data?.code}
              </Col>
            </Row>
            <Row>
              <Col md={3} className="h3 text-sm mt-3 font-weight-500">
                Tên sản phẩm
              </Col>
              <Col md={9} className="text-sm mt-3">
                : {data?.name}
              </Col>
            </Row>
            <Row>
              <Col md={3} className="h3 text-sm mt-3 font-weight-500">
                Dòng sản phẩm
              </Col>
              <Col md={9} className="text-sm mt-3">
                : {data?.parentId?.name}
              </Col>
            </Row>
            <Row>
              <Col md={3} className="h3 text-sm mt-3 font-weight-500">
                Mô tả
              </Col>
              <Col md={9} className="text-sm mt-3">
                : {data?.notes}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="h3 text-sm mt-3 font-weight-500">
                Các vị trí đo:
              </Col>
              <Col md={12} className="text-sm">
                {!_.isEmpty(data?.productParameterIds) &&
                  data.productParameterIds.map((item, index) =>
                    data.productParameterIds.length - 1 === index
                      ? `${item.name}.`
                      : `${item.name}, `
                  )}
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <Card className="mb-0">
              <CardBody>
                <p className="h3 text-sm font-weight-500">Ảnh mô tả</p>
                <DropdownItem divider />
                <UploadFileCustom handleGetImage={() => {}} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center pt-0">
        <Button onClick={toggle}>Đóng</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DetailProduct;
