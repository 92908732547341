import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import roleReducer from './roleReducer';
import customerReducer from './customerReducer';
import producerReducer from './producerReducer';
import nplReducer from './nplReducer';
import orderReducer from './orderReducer';
import productTypesReducer from './productTypesReducer';
import productReducer from './productReducer';
import measurementStandardsReducer from './measurementStandardsReducer';
import orderPlanReducer from './orderPlanReducer';
import provincesReducer from './provincesReducer';
import measureSizeReducer from './measureSizeReducer';
import unitReducer from './unitReducer';
import materialNormReducer from './materialNormReducer';
import nplOrderReducer from './nplOrderReducer';
import productionCommandReducer from './productionCommandReducer';
import deliveryReducer from './deliveryReducer';
import qualityReducer from './qualityReducer';
import completeStageReducer from './completeStageReducer';
import exchangeHistoryReducer from './exchangeHistoryReducer';
import reportsReducer from './reportsReducer';
import exportReducer from './exportReducer';
import syntheticReducer from './syntheticReducer';
const rootReducers = combineReducers({
  accountReducer,
  roleReducer,
  customerReducer,
  producerReducer,
  nplReducer,
  orderReducer,
  productTypesReducer,
  productReducer,
  measurementStandardsReducer,
  orderPlanReducer,
  provincesReducer,
  measureSizeReducer,
  unitReducer,
  materialNormReducer,
  nplOrderReducer,
  productionCommandReducer,
  deliveryReducer,
  qualityReducer,
  completeStageReducer,
  exchangeHistoryReducer,
  reportsReducer,
  exportReducer,
  syntheticReducer,
});
export default rootReducers;
