import React, { useState, useEffect, useRef } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  CardBody,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import { notify } from 'common';
import { Style } from './style';
import Filter from './components/Filter';
import { completeStageActions, exportActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import Header from './components/Header';
import FileDownload from 'js-file-download';

const ManagePayingCustomer = () => {
  const { productsDelivering } = useSelector(
    (state) => state.completeStageReducer
  );
  const dispatch = useDispatch();
  const [sectionState, setSectionState] = useState(0);
  const history = useHistory();
  const [currentOrders, setCurrentOrders] = useState({});
  const notificationAlertRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterBy, setFilterBy] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });
  const [filterByName, setFilterByName] = useState({});
  const [firstCalled, setFirstCalled] = useState(false);
  const [valueFilter, setValueFilter] = useState({
    barcode: '',
  });
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transportStatus, setTransportStatus] = useState(
    CONSTANTS.TRANSPORT_STATUS.DELIVERING
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    transportStatus: transportStatus,
    populate:
      'customerOrgId, productId, orderId, productionCommandId.producerCutId producerProductionId',
  });
  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getProductsDelivering(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const changeSectionState = (number) => {
    setSectionState(number);
  };
  const handleFilter = (values) => {
    setFilterBy({ ...values });
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(values).map((key, index) => {
      if (!_.isEmpty(values[key])) {
        tempFilter[key] = values[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getProductsDelivering(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  const columns = [
    {
      dataField: 'barcode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.barcode}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, barcode: e.target.value })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',

      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'orderId.name',
      text: 'Đơn hàng',

      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
    },
    {
      dataField: 'amount',
      text: `Số lượng (${productsDelivering?.totalResults})`,
      style: { textAlign: 'center' },
      headerAlign: 'center',
      formatter: (cell) => {
        return 1;
      },
    },
    {
      dataField: 'productionCommandId.producerCutId',
      text: 'Nhà gia công',
      formatter: (cell, row) => {
        return !!cell?.name ? (
          <>
            <span className="font-weight-600">Cắt: </span>
            {cell.name}, <span className="font-weight-600">May: </span>{' '}
            {row?.productionCommandId?.producerProductionId?.name}
          </>
        ) : null;
      },
    },
    {
      dataField: 'sendDate',
      text: 'Ngày nhận SP',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
    {
      dataField: 'qcDate',
      text: 'Ngày trả khách',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: productsDelivering?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > productsDelivering.totalResults
              ? !isNaN(productsDelivering?.totalResults)
                ? productsDelivering.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(productsDelivering?.totalResults)
              ? productsDelivering.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetProductsDelivering = () => {
    let payload = _.cloneDeep(query);
    if (!_.isEmpty(currentOrders)) {
      payload = { ...payload, orderId: currentOrders };
    }
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getProductsDelivering(
        queryString.stringify({ ...payload, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setFirstCalled(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    const tempFilter = {};
    Object.keys(filterBy).map((key, index) => {
      if (!_.isEmpty(filterBy[key])) {
        tempFilter[key] = filterBy[key].label;
      }
    });
    setFilterByName(tempFilter);
  }, [filterBy]);

  useEffect(() => {
    handleGetProductsDelivering();
  }, [query]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [valueFilter]);

  const handleExportProductSendCustomer = () => {
    // const id =
    dispatch(
      exportActions.exportProductSendCustomer(
        '',
        queryString.stringify({
          transportStatus: 5,
        }),
        {
          success: (data) => {
            const filename = `DANH_SACH_SAN_PHAM_TRA_KHACH.xlsx`;
            FileDownload(data, filename);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xuất danh sách sản phẩm trả khách thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xuất danh sách sản phẩm trả khách thất bại. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <Header
        filterByName={filterByName}
        toggle={() => {
          setOpenFilter(true);
        }}
        // setCurrentOrders={setCurrentOrders}
        // handleTransportCustomers={() => {
        // 	history.push("/form-transfer-customer");
        // }}
        options={filterValues}
        handleExportProductSendCustomer={handleExportProductSendCustomer}
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={productsDelivering.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <CardHeader>
                      <Row>
                        <Col className="d-flex align-items-center" md="8">
                          <h3 className="mb-0">Danh sách sản phẩm trả khách</h3>
                        </Col>
                        <Col
                          className="d-flex justify-content-end"
                          md="4"
                        ></Col>
                      </Row>
                      {/* <div className="d-flex">
                        <div
                          onClick={() => {
                            changeSectionState(0);
                          }}
                          className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                            sectionState === 0 ? "nav-active" : ""
                          }`}
                        >
                          <FileClipboardBlockSVG />
                          <p
                            className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                              sectionState !== 0 ? "text-muted" : ""
                            }`}
                          >
                            Danh sách sản phẩm đang trả khách
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            changeSectionState(1);
                          }}
                          className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                            sectionState === 1 ? "nav-active" : ""
                          }`}
                        >
                          <FileClipboardCheckSVG />
                          <p
                            className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                              sectionState !== 1 ? "text-muted" : ""
                            }`}
                          >
                            Danh sách sản phẩm đã trả khách
                          </p>
                        </div>
                      </div> */}
                    </CardHeader>

                    <CardBody className="pt-0">
                      <BootstrapTable
                        {...props.baseProps}
                        noDataIndication={() => {
                          return (
                            <span className="font-weight-bold text-danger">
                              Không có dữ liệu!
                            </span>
                          );
                        }}
                        onTableChange={() => {
                          return <div className="spinner-border text-info" />;
                        }}
                        filter={filterFactory()}
                        pagination={pagination}
                        bordered={false}
                        hover
                        remote
                      />
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {openFilter && (
        <Filter
          openFilter={openFilter}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
        />
      )}
    </Style>
  );
};

export default ManagePayingCustomer;
