import TypeActions from "../TypeActions";

const initialState = {
	deliveries: {
		results: [],
	},
	productsErrorFromCustomerDeliveries: {
		results: [],
	},
	productsFaultPaidCustomer: {
		results: [],
	},
	deliveryById: {},
	customerByBarcode: {},
	productFaultPaidCustomerByBarcode: {},
	isGetDeliveries: false,
	isGetProductErrorFromCustomerDeliveries: false,
	isGetProductsFaultPaidCustomer: false,
	isGetDeliveryById: false,
	isCreateDelivery: false,
	isUpdateDelivery: false,
	isDeleteDelivery: false,
	isGetCustomerByBarcode: false,
	isCoordinatorDelivery: false,
	isReceiveFromGC: false,
	isReceiveFromCustomer: false,
	isGetProductFaultPaidCustomerByBarcode: false,
	errors: {
		getDeliveries: "",
		getProductsFaultPaidCustomer: "",
		getdeliveryById: "",
		createDelivery: "",
		updateDelivery: "",
		deleteDelivery: "",
		getCustomerByBarcode: "",
		coordinatorDelivery: "",
		receiveFromGC: "",
		receiveFromCustomer: "",
		getProductErrorFromCustomerDeliveries: "",
		getProductFaultPaidCustomerByBarcode: "",
	},
};

export const deliveryReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case TypeActions.GET_DELIVERIES_REQUEST:
			return {
				...state,
				// deliveries: { results: [] },
				isGetDeliveries: true,
				errors: { ...state.errors, getDeliveries: "" },
			};
		case TypeActions.GET_DELIVERIES_SUCCESS:
			return {
				...state,
				deliveries: actions.data || { results: [] },
				isGetDeliveries: false,
				errors: { ...state.errors, getDeliveries: "" },
			};
		case TypeActions.GET_DELIVERIES_FAILED:
			return {
				...state,
				deliveries: { results: [] },
				isGetDeliveries: false,
				errors: { ...state.errors, getDeliveries: actions.error },
			};

		case TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_REQUEST:
			return {
				...state,
				// productsFaultPaidCustomer: { results: [] },
				isGetProductsFaultPaidCustomer: true,
				errors: { ...state.errors, getProductsFaultPaidCustomer: "" },
			};
		case TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_SUCCESS:
			return {
				...state,
				productsFaultPaidCustomer: actions.data || { results: [] },
				isGetProductsFaultPaidCustomer: false,
				errors: { ...state.errors, getProductsFaultPaidCustomer: "" },
			};
		case TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_FAILED:
			return {
				...state,
				productsFaultPaidCustomer: { results: [] },
				isGetProductsFaultPaidCustomer: false,
				errors: {
					...state.errors,
					getProductsFaultPaidCustomer: actions.error,
				},
			};

		case TypeActions.GET_DELIVERY_BY_ID_REQUEST:
			return {
				...state,
				// deliveryById: {},
				isGetDeliveryById: true,
				errors: { ...state.errors, getdeliveryById: "" },
			};
		case TypeActions.GET_DELIVERY_BY_ID_SUCCESS:
			return {
				...state,
				deliveryById: actions.data || {},
				isGetDeliveryById: false,
				errors: { ...state.errors, getdeliveryById: "" },
			};
		case TypeActions.GET_DELIVERY_BY_ID_FAILED:
			return {
				...state,
				deliveryById: {},
				isGetDeliveryById: false,
				errors: { ...state.errors, getdeliveryById: actions.error },
			};

		case TypeActions.CREATE_DELIVERY_REQUEST:
			return {
				...state,
				isCreateDelivery: true,
				errors: { ...state.errors, createDelivery: "" },
			};
		case TypeActions.CREATE_DELIVERY_SUCCESS:
			return {
				...state,
				isCreateDelivery: false,
				errors: { ...state.errors, createDelivery: "" },
			};
		case TypeActions.CREATE_DELIVERY_FAILED:
			return {
				...state,
				isCreateDelivery: false,
				errors: { ...state.errors, createDelivery: actions.error },
			};

		case TypeActions.UPDATE_DELIVERY_REQUEST:
			return {
				...state,
				isUpdateDelivery: true,
				errors: { ...state.errors, updateDelivery: "" },
			};
		case TypeActions.UPDATE_DELIVERY_SUCCESS:
			return {
				...state,
				isUpdateDelivery: false,
				errors: { ...state.errors, updateDelivery: "" },
			};
		case TypeActions.UPDATE_DELIVERY_FAILED:
			return {
				...state,
				isUpdateDelivery: false,
				errors: { ...state.errors, updateDelivery: actions.error },
			};

		case TypeActions.DELETE_DELIVERY_REQUEST:
			return {
				...state,
				isDeleteDelivery: true,
				errors: { ...state.errors, deleteDelivery: "" },
			};
		case TypeActions.DELETE_DELIVERY_SUCCESS:
			return {
				...state,
				isDeleteDelivery: false,
				errors: { ...state.errors, deleteDelivery: "" },
			};
		case TypeActions.DELETE_DELIVERY_FAILED:
			return {
				...state,
				isDeleteDelivery: false,
				errors: { ...state.errors, deleteDelivery: actions.error },
			};

		case TypeActions.GET_CUSTOMER_BY_BARCODE_REQUEST:
			return {
				...state,
				// customerByBarcode: {},
				isGetCustomerByBarcode: true,
				errors: { ...state.errors, getCustomerByBarcode: "" },
			};
		case TypeActions.GET_CUSTOMER_BY_BARCODE_SUCCESS:
			return {
				...state,
				customerByBarcode: actions.data || {},
				isGetCustomerByBarcode: false,
				errors: { ...state.errors, getCustomerByBarcode: "" },
			};
		case TypeActions.GET_CUSTOMER_BY_BARCODE_FAILED:
			return {
				...state,
				customerByBarcode: {},
				isGetCustomerByBarcode: false,
				errors: { ...state.errors, getCustomerByBarcode: actions.error },
			};

		case TypeActions.COORDINATOR_DELIVERY_REQUEST:
			return {
				...state,
				isCoordinatorDelivery: true,
				errors: { ...state.errors, coordinatorDelivery: "" },
			};
		case TypeActions.COORDINATOR_DELIVERY_SUCCESS:
			return {
				...state,
				isCoordinatorDelivery: false,
				errors: { ...state.errors, coordinatorDelivery: "" },
			};
		case TypeActions.COORDINATOR_DELIVERY_FAILED:
			return {
				...state,
				isCoordinatorDelivery: false,
				errors: { ...state.errors, coordinatorDelivery: actions.error },
			};

		case TypeActions.RECEIVE_FORM_GC_REQUEST:
			return {
				...state,
				isReceiveFromGC: true,
				errors: { ...state.errors, receiveFromGC: "" },
			};
		case TypeActions.RECEIVE_FORM_GC_SUCCESS:
			return {
				...state,
				isReceiveFromGC: false,
				errors: { ...state.errors, receiveFromGC: "" },
			};
		case TypeActions.RECEIVE_FORM_GC_FAILED:
			return {
				...state,
				isReceiveFromGC: false,
				errors: { ...state.errors, receiveFromGC: actions.error },
			};

		case TypeActions.RECEIVE_FROM_CUSTOMER_REQUEST:
			return {
				...state,
				isReceiveFromCustomer: true,
				error: {
					...state.errors,
					receiveFromCustomer: "",
				},
			};
		case TypeActions.RECEIVE_FROM_CUSTOMER_SUCCESS:
			return {
				...state,
				isReceiveFromCustomer: false,
				error: {
					...state.errors,
					receiveFromCustomer: "",
				},
			};
		case TypeActions.RECEIVE_FROM_CUSTOMER_FAILED:
			return {
				...state,
				isReceiveFromCustomer: false,
				error: {
					...state.errors,
					receiveFromCustomer: actions.error,
				},
			};

		case TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_REQUEST:
			return {
				...state,
				// productsErrorFromCustomerDeliveries: { results: [] },
				isGetProductErrorFromCustomerDeliveries: true,
				errors: { ...state.errors, getProductErrorFromCustomerDeliveries: "" },
			};
		case TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_SUCCESS:
			return {
				...state,
				productsErrorFromCustomerDeliveries: actions.data || { results: [] },
				isGetProductErrorFromCustomerDeliveries: false,
				errors: { ...state.errors, getProductErrorFromCustomerDeliveries: "" },
			};
		case TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_FAILED:
			return {
				...state,
				productsErrorFromCustomerDeliveries: { results: [] },
				isGetProductErrorFromCustomerDeliveries: false,
				errors: {
					...state.errors,
					getProductErrorFromCustomerDeliveries: actions.error,
				},
			};

		case TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_REQUEST:
			return {
				...state,
				// productFaultPaidCustomerByBarcode: {},
				isGetProductFaultPaidCustomerByBarcode: true,
				errors: { ...state.errors, getProductFaultPaidCustomerByBarcode: "" },
			};
		case TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_SUCCESS:
			return {
				...state,
				productFaultPaidCustomerByBarcode: actions.data || {},
				isGetProductFaultPaidCustomerByBarcode: false,
				errors: { ...state.errors, getProductFaultPaidCustomerByBarcode: "" },
			};
		case TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_FAILED:
			return {
				...state,
				productFaultPaidCustomerByBarcode: {},
				isGetProductFaultPaidCustomerByBarcode: false,
				errors: {
					...state.errors,
					getProductFaultPaidCustomerByBarcode: actions.error,
				},
			};

		default:
			return { ...state };
	}
};

export default deliveryReducer;
