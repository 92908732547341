import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SimpleHeader from './components/Header';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import { EditSVG, DeleteSVG } from 'assets/svg';
import Autosuggest from 'react-autosuggest';
import { useDispatch, useSelector } from 'react-redux';
import { producerActions } from 'Redux/Actions';
import queryString from 'query-string';
import _ from 'lodash';
import { notify, checkRole } from 'common';
import NotificationAlert from 'react-notification-alert';
import InputCustom from 'views/pages/components/InputCustom';
import FormProducer from './FormProducer';
import ReactNotificationAlert from 'react-notification-alert';
import CONSTANTS from 'constant';

const ProducerManage = () => {
  const dispatch = useDispatch();
  const { producers, isDeleteProducer, isGetProducers } = useSelector(
    (state) => state.producerReducer
  );
  const { accounts, isDeleteAccount, isGetAccounts, currentAccount } =
    useSelector((state) => state.accountReducer);
  const [roleManage, setRoleManage] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);

  const notificationAlertRef = useRef(null);
  const [producer, setProducer] = useState({});
  const [producerSearch, setProducerSearch] = useState('');
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
  });

  const boxAction = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {roleManage && (
          <>
            <button
              style={{
                padding: 0,
                border: 'none',
                marginRight: '.5rem',
                background: 'none',
              }}
              id="edit"
              onClick={() => {
                setFormModal(true);
                setIsModalAdd(false);
                setProducer(row);
              }}
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target="edit">
              Cập nhật thông tin nhà sản xuất
            </UncontrolledTooltip>
          </>
        )}
        {roleDelete && (
          <>
            <button
              id="delete"
              onClick={() => {
                setNotificationModal(true);
                setProducer(row);
              }}
              style={{ padding: 0, border: 'none', background: 'none' }}
            >
              <DeleteSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target="delete">
              Xóa nhà sản xuất
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const [columns, setColumns] = useState([
    {
      dataField: 'code',
      text: 'Mã NSX',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'name',
      text: 'Tên nhà sản xuất',
    },
    {
      dataField: 'type',
      text: 'Thể loại',
      formatter: (cell) => {
        if (cell === CONSTANTS.PRODUCER_TYPE.ALL) {
          return 'Cắt, May.';
        } else if (cell === CONSTANTS.PRODUCER_TYPE.CUT) {
          return 'Cắt';
        } else if (cell === CONSTANTS.PRODUCER_TYPE.PRODUCTION) {
          return 'May.';
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'address',
      text: 'Địa chỉ',
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'contactPerson',
      text: 'Người liên hệ',
    },
    {
      dataField: 'capacity',
      text: 'Công xuất gia công',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'notes',
      text: 'Ghi chú',
    },
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
  ]);

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: producers?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col>
          <p>
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > producers.totalResults
              ? !isNaN(producers?.totalResults)
                ? producers.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(producers?.totalResults) ? producers.totalResults : 0} bản
            ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleDelete = () => {
    dispatch(
      producerActions.deleteProducer(producer.id, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa nhà sản xuất thành công!`
          );
          handleGetProducers();
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa nhà sản xuất thất bại!`
          );
        },
      })
    );
  };

  const handleGetProducers = () => {
    if (producerSearch === '')
      dispatch(producerActions.getProducers(queryString.stringify(query)));
    else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ ...query, name: producerSearch })
        )
      );
    }
  };

  useEffect(() => {
    handleGetProducers();
  }, [query]);

  useEffect(() => {
    if (!_.isEmpty(currentAccount)) {
      if (checkRole(currentAccount, { roles: ['manage_producer'] })) {
        setRoleManage(true);
      }
      if (checkRole(currentAccount, { roles: ['delete_producer'] })) {
        setRoleDelete(true);
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    if (!roleManage && !roleDelete) {
      const index = columns.findIndex((item) => item.dataField === 'actions');
      if (index !== -1)
        setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    } else {
      setColumns([
        {
          dataField: 'code',
          text: 'Mã NSX',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'name',
          text: 'Tên nhà sản xuất',
        },
        {
          dataField: 'type',
          text: 'Thể loại',
          formatter: (cell) => {
            if (cell === CONSTANTS.PRODUCER_TYPE.ALL) {
              return 'Cắt, May.';
            } else if (cell === CONSTANTS.PRODUCER_TYPE.CUT) {
              return 'Cắt';
            } else if (cell === CONSTANTS.PRODUCER_TYPE.PRODUCTION) {
              return 'May.';
            } else {
              return '';
            }
          },
        },
        {
          dataField: 'address',
          text: 'Địa chỉ',
        },
        {
          dataField: 'phone',
          text: 'Số điện thoại',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'contactPerson',
          text: 'Người liên hệ',
        },
        {
          dataField: 'capacity',
          text: 'Công xuất gia công',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'notes',
          text: 'Ghi chú',
        },
        {
          dataField: 'actions',
          text: 'Hành động',
          formatter: boxAction,
          style: { textAlign: 'center' },
          headerStyle: {
            textAlign: 'center',
          },
        },
      ]);
    }
  }, [roleManage, roleDelete]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="nhà sản xuất"
          func={handleDelete}
          isDelete={isDeleteProducer}
        />
      )}
      {formModal && (
        <FormProducer
          isModalAdd={isModalAdd}
          formModal={formModal}
          setFormModal={setFormModal}
          producer={producer}
          handleGetProducers={handleGetProducers}
          notificationAlertRef={notificationAlertRef}
        />
      )}
      <SimpleHeader
        name=""
        parentName="Quản lý"
        setFormModal={setFormModal}
        setIsModalAdd={setIsModalAdd}
        setProducer={setProducer}
        roleManage={roleManage}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={producers.results}
                keyField="id"
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">Danh sách nhà sản xuất</h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <CardHeader>
                          <div className="mb-0 d-flex align-items-center">
                            <p
                              className="mb-0"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Hiển thị{' '}
                            </p>
                            {
                              <select
                                value={rowsPerPage}
                                name="datatable-basic_length"
                                aria-controls="datatable-basic"
                                className="form-control form-control-sm mx-2"
                                style={{ maxWidth: 60 }}
                                onChange={(e) =>
                                  onSizePerPageChange(e.target.value)
                                }
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            }{' '}
                            <p className="mb-0">dòng</p>
                          </div>
                        </CardHeader>
                      </Col>
                      <Col className="d-flex align-items-center mr-4 justify-content-end">
                        <Row style={{ width: '100%' }}>
                          <Col
                            md={6}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <h4 className="mb-0">Tìm kiếm tên nhà sản xuất</h4>
                          </Col>
                          <Col md={6} className="d-flex align-items-center">
                            <Input
                              id="search-by-name"
                              placeholder="Nhập tên"
                              type="text"
                              value={producerSearch}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  setPage(1);
                                  setQuery({ ...query, page: 1 });
                                  handleGetProducers();
                                }
                              }}
                              onChange={(e) => {
                                setProducerSearch(e.target.value);
                              }}
                              className="py-0"
                              size="sm"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      hover
                      remote
                      filter={filterFactory()}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProducerManage;
