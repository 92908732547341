import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CardBody, CardHeader, Card, Modal, Button } from 'reactstrap';
// import JsBarcode from 'jsbarcode';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { orderActions } from 'Redux/Actions';
import bwipjs from 'bwip-js';

const w = 36; // mm
const h = 40; // mm

const DialogPrintBarCode = ({
  formModal,
  setFormModal,
  totalResults,
  typePrint,
  sewTypes,
  productionCommandId,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [nameFile, setNameFile] = useState('');
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(50);
  const [query, setQuery] = useState({
    page: 1,
    limit: 50,
    sortBy: 'customerName:asc',
    populate:
      'productTypeId, customerOrgId, customerSizeId, standardSizeId,customerOrgId.provinceId,sizes.productParameterId',
  });
  const [isHandleBarcode, setIsHandleBarcode] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    documentTitle: nameFile,
  });

  const handleRenderBarcode = (data) => {
    try {
      const arrData = [];
      if (Array.isArray(data)) {
        [...data].forEach((item) => {
          Array.apply(null, Array(item.quota)).forEach(() => {
            arrData.push(item);
          });
        });
      } else {
        [data].forEach((item) => {
          Array.apply(null, Array(item.quota)).forEach(() => {
            arrData.push(item);
          });
        });
      }
      arrData.forEach((item, idx) => {
        const divBarcode = document.createElement('div');
        divBarcode.classList.add('d-flex');
        divBarcode.classList.add('flex-column');
        divBarcode.classList.add('align-items-start');
        divBarcode.style.width = `${w}mm`;
        divBarcode.style.height = `${h}mm`;
        divBarcode.style.paddingTop = '2mm';
        if (idx > 1) {
          // divBarcode.style.marginTop = '0.344cm';
        }
        const barcode = document.createElement('div');
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', 'barcode-' + idx);
        // customer name
        const pName = document.createElement('span');
        pName.innerHTML = item?.customerName;
        pName.style.fontSize = '8px';
        pName.style.fontWeight = 600;
        pName.classList.add('mb-0');
        pName.classList.add('pl-1');
        // customerOrgId
        const pBarcode = document.createElement('span');
        pBarcode.innerHTML = item.barcode;
        pBarcode.style.fontSize = '9px';
        pBarcode.style.fontWeight = 600;
        pBarcode.classList.add('text-center');
        pBarcode.style.width = '100%';
        // customerOrgId
        const pCustomerOrgId = document.createElement('span');
        pCustomerOrgId.innerHTML = item?.customerOrgId?.name;
        pCustomerOrgId.style.fontSize = '8px';
        pCustomerOrgId.style.fontWeight = 600;
        pCustomerOrgId.classList.add('mb-0');
        pCustomerOrgId.classList.add('pl-1');
        // provine name
        const pProvince = document.createElement('span');
        pProvince.innerHTML = item?.customerOrgId?.provinceId?.provinceName;
        pProvince.style.fontSize = '8px';
        pProvince.style.fontWeight = 600;
        pProvince.classList.add('mb-0');
        pProvince.classList.add('pl-1');
        // table
        const table = document.createElement('table');
        table.style.border = '.5px solid #333';
        table.style.fontSize = '8px';
        table.style.width = '95%';
        table.style.margin = 'auto';
        if (!_.isEmpty(item?.sizes)) {
          const sizes = [];
          let childArr = [];
          item.sizes.forEach((v, iv) => {
            const td = document.createElement('td');
            td.style.border = '.5px solid #333';
            td.style.textAlign = 'center';
            td.style.fontWeight = 600;
            td.style.width = '25%';
            td.innerHTML = `${v?.productParameterId?.pantCode} :  ${v?.size}`;

            childArr.push(td);
            if ((iv + 1) % 4 === 0) {
              sizes.push(childArr);
              childArr = [];
            }
          });
          sizes.forEach((v) => {
            const tr = document.createElement('tr');
            tr.style.width = '100%';
            v.forEach((o) => {
              tr.appendChild(o);
            });
            table.appendChild(tr);
          });
          if (sizes.length < 2) {
            for (let i = 0; i < 2 - sizes.length; i++) {
              const tr = document.createElement('tr');
              for (let o = 0; o < 4; o++) {
                const td = document.createElement('td');
                td.style.fontWeight = 900;
                td.innerHTML = '-';
                td.style.border = '.5px solid #333';
                td.style.textAlign = 'center';
                tr.appendChild(td);
              }
              table.appendChild(tr);
            }
          }
        }

        barcode.appendChild(canvas);
        barcode.style.zoom = 0.75;
        barcode.style.width = '100%';
        barcode.classList.add('d-flex');
        barcode.classList.add('justify-content-center');

        divBarcode.appendChild(barcode);
        divBarcode.appendChild(pBarcode);
        divBarcode.appendChild(pName);
        divBarcode.appendChild(pCustomerOrgId);
        divBarcode.appendChild(pProvince);
        divBarcode.appendChild(table);
        componentRef.current.appendChild(divBarcode);
        bwipjs.toCanvas(`#barcode-${idx}`, {
          text: item.barcode,
          bcid: 'code128', // Barcode type
          scaleX: 1,
          height: 27, // Bar height, in millimeters
        });
      });
      if (arrData.length % 2 !== 0 && arrData.length > 1) {
        const width = document.getElementById(`barcode-0`).style.width;
        const divBarcode = document.createElement('div');
        const canvas = document.createElement('canvas');
        canvas.style.width = width;
        divBarcode.appendChild(canvas);
        componentRef.current.appendChild(divBarcode);
      }

      setIsHandleBarcode(false);
      handlePrint();
    } catch (error) {
      setIsHandleBarcode(false);
    }
  };

  const handleExportCustomersInOrderGeneral = () => {
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...query,
            productionCommandId: productionCommandId,
            sewTypes: [-1],
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setNameFile(
              'Danh_SachKH_So_Do_Chung_' +
                moment().format('DD-MM-YYYY_hh-mm-ss')
            );
            setIsHandleBarcode(true);
            if (!_.isEmpty(componentRef?.current)) {
              handleRenderBarcode(data.results);
            }
          },
          failed: (mess) => {},
        }
      )
    );
  };

  const handleExportCustomersInOrderPrivate = () => {
    const tempSewTypes = [];
    if (
      _.isEmpty(productionCommandId) ||
      (!sewTypes.private1 &&
        !sewTypes.private2 &&
        !sewTypes.private3 &&
        !sewTypes.private4)
    ) {
      return;
    } else if (sewTypes.private1) {
      tempSewTypes.push(1);
    } else if (sewTypes.private2) {
      tempSewTypes.push(2);
    } else if (sewTypes.private3) {
      tempSewTypes.push(3);
    } else if (sewTypes.private4) {
      tempSewTypes.push(0);
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...query,
            productionCommandId: productionCommandId,
            sewTypes: tempSewTypes,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setNameFile(
              'Danh_SachKH_So_Do_Rieng_' +
                moment().format('DD-MM-YYYY_hh-mm-ss')
            );
            setIsHandleBarcode(true);
            if (!_.isEmpty(componentRef?.current)) {
              handleRenderBarcode(data.results);
            }
          },
          failed: (mess) => {},
        }
      )
    );
  };

  // useEffect(() => {
  //   setIsHandleBarcode(true);
  //   setTimeout(() => {
  //     if (!_.isEmpty(componentRef?.current)) {
  //       handleRenderBarcode(data);
  //     }
  //   }, 500);
  // }, [data, componentRef, formModal]);

  useEffect(() => {
    if (formModal) {
      if (typePrint === 'general') {
        handleExportCustomersInOrderGeneral();
      } else {
        handleExportCustomersInOrderPrivate();
      }
    }
  }, [formModal, query]);

  const onSizePerPageChange = (value) => {
    componentRef.current.innerHTML = '';
    setRowPerPage(value);
    setPage(1);
    setQuery({
      ...query,
      page: 1,
      limit: value,
    });
  };

  const onPageChange = (value) => {
    componentRef.current.innerHTML = '';
    setPage(value);
    setQuery({
      ...query,
      page: value,
    });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={formModal}
      style={{ minWidth: `${w * 2}mm`, maxWidth: `${w * 2}mm` }}
      toggle={() => {
        setFormModal(false);
      }}
    >
      <div className="modal-body p-0">
        <Card
          style={{ width: `${w * 2}mm` }}
          className="bg-white border-0 mb-0 d-flex flex-column align-items-center"
        >
          <CardHeader
            style={{ width: `${w * 2}mm` }}
            className="d-flex flex-column justify-content-end p-1"
          >
            <div className="mb-0 d-flex align-items-center my-1">
              <p className="mb-0">Trang</p>
              {
                <select
                  value={page}
                  name="datatable-basic_length"
                  aria-controls="datatable-basic"
                  className="form-control form-control-sm mx-2"
                  style={{ maxWidth: 60 }}
                  onChange={(e) => onPageChange(e.target.value)}
                >
                  {Array.apply(
                    null,
                    Array(Math.ceil(totalResults / rowPerPage))
                  ).map((item, index) => {
                    return <option value={index + 1}>{index + 1}</option>;
                  })}
                  {/* <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option> */}
                </select>
              }
            </div>
            <div className="mb-0 d-flex align-items-center my-1">
              <p className="mb-0">in</p>
              {
                <select
                  value={rowPerPage}
                  name="datatable-basic_length"
                  aria-controls="datatable-basic"
                  className="form-control form-control-sm mx-2"
                  style={{ maxWidth: 60 }}
                  onChange={(e) => onSizePerPageChange(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              }{' '}
              <p className="mb-0">
                bản ghi trong số {!isNaN(totalResults) ? totalResults : 0}.
              </p>
            </div>

            <Button color="primary" onClick={handlePrint}>
              In Phiếu
            </Button>
          </CardHeader>
          {isHandleBarcode && (
            <div className="d-flex justify-content-between flex-column align-items-center">
              <div className="spinner-border text-info" />
              <p className="text-center">Đang xử lý ...</p>
            </div>
          )}
          <CardBody
            className="p-0"
            style={{ width: `${w * 2}mm`, paddingTop: 5 }}
          >
            <div
              style={{
                width: `${w * 2}mm`,
                // display: isHandleBarcode ? 'none' : 'flex',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
              }}
              ref={componentRef}
            ></div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default DialogPrintBarCode;
