import TypeActions from "Redux/TypeActions";

const initialState = {
	dashboard: {},
	isGetDashboard: false,
	errors: {
		getDashboard: "",
	},
};

export const reportsReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case TypeActions.GET_DASHBOARD_REQUEST:
			return {
				...state,
				isGetDashboard: true,
				errors: { ...state.errors, getDashboard: "" },
			};
		case TypeActions.GET_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboard: actions.data || {},
				isGetDashboard: false,
				errors: { ...state.errors, getDashboard: "" },
			};
		case TypeActions.GET_DASHBOARD_FAILED:
			return {
				...state,
				dashboard: {},
				isGetDashboard: false,
				errors: { ...state.errors, getDashboard: "" },
			};
		default:
			return { ...state };
	}
};
export default reportsReducer;
