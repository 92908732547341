import TypeActions from "Redux/TypeActions";

export const getDashboard = (params, query, callback) => {
	return {
		type: TypeActions.GET_DASHBOARD_REQUEST,
		params,
		query,
		callback,
	};
};

export default {
	getDashboard,
};
