import React, { useState, useEffect } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  UncontrolledTooltip,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Style } from '../style';
import SimpleHeader from '../components/Header';
import BootstrapTable from 'react-bootstrap-table-next';
import { EditSVG } from 'assets/svg';
import { ViewSVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import orderActions from 'Redux/Actions/orderActions';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { checkRole } from 'common';
const NormMaterials = ({ location }) => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const [firstRender, setFirstRender] = useState(true);
  const { isGetOrders, orders } = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [valueFilter, setValueFilter] = useState({
    code: '',
    name: '',
  });
  const [orderSearch, setMaterialNormSearch] = useState('');
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    sortBy: 'createdAt:desc',
    populate: 'orderId, productId, materialId,updatedBy',
  });

  const boxAction = (cell, row, id) => {
    return (
      <>
        <button
          id={'view' + id}
          onClick={() => {
            history.push(`/norm-materials/detail/${row.id}`, {
              page,
              rowsPerPage,
            });
          }}
          className="btn-none"
        >
          <ViewSVG />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
          Xem chi tiết
        </UncontrolledTooltip>
        {checkRole(currentAccount, { roles: ['manage_materialNorm'] }) && (
          <>
            <button
              id={'edit' + id}
              onClick={() => {
                history.push(`/norm-materials/update/${row.id}`, {
                  page,
                  rowsPerPage,
                });
              }}
              className="btn-none"
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Cập nhật định mức NPL
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: 'code',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.code}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, code: e.target.value })
            }
            placeholder="Mã đơn hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.name}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, name: e.target.value })
            }
            placeholder="Tên đơn hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Ngày cập nhật',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'updatedBy.name',
      text: 'Người cập nhật',
      formatter: (cell) => {
        return cell ? cell : '';
      },
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'status',
      text: 'Trạng thái',
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'actions',
      text: 'Hành động',
      headerStyle: {
        textAlign: 'center',
      },
      formatter: boxAction,
      style: { textAlign: 'center' },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: orders?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{whiteSpace: 'nowrap'}}>Hiển thị </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > orders.totalResults
              ? !isNaN(orders?.totalResults)
                ? orders.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số {!isNaN(orders?.totalResults) ? orders.totalResults : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(orderActions.getOrders(queryString.stringify(query)));
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...query, name: orderSearch })
        )
      );
    }
  };

  useEffect(() => {
    handleGetOrders();
  }, [query]);

  useEffect(() => {
    if (!firstRender) {
      const delay = setTimeout(() => {
        const tempQuery = { ...valueFilter };
        Object.keys(tempQuery).map((key, index) => {
          if (_.isEmpty(tempQuery[key])) {
            delete tempQuery[key];
          }
        });
        setPage(1);
        if (!_.isEmpty(tempQuery)) {
          setQuery({
            ...tempQuery,
            page: 1,
            limit: rowsPerPage,
            populate: 'orderId, productId, materialId,updatedBy',
          });
        } else {
          setQuery({
            page: 1,
            limit: rowsPerPage,
            populate: 'orderId, productId, materialId,updatedBy',
          });
        }
      }, 1000);
      return () => {
        clearTimeout(delay);
      };
    } else {
      setFirstRender(false);
    }
  }, [valueFilter]);

  return (
    <Style>
      <SimpleHeader name="Quản lý nguyên phụ liệu" handleFilter={() => {}} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                // data={orders.results}
                data={orders.results.sort((a, b) =>
                  b.createdAt.localeCompare(a.createdAt)
                )}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">
                            Danh sách đơn hàng có định mức NPL cho đơn hàng
                          </h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      filter={filterFactory()}
                      pagination={pagination}
                      bordered={false}
                      hover
                      remote
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </Style>
  );
};

export default NormMaterials;
