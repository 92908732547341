import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GETFILE } from 'Services/ServiceBase';
import ServiceURL from 'Services/ServiceURL';
import TypeActions from '../TypeActions';

export function* exportMeasureTemplate(data) {
  const url =
    ServiceURL.customerInOrders + '/export/measureTemplate/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_MEASURE_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_MEASURE_TEMPLATE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_MEASURE_TEMPLATE_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportSewGeneralPrivate(data) {
  const url =
    ServiceURL.customerInOrders +
    '/export/ByFilter/' +
    data.params +
    '?' +
    data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_SEW_GENERAL_PRIVATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_SEW_GENERAL_PRIVATE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_SEW_GENERAL_PRIVATE_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportSuggest(data) {
  const url =
    ServiceURL.materialSuggests +
    '/exports/suggest/' +
    data.params +
    '?' +
    data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_SUGGGEST_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_SUGGGEST_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_SUGGGEST_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportProductionCommand(data) {
  const url =
    ServiceURL.productionCommands +
    '/exports/exportProductionCommand/' +
    data.params +
    '?' +
    data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_PRODUCTION_COMMAND_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_PRODUCTION_COMMAND_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_PRODUCTION_COMMAND_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportProductSendCustomer(data) {
  const url = ServiceURL.transports + '/exports?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportAggrementPlan(data) {
  const url =
    ServiceURL.orderPlans + '/' + ServiceURL.exports + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_AGGREMENT_PLAN_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_AGGREMENT_PLAN_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_AGGREMENT_PLAN_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportDetailPlan(data) {
  const url =
    ServiceURL.orderPlans + '/' + ServiceURL.exports + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_DETAIL_PLAN_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_DETAIL_PLAN_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_DETAIL_PLAN_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportQcCompleteProductsError(data) {
  const url = ServiceURL.transports + '/exports?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportTemplateOrderPrint(data) {
  const url = '';
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* exportOrderDetail(data) {
  const url = '';
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_ORDER_DETAIL_FAILED,
        error: res?.message,
      });
      !!callback?.failed && callback.failed(res?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_ORDER_DETAIL_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_ORDER_DETAIL_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export default function* exportSaga() {
  yield takeLatest(
    TypeActions.EXPORT_MEASURE_TEMPLATE_REQUEST,
    exportMeasureTemplate
  );
  yield takeLatest(
    TypeActions.EXPORT_SEW_GENERAL_PRIVATE_REQUEST,
    exportSewGeneralPrivate
  );
  yield takeLatest(TypeActions.EXPORT_SUGGGEST_REQUEST, exportSuggest);
  yield takeLatest(
    TypeActions.EXPORT_PRODUCTION_COMMAND_REQUEST,
    exportProductionCommand
  );
  yield takeLatest(
    TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_REQUEST,
    exportProductSendCustomer
  );
  yield takeLatest(
    TypeActions.EXPORT_AGGREMENT_PLAN_REQUEST,
    exportAggrementPlan
  );
  yield takeLatest(TypeActions.EXPORT_DETAIL_PLAN_REQUEST, exportDetailPlan);
  yield takeLatest(
    TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_REQUEST,
    exportQcCompleteProductsError
  );

  yield takeLatest(
    TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_REQUEST,
    exportTemplateOrderPrint
  );
  yield takeLatest(TypeActions.EXPORT_ORDER_DETAIL_REQUEST, exportOrderDetail);
}
