import TypeActions from '../TypeActions';

const initialState = {
  isExportMeasureTemplate: false,
  isExportSewGeneralPrivate: false,
  isExportSuggest: false,
  isExportProductSendCustomer: false,
  isExportAggrementPlan: false,
  isExportDetailPlan: false,
  isExportQcCompleteProductsError: false,
  isExportTemplateOrderPrint: false,
  isExportOrderDetail: false,

  errors: {
    exportMeasureTemplate: '',
    exportSewGeneralPrivate: '',
    exportSuggest: '',
    exportProductSendCustomer: '',
    exportAggrementPlan: '',
    exportDetailPlan: '',
    exportQcCompleteProductsError: '',
    exportTemplateOrderPrint: '',
    exportOrderDetail: '',
  },
};

export const exortReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.EXPORT_MEASURE_TEMPLATE_REQUEST:
      return {
        ...state,
        isExportMeasureTemplate: true,
        errors: {
          ...state.errors,
          exportMeasureTemplate: '',
        },
      };
    case TypeActions.EXPORT_MEASURE_TEMPLATE_SUCCESS:
      return {
        ...state,
        unitById: actions.data || {},
        isExportMeasureTemplate: false,
        errors: {
          ...state.errors,
          exportMeasureTemplate: '',
        },
      };
    case TypeActions.EXPORT_MEASURE_TEMPLATE_FAILED:
      return {
        ...state,
        isExportMeasureTemplate: false,
        unitById: {},
        errors: {
          ...state.errors,
          exportMeasureTemplate: actions.error,
        },
      };
    case TypeActions.EXPORT_SEW_GENERAL_PRIVATE_REQUEST:
      return {
        ...state,
        isExportSewGeneralPrivate: true,
        errors: {
          ...state.errors,
          exportSewGeneralPrivate: '',
        },
      };
    case TypeActions.EXPORT_SEW_GENERAL_PRIVATE_SUCCESS:
      return {
        ...state,
        unitById: actions.data || {},
        isExportSewGeneralPrivate: false,
        errors: {
          ...state.errors,
          exportSewGeneralPrivate: '',
        },
      };
    case TypeActions.EXPORT_SEW_GENERAL_PRIVATE_FAILED:
      return {
        ...state,
        isExportSewGeneralPrivate: false,
        unitById: {},
        errors: {
          ...state.errors,
          exportSewGeneralPrivate: actions.error,
        },
      };

    case TypeActions.EXPORT_SUGGGEST_REQUEST:
      return {
        ...state,
        isExportSuggest: true,
        errors: {
          ...state.errors,
          exportSuggest: '',
        },
      };
    case TypeActions.EXPORT_SUGGGEST_SUCCESS:
      return {
        ...state,
        unitById: actions.data || {},
        isExportSuggest: false,
        errors: {
          ...state.errors,
          exportSuggest: '',
        },
      };
    case TypeActions.EXPORT_SUGGGEST_FAILED:
      return {
        ...state,
        isExportSuggest: false,
        unitById: {},
        errors: {
          ...state.errors,
          exportSuggest: actions.error,
        },
      };

    case TypeActions.EXPORT_PRODUCTION_COMMAND_REQUEST:
      return {
        ...state,
        isExportProductCommand: true,
        errors: {
          ...state.errors,
          exportProductCommand: '',
        },
      };
    case TypeActions.EXPORT_PRODUCTION_COMMAND_SUCCESS:
      return {
        ...state,
        isExportProductCommand: false,
        errors: {
          ...state.errors,
          exportProductCommand: '',
        },
      };
    case TypeActions.EXPORT_PRODUCTION_COMMAND_FAILED:
      return {
        ...state,
        isExportProductCommand: false,
        errors: {
          ...state.errors,
          exportProductCommand: actions.error,
        },
      };

    case TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_REQUEST:
      return {
        ...state,
        isExportProductSendCustomer: true,
        errors: {
          ...state.errors,
          exportProductSendCustomer: '',
        },
      };
    case TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_SUCCESS:
      return {
        ...state,
        isExportProductSendCustomer: false,
        errors: {
          ...state.errors,
          exportProductSendCustomer: '',
        },
      };
    case TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_FAILED:
      return {
        ...state,
        isExportProductSendCustomer: false,
        errors: {
          ...state.errors,
          exportProductSendCustomer: actions.error,
        },
      };

    case TypeActions.EXPORT_AGGREMENT_PLAN_REQUEST:
      return {
        ...state,
        isExportAggrementPlan: true,
        errors: {
          ...state.errors,
          exportAggrementPlan: '',
        },
      };
    case TypeActions.EXPORT_AGGREMENT_PLAN_SUCCESS:
      return {
        ...state,
        isExportAggrementPlan: false,
        errors: {
          ...state.errors,
          exportAggrementPlan: '',
        },
      };
    case TypeActions.EXPORT_AGGREMENT_PLAN_FAILED:
      return {
        ...state,
        isExportAggrementPlan: false,
        errors: {
          ...state.errors,
          exportAggrementPlan: actions.error,
        },
      };

    case TypeActions.EXPORT_DETAIL_PLAN_REQUEST:
      return {
        ...state,
        isExportDetailPlan: true,
        errors: {
          ...state.errors,
          exportDetailPlan: '',
        },
      };
    case TypeActions.EXPORT_DETAIL_PLAN_SUCCESS:
      return {
        ...state,
        isExportDetailPlan: false,
        errors: {
          ...state.errors,
          exportDetailPlan: '',
        },
      };
    case TypeActions.EXPORT_DETAIL_PLAN_FAILED:
      return {
        ...state,
        isExportDetailPlan: false,
        errors: {
          ...state.errors,
          exportDetailPlan: actions.error,
        },
      };

    case TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_REQUEST:
      return {
        ...state,
        isExportQcCompleteProductsError: true,
        errors: {
          ...state.errors,
          exportQcCompleteProductsError: '',
        },
      };
    case TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_SUCCESS:
      return {
        ...state,
        isExportQcCompleteProductsError: false,
        errors: {
          ...state.errors,
          exportQcCompleteProductsError: '',
        },
      };
    case TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_FAILED:
      return {
        ...state,
        isExportQcCompleteProductsError: false,
        errors: {
          ...state.errors,
          exportQcCompleteProductsError: actions.error,
        },
      };

    case TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_REQUEST:
      return {
        ...state,
        isExportTemplateOrderPrint: true,
        errors: {
          ...state.errors,
          exportTemplateOrderPrint: '',
        },
      };
    case TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_SUCCESS:
      return {
        ...state,
        isExportTemplateOrderPrint: false,
        errors: {
          ...state.errors,
          exportTemplateOrderPrint: '',
        },
      };
    case TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_FAILED:
      return {
        ...state,
        isExportTemplateOrderPrint: false,
        errors: {
          ...state.errors,
          exportTemplateOrderPrint: actions.error,
        },
      };

    case TypeActions.EXPORT_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        isExportOrderDetail: true,
        errors: {
          ...state.errors,
          exportOrderDetail: '',
        },
      };
    case TypeActions.EXPORT_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        isExportOrderDetail: false,
        errors: {
          ...state.errors,
          exportOrderDetail: '',
        },
      };
    case TypeActions.EXPORT_ORDER_DETAIL_FAILED:
      return {
        ...state,
        isExportOrderDetail: false,
        errors: {
          ...state.errors,
          exportOrderDetail: actions.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default exortReducer;
