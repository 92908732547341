import { FileClipboardTextSVG } from 'assets/svg';
import { FileClipboardCheckSVG } from 'assets/svg';
import { FileClipboardBlockSVG } from 'assets/svg';
import { checkRole } from 'common';
import { notify } from 'common';
import React, { useRef, useState, useEffect } from 'react';
import ReactNotificationAlert from 'react-notification-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { exportActions } from 'Redux/Actions';
import { orderActions } from 'Redux/Actions';
import { Style } from '../style';
import Filter from './components/Filter';
import Header from './components/Header';
import ListResidual from './components/ListResidual';
import ListResolved from './components/ListResolved';
import UnusualList from './components/UnusualList';
import queryString from 'query-string';
import constant from 'constant';
import FileDownload from 'js-file-download';
import moment from 'moment';
import ModalConfirm from 'views/pages/components/ModalConfirm';

const CensorshipResult = () => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const notificationAlertRef = useRef();
  const [sectionState, setSectionState] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [valueFilter, setValueFilter] = useState({});
  const [currentOrder, setCurrentOrder] = useState('');
  const [allowClick, setAllowClick] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectItems, setSelectItems] = useState([]);
  const { unusualList } = useSelector((state) => state.orderReducer);
  const changeSectionState = (number) => {
    setSectionState(number);
  };
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleCheckUnusual = () => {
    if (currentOrder === '') {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng chọn đơn hàng để kiểm tra bất thường`
      );
    }
    dispatch(
      orderActions.checkUnusualSize({}, currentOrder, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Kiểm tra bất thường thành công!`
          );
          setLoading(true);
        },
        failed: (err) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Kiểm tra bất thường thất bại!, Lỗi : ${err}`
          );
        },
      })
    );
  };
  const handleAutoAssignSize = () => {
    if (currentOrder === '') {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng chọn đơn hàng để quy size`
      );
    }
    dispatch(
      orderActions.autoAssignSize(
        currentOrder,
        {},
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Quy size thành công!`
            );
            setLoading(true);
          },
          failed: (err) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Quy size thất bại!, Lỗi : ${err}`
            );
          },
        }
      )
    );
  };
  const exportFileData = () => {
    if (currentOrder === '') {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng chọn đơn hàng để xuất danh sách`
      );
    }
    dispatch(
      exportActions.exportSewGeneralPrivate(
        currentOrder,
        queryString.stringify({
          customerInOrderSizeStatus:
            sectionState === 0
              ? constant.CUSTOMER_IN_ORDER_SIZE_STATUS.Abnormal
              : sectionState === 1
              ? constant.CUSTOMER_IN_ORDER_SIZE_STATUS.SuccessAssigned
              : constant.CUSTOMER_IN_ORDER_SIZE_STATUS.FailedAssigned,
        }),
        {
          success: (data) => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xuất file thành công`
            );
            const filename =
              'Danh_Sach_So_Do_' +
              (sectionState === 0
                ? 'Bat_Thuong'
                : sectionState === 1
                ? 'Da_Quy_Size'
                : 'Ton_Sau_Quy_Size') +
              moment().format('DD-MM-YYYY') +
              '.xlsx';
            FileDownload(data, filename);
            setLoading(true);
          },
          failed: (err) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xuất file thất bại!, Lỗi : ${err}`
            );
          },
        }
      )
    );
  };

  const handleConvertOwnDesigns = (vipValue) => {
    dispatch(
      orderActions.convertOwnDesigns(
        { sewType: vipValue, customerInOrderIds: selectItems },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Chuyển may thêm thành công!`
            );
            setLoading(true);
            setIsOpenConfirmModal(false);
            setSelectItems([]);
          },
          failed: (err) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Chuyển may thêm thất bại!!! Lỗi: ${err}`
            );
          },
        }
      )
    );
  };

  const toggleConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };

  useEffect(() => {
    setValueFilter({});
    setAllowClick(false);
    setSelectItems([]);
  }, [sectionState, currentOrder]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <ModalConfirm
        open={isOpenConfirmModal}
        toggle={toggleConfirmModal}
        name="Bạn chắc chắn muốn chuyển đổi may thêm ? "
        handle={handleConvertOwnDesigns}
      />
      <Header
        name=""
        toggle={toggleOpenFilter}
        setCurrentOrders={setCurrentOrder}
        handleExportFile={exportFileData}
        handleCheckUnusual={() => {
          // if (!allowClick) return;
          checkRole(currentAccount, { roles: ['manage_customerInOrder'] }) &&
            handleCheckUnusual();
        }}
        handleAutoAssignSize={() => {
          // if (!allowClick) return;
          checkRole(currentAccount, { roles: ['manage_customerInOrder'] }) &&
            handleAutoAssignSize();
        }}
        options={valueFilter}
        selectItems={selectItems}
        setIsOpenConfirmModal={setIsOpenConfirmModal}
      />
      <Container fluid className="mt--6">
        <Card>
          <CardHeader className="py-0">
            <div className="d-flex">
              <div
                onClick={() => {
                  changeSectionState(0);
                }}
                className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                  sectionState === 0 ? 'nav-active' : ''
                }`}
              >
                <FileClipboardBlockSVG />
                <p
                  className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                    sectionState !== 0 ? 'text-muted' : ''
                  }`}
                >
                  Danh sách số đo bất thường{' '}
                  {sectionState === 0 && `(${unusualList.totalResults || 0})`}
                </p>
              </div>
              <div
                onClick={() => {
                  changeSectionState(1);
                }}
                className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                  sectionState === 1 ? 'nav-active' : ''
                }`}
              >
                <FileClipboardCheckSVG />
                <p
                  className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                    sectionState !== 1 ? 'text-muted' : ''
                  }`}
                >
                  Danh sách đã quy size{' '}
                  {sectionState === 1 && `(${unusualList.totalResults || 0})`}
                </p>
              </div>
              <div
                onClick={() => {
                  changeSectionState(2);
                }}
                className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                  sectionState === 2 ? 'nav-active' : ''
                }`}
              >
                <FileClipboardTextSVG />
                <p
                  className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                    sectionState !== 2 ? 'text-muted' : ''
                  }`}
                >
                  Danh sách tồn sau quy size{' '}
                  {sectionState === 2 && `(${unusualList.totalResults || 0})`}
                </p>
              </div>
            </div>
          </CardHeader>

          {sectionState === 0 ? (
            <UnusualList
              currentOrder={currentOrder}
              loading={loading}
              setLoading={setLoading}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
              setAllowClick={setAllowClick}
              selectItems={selectItems}
              setSelectItems={setSelectItems}
            />
          ) : sectionState === 1 ? (
            <ListResolved
              currentOrder={currentOrder}
              loading={loading}
              setLoading={setLoading}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
              setAllowClick={setAllowClick}
              selectItems={selectItems}
              setSelectItems={setSelectItems}
            />
          ) : (
            <ListResidual
              currentOrder={currentOrder}
              loading={loading}
              setLoading={setLoading}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
              setAllowClick={setAllowClick}
              selectItems={selectItems}
              setSelectItems={setSelectItems}
            />
          )}
        </Card>
      </Container>
      <Filter
        open={openFilter}
        toggle={toggleOpenFilter}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
      />
    </Style>
  );
};

export default CensorshipResult;
