import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { deliveryActions } from "Redux/Actions";
import * as queryString from "query-string";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useRef } from "react";
const SelectBarcode = ({
	transportStatus,
	productQuality,
	handleGetCustomerByBarcode,
	innerRef,
}) => {
	const dispatch = useDispatch();
	const { deliveries } = useSelector((state) => state.deliveryReducer);
	const [barcode, setBarcode] = useState("");
	const inputRef = useRef(null);
	let query = {
		limit: 10,
		transportStatus: transportStatus,
		productQuality: productQuality,
	};

	const searchBarcode = (key) => {
		for (const x in query) {
			if (!_.isEmpty(query[x])) {
				delete query[x];
			}
		}
		dispatch(
			deliveryActions.getDeliveries(
				queryString.stringify(key === "" ? query : { ...query, barcode: key })
			)
		);
	};
	useEffect(() => {
		dispatch(deliveryActions.getDeliveries(queryString.stringify(query)));
	}, []);
	useEffect(() => {
		if (!!inputRef?.current) {
			//inputRef.current.focus();
		}
	}, [inputRef]);
	return (
		<div>
			<Select
				innerRef={inputRef}
				placeholder="Nhập để tìm kiếm"
				isClearable={true}
				value={barcode}
				onChange={(e) => {
					setBarcode(e);
				}}
				options={deliveries.results.map((item) => ({
					label: item.barcode,
					value: item.id,
				}))}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleGetCustomerByBarcode(barcode);
					}
				}}
				onInputChange={(value) => {
					searchBarcode(value);
					setBarcode(value);
				}}
			/>
		</div>
	);
};

export default SelectBarcode;
