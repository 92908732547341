import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  CardFooter,
} from "reactstrap";
import { provincesActions } from "Redux/Actions";
// import { FilterContext } from "../ListOrder/context";
import queryString from "query-string";
import ReactSelect from "react-select";
import { orderActions } from "Redux/Actions";
import { productActions } from "Redux/Actions";
import { productTypesActions } from "Redux/Actions";
// import { Style } from "./style";
const Filter = ({ open, toggle, valueFilter, setValueFilter }) => {
  const range = (start, step) => {
    const date = new Date();
    const stop = date.getFullYear();
    return Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  };
  const [textSearchProduct, setTextSearchProduct] = useState("");
  const dispatch = useDispatch();
  const { provinces } = useSelector((state) => state.provincesReducer);
  const { products } = useSelector((state) => state.productReducer);
  const [payloadFilter, setPayloadFilter] = useState(valueFilter);

  const handleFindProduct = () => {
    if (textSearchProduct === "") {
      dispatch(productActions.getProducts(queryString.stringify({ limit: 5 })));
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({ limit: 5, name: textSearchProduct })
        )
      );
    }
  };
  useEffect(() => {
    dispatch(
      provincesActions.getProvinces(queryString.stringify({ limit: 100 }))
    );
  }, []);
  useEffect(() => {
    handleFindProduct();
  }, [textSearchProduct]);
  useEffect(() => {
    setPayloadFilter(valueFilter);
  }, [valueFilter]);
  return (
    <>
      <Card className={`filterOption ${open && "show"}`}>
        <CardHeader>
          <span
            style={{ cursor: "pointer" }}
            className="text-danger"
            onClick={toggle}
          >
            Đóng
          </span>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label className="form-control-label" htmlFor="example-email-input">
              Chọn sản phẩm
            </Label>
            <ReactSelect
              options={products.results.map((item) => {
                return { value: item.id, label: item.name };
              })}
              placeholder="Chọn"
              isClearable={true}
              value={payloadFilter?.productTypeId || null}
              onChange={(e) => {
                setPayloadFilter({ ...payloadFilter, productTypeId: e });
              }}
              onInputChange={(value) => setTextSearchProduct(value)}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            className="btn-block"
            onClick={() => {
              setValueFilter(null);
              setPayloadFilter({});
              toggle();
            }}
          >
            Xóa bộ lọc
          </Button>
          <Button
            className="btn-block"
            color="primary"
            onClick={() => {
              setValueFilter(payloadFilter);
            }}
          >
            Áp dụng bộ lọc
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Filter;
