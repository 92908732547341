import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import { Style } from '../style';
import Header from './components/Header';
import TableData from './components/TableData';
import { dataTable } from 'variables/general';
import BoxComment from 'views/pages/components/BoxComment';
import { SendMailSVG } from 'assets/svg';
import Filter from './components/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import InputCustom from 'views/pages/components/InputCustom';
import { measureSizeActions } from 'Redux/Actions';
import queryString from 'query-string';
import { checkRole } from 'common';
const CensorshipConversion = () => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const notificationAlertRef = useRef();
  const [valueFilter, setValueFilter] = useState({});
  const [currentOrder, setCurrentOrder] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [comment, setComment] = useState('');
  const { isApproveManyCustomersInOrder } = useSelector(
    (state) => state.orderReducer
  );
  const [listCustomerInOrderConvert, setListCustomerinOrderConvert] = useState(
    []
  );
  const { ordersConvert, isGetOrdersConvert } = useSelector(
    (state) => state.orderReducer
  );
  const { comments } = useSelector((state) => state.measureSizeReducer);
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleGetComments = () => {
    if (!_.isEmpty(currentOrder) && currentOrder !== '') {
      dispatch(
        measureSizeActions.getCommentsMeasureSizes(
          queryString.stringify({
            orderId: currentOrder,
            populate: 'userId',
            sortBy: 'time:desc',
          })
        )
      );
    }
  };
  const handleCreateComment = () => {
    dispatch(
      measureSizeActions.createCommentMeasureSize(
        {
          orderId: currentOrder,
          comment: comment,
        },
        {
          success: () => {
            handleGetComments();
            setComment('');
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi bình luận thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const hanldeAccept = () => {
    dispatch(
      orderActions.approveManyCustomerInOrder(
        { customerInOrderIds: listCustomerInOrderConvert },
        'true',
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Duyệt chuyển đổi số đo thành công!`
            );
            setListCustomerinOrderConvert([]);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Duyệt chuyển đổi số đo thất bại!, Lỗi : ${mess}`
            );
          },
        }
      )
    );
  };
  const handleReject = () => {
    dispatch(
      orderActions.approveManyCustomerInOrder(
        { customerInOrderIds: listCustomerInOrderConvert },
        'false',
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Từ chối chuyển đổi số đo thành công!`
            );
            setListCustomerinOrderConvert([]);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Từ chối chuyển đổi số đo thất bại!, Lỗi : ${mess}`
            );
          },
        }
      )
    );
  };
  useEffect(() => {
    handleGetComments();
    const loop = setInterval(() => {
      handleGetComments();
    }, 3000);
    return () => {
      clearInterval(loop);
    };
  }, [currentOrder]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Header
        name=""
        toggle={toggleOpenFilter}
        setCurrentOrders={setCurrentOrder}
        options={valueFilter}
      />
      <Container fluid className="mt--6">
        <Card>
          <CardHeader className="py-3">
            <Row className="justify-content-between align-items-center px-3">
              <h3 className="mb-0">Danh sách số đo đã chuyển đổi</h3>
              {checkRole(currentAccount, {
                roles: ['approve_customerInOrder'],
              }) && (
                <div className="d-flex py-0">
                  <Button
                    size="md"
                    color="danger"
                    disabled={listCustomerInOrderConvert.length === 0}
                    onClick={handleReject}
                  >
                    Từ chối
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    disabled={listCustomerInOrderConvert.length === 0}
                    onClick={hanldeAccept}
                  >
                    Duyệt
                  </Button>
                </div>
              )}
            </Row>
          </CardHeader>

          {!isApproveManyCustomersInOrder && (
            <TableData
              currentOrders={currentOrder}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
              listCustomerInOrderConvert={listCustomerInOrderConvert}
              setListCustomerinOrderConvert={setListCustomerinOrderConvert}
            />
          )}
        </Card>
        {currentOrder !== '' && (
          <Card className="px-5 py-3">
            <p className="h2 my-3">Lịch sử trao đổi thông tin</p>

            <BoxComment
              comments={!_.isEmpty(ordersConvert) ? comments.results : []}
            />
            <Row className="mt-3">
              <Col>
                <InputCustom
                  resize="none"
                  rows="2"
                  type="textarea"
                  placeholder="Nhập yêu cầu thêm"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Col>
              <Col style={{ maxWidth: '80px' }}>
                <button
                  className="btn-none"
                  onClick={() => {
                    handleCreateComment();
                  }}
                >
                  <SendMailSVG />
                </button>
              </Col>
            </Row>
          </Card>
        )}
      </Container>
      {openFilter && (
        <Filter
          open={openFilter}
          toggle={toggleOpenFilter}
          valueFilter={valueFilter}
          setValueFilter={setValueFilter}
        />
      )}
    </Style>
  );
};

export default CensorshipConversion;
