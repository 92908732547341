import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import orderActions from 'Redux/Actions/orderActions';
import { measureSizeActions } from 'Redux/Actions';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import FileDownload from 'js-file-download';
import { useHistory } from 'react-router';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
function Header({ toggle, handleExportProductSendCustomer, filterByName }) {
  const notificationAlertRef = useRef();
  const history = useHistory();

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-end py-4">
              <Col md="8">
                {_.isEmpty(filterByName) ? (
                  <></>
                ) : (
                  <>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {filterByName?.orderId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Đơn hàng: {filterByName?.orderId};
                      </span>
                    )}
                    {filterByName?.productId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Sản phẩm: {filterByName?.productId};
                      </span>
                    )}
                    {filterByName?.producerId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Nhà gia công: {filterByName?.producerId};
                      </span>
                    )}
                  </>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right pl-0" md="4">
                <Button
                  onClick={handleExportProductSendCustomer}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Xuất danh sách
                </Button>
                <Button
                  onClick={toggle}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Header;
