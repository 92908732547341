import React, { useEffect, useState, useRef } from 'react';
import { Style } from '../style';
import SimpleHeader from 'components/Headers/SimpleHeader';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Input,
} from 'reactstrap';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import InputCustom from 'views/pages/components/InputCustom';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import {
  customerActions,
  productionCommandActions,
  materialNormActions,
  producerActions,
  measurementStandardsActions,
  orderPlanActions,
} from 'Redux/Actions';
import * as yup from 'yup';
import Select from 'react-select';
import { Formik } from 'formik';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import moment from 'moment';
import Error from 'views/pages/components/Error';
import RequireCustom from 'views/pages/components/RequireCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { AddSVG, BinSVG } from 'assets/svg';
import makeAnimated from 'react-select/animated';
import { useParams, useHistory } from 'react-router-dom';
import CONSTANTS from 'constant';
import DatePicker from 'reactstrap-date-picker';
const animatedComponents = makeAnimated();

const Size = ({
  item,
  index,
  arrSize,
  setArrSize,
  productId,
  length,
  orderValue,
}) => {
  const dispatch = useDispatch();
  const { measurementStandards } = useSelector(
    (state) => state.measurementStandardsReducer
  );
  const [maxProduct, setMaxProduct] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(item?.sizeValue?.value) && !_.isEmpty(orderValue?.value)) {
      dispatch(
        productionCommandActions.getMaxProduct(
          queryString.stringify({
            orderId: orderValue.value,
            standardSizeId: item.sizeValue.value,
          }),
          {
            success: (data) => {
              setMaxProduct(data.maxProduct || 0);
            },
          }
        )
      );
    }
  }, [item?.sizeValue?.value]);

  return (
    <Row className="mb-2">
      <Col md="5">
        <Select
          isDisabled={_.isEmpty(productId)}
          className="mb-2 mt--1 text-sm"
          placeholder="Chọn size"
          name="customerParentId"
          onInputChange={(value) => {}}
          value={item.sizeValue}
          onChange={(e) => {
            setArrSize([
              ...arrSize.slice(0, index),
              {
                ...arrSize[index],
                sizeValue: e,
              },
              ...arrSize.slice(index + 1),
            ]);
          }}
          options={measurementStandards.results.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Col>

      <Col md="6">
        <InputCustom
          disabled={_.isEmpty(item.sizeValue)}
          className="w-50 max-height-input-custom"
          name="amount"
          onChange={(e) => {
            setArrSize([
              ...arrSize.slice(0, index),
              {
                ...arrSize[index],
                amount:
                  e.target.value === ''
                    ? ''
                    : Number(e.target.value) < 0
                    ? 0
                    : Number(e.target.value) > maxProduct
                    ? maxProduct
                    : Number(e.target.value),
              },
              ...arrSize.slice(index + 1),
            ]);
          }}
          value={item.amount}
          placeholder="Nhập số lượng"
          type="number"
        />
      </Col>
      <Col md="1" className="d-flex align-items-center justify-content-end">
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            let newArrSize = [
              ...arrSize.slice(0, index),
              ...arrSize.slice(index + 1),
            ];
            setArrSize(newArrSize);
          }}
        >
          {length !== 1 && <BinSVG />}
        </div>
      </Col>
    </Row>
  );
};

const UpdateProductionCommand = ({ location }) => {
  const notificationAlertRef = useRef();
  const dispatch = useDispatch();
  const { productionCommandId } = useParams();
  const history = useHistory();
  // reducer
  const { orders } = useSelector((state) => state.orderReducer);
  // const { producers } = useSelector((state) => state.producerReducer);
  const { allChildOfCustomer } = useSelector((state) => state.customerReducer);
  const { orderPlans } = useSelector((state) => state.orderPlanReducer);

  const [producerCuts, setProducerCuts] = useState({ results: [] });
  const [producerProductions, setProducerProductions] = useState({
    results: [],
  });
  const {
    isExcludeCustomerCommand,
    productionCommandById,
    isUpdateProductionCommand,
  } = useSelector((state) => state.productionCommandReducer);
  const { allProductInOrder } = useSelector(
    (state) => state.materialNormReducer
  );

  const [customersInOrderGeneral, setCustomersInOrderGeneral] = useState({
    results: [],
  });
  const [customersInOrderPrivate, setCustomersInOrderPrivate] = useState({
    results: [],
  });
  const [stageValue, setStageValue] = useState({
    label: 'Cả hai',
    value: '0',
  });
  const [isExcludeCustomerCommandGeneral, setIsExcludeCustomerCommandGeneral] =
    useState(false);

  const [isExcludeCustomerCommandPrivate, setIsExcludeCustomerCommandPrivate] =
    useState(false);
  const [calledGetCustomerInOrderGeneral, setCalledGetCustomerInOrderGeneral] =
    useState(false);
  const [calledGetCustomerInOrderPrivate, setCalledGetCustomerInOrderPrivate] =
    useState(false);

  // schema
  const productionCommandSchema = yup.object().shape({
    code: yup.string().required('Số lệnh không được để trống!'),
    name: yup.string().required('Tên lệnh SX không được để trống!'),
    orderId: yup.string().required('Vui lòng chọn đơn hàng!'),
    orderPlanId: yup.string().required('Vui lòng chọn sản phẩm!'),
    producerCutId: yup.string().required('Vui lòng chọn nhà gia công cắt!'),
    producerProductionId: yup
      .string()
      .required('Vui lòng chọn nhà gia công may!'),
    sewTypes: yup.array().min(1, 'Vui lòng chọn kiểu may!'),
  });

  const [focused, setFocused] = useState({
    orderId: false,
    orderPlanId: false,
    producerCutId: false,
    producerProductionId: false,
  });
  const [customerIds, setCustomerIds] = useState([]);
  const [getCustomerInOrderSucuess, setGetCustomerInOrderSucuess] =
    useState(false);
  const [sewTypes, setSewTypes] = useState({
    general: false,
    private1: false,
    private2: false,
    private3: false,
    private4: false,
    all: false,
  });
  const [errorCustomer, setErrorCustomer] = useState(false);
  const [selectItemsGeneral, setSelectItemsGeneral] = useState([]);
  const [selectItemsPrivate, setSelectItemsPrivate] = useState([]);
  const [
    getCustomerInOrderSucuessGeneral,
    setGetCustomerInOrderSucuessGeneral,
  ] = useState(false);
  const [
    getCustomerInOrderSucuessPrivate,
    setGetCustomerInOrderSucuessPrivate,
  ] = useState(false);
  // value select
  const [orderValue, setOrderValue] = useState(null);
  const [orderPlanValue, setOrderPlanValue] = useState(null);
  const [productValue, setProductValue] = useState(null);
  const [producerValue, setProducerValue] = useState(null);
  const [customerValues, setCustomerValues] = useState(null);
  const [producerCutValue, setProducerCutValue] = useState(null);
  const [producerProductionValue, setProducerProductionValue] = useState(null);
  const [arrCustomer, setArrCustomer] = useState([
    {
      customerParentValue: null,
      customerValues: null,
      allChildOfCustomer: { results: [] },
    },
  ]);

  const [valueFilterGeneral, setValueFilterGeneral] = useState({
    customerCode: '',
    customerOrgName: '',
  });
  const [valueFilterPrivate, setValueFilterPrivate] = useState({
    customerCode: '',
    customerOrgName: '',
  });

  const [productionCommandInfo, setProductionCommandInfo] = useState({
    code: '',
    name: '',
    orderId: '',
    productId: '',
    orderPlanId: '',
    producerCutId: '',
    producerProductionId: '',
    producerId: '',
    customerOrgIds: [],
    productionCommanDate: new Date().toISOString(),
    productSyncDate: new Date().toISOString(),
    embroiderDate: new Date().toISOString(),
    warehouseReceipDate: new Date().toISOString(),
    productReceiveDate: new Date().toISOString(),
    produceCutDate: new Date().toISOString(),
    qcDate: new Date().toISOString(),
    qcCustomerDate: new Date().toISOString(),
    notes: '',
    sewTypes: [],
    productionInfos: [],
  });
  // page
  const [pageCusomersInOrder, setPageCusomersInOrder] = useState(1);
  const [rowsPerCustomersInOrder, setRowsPerPageCusomerInOrder] = useState(10);
  const [pageCusomersInOrderGeneral, setPageCusomersInOrderGeneral] =
    useState(1);
  const [rowsPerCustomersInOrderGeneral, setRowsPerPageCusomerInOrderGeneral] =
    useState(10);
  const [pageCusomersInOrderPrivate, setPageCusomersInOrderPrivate] =
    useState(1);
  const [rowsPerCustomersInOrderPrivate, setRowsPerPageCusomerInOrderPrivate] =
    useState(10);

  // search
  const [productSearch, setProductSearch] = useState('');
  const [orderSearch, setOrderSearch] = useState('');
  const [orderPlanSearch, setOrderPlanSearch] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [productionCommandInfos, setProductionCommandInfos] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [producerCutSearch, setProducerCutSearch] = useState('');
  const [producerProductionSearch, setProducerProductionSearch] = useState('');
  const [arrSize, setArrSize] = useState([
    {
      sizeValue: null,
      amount: '',
    },
  ]);

  // query
  const [queryOrder, setQueryOrder] = useState({
    limit: 10,
    page: 1,
  });

  const [customerQuery, setCustomerQuery] = useState({
    limit: 10,
    page: 1,
  });
  const [queryCustomersInOrder, setQueryCustomersInOrder] = useState({
    page: pageCusomersInOrder,
    limit: rowsPerCustomersInOrder,
    populate: 'productTypeId, customerOrgId, customerSizeId, standardSizeId',
  });

  const [queryCustomersInOrderGeneral, setQueryCustomersInOrderGeneral] =
    useState({
      page: pageCusomersInOrderGeneral,
      limit: rowsPerCustomersInOrderGeneral,
      populate: 'productTypeId, customerOrgId, customerSizeId, standardSizeId',
    });
  const [queryCustomersInOrderPrivate, setQueryCustomersInOrderPrivate] =
    useState({
      page: pageCusomersInOrderPrivate,
      limit: rowsPerCustomersInOrderPrivate,
      populate: 'productTypeId, customerOrgId, customerSizeId, standardSizeId',
    });

  const selectRowGeneral = {
    mode: 'checkbox',
    selectColumnPosition: 'right',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const temp = [...selectItemsGeneral, row.id];
        setSelectItemsGeneral(temp);
      } else {
        let temp = [...selectItemsGeneral];
        const index = selectItemsGeneral.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
        setSelectItemsGeneral(temp);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const temp = rows.map((item) => item.id);
        setSelectItemsGeneral(temp);
      } else {
        setSelectItemsGeneral([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="text-md-right" style={{ verticalAlign: 'inherit' }}>
        Lựa chọn{' '}
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="d-flex justify-content-end">
        <input type={mode} {...rest} />
      </div>
    ),
    selected: selectItemsGeneral,
  };
  const selectRowPrivate = {
    mode: 'checkbox',
    selectColumnPosition: 'right',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const temp = [...selectItemsPrivate, row.id];
        setSelectItemsPrivate(temp);
      } else {
        let temp = [...selectItemsPrivate];
        const index = selectItemsPrivate.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
        setSelectItemsPrivate(temp);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const temp = rows.map((item) => item.id);
        setSelectItemsPrivate(temp);
      } else {
        setSelectItemsPrivate([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="text-md-right" style={{ verticalAlign: 'inherit' }}>
        Lựa chọn{' '}
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="d-flex justify-content-end">
        <input type={mode} {...rest} />
      </div>
    ),
    selected: selectItemsPrivate,
  };

  const columnsGeneral = [
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterGeneral.customerCode}
            onChange={(e) => {
              setValueFilterGeneral({
                ...valueFilterGeneral,
                customerCode: e.target.value,
              });
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Họ và tên',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterGeneral.customerOrgName}
            onChange={(e) => {
              setValueFilterGeneral({
                ...valueFilterGeneral,
                customerOrgName: e.target.value,
              });
            }}
            placeholder="Phòng ban"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productTypeId.code',
      text: 'Mã SP KH',
    },
    {
      dataField: 'barcode',
      text: 'Mã barcode',
    },
    {
      dataField: 'quota',
      text: 'Số lượng SX',
      headerStyle: {
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'standardSizeId.name',
      text: 'Size',
    },
    {
      dataField: 'customerSizeId.wearType',
      text: 'Mặc',
    },
    // {
    //   dataField: "customerSizeId.notes",
    //   text: "Ghi chú",
    //   headerStyle: {
    //     textAlign: "center",
    //   },
    //   style: {
    //     textAlign: "left",
    //   },
    // },
  ];
  const columnsPrivate = [
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterPrivate.customerCode}
            onChange={(e) => {
              setValueFilterPrivate({
                ...valueFilterPrivate,
                customerCode: e.target.value,
              });
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Họ và tên',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterPrivate.customerOrgName}
            onChange={(e) => {
              setValueFilterPrivate({
                ...valueFilterPrivate,
                customerOrgName: e.target.value,
              });
            }}
            placeholder="Phòng ban"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productTypeId.code',
      text: 'Mã SP KH',
    },
    {
      dataField: 'barcode',
      text: 'Mã barcode',
    },
    {
      dataField: 'quota',
      text: 'Số lượng SX',
      headerStyle: {
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'standardSizeId.name',
      text: 'Size',
    },
    {
      dataField: 'customerSizeId.wearType',
      text: 'Mặc',
    },
    // {
    //   dataField: "customerSizeId.notes",
    //   text: "Ghi chú",
    //   headerStyle: {
    //     textAlign: "center",
    //   },
    //   style: {
    //     textAlign: "left",
    //   },
    // },
  ];

  const onSizePerPageChangeGeneral = (value) => {
    // setValueFilterGeneral({
    //   customerCode: "",
    //   customerOrgName: "",
    // });
    setRowsPerPageCusomerInOrderGeneral(value);
    setPageCusomersInOrderGeneral(1);

    setQueryCustomersInOrderGeneral({
      ...queryCustomersInOrderGeneral,
      page: 1,
      limit: value,
    });
  };
  const onSizePerPageChangePrivate = (value) => {
    // setValueFilterPrivate({
    //   customerCode: "",
    //   customerOrgName: "",
    // });
    setRowsPerPageCusomerInOrderPrivate(value);
    setPageCusomersInOrderPrivate(1);

    setQueryCustomersInOrderPrivate({
      ...queryCustomersInOrderPrivate,
      page: 1,
      limit: value,
    });
  };

  const paginationCustomersInOrderGeneral = paginationFactory({
    page: pageCusomersInOrderGeneral,
    onPageChange: (value) => {
      // setValueFilterGeneral({
      //   customerCode: "",
      //   customerOrgName: "",
      // });
      setPageCusomersInOrderGeneral(value);
      setQueryCustomersInOrderGeneral({
        ...queryCustomersInOrderGeneral,
        page: value,
      });
    },
    sizePerPage: rowsPerCustomersInOrderGeneral,
    totalSize: !isNaN(customersInOrderGeneral?.totalResults)
      ? customersInOrderGeneral.totalResults
      : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerCustomersInOrderGeneral}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChangeGeneral(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ{' '}
            {(pageCusomersInOrderGeneral - 1) * rowsPerCustomersInOrderGeneral +
              1}{' '}
            đến{' '}
            {pageCusomersInOrderGeneral * rowsPerCustomersInOrderGeneral >
            customersInOrderGeneral.totalResults
              ? !isNaN(customersInOrderGeneral?.totalResults)
                ? customersInOrderGeneral.totalResults
                : 0
              : pageCusomersInOrderGeneral *
                rowsPerCustomersInOrderGeneral}{' '}
            trong số{' '}
            {!isNaN(customersInOrderGeneral?.totalResults)
              ? customersInOrderGeneral.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });
  const paginationCustomersInOrderPrivate = paginationFactory({
    page: pageCusomersInOrderPrivate,
    onPageChange: (value) => {
      // setValueFilterPrivate({
      //   customerCode: "",
      //   customerOrgName: "",
      // });
      setPageCusomersInOrderPrivate(value);
      setQueryCustomersInOrderPrivate({
        ...queryCustomersInOrderPrivate,
        page: value,
      });
    },
    sizePerPage: rowsPerCustomersInOrderPrivate,
    totalSize: !isNaN(customersInOrderPrivate?.totalResults)
      ? customersInOrderPrivate.totalResults
      : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerCustomersInOrderPrivate}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChangePrivate(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ{' '}
            {(pageCusomersInOrderPrivate - 1) * rowsPerCustomersInOrderPrivate +
              1}{' '}
            đến{' '}
            {pageCusomersInOrderPrivate * rowsPerCustomersInOrderPrivate >
            customersInOrderPrivate.totalResults
              ? !isNaN(customersInOrderPrivate?.totalResults)
                ? customersInOrderPrivate.totalResults
                : 0
              : pageCusomersInOrderPrivate *
                rowsPerCustomersInOrderPrivate}{' '}
            trong số{' '}
            {!isNaN(customersInOrderPrivate?.totalResults)
              ? customersInOrderPrivate.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  // handle get
  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(orderActions.getOrders(queryString.stringify(queryOrder)));
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrder, name: orderSearch })
        )
      );
    }
  };

  const handleGetAllProductInOrder = () => {
    if (!_.isEmpty(orderValue)) {
      if (productSearch) {
        dispatch(
          materialNormActions.getAllProductInOrder(
            orderValue.value,
            queryString.stringify({ sortBy: 'name' })
          )
        );
      } else {
        dispatch(
          materialNormActions.getAllProductInOrder(
            orderValue.value,
            queryString.stringify({ sortBy: 'name', name: productSearch })
          )
        );
      }
    }
  };

  const handleGetProducerCuts = () => {
    if (producerCutSearch === '') {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({
            limit: 10,
            page: 1,
            type: CONSTANTS.PRODUCER_TYPE.CUT,
          }),
          {
            success: (data) => {
              setProducerCuts(data);
            },
          }
        )
      );
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({
            limit: 10,
            page: 1,
            type: CONSTANTS.PRODUCER_TYPE.CUT,
            name: producerCutSearch,
          }),
          {
            success: (data) => {
              setProducerCuts(data);
            },
          }
        )
      );
    }
  };

  const handleGetProducerProductions = () => {
    if (producerProductionSearch === '') {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({
            limit: 10,
            page: 1,
            type: CONSTANTS.PRODUCER_TYPE.PRODUCTION,
          }),
          {
            success: (data) => {
              setProducerProductions(data);
            },
          }
        )
      );
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({
            limit: 10,
            page: 1,
            type: CONSTANTS.PRODUCER_TYPE.PRODUCTION,
            name: producerProductionSearch,
          }),
          {
            success: (data) => {
              setProducerProductions(data);
            },
          }
        )
      );
    }
  };

  const handleGetCustomers = () => {
    if (customerSearch === '') {
      dispatch(
        customerActions.getCustomers(queryString.stringify(customerQuery))
      );
    } else {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({ ...customerQuery, name: customerSearch })
        )
      );
    }
  };

  const handleGetCustomersInOrderGeneral = (sewTypes) => {
    const tempQuerySearch = _.cloneDeep(valueFilterGeneral);
    if (tempQuerySearch.customerCode === '') {
      delete tempQuerySearch.customerCode;
    }
    if (tempQuerySearch.customerOrgName === '') {
      delete tempQuerySearch.customerOrgName;
    }
    if (_.isEmpty(productionCommandId) || !sewTypes.general) {
      setCustomersInOrderGeneral({ results: [] });
      return;
    }
    setSelectItemsGeneral([]);
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderGeneral,
            productionCommandId: productionCommandId,
            sewTypes: [-1],
            ...tempQuerySearch,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setCalledGetCustomerInOrderGeneral(true);
            setCustomersInOrderGeneral(data);
            setGetCustomerInOrderSucuessGeneral(true);
          },
          failed: () => {
            setCustomersInOrderGeneral({ results: [] });
            setGetCustomerInOrderSucuessGeneral(false);
          },
        }
      )
    );
  };

  const handleGetCustomersInOrderPrivate = (sewTypes) => {
    const tempQuerySearch = _.cloneDeep(valueFilterPrivate);
    if (tempQuerySearch.customerCode === '') {
      delete tempQuerySearch.customerCode;
    }
    if (tempQuerySearch.customerOrgName === '') {
      delete tempQuerySearch.customerOrgName;
    }
    const tempSewTypes = [];
    if (
      _.isEmpty(productionCommandId) ||
      (!sewTypes.private1 &&
        !sewTypes.private2 &&
        !sewTypes.private3 &&
        !sewTypes.private4)
    ) {
      setCustomersInOrderPrivate({ results: [] });
      return;
    } else if (sewTypes.private1) {
      tempSewTypes.push(1);
    } else if (sewTypes.private2) {
      tempSewTypes.push(2);
    } else if (sewTypes.private3) {
      tempSewTypes.push(3);
    } else if (sewTypes.private4) {
      tempSewTypes.push(0);
    }
    setSelectItemsPrivate([]);
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderPrivate,
            productionCommandId: productionCommandId,
            sewTypes: tempSewTypes,
            ...tempQuerySearch,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setCalledGetCustomerInOrderPrivate(true);
            setCustomersInOrderPrivate(data);
            setGetCustomerInOrderSucuessPrivate(true);
          },
          failed: () => {
            setCustomersInOrderPrivate({ results: [] });
            setGetCustomerInOrderSucuessPrivate(false);
          },
        }
      )
    );
  };

  const handleGetProductionCommandById = () => {
    if (!_.isEmpty(productionCommandId)) {
      dispatch(
        productionCommandActions.getProductionCommandById(
          productionCommandId,
          queryString.stringify({
            populate:
              'orderId.customerOrganizationId, customerOrgIds, producerCutId, producerProductionId, productId, productionInfos.standardSizeId, orderPlanId',
          })
        )
      );
    }
  };

  const handleGetAllChildOfCustomer = () => {
    if (!_.isEmpty(orderValue?.customerOrganizationId)) {
      if (customerSearch === '') {
        dispatch(
          customerActions.getAllChildOfCustomer(
            orderValue?.customerOrganizationId,
            queryString.stringify({})
          )
        );
      } else {
        dispatch(
          customerActions.getAllChildOfCustomer(
            orderValue?.customerOrganizationId,
            queryString.stringify({ name: customerSearch })
          )
        );
      }
    }
  };

  const handleGetOrderPlans = () => {
    if (_.isEmpty(orderValue)) return;
    if (orderPlanSearch === '') {
      dispatch(
        orderPlanActions.getOrderPlans(
          queryString.stringify(
            {
              orderIds: [orderValue.value],
              orderPlanStatus: [CONSTANTS.ORDER_PLAN_STATUS.Accept],
              populate: 'productId',
            },
            { arrayFormat: 'bracket' }
          )
        )
      );
    } else {
      dispatch(
        orderPlanActions.getOrderPlans(
          queryString.stringify(
            {
              orderIds: [orderValue.value],
              name: orderPlanSearch,
              orderPlanStatus: [CONSTANTS.ORDER_PLAN_STATUS.Accept],
              populate: 'productId',
            },
            { arrayFormat: 'bracket' }
          )
        )
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      calledGetCustomerInOrderGeneral &&
        handleGetCustomersInOrderGeneral(sewTypes);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [valueFilterGeneral]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      calledGetCustomerInOrderPrivate &&
        handleGetCustomersInOrderPrivate(sewTypes);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [valueFilterPrivate]);

  // useEffect
  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);

  useEffect(() => {
    handleGetAllProductInOrder();
  }, [productSearch, orderValue]);

  useEffect(() => {
    // handleGetProducers();
  }, [producerSearch]);

  useEffect(() => {
    handleGetProducerCuts();
  }, [producerCutSearch]);
  useEffect(() => {
    handleGetProducerProductions();
  }, [producerProductionSearch]);

  useEffect(() => {
    handleGetCustomers();
  }, [customerSearch]);

  useEffect(() => {
    handleGetAllChildOfCustomer();
  }, [orderValue]);

  useEffect(() => {
    handleGetProductionCommandById();
  }, [productionCommandId]);

  useEffect(() => {
    setGetCustomerInOrderSucuessGeneral(false);
    handleGetCustomersInOrderGeneral(sewTypes);
  }, [queryCustomersInOrderGeneral]);

  useEffect(() => {
    setGetCustomerInOrderSucuessPrivate(false);
    handleGetCustomersInOrderPrivate(sewTypes);
  }, [queryCustomersInOrderPrivate]);

  useEffect(() => {
    handleGetOrderPlans();
  }, [orderPlanSearch, orderValue]);

  useEffect(() => {
    if (!_.isEmpty(productionCommandById)) {
      setProductionCommandInfo({
        code: productionCommandById?.code,
        name: productionCommandById?.name,
        orderId: productionCommandById?.orderId?.id,
        productId: productionCommandById?.orderPlanId?.productId?.id,
        orderPlanId: productionCommandById?.orderPlanId?.id,
        producerCutId: productionCommandById?.producerCutId?.id,
        producerProductionId: productionCommandById?.producerProductionId?.id,
        customerOrgIds: productionCommandById?.customerOrgIds?.map(
          (item) => item.id
        ),
        productionCommanDate: moment(
          productionCommandById?.productionCommanDate
        ).toISOString(),
        productSyncDate: moment(
          productionCommandById?.productSyncDate
        ).toISOString(),
        embroiderDate: moment(
          productionCommandById?.embroiderDate
        ).toISOString(),
        warehouseReceipDate: moment(
          productionCommandById?.warehouseReceipDate
        ).toISOString(),
        productReceiveDate: moment(
          productionCommandById?.productReceiveDate
        ).toISOString(),
        produceCutDate: moment(
          productionCommandById?.produceCutDate
        ).toISOString(),
        qcDate: moment(productionCommandById?.qcDate).toISOString(),
        qcCustomerDate: moment(
          productionCommandById?.qcCustomerDate
        ).toISOString(),
        notes: productionCommandById?.notes,
        sewTypes: productionCommandById?.sewTypes || [],
      });
      setProductionCommandInfos(productionCommandById?.productionCommandInfos);
      if (!_.isEmpty(productionCommandById?.sewTypes)) {
        // setSewTypes({
        //   general: productionCommandById?.sewTypes.includes(-1) ? true : false,
        //   private1: productionCommandById?.sewTypes.includes(1) ? true : false,
        //   private2: productionCommandById?.sewTypes.includes(2) ? true : false,
        //   private3: productionCommandById?.sewTypes.includes(3) ? true : false,
        //   private4: productionCommandById?.sewTypes.includes(0) ? true : false,
        // });
        handleCheckAll({
          ...sewTypes,
          general: productionCommandById?.sewTypes.includes(-1) ? true : false,
          private1: productionCommandById?.sewTypes.includes(1) ? true : false,
          private2: productionCommandById?.sewTypes.includes(2) ? true : false,
          private3: productionCommandById?.sewTypes.includes(3) ? true : false,
          private4: productionCommandById?.sewTypes.includes(0) ? true : false,
        });
      }
      if (!_.isEmpty(productionCommandById?.productionInfos)) {
        setArrSize(
          productionCommandById.productionInfos.map((item) => ({
            sizeValue: {
              label: item.standardSizeId.name,
              value: item.standardSizeId.id,
            },
            amount: item.amount,
          }))
        );
      } else {
        setArrSize([
          {
            sizeValue: null,
            amount: '',
          },
        ]);
      }
      setOrderValue({
        label: productionCommandById?.orderId?.name,
        value: productionCommandById?.orderId?.id,
        customerOrganizationId:
          productionCommandById?.orderId?.customerOrganizationId?.id,
        customerOrganizationName:
          productionCommandById?.orderId?.customerOrganizationId?.name,
      });
      setProductValue({
        label: productionCommandById?.orderPlanId?.productId?.name,
        value: productionCommandById?.orderPlanId?.productId?.id,
      });
      setOrderPlanValue({
        label: productionCommandById?.orderPlanId?.name,
        value: productionCommandById?.orderPlanId?.id,
        productId: productionCommandById?.productId,
      });
      handleGetMeasurementStandards(
        productionCommandById?.orderPlanId?.productId?.id
      );
      // setProducerValue({
      //   label: productionCommandById?.producerId?.name,
      //   value: productionCommandById?.producerId?.id,
      // });
      if (!_.isEmpty(productionCommandById?.producerProductionId)) {
        setProducerProductionValue({
          label: productionCommandById?.producerProductionId?.name,
          value: productionCommandById?.producerProductionId?.id,
        });
      }
      if (!_.isEmpty(productionCommandById?.producerCutId)) {
        setProducerCutValue({
          label: productionCommandById?.producerCutId?.name,
          value: productionCommandById?.producerCutId?.id,
        });
      }
      setCustomerValues(
        productionCommandById?.customerOrgIds?.map((item) => ({
          value: item.id,
          label: `${item.code}-${item.name}`,
        }))
      );
      handleGetCustomersInOrderGeneral({
        general: productionCommandById?.sewTypes.includes(-1) ? true : false,
        private1: productionCommandById?.sewTypes.includes(1) ? true : false,
        private2: productionCommandById?.sewTypes.includes(2) ? true : false,
        private3: productionCommandById?.sewTypes.includes(3) ? true : false,
        private4: productionCommandById?.sewTypes.includes(0) ? true : false,
      });
      handleGetCustomersInOrderPrivate({
        general: productionCommandById?.sewTypes.includes(-1) ? true : false,
        private1: productionCommandById?.sewTypes.includes(1) ? true : false,
        private2: productionCommandById?.sewTypes.includes(2) ? true : false,
        private3: productionCommandById?.sewTypes.includes(3) ? true : false,
        private4: productionCommandById?.sewTypes.includes(0) ? true : false,
      });
    }
  }, [productionCommandById]);

  const onSubmit = (values, actions) => {
    const tempProductionInfos = [];
    arrSize.forEach((item) => {
      if (!_.isEmpty(item?.sizeValue) && item.amount !== '') {
        tempProductionInfos.push({
          standardSizeId: item.sizeValue.value,
          amount: item.amount,
        });
      }
    });

    const body = {
      ...values,
      productionInfos: tempProductionInfos,
    };
    dispatch(
      productionCommandActions.updateProductionCommand(
        body,
        productionCommandId,
        {
          success: (data) => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật lệnh sản xuất thành công`
            );
            setProductionCommandInfos(data?.productionCommandInfos);
            handleGetCustomersInOrderGeneral({
              general: values?.sewTypes.includes(-1) ? true : false,
              private1: values?.sewTypes.includes(1) ? true : false,
              private2: values?.sewTypes.includes(2) ? true : false,
              private3: values?.sewTypes.includes(3) ? true : false,
              private4: values?.sewTypes.includes(0) ? true : false,
            });
            handleGetCustomersInOrderPrivate({
              general: values?.sewTypes.includes(-1) ? true : false,
              private1: values?.sewTypes.includes(1) ? true : false,
              private2: values?.sewTypes.includes(2) ? true : false,
              private3: values?.sewTypes.includes(3) ? true : false,
              private4: values?.sewTypes.includes(0) ? true : false,
            });
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật lệnh sản xuất thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleExcludeCustomerCommandGeneral = () => {
    // if (!_.isEmpty(selectItemsGeneral)) {
    dispatch(
      productionCommandActions.excludeCustomerCommand(
        productionCommandId,
        {
          excludeCustomerInOrderIds: selectItemsGeneral,
        },
        {
          success: () => {
            setIsExcludeCustomerCommandGeneral(false);
            handleGetCustomersInOrderGeneral(sewTypes);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `${
                selectItemsGeneral.length === 0 ? 'Lưu' : 'Loại bỏ'
              } khách hàng thành công!`
            );
            setSelectItemsGeneral([]);
          },
          failed: (mess) => {
            setIsExcludeCustomerCommandGeneral(false);
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `${
                selectItemsGeneral.length === 0 ? 'Lưu' : 'Loại bỏ'
              } khách hàng thất bại. Lỗi: ${mess} !`
            );
          },
        }
      )
    );
  };

  const handleExcludeCustomerCommandPrivate = () => {
    dispatch(
      productionCommandActions.excludeCustomerCommand(
        productionCommandId,
        {
          excludeCustomerInOrderIds: selectItemsPrivate,
        },
        {
          success: () => {
            setIsExcludeCustomerCommandPrivate(false);
            handleGetCustomersInOrderPrivate(sewTypes);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `${
                selectItemsPrivate.length === 0 ? 'Lưu' : 'Loại bỏ'
              } khách hàng thành công!`
            );
            setSelectItemsPrivate([]);
          },
          failed: (mess) => {
            setIsExcludeCustomerCommandPrivate(false);
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `${
                selectItemsPrivate.length === 0 ? 'Lưu' : 'Loại bỏ'
              } khách hàng thất bại. Lỗi: ${mess} !`
            );
          },
        }
      )
    );
    // } else {
    //   setIsExcludeCustomerCommandPrivate(false);
    //   notify(
    //     notificationAlertRef,
    //     "warning",
    //     "Thông báo",
    //     `Vui lòng chọn khách hàng để loại bỏ!`
    //   );
    // }
  };

  const handleGetMeasurementStandards = (id) => {
    dispatch(
      measurementStandardsActions.getMeasurementStandards(
        queryString.stringify({ productTypeId: id, limit: 999 })
      )
    );
  };

  const clearData = () => {
    setSewTypes({
      general: false,
      private1: false,
      private2: false,
      private3: false,
      private4: false,
      all: false,
    });
    setOrderValue(null);
    setProductValue(null);
    setProducerValue(null);
    setArrCustomer([
      {
        customerParentValue: null,
        customerValues: null,
        allChildOfCustomer: { results: [] },
      },
    ]);
    setProductionCommandInfo({
      code: '',
      name: '',
      orderId: '',
      productId: '',
      orderPlanId: '',
      producerId: '',
      customerOrgIds: [],
      productionCommanDate: new Date().toISOString(),
      productSyncDate: new Date().toISOString(),
      embroiderDate: new Date().toISOString(),
      warehouseReceipDate: new Date().toISOString(),
      productReceiveDate: new Date().toISOString(),
      produceCutDate: new Date().toISOString(),
      qcDate: new Date().toISOString(),
      qcCustomerDate: new Date().toISOString(),
      notes: '',
    });
    setPageCusomersInOrderGeneral(1);
    setRowsPerPageCusomerInOrderGeneral(10);
    setPageCusomersInOrderPrivate(1);
    setRowsPerPageCusomerInOrderPrivate(10);
  };

  const handleCheckAll = (sewTypes) => {
    let checkAll = true;
    Object.keys(sewTypes).map(function (key, index) {
      if (sewTypes[key] === false && key !== 'all') {
        checkAll = false;
      }
    });
    setSewTypes({
      ...sewTypes,
      all: checkAll,
    });
  };

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name="Cập nhật lệnh sản xuất"></SimpleHeader>
      <Container fluid className="mt--6">
        <Formik
          initialValues={productionCommandInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={productionCommandSchema}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
          }) => {
            return (
              <>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="7" style={{ borderRight: '1px solid #DADADA' }}>
                        <Row className="mb-2">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Số lệnh
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <InputCustom
                              className="w-50 max-height-input-custom"
                              name="code"
                              messageInvalid={errors.code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.code}
                              invalid={touched.code && errors.code}
                              placeholder="Nhập mã lệnh"
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Tên lệnh SX
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <InputCustom
                              className="max-height-input-custom"
                              name="name"
                              invalid={touched.name && errors.name}
                              messageInvalid={errors.name}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Nhập tên lệnh"
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Tên đơn hàng <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              className="mb-2 mt--1 text-sm"
                              placeholder="Chọn mã đơn hàng"
                              name="orderId"
                              onInputChange={(value) => {
                                setOrderSearch(value);
                              }}
                              value={orderValue}
                              onChange={(e) => {
                                setOrderValue(e);
                                setFieldValue('orderId', e ? e.value : '');
                                setProductValue(null);
                              }}
                              options={orders.results.map((item) => ({
                                label: item.name,
                                value: item.id,
                                customerOrganizationId:
                                  item?.customerOrganizationId,
                              }))}
                              isDisabled={true}
                              onFocus={() => {
                                setFocused({
                                  ...focused,
                                  orderId: true,
                                });
                              }}
                            />
                            {focused.orderId && errors.orderId && (
                              <Error messageInvalid={errors.orderId} />
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Kế hoạch SX
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              isDisabled={_.isEmpty(orderValue)}
                              className="mb-2 mt--1 text-sm w-50"
                              placeholder="Chọn kế hoạch SX"
                              onInputChange={(value) => {
                                setOrderPlanSearch(value);
                              }}
                              value={orderPlanValue}
                              onChange={(e) => {
                                if (!!e) {
                                  setFieldValue('productId', e.productId.id);
                                  handleGetMeasurementStandards(e.productId.id);
                                }
                                setOrderPlanValue(e);
                                setFieldValue('orderPlanId', e ? e.value : '');
                              }}
                              options={orderPlans.results.map((item) => ({
                                label: item.name,
                                value: item.id,
                                productId: { ...item.productId },
                              }))}
                              onFocus={() => {
                                setFocused({
                                  ...focused,
                                  orderPlanId: true,
                                });
                              }}
                            />
                            {focused.orderPlanId && errors.orderPlanId && (
                              <Error messageInvalid={errors.orderPlanId} />
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Sản phẩm
                            </p>
                          </Col>
                          <Col md="9">
                            {!_.isEmpty(orderPlanValue) ? (
                              orderPlanValue?.productId?.name
                            ) : (
                              <span style={{ color: '#fb6340' }}>
                                Vui lòng chọn kế hoạch sản xuất!
                              </span>
                            )}
                          </Col>
                        </Row>
                        {/* <Row className="mb-3">
													<Col className="d-flex align-items-center" md="3">
														<p className="text-sm font-weight-500 h3 mb-0">
															Sản phẩm
															<RequireCustom />
														</p>
													</Col>
													<Col md="9">
														<Select
															isDisabled={_.isEmpty(orderValue)}
															className="mb-2 mt--1 text-sm w-50"
															placeholder="Chọn sản phẩm"
															onInputChange={(value) => {
																setProductSearch(value);
															}}
															value={productValue}
															onChange={(e) => {
																setProductValue(e);
																setFieldValue("productId", !!e ? e.value : "");
																if (!!e) {
																	handleGetMeasurementStandards(e.value);
																}
															}}
															options={allProductInOrder.results.map(
																(item) => ({
																	label: `${item.code}-${item.name}`,
																	value: item._id,
																})
															)}
															onFocus={() => {
																setFocused({
																	...focused,
																	productId: true,
																});
															}}
														/>
														{focused.productId && errors.productId && (
															<Error messageInvalid={errors.productId} />
														)}
													</Col>
												</Row> */}
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Nhà gia công may
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              className="mb-2 mt--1 text-sm"
                              placeholder="Chọn nhà gia công"
                              name="producerId"
                              onInputChange={(value) => {
                                setProducerProductionSearch(value);
                              }}
                              value={producerProductionValue}
                              onChange={(e) => {
                                setProducerProductionValue(e);
                                setFieldValue(
                                  'producerProductionId',
                                  e ? e.value : ''
                                );
                              }}
                              options={producerProductions.results.map(
                                (item) => ({
                                  label: `${item.code}-${item.name}`,
                                  value: item.id,
                                })
                              )}
                              onFocus={() => {
                                setFocused({
                                  ...focused,
                                  producerProductionId: true,
                                });
                              }}
                            />
                            {focused.producerProductionId &&
                              errors.producerProductionId && (
                                <Error
                                  messageInvalid={errors.producerProductionId}
                                />
                              )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Nhà gia công cắt
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              className="mb-2 mt--1 text-sm"
                              placeholder="Chọn nhà gia công"
                              name="producerCutId"
                              onInputChange={(value) => {
                                setProducerCutSearch(value);
                              }}
                              value={producerCutValue}
                              onChange={(e) => {
                                setProducerCutValue(e);
                                setFieldValue(
                                  'producerCutId',
                                  e ? e.value : ''
                                );
                              }}
                              options={producerCuts.results.map((item) => ({
                                label: `${item.code}-${item.name}`,
                                value: item.id,
                              }))}
                              onFocus={() => {
                                setFocused({
                                  ...focused,
                                  producerCutId: true,
                                });
                              }}
                            />
                            {focused.producerCutId && errors.producerCutId && (
                              <Error messageInvalid={errors.producerCutId} />
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-start" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Chi nhánh
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              isDisabled={true}
                              className="mb-2 mt--1 text-sm"
                              placeholder="Chọn chi nhánh"
                              name="customerParentId"
                              value={
                                !_.isEmpty(orderValue)
                                  ? {
                                      label:
                                        orderValue?.customerOrganizationName,
                                      value: orderValue?.customerOrganizationId,
                                    }
                                  : null
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-general"
                              type="checkbox"
                              checked={sewTypes.general}
                              onChange={(e) => {
                                // setSewTypes({
                                //   ...sewTypes,
                                //   general: e.target.checked,
                                // });
                                handleCheckAll({
                                  ...sewTypes,
                                  general: e.target.checked,
                                });
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, -1])
                                  );
                                } else {
                                  const index = values.sewTypes.findIndex(
                                    (item) => item === -1
                                  );
                                  if (index !== -1) {
                                    setFieldValue('sewTypes', [
                                      ...values.sewTypes.slice(0, index),
                                      ...values.sewTypes.slice(index + 1),
                                    ]);
                                  }
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">Chọn may số đo chung</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private1}
                              onChange={(e) => {
                                // setSewTypes({
                                //   ...sewTypes,
                                //   private1: e.target.checked,
                                // });
                                handleCheckAll({
                                  ...sewTypes,
                                  private1: e.target.checked,
                                });
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, 1])
                                  );
                                } else {
                                  const index = values.sewTypes.findIndex(
                                    (item) => item === 1
                                  );
                                  if (index !== -1) {
                                    setFieldValue('sewTypes', [
                                      ...values.sewTypes.slice(0, index),
                                      ...values.sewTypes.slice(index + 1),
                                    ]);
                                  }
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 1</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private2}
                              onChange={(e) => {
                                // setSewTypes({
                                //   ...sewTypes,
                                //   private2: e.target.checked,
                                // });
                                handleCheckAll({
                                  ...sewTypes,
                                  private2: e.target.checked,
                                });
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, 2])
                                  );
                                } else {
                                  const index = values.sewTypes.findIndex(
                                    (item) => item === 2
                                  );
                                  if (index !== -1) {
                                    setFieldValue('sewTypes', [
                                      ...values.sewTypes.slice(0, index),
                                      ...values.sewTypes.slice(index + 1),
                                    ]);
                                  }
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 2</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private3}
                              onChange={(e) => {
                                // setSewTypes({
                                //   ...sewTypes,
                                //   private3: e.target.checked,
                                // });
                                handleCheckAll({
                                  ...sewTypes,
                                  private3: e.target.checked,
                                });
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, 3])
                                  );
                                } else {
                                  const index = values.sewTypes.findIndex(
                                    (item) => item === 3
                                  );
                                  if (index !== -1) {
                                    setFieldValue('sewTypes', [
                                      ...values.sewTypes.slice(0, index),
                                      ...values.sewTypes.slice(index + 1),
                                    ]);
                                  }
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 3</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private4}
                              onChange={(e) => {
                                setSewTypes({
                                  ...sewTypes,
                                  private4: e.target.checked,
                                });
                                handleCheckAll({
                                  ...sewTypes,
                                  private4: e.target.checked,
                                });
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, 0])
                                  );
                                } else {
                                  const index = values.sewTypes.findIndex(
                                    (item) => item === 0
                                  );
                                  if (index !== -1) {
                                    setFieldValue('sewTypes', [
                                      ...values.sewTypes.slice(0, index),
                                      ...values.sewTypes.slice(index + 1),
                                    ]);
                                  }
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">May riêng khác</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.all}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSewTypes({
                                    general: true,
                                    private1: true,
                                    private2: true,
                                    private3: true,
                                    private4: true,
                                    all: true,
                                  });
                                } else {
                                  setSewTypes({
                                    general: false,
                                    private1: false,
                                    private2: false,
                                    private3: false,
                                    private4: false,
                                    all: false,
                                  });
                                }
                                if (e.target.checked) {
                                  setFieldValue(
                                    'sewTypes',
                                    _.uniq([...values.sewTypes, -1, 0, 1, 2, 3])
                                  );
                                } else {
                                  setFieldValue('sewTypes', []);
                                }
                              }}
                            />
                            <h5 className="mb-0 ml-2">Chọn tất cả</h5>
                          </Col>
                          <Col md="12">
                            {errors.sewTypes && (
                              <Error messageInvalid={errors.sewTypes} />
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-start" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Phòng ban
                            </p>
                          </Col>
                          <Col md="9">
                            <Select
                              isClearable={false}
                              isMulti
                              components={animatedComponents}
                              closeMenuOnSelect={false}
                              className="select-muti"
                              placeholder="Chọn phòng ban"
                              isDisabled={_.isEmpty(orderValue)}
                              onInputChange={(value) => {
                                setCustomerSearch(value);
                              }}
                              value={customerValues}
                              onChange={(e, remove) => {
                                if (_.isEmpty(remove?.removedValue)) {
                                  setCustomerValues([...e]);
                                  setFieldValue(
                                    'customerOrgIds',
                                    _.uniq([
                                      ...values.customerOrgIds,
                                      ...e.map((v) => v.value),
                                    ])
                                  );
                                } else {
                                  const tempCustomerValues =
                                    customerValues.filter(
                                      (item) =>
                                        item.value !== remove.removedValue.value
                                    );
                                  setCustomerValues(tempCustomerValues);
                                  setFieldValue('customerOrgIds', [
                                    ...tempCustomerValues.map((v) => v.value),
                                  ]);
                                }
                              }}
                              options={allChildOfCustomer.results.map(
                                (item) => ({
                                  label: `${item.code}-${item.name}`,
                                  value: item.id,
                                })
                              )}
                            />
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-2">
                          <Col className="d-flex align-items-start" md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Kích thước (Size)
                            </p>
                          </Col>
                          <Col className="d-flex align-items-start" md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Số lượng
                            </p>
                          </Col>
                        </Row>
                        {arrSize.map((item, index) => {
                          return (
                            <Size
                              key={index}
                              index={index}
                              item={item}
                              arrSize={arrSize}
                              setArrSize={setArrSize}
                              setFieldValue={setFieldValue}
                              productId={values.productId}
                              productionInfos={values.productionInfos}
                              length={arrSize.length}
                              orderValue={orderValue}
                            />
                          );
                        })}
                        <Row className="mb-3">
                          <Col className="d-flex justify-content-end" md="12">
                            <span
                              onClick={() => {
                                setArrSize([
                                  ...arrSize,
                                  {
                                    sizeValue: null,
                                    amount: '',
                                  },
                                ]);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <AddSVG />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="5">
                        <Row>
                          <Col className="mb-3" md="12">
                            <h5 className="fullcalendar-title text-muted text-uppercase d-inline-block mb-0">
                              Thiết lập thời gian sản xuất
                            </h5>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="h5 font-weight-600">
                              1. Ngày cấp lệnh NPL
                            </p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.productionCommanDate}
                              onChange={(v, f) => {
                                setFieldValue('productionCommanDate', v);
                              }}
                            />
                          </Col>

                          <Col md="6">
                            <p className="h5 font-weight-600">
                              2. Ngày đồng bộ NPL
                            </p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.productSyncDate}
                              onChange={(v, f) => {
                                setFieldValue('productSyncDate', v);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="h5 font-weight-600">3. Ngày cắt SX</p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.produceCutDate}
                              onChange={(v, f) => {
                                setFieldValue('produceCutDate', v);
                              }}
                            />
                          </Col>

                          <Col md="6">
                            <p className="h5 font-weight-600">4. Ngày thêu</p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.embroiderDate}
                              onChange={(v, f) => {
                                setFieldValue('embroiderDate', v);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="h5 font-weight-600">5. Ngày QC KH</p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.qcCustomerDate}
                              onChange={(v, f) => {
                                setFieldValue('qcCustomerDate', v);
                              }}
                            />
                          </Col>
                          <Col md="6">
                            <p className="h5 font-weight-600">
                              6. Ngày nhận sản phẩm
                            </p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.productReceiveDate}
                              onChange={(v, f) => {
                                setFieldValue('productReceiveDate', v);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="h5 font-weight-600">
                              7. Ngày giao QC
                            </p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.qcDate}
                              onChange={(v, f) => {
                                setFieldValue('qcDate', v);
                              }}
                            />
                          </Col>
                          <Col md="6">
                            <p className="h5 font-weight-600">8. Ngày NK KH</p>
                            <DatePicker
                              dateFormat="DD/MM/YYYY"
                              calendarPlacement="bottom"
                              value={values.warehouseReceipDate}
                              onChange={(v, f) => {
                                setFieldValue('warehouseReceipDate', v);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <InputCustom
                              name="notes"
                              invalid={touched.notes && errors.notes}
                              messageInvalid={errors.notes}
                              value={values.notes}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Những lưu ý khi sản xuất/ KSC"
                              placeholder="Nhập ghi chú thông tin"
                              type="textarea"
                              rows="10"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex justify-content-center">
                      <Button
                        onClick={() => {
                          history.push(location.state.route, {
                            ...location.state,
                          });
                        }}
                      >
                        Quay lại
                      </Button>
                      <LoadingButtonCustom
                        color="primary"
                        onClick={() => {
                          setFocused({
                            orderId: true,
                            orderPlanId: true,
                            producerCutId: true,
                            producerProductionId: true,
                          });
                          handleSubmit();
                        }}
                        loading={isUpdateProductionCommand}
                      >
                        Lưu lại
                      </LoadingButtonCustom>
                    </div>
                  </CardFooter>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col style={{ borderRight: '1px solid #DADADA' }} md="6">
                        <Row>
                          <Col md="12">
                            <h4>Thống kê tổng hợp may số đo chung</h4>
                          </Col>
                          {!_.isEmpty(
                            productionCommandInfos.filter(
                              (item) => item.sewType === -1
                            )
                          ) ? (
                            productionCommandInfos
                              .filter((item) => item.sewType === -1)
                              .map((item) => (
                                <>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-end"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      Sản phẩm:
                                    </p>
                                  </Col>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-start"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      {productionCommandById?.productId?.name} (
                                      <b>size</b>: {item?.standardSizeName}) (
                                      <b>SL</b>: {item?.totalProduct})
                                    </p>
                                  </Col>
                                </>
                              ))
                          ) : (
                            <Col
                              md="12"
                              className="d-flex justify-content-center"
                            >
                              Không có sản phẩm may chung!
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col md="12">
                            <h4>Thống kê tổng hợp may số đo riêng</h4>
                          </Col>
                          {!_.isEmpty(
                            productionCommandInfos.filter(
                              (item) => item.sewType !== -1
                            )
                          ) ? (
                            productionCommandInfos
                              .filter((item) => item.sewType !== -1)
                              .map((item) => (
                                <>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-end"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      Sản phẩm:
                                    </p>
                                  </Col>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-start"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      {productionCommandById?.productId?.name} (
                                      <b>size</b>: {item?.standardSizeName}) (
                                      <b>SL</b>: {item?.totalProduct})
                                    </p>
                                  </Col>
                                </>
                              ))
                          ) : (
                            <Col
                              md="12"
                              className="d-flex justify-content-center"
                            >
                              Không có sản phẩm may riêng!
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Row className="mb-2">
                          <Col md="6">
                            <h4>Danh sách khách hàng may số đo chung</h4>
                          </Col>
                          <Col className="d-flex justify-content-end" md="6">
                            {customersInOrderGeneral.results.length > 0 && (
                              <LoadingButtonCustom
                                outline={true}
                                size="sm"
                                onClick={() => {
                                  setIsExcludeCustomerCommandGeneral(true);
                                  handleExcludeCustomerCommandGeneral();
                                }}
                                loading={isExcludeCustomerCommandGeneral}
                                style={{ border: '1px solid #4385b1' }}
                              >
                                Loại bỏ và lưu khách hàng đã lựa chọn
                              </LoadingButtonCustom>
                            )}
                          </Col>
                        </Row>
                        <ToolkitProvider
                          data={
                            sewTypes.general
                              ? customersInOrderGeneral.results
                              : []
                          }
                          keyField="id"
                          columns={columnsGeneral}
                          bootstrap4={true}
                          search
                        >
                          {(props) => (
                            <>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={() => {
                                  return (
                                    <span className="font-weight-bold text-danger">
                                      Không có dữ liệu!
                                    </span>
                                  );
                                }}
                                onTableChange={() => {
                                  return (
                                    <div className="spinner-border text-info" />
                                  );
                                }}
                                filter={filterFactory()}
                                pagination={paginationCustomersInOrderGeneral}
                                selectRow={selectRowGeneral}
                                bordered={false}
                                hover
                                remote
                              />
                            </>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Row className="mb-2">
                          <Col md="6">
                            <h4>Danh sách khách hàng may số đo riêng</h4>
                          </Col>
                          <Col className="d-flex justify-content-end" md="6">
                            {customersInOrderPrivate.results.length > 0 && (
                              <LoadingButtonCustom
                                outline={true}
                                size="sm"
                                onClick={() => {
                                  setIsExcludeCustomerCommandPrivate(true);
                                  handleExcludeCustomerCommandPrivate();
                                }}
                                loading={isExcludeCustomerCommandPrivate}
                                style={{ border: '1px solid #4385b1' }}
                              >
                                Loại bỏ và lưu khách hàng đã lựa chọn
                              </LoadingButtonCustom>
                            )}
                          </Col>
                        </Row>
                        <ToolkitProvider
                          data={
                            sewTypes.private1 ||
                            sewTypes.private2 ||
                            sewTypes.private3 ||
                            sewTypes.private4
                              ? customersInOrderPrivate.results
                              : []
                          }
                          keyField="id"
                          columns={columnsPrivate}
                          bootstrap4={true}
                          search
                        >
                          {(props) => (
                            <>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={() => {
                                  return (
                                    <span className="font-weight-bold text-danger">
                                      Không có dữ liệu!
                                    </span>
                                  );
                                }}
                                onTableChange={() => {
                                  return (
                                    <div className="spinner-border text-info" />
                                  );
                                }}
                                filter={filterFactory()}
                                pagination={paginationCustomersInOrderPrivate}
                                selectRow={selectRowPrivate}
                                bordered={false}
                                hover
                                remote
                              />
                            </>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            );
          }}
        </Formik>
      </Container>
    </Style>
  );
};

export default UpdateProductionCommand;
