import _ from "lodash";
import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Services/ServiceBase";
import ServiceURL from "Services/ServiceURL";
import TypeActions from "../TypeActions";

export function* getDashboard(data) {
	const url =
		ServiceURL.reports + "/dashboard" + data.params + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_DASHBOARD_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_DASHBOARD_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_DASHBOARD_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}
export default function* reportSaga() {
	yield takeLatest(TypeActions.GET_DASHBOARD_REQUEST, getDashboard);
}
