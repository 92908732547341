import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { exportActions } from 'Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { notify } from 'common';
import FileDownload from 'js-file-download';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';

const FormNplOrderDetail = ({
  open,
  setFormModalDetail,
  nplOrder,
  notificationAlertRef,
  handleGetNplOrders,
}) => {
  const dispatch = useDispatch();
  const { isExportSuggest } = useSelector((state) => state.exportReducer);

  const handleExportSuggest = () => {
    dispatch(
      exportActions.exportSuggest(nplOrder.id, queryString.stringify({}), {
        success: (data) => {
          const filename = `MAU_NPL_${nplOrder.code}.xlsx`;
          FileDownload(data, filename);
          setFormModalDetail(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất mẫu nguyên phụ liệu thành công!`
          );
          handleGetNplOrders();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xuất mẫu nguyên phụ liệu thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={open}
        toggle={() => {
          setFormModalDetail(false);
        }}
      >
        <Card className="mb-0">
          <CardHeader className="modal-header">
            <p className="h2 text-uppercase">Đề xuất nguyên phụ liệu</p>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFormModalDetail(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </CardHeader>
          <CardBody className="pb-0">
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Người đề xuất</p>
              </Col>
              <Col xs={9}>{nplOrder.createdBy.name}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Ngày đề xuất</p>
              </Col>
              <Col xs={9}>
                {moment(nplOrder.suggestDate).format('DD/MM/YYYY')}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Mã đề xuất</p>
              </Col>
              <Col xs={9}>{nplOrder.code}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Tên đề xuất</p>
              </Col>
              <Col xs={9}>{nplOrder.name}</Col>
            </Row>
            <Row className="mb-0">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Kế hoạch SX</p>
              </Col>
              <Col xs={9}>{nplOrder?.orderPlanId?.name}</Col>
            </Row>
            <Row className="mb-0">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500"> Ngày mua thực tế</p>
              </Col>
              <Col xs={9}>
                {!!nplOrder?.actualDate
                  ? moment(nplOrder.actualDate).format('DD/MM/YYYY')
                  : ''}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card style={{ boxShadow: 'none' }} className="mb-0">
          <CardBody>
            Danh sách nguyên phụ liệu
            <Table>
              <thead>
                <tr>
                  <td>STT</td>
                  <td>Tên vật tư</td>
                  <td className="text-center">Đơn vị tính</td>
                  <td className="text-center">Số lượng SP</td>
                  <td className="text-center">Số lượng NPL</td>
                  <td className="text-center">Tồn kho</td>
                  <td className="text-center">SL đặt mua</td>
                </tr>
              </thead>
              <tbody>
                {nplOrder.suggestDetails.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.materialId?.name}</td>
                      <td className="text-center">
                        {item?.materialId?.unitId?.name}
                      </td>
                      <td className="text-center">{nplOrder.totalProduct}</td>
                      <td className="text-center">
                        {item?.totalNeed.toFixed(2)}
                      </td>
                      <td className="text-center">{item?.remainAmount}</td>
                      <td className="text-center">{item?.needBuy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center mt-2">
              <Button
                onClick={() => {
                  setFormModalDetail(false);
                }}
              >
                Hủy bỏ
              </Button>
              <LoadingButtonCustom
                loading={isExportSuggest}
                type="button"
                color="primary"
                onClick={() => {
                  handleExportSuggest();
                }}
              >
                Xuất mẫu nguyên phụ liệu
              </LoadingButtonCustom>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default FormNplOrderDetail;
