import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import AuthHeader from 'components/Headers/AuthHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { accountActions } from 'Redux/Actions';
import { emailRegex } from 'common';
import HelperText from 'views/pages/components/HelperText';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { Formik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  //!State
  const accountSchema = yup.object().shape({
    email: yup
      .string()
      .email('Email không đúng định dạng!')
      .required('Email không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
  });
  const [accountInfo, setAccountInfo] = useState({
    email: '',
    password: '',
  });
  const [websiteValue, setWebsiteValue] = useState({
    label: 'Phần mềm quản lý đơn hàng',
    value: 1,
    url: '/',
  });

  const dauThauURL = process.env.REACT_APP_DAU_THAU_LOGIN_URL;

  const websites = [
    {
      label: 'Phần mềm quản lý đấu thầu',
      value: 0,
      url: dauThauURL,
    },
    {
      label: 'Phần mềm quản lý đơn hàng',
      value: 1,
      url: '/',
    },
  ];
  const { isLogIn } = useSelector((state) => state.accountReducer);
  const [focusedEmail, setFocusedEmail] = React.useState(false);
  const [focusedPassword, setFocusedPassword] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessages] = React.useState('');
  const [valueAppLogin, setValueAppLogin] = React.useState(1);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value === '') {
      setEmailError('Email không được để trống!');
    } else if (!emailRegex.test(e.target.value)) {
      setEmailError('Vui lòng nhập đúng định dạng email!');
    } else {
      setEmailError('');
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value === '') {
      setPasswordError('Mật khẩu không được để trống!');
    } else {
      setPasswordError('');
    }
  };

  const onSubmit = (values) => {
    if (
      ((values.email === 'ngamtq@fovina.vn' ||
        values.email === 'yenvh@fovina.vn') &&
        websiteValue.value === 0) ||
      websiteValue.value === 1
    ) {
      dispatch(
        accountActions.accountLogin(values, {
          success: (data) => {
            setMessages('');
            const token = data.tokens.access.token;
            const roleId = data.user.roleId;
            const refreshtoken = data.tokens.refresh.token;
            const id = data.user.id;
            localStorage.setItem('token', token);
            localStorage.setItem('roleId', roleId);
            localStorage.setItem('refreshtoken', refreshtoken);
            localStorage.setItem('id', id);
            if (websiteValue.value === 0) {
              window.location.href = `${websiteValue.url}?token=${token}&roleId=${roleId}&id=${id}&refreshtoken=${refreshtoken}`;
            } else {
              history.push(websiteValue.url);
            }
          },
          failed: (mess) => {
            setMessages(mess);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (!!location.search) {
      const query = queryString.parse(location.search);
      const { token, refreshtoken, roleId, id } = query;
      if (!!token && !!refreshtoken && !!roleId && !!id) {
        localStorage.setItem('token', token);
        localStorage.setItem('roleId', roleId);
        localStorage.setItem('refreshtoken', refreshtoken);
        localStorage.setItem('id', id);
        history.push('/');
      }
    } else {
      localStorage.clear();
    }
  }, [location]);

  return (
    <>
      <AuthHeader
        title="Hệ thống quản lý sản xuất"
        lead="Đăng nhập để sử dụng các chức năng của hệ thống"
      />
      <Formik
        initialValues={accountInfo}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={accountSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          resetForm,
          handleBlur,
        }) => {
          return (
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="8">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <h1 className="text-center text-uppercase">Đăng nhập</h1>
                      <Form role="form">
                        <FormGroup
                          className={classnames({
                            focused: focusedEmail,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative mb-0">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value={values.email}
                              placeholder="Email"
                              type="email"
                              name="email"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('email', e.target.value);
                              }}
                            />
                          </InputGroup>
                          {errors.email && touched.email && (
                            <HelperText message={errors.email} />
                          )}
                        </FormGroup>
                        <FormGroup
                          className={classnames({
                            focused: focusedPassword,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('password', e.target.value);
                              }}
                            />
                          </InputGroup>
                          {errors.password && touched.password && (
                            <HelperText message={errors.password} />
                          )}
                        </FormGroup>
                        <div className="d-flex justify-content-center">
                          {message !== '' ? (
                            <span className="text-danger">{message}</span>
                          ) : null}
                        </div>
                        <FormGroup>
                          <Label className="form-control-label text-sm">
                            Lựa chọn
                          </Label>
                          <Select
                            placeholder="Nhập để tìm kiếm"
                            isClearable={false}
                            value={websiteValue}
                            onChange={(e) => {
                              setWebsiteValue(e);
                            }}
                            options={websites.map((item) => ({
                              label: item.label,
                              value: item.value,
                              url: item.url,
                            }))}
                          />
                        </FormGroup>
                        <div className="text-center">
                          <LoadingButtonCustom
                            loading={isLogIn}
                            onClick={handleSubmit}
                            className="mt-4"
                            color="info"
                            type="button"
                            outline={false}
                            block={false}
                          >
                            Đăng nhập
                          </LoadingButtonCustom>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col xs="6">
                      <a className="text-light" href="forgot-password">
                        <small>Quên mật khẩu ?</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginPage;
