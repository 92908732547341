import React, { useState, useEffect, useRef } from "react";
import {
	Card,
	Table,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
	Input,
	DropdownItem,
	CardBody,
} from "reactstrap";
import { BinSVG } from "assets/svg";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import queryString from "query-string";
import { notify } from "common";
import InputCustom from "views/pages/components/InputCustom";
import RequireCustom from "views/pages/components/RequireCustom";
import LoadingButtonCustom from "views/pages/components/LoadingButtonCustom";
import { Formik } from "formik";
import * as yup from "yup";
import ReactNotificationAlert from "react-notification-alert";
import { completeStageActions } from "Redux/Actions";
import { useHistory } from "react-router-dom";
import CONSTANTS from "constant";
import { manageDataProducts } from "utils/checkDataLocalStorage";
import { NAME_DATA } from "utils/checkDataLocalStorage";
import SelectBarcode from "views/pages/components/SelectBarcode";

const RowTable = ({ item, setProducts, products, indexP }) => {
	return (
		<tr>
			<td>{item?.barcode}</td>
			<td>{item?.productId?.name}</td>
			<td>{item?.customerName}</td>
			<td>{item?.customerOrgId?.name}</td>

			<td>
				<Input
					type="number"
					className="max-height-input-custom"
					style={{ maxWidth: 100 }}
					value={item?.quota}
					onChange={(e) => {
						let tempProducts = _.cloneDeep(products);
						const index = tempProducts.findIndex(
							(val) => val.barcode === item.barcode
						);
						if (index !== -1) {
							tempProducts[index] = {
								...item,
								quota:
									e.target.value === ""
										? ""
										: Number(e.target.value) < 0
										? 0
										: Number(e.target.value),
							};
							setProducts([...tempProducts]);
							manageDataProducts.set({
								name: NAME_DATA.FORM_TRANSFER_CUSTOMER,
								data: tempProducts,
							});
						}
					}}
					placeholder="Nhập"
				/>
			</td>
			<td>{item?.packNotes}</td>
			<td className="d-flex justify-content-center">
				<button
					onClick={() => {
						const newProducts = products.filter((val) => val.key !== item.key);
						setProducts(newProducts);
						manageDataProducts.set({
							name: NAME_DATA.FORM_TRANSFER_CUSTOMER,
							data: newProducts,
						});
					}}
					className="btn-none"
					id={"delete" + indexP}
				>
					<BinSVG />
				</button>
				<UncontrolledTooltip
					delay={1}
					placement="top"
					target={"delete" + indexP}
				>
					Xóa sản phẩm tiếp nhận
				</UncontrolledTooltip>
			</td>
		</tr>
	);
};
const TransferCustomer = ({ location }) => {
	const { currentAccount } = useSelector((state) => state.accountReducer);

	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const history = useHistory();
	const [barcode, setBarcode] = useState("");
	// QLTT_CHUCTV_001-QLTTAGCP-QLTTAG01-TPNU02
	const qcInfoSchema = yup.object().shape({});
	const [qcInfo, setQcInfo] = useState({
		packNotes: "",
	});
	const [customerInfo, setCustomerInfo] = useState({});
	const [products, setProducts] = useState([]);
	const notificationAlertRef = useRef(null);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [query, setQuery] = useState({
		page: page,
		limit: rowsPerPage,
	});

	const handleGetCustomerByBarcodeQuality = (barcode) => {
		if (barcode === "" || _.isEmpty(barcode)) {
			notify(
				notificationAlertRef,
				"warning",
				"Thông báo",
				`Vui điền mã sản phẩm vàn nhấn ENTER!`
			);
			return;
		}
		dispatch(
			completeStageActions.getCustomerByBarcodeCompleteStage(
				queryString.stringify({
					barcode: barcode?.label || barcode,
					populate: "customerOrgId,productId,orderId",
					transportStatus: CONSTANTS.TRANSPORT_STATUS.PACK,
				}),
				{
					success: (data) => {
						const temp = { ...data, key: `${new Date().getTime()}` };
						setCustomerInfo({ ...temp });
						let tempProducts = _.cloneDeep(products);
						let checkValidProduct = false;
						products.every((item, index) => {
							if (item.barcode === temp.barcode) {
								if (typeof tempProducts[index].quota === "number") {
									tempProducts[index] = {
										...item,
										quota: tempProducts[index].quota + 1,
									};
								} else {
									tempProducts[index] = {
										...item,
										quota: 1,
									};
								}
								checkValidProduct = true;
								return false;
							}
							return true;
						});
						if (!checkValidProduct) {
							// tempProducts.push({ ...temp, quota: 1 });
							tempProducts = [{ ...temp, quota: 1 }, ...tempProducts];
						}
						setProducts([...tempProducts]);
						setBarcode("");
						//inputRef.current.focus();
						manageDataProducts.set({
							name: NAME_DATA.FORM_TRANSFER_CUSTOMER,
							data: tempProducts,
						});
					},
					failed: (mess) => {
						notify(
							notificationAlertRef,
							"danger",
							"Thông báo",
							`Lấy thông tin sản phẩm thất bại. Lỗi: ${mess}!`
						);
						setCustomerInfo({});
					},
				}
			)
		);
	};

	useEffect(() => {
		if (!!inputRef?.current) {
			//inputRef.current.focus();
		}
	}, [inputRef]);

	const onSubmit = (values, actions) => {
		const barcodes = [];
		products.forEach((item) => {
			Array.apply(
				null,
				Array(typeof item.quota === "number" ? item.quota : 0)
			).forEach(() => {
				barcodes.push(item.barcode);
			});
		});
		if (barcodes.length === 0) {
			notify(
				notificationAlertRef,
				"warning",
				"Thông báo",
				`Vui lòng nhập số lượng sản phẩm!`
			);
			return;
		}
		dispatch(
			completeStageActions.transferCustomer(
				{
					...values,
					barcodes: products.map((item) => item.barcode),
				},
				{
					success: () => {
						notify(
							notificationAlertRef,
							"success",
							"Thông báo",
							`Chuyển trả khách thành công!`
						);
						setProducts([]);
						setCustomerInfo({});
						setBarcode("");
						setQcInfo({
							packNotes: "",
						});
						actions.resetForm();
						manageDataProducts.remove(NAME_DATA.FORM_TRANSFER_CUSTOMER);
						setTimeout(() => {
							history.push("/manage-paying-customer");
						}, 2000);
					},
					failed: (mess) => {
						notify(
							notificationAlertRef,
							"danger",
							"Thông báo",
							`Chuyển trả khách thất bại. Lỗi: ${mess}!`
						);
					},
				}
			)
		);
	};

	useEffect(() => {
		setCustomerInfo({});
		setProducts(manageDataProducts.get(NAME_DATA.FORM_TRANSFER_CUSTOMER));
	}, [history]);

	return (
		<Formik
			initialValues={qcInfo}
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={qcInfoSchema}
		>
			{({
				values,
				setFieldValue,
				handleSubmit,
				errors,
				touched,
				resetForm,
				handleBlur,
			}) => {
				return (
					<>
						<div className="rna-wrapper">
							<ReactNotificationAlert ref={notificationAlertRef} />
						</div>
						<div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
							<Container fluid>
								<div className="header-body">
									<Row className="align-items-center pb-4 pt-3">
										<Col md="6">
											<h4 className="text-info mb-0">
												Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin
												tiếp theo form
											</h4>
										</Col>
										<Col className="mt-3 mt-md-0 text-md-right" md="6">
											<LoadingButtonCustom
												onClick={() => {
													history.push(location.state.route, {
														...location.state,
													});
												}}
												className="btn-neutral"
												color="default"
												size="md"
											>
												Quay lại
											</LoadingButtonCustom>
											<LoadingButtonCustom
												onClick={() => {
													if (!_.isEmpty(products)) {
														handleSubmit();
													} else {
														notify(
															notificationAlertRef,
															"warning",
															"Thông báo",
															`Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin!`
														);
													}
												}}
												className="btn-neutral"
												color="default"
												size="md"
											>
												Lưu và thoát
											</LoadingButtonCustom>
										</Col>
									</Row>
								</div>
							</Container>
						</div>
						<Container className="mt--6" fluid>
							<Card className="px-4 py-3">
								<Row className="justify-content-center">
									<Col md="12">
										<h3>SẢN PHẨM</h3>
										<DropdownItem divider />

										<Row>
											<Col style={{ borderRight: "1px solid #e9ecef" }} md="6">
												<Row className="mt-4">
													<Col className="mb-3" md="4">
														<label className="form-control-label">
															Mã sản phẩm
															<RequireCustom />
														</label>
													</Col>
													<Col className="mb-3" md="8">
														{/* <InputCustom
															className="max-height-input-custom"
															placeholder="Nhập mã để tìm kiếm"
															type="text"
															id="code"
															name="code"
															onBlur={handleBlur}
															innerRef={inputRef}
															onChange={(e) => {
																setBarcode(e.target.value);
															}}
															onKeyDown={(e) => {
																if (e.keyCode === 13) {
																	handleGetCustomerByBarcodeQuality();
																}
															}}
															invalid={barcode === ""}
															value={barcode}
														/> */}
														<SelectBarcode
															innerRef={inputRef}
															transportStatus={CONSTANTS.TRANSPORT_STATUS.PACK}
															handleGetCustomerByBarcode={
																handleGetCustomerByBarcodeQuality
															}
														/>
													</Col>
													<Col md="12">
														<h4>Sản phẩm vừa được quét</h4>
													</Col>
													<Col className="mb-2" md="4">
														<label className="form-control-label">
															Mã SP KH
														</label>
													</Col>
													<Col className="mb-2" md="8">
														: {customerInfo?.barcode}
													</Col>
													<Col className="mb-3" md="4">
														<label className="form-control-label">
															Tên sản phẩm
														</label>
													</Col>
													<Col className="mb-3" md="8">
														: {customerInfo?.productId?.name}
													</Col>
													<Col className="mb-3" md="4">
														<label className="form-control-label">
															Mã khách hàng
														</label>
													</Col>
													<Col className="mb-3" md="8">
														: {customerInfo?.customerCode}
													</Col>
													<Col className="mb-3" md="4">
														<label className="form-control-label">
															Tên khách hàng
														</label>
													</Col>
													<Col className="mb-3" md="8">
														: {customerInfo?.customerName}
													</Col>
													<Col className="mb-3" md="4">
														<label className="form-control-label">
															Tên đơn hàng
														</label>
													</Col>
													<Col className="mb-3" md="8">
														: {customerInfo?.orderId?.name}
													</Col>
													<Col className="mb-3" md="4">
														<label
															className="form-control-label"
															style={{ whiteSpace: "nowrap" }}
														>
															Đơn vị phòng ban
														</label>
													</Col>
													<Col className="mb-3" md="8">
														: {customerInfo?.customerOrgId?.name}
													</Col>
												</Row>
											</Col>
											<Col md="6" className="pl-5 pr-5">
												<Row className="mt-4">
													<Col className="mb-3" md="3">
														<label className="form-control-label">
															Người hoàn thiện
														</label>
													</Col>
													<Col className="mb-3" md="9">
														: {currentAccount?.name}
													</Col>
													<Col className="mb-3" md="12">
														<InputCustom
															label="Ghi chú"
															placeholder="Nhập"
															type="textarea"
															id="packNotes"
															name="packNotes"
															rows="5"
															onBlur={handleBlur}
															invalid={errors.packNotes && touched.packNotes}
															onChange={(e) => {
																setFieldValue("packNotes", e.target.value);
															}}
															messageInvalid={errors.packNotes}
															value={values.packNotes}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
										{/* <Row className="d-flex justify-content-center mt-3">
                      <LoadingButtonCustom
                        onClick={() => {
                          if (!_.isEmpty(customerInfo)) {
                            setProducts([...products, customerInfo]);
                            setBarcode("");
                            setCustomerInfo({});
                            //inputRef.current.focus();
                          } else {
                            notify(
                              notificationAlertRef,
                              "warning",
                              "Thông báo",
                              `Vui điền mã sản phẩm vàn nhấn ENTER!`
                            );
                            //inputRef.current.focus();
                          }
                        }}
                        color="primary"
                        loading={isGetCustomerByBarcodeCompleteStage}
                      >
                        Thêm
                      </LoadingButtonCustom>
                    </Row> */}
									</Col>
								</Row>
							</Card>
							<Row>
								<Col md="12">
									<Card className="pb-3">
										<CardBody className="py-0">
											<Row>
												<Col className="py-3" md={12}>
													<h3>
														Danh sách sản phẩm hoàn thiện đóng gói(
														{products.reduce(
															(preValue, currentValue) =>
																preValue + currentValue.quota,
															0
														)}
														)
														<RequireCustom />
													</h3>
													<Table>
														<thead>
															<th
																className="h3 font-weight-600 p-2"
																style={{ paddingRight: 16, minWidth: 200 }}
															>
																Mã SP KH
															</th>
															<th
																style={{ minWidth: 80 }}
																className="h3 font-weight-600 p-2 "
															>
																Tên sản phẩm
															</th>
															<th className="h3 font-weight-600 p-2 ">
																Tên khách hàng
															</th>
															<th className="h3 font-weight-600 p-2 ">
																Đơn vị/phòng ban
															</th>
															<th className="h3 font-weight-600 p-2 ">
																Số lượng
															</th>
															<th className="h3 font-weight-600 p-2 ">
																Ghi chú
															</th>
															<th className="h3 font-weight-600 p-2 text-center">
																Hành động
															</th>
														</thead>
														<tbody>
															{products.map((item, index) => {
																return (
																	<RowTable
																		key={index}
																		item={item}
																		setProducts={setProducts}
																		products={products}
																		indexP={index}
																	/>
																);
															})}
														</tbody>
													</Table>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</>
				);
			}}
		</Formik>
	);
};

export default TransferCustomer;
