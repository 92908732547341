import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import queryString from 'query-string';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, customerActions } from 'Redux/Actions';
import Select from 'react-select';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import FileDownload from 'js-file-download';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import ModalAddCustomer from '../ModalAddCustomer';

const CustomerInfor = ({ handleChangeTab }) => {
  const {
    customersInOrder,
    orderById,
    isGetTemplateCustomerOrder,
    isUploadCustomerOrder,
  } = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const history = useHistory();
  const [listCustomer, setListCustomer] = useState({ results: [] });
  const [listSize, setListSizes] = useState([]);
  const notificationAlertRef = useRef(null);
  const [customerOrganValue, setCustomerOrganValue] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);
  const [branchValue, setBranchValue] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [isShowModalAddCustomer, setIsShowModalAddCustomer] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate:
      'customerOrgId.parentId,customerSizeId.sizes.productParameterId, productTypeId',
  });
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
  });

  const [fileUpload, setFileUpload] = useState({
    file: '',
    name: '',
  });

  const [queryOrderById, setQueryOrderById] = useState({
    populate:
      'products.productTypeId.productParameterIds, manager, customerOrganizationId.childrentIds, orderDetails.listProduct,productTypeId',
  });

  const columns = [
    {
      dataField: 'customerSizeId.workbook',
      text: 'Mã sổ',
    },
    {
      dataField: 'customerSizeId.staffCode',
      text: 'Mã nhân viên đo',
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
      formatter: (cell, row) => {
        return !!cell ? cell : row.customerOrgId.name;
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Phòng ban',
    },
    {
      dataField: 'customerCode',
      text: 'Mã khách hàng',
      headerFormatter: () => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) => {
              setSearchValues({
                ...searchValues,
                customerCode: e.target.value,
              });
              setPage(1);
              setRowsPerPage(10);
              setQuery({
                ...query,
                page: 1,
                limit: 10,
              });
            }}
            style={{
              border: 'none',
              borderRadius: 0,
              padding: 0,
              height: '2rem',
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 150,
      },
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
      headerFormatter: () => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) => {
              setSearchValues({
                ...searchValues,
                customerName: e.target.value,
              });
              setPage(1);
              setRowsPerPage(10);
              setQuery({
                ...query,
                page: 1,
                limit: 10,
              });
            }}
            placeholder="Tên khách hàng"
            style={{
              backgroundColor: 'white !important',
              boxShadow: 'none !important',
              border: 'none',
              borderRadius: 0,
              padding: 0,
              height: '2rem',
            }}
          />
        );
      },
      headerStyle: {
        minWidth: 150,
      },
    },
    {
      dataField: 'age',
      text: 'Tuổi',
    },
    {
      dataField: 'gender',
      text: 'Giới tính',
    },
    {
      dataField: 'productTypeId.name',
      text: 'Sản phẩm may',
    },
    ...listSize.map((item) => {
      return {
        dataField: item.code,
        text: item.name,
        headerStyle: {
          maxWidth: 55,
          whiteSpace: 'initial',
          textAlign: 'center',
        },
        formatter: (__, row) => {
          const sd =
            row?.customerSizeId?.sizes?.find(
              (value) => value.productParameterId.code === item.code
            )?.size || '';

          return <span>{sd}</span>;
        },
        style: { textAlign: 'center' },
      };
    }),
    {
      dataField: 'customerSizeId.notes',
      text: 'Ghi chú',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
      },
      style: { minWidth: 200, textAlign: 'justify' },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: customersInOrder?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p style={{ fontSize: 14, whiteSpace: 'nowrap' }} className="mb-0">
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p style={{ fontSize: 14 }} className="mb-0">
              dòng.
            </p>
          </div>
          <p style={{ fontSize: 14 }} className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > customersInOrder.totalResults
              ? !isNaN(customersInOrder?.totalResults)
                ? customersInOrder.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(customersInOrder?.totalResults)
              ? customersInOrder.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetCustomersInOrder = () => {
    if (!branchValue) return;
    const tmp = _.cloneDeep({
      ...query,
      orderId: orderId,
    });
    const tempSearch = _.cloneDeep({ ...searchValues });
    Object.keys(tempSearch).map((key) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    if (branchValue?.value !== 'all') {
      tmp.customerOrgId = customerOrganValue.value;
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({ ...tmp, ...tempSearch }),
        {
          success: (data) => {
            setListCustomer(data);
            const list = data.results.map((item) => {
              const arr1 = item.sizes.map((i) => {
                return {
                  name: i.productParameterId.name,
                  id: i.productParameterId.id,
                  code: i.productParameterId.code,
                };
              });
              const arr2 =
                item?.customerSizeId?.sizes?.map((i) => {
                  return {
                    name: i.productParameterId.name,
                    id: i.productParameterId.id,
                    code: i.productParameterId.code,
                  };
                }) || [];

              return arr1.concat(arr2);
            });
            let results = [];
            list.forEach((item) => {
              results = results.concat(item);
            });
            results = results.filter(
              (v, i, a) => a.findIndex((item) => _.isEqual(item, v)) === i
            );

            setListSizes(results);
          },
        }
      )
    );
  };

  const handleGetOrderById = () => {
    dispatch(
      orderActions.getOrderById(orderId, queryString.stringify(queryOrderById))
    );
  };

  useEffect(() => {
    if (orderId !== undefined) {
      handleGetOrderById();
    }
  }, [orderId]);

  const handleSubmit = () => {
    const body = {
      ...orderById,
      manager: orderById.manager.id,
      // orderDetails: orderDetails,
      products: orderById.products.map((item) => ({
        amount: item.amount,
        productTypeId: item.productTypeId.id,
        price: item.price,
      })),
      orderDetails: orderById.orderDetails.map((item) => {
        let { _id, ...payload } = item;
        payload.listProduct = payload.listProduct.map((x) => ({
          amount: x.amount,
          productTypeId: x.productTypeId,
        }));
        return payload;
      }),
    };
    const {
      id,
      history,
      createdBy,
      orderStatus,
      orderPlanStatus,
      customerOrganizationId,
      createdAt,
      updatedAt,
      ...payload
    } = body;
    delete payload.realTotalProducts;
    dispatch(
      orderActions.updateOrder(payload, orderId, {
        success: () => {
          setTimeout(() => {
            handleChangeTab(0);
            history.push('/add-order');
          }, 2000);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật đơn hàng thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật đơn hàng thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };
  const handleAlert = (type, title, message) => {
    notify(notificationAlertRef, type, title, message);
  };
  const handleChangeFile = (e) => {
    if (_.isEmpty(e)) return;
    const arrNameFile = e.target.files[0].name.split('.');
    if (arrNameFile[arrNameFile.length - 1] === 'xlsx') {
      setFileUpload({
        name: arrNameFile,
        file: e.target.files[0],
      });
    } else {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Vui lòng chọn đúng định dạng file exel!`
      );
    }
  };

  const handleGetTemplateCustomer = () => {
    dispatch(
      orderActions.getTemplateCustomerOrder(orderById.id, {
        success: (data) => {
          const filename = `MAU_DANH_SACH_NHAN_VIEN_DON_HANG_${orderById.name.toUpperCase()}.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Tải tệp tệp mẫu thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải tệp mẫu thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  const handleUploadFileCustomerOrder = () => {
    const params = `${orderById.id}`;
    dispatch(
      orderActions.uploadCustomerOrder(fileUpload.file, params, {
        success: () => {
          setFileUpload({
            name: '',
            file: '',
          });
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Tải tệp lên thành công cho đơn hàng ${orderById.name}`
          );
          handleGetCustomersInOrder();
        },
        failed: (mess) => {
          // setFileUpload({
          //   name: "",
          //   file: "",
          // });
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Tải tệp lên không thành công cho đơn hàng ${orderById.name}. Lỗi: ${mess}`
          );
        },
      })
    );
  };
  const showModalAddCustomer = () => {
    setIsShowModalAddCustomer((isShow) => !isShow);
  };
  useEffect(() => {
    if (!_.isEmpty(orderId) && !_.isEmpty(customerOrganValue?.value)) {
      setQuery({
        ...query,
        page: 1,
        limit: 10,
      });
      setPage(1);
      setRowsPerPage(10);
    }
  }, [customerOrganValue]);

  useEffect(() => {
    if (!_.isEmpty(orderById?.customerOrganizationId)) {
      const arr = orderById?.customerOrganizationId?.childrentIds
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
        .concat({
          value: orderById?.customerOrganizationId.id,
          label: orderById?.customerOrganizationId.name,
        });
      arr.unshift({
        value: 'all',
        label: 'Tất cả',
      });
      setBranchOptions(arr);
      // if (!_.isEmpty(orderById?.customerOrganizationId?.childrentIds)) {
      //   setBranchValue({
      //     value: orderById?.customerOrganizationId?.childrentIds[0]?.id,
      //     label: orderById?.customerOrganizationId?.childrentIds[0]?.name,
      //   });
      //   handleGetAllChildOrg(
      //     orderById?.customerOrganizationId?.childrentIds[0]?.id
      //   );
      // }
      setBranchValue(
        //   {
        //   value:
        //     orderById?.customerOrganizationId?.childrentIds[0]?.id ||
        //     orderById?.customerOrganizationId?.id,
        //   label:
        //     orderById?.customerOrganizationId?.childrentIds[0]?.name ||
        //     orderById?.customerOrganizationId?.name,
        // }
        { ...arr[0] }
      );
      // handleGetAllChildOrg(
      //   orderById?.customerOrganizationId?.childrentIds[0]?.id ||
      //     orderById?.customerOrganizationId?.id
      // );
    }
  }, [orderById]);

  const handleGetAllChildOrg = (id) => {
    if (id === undefined) return;
    dispatch(
      customerActions.getAllChildOfCustomer(id, '', {
        success: (data) => {
          setDepartmentOptions(
            data?.results.map((item) => ({
              label: item?.name,
              value: item?.id,
            })) || []
          );

          setCustomerOrganValue({
            value: data?.results[0]?.id || orderById?.customerOrganizationId.id,
            label:
              data?.results[0]?.name || orderById?.customerOrganizationId.name,
          });

          // if (!_.isEmpty(data?.results)) {
          //   setCustomerOrganValue({
          //     value: data?.results[0]?.id,
          //     label: data?.results[0]?.name,
          //   });
          // }
        },
        failed: (mess) => {},
      })
    );
  };

  useEffect(() => {
    if (branchValue?.value === 'all') {
      handleGetCustomersInOrder();
    }
  }, [branchValue]);

  useEffect(() => {
    handleGetCustomersInOrder();
  }, [query]);

  return (
    <div>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Col md={12} className="d-flex align-items-center">
          Vui lòng tải mẫu file:
          <LoadingButtonCustom
            onClick={handleGetTemplateCustomer}
            // style={{ verticalAlign: "none" }}
            className="text-primary btn py-0 px-1 d-flex"
            type="button"
            style={{
              background: 'transparent',
              boxShadow: 'none',
              border: 'none',
            }}
            loading={isGetTemplateCustomerOrder}
          >
            mẫu điền danh sách tại đây
          </LoadingButtonCustom>
        </Col>
        <Col md={12} className="mt-2">
          <div className="d-flex align-items-center">
            Cập nhật danh sách khách hàng
            <input
              className="custom-file-input-css"
              id="customFileLang"
              lang="en"
              type="file"
              accept=".xlsx"
              onChange={(e) => handleChangeFile(e)}
              style={{ display: 'none' }}
            />
            <label
              className="mb-0 ml-3 btn-secondary btn-sm btn"
              htmlFor="customFileLang"
            >
              {fileUpload.name === '' ? 'Chọn file (Excel)' : fileUpload.name}
            </label>
            &emsp;
            <LoadingButtonCustom
              onClick={() => {
                fileUpload.file && handleUploadFileCustomerOrder();
              }}
              color="info"
              size="sm"
              loading={isUploadCustomerOrder}
            >
              Tải lên
            </LoadingButtonCustom>
          </div>
        </Col>
      </Row>
      <div
      // className="accordion"
      >
        <Row className="mt-3">
          <Col md="12">
            <div className="d-flex justify-content-between align-center">
              <div style={{ flex: 1 }}>
                <label className="form-control-label">
                  Danh sách khách hàng của đơn vị/phòng ban:
                </label>
                <div className="mb-3 d-flex align-items-center">
                  <span className="text-md-right font-weight-500 mb-0 h4 mr-3">
                    Chi nhánh:{'  '}
                  </span>
                  <div style={{ minWidth: 250 }}>
                    <Select
                      id="branch"
                      name="branch"
                      placeholder="Lựa chọn"
                      onChange={(e) => {
                        if (e === null) return;
                        if (branchValue.value !== e.value) {
                          if (e.value !== 'all') {
                            handleGetAllChildOrg(e.value);
                          }
                          setBranchValue(e);
                        }
                      }}
                      value={branchValue}
                      options={branchOptions}
                    />
                  </div>
                  {branchValue?.value !== 'all' && (
                    <>
                      <span className="text-md-right font-weight-500 mb-0 h4 mx-3">
                        Phòng ban:
                      </span>
                      <div style={{ minWidth: 250 }}>
                        <Select
                          id="department"
                          name="department"
                          isDisabled={_.isEmpty(branchValue)}
                          placeholder="Lựa chọn"
                          onChange={(e) => {
                            if (e === null) return;
                            if (e.value !== customerOrganValue.value)
                              setCustomerOrganValue(e);
                          }}
                          value={customerOrganValue}
                          options={departmentOptions}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div>
                <Button outline color="primary" onClick={showModalAddCustomer}>
                  Thêm mới khách hàng
                </Button>
              </div>
            </div>
          </Col>
          <Col className="mt-2" md="12">
            <ToolkitProvider
              data={listCustomer.results}
              keyField="id"
              columns={columns}
              search
            >
              {(props) => (
                <>
                  <div>
                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      hover
                      remote
                      filter={filterFactory()}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                </>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
        <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary"
          >
            Lưu và hoàn tất
          </Button>
        </div>
      </div>
      <ModalAddCustomer
        isOpen={isShowModalAddCustomer}
        toggle={showModalAddCustomer}
        handleAddCustomer={(value) => {
          handleGetCustomersInOrder();
        }}
        handleAlert={handleAlert}
      ></ModalAddCustomer>
    </div>
  );
};

export default CustomerInfor;
