export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const logIn = "auth/login";
export const logOut = "auth/logout";
export const forgotPassword = "auth/forgot-password";
export const resetPassword = "auth/reset-password";
export const accounts = "users";
export const changePassword = "change-password";
export const configPasswordAccount = "set-password";
export const roles = "roles";
export const customers = "customerOrganizations";
export const producers = "producers";
export const npls = "materials";
export const nplGroups = "materialTypes";
export const orders = "orders";
export const permissions = "permissions";
export const provinces = "provinces";
export const productTypes = "productTypes";
export const product = "product";
export const standardSizes = "standardSizes";
export const productParameters = "productParameters";
export const orderPlans = "orderPlans";
export const orderPlan = "orderPlan";
export const customerInOrders = "customerInOrders";
export const measureSizes = "customerSizes";
export const units = "units";
export const materialNorms = "materialNorms";
export const getAllProductInOrder = "getAllProductInOrder";
export const materialSuggests = "materialSuggests";
export const productionCommands = "productionCommands";
export const delivery = "delivery";
export const quality = "quality";
export const completeStage = "completeStage";
export const transports = "transports";
export const exchangeHistory = "conversations";
export const conversations = "conversations";
export const reports = "reports";
export const deliveryReport = "deliveryReport";
export const exports = "exports";
export const deliveryDetailsReport = "deliveryDetailsReport";
export const productionReport = "productionReport";
export const productionDetailsReport = "productionDetailsReport";
export const generalReport = "generalReport";
export const generalDetailsReport = "generalDetailsReport";
export const completeReport = "completeReport";
export const completeDetailsReport = "completeDetailsReport";
export default {
	BASE_URL,
	logIn,
	logOut,
	forgotPassword,
	resetPassword,
	accounts,
	changePassword,
	configPasswordAccount,
	roles,
	customers,
	producers,
	npls,
	nplGroups,
	orders,
	permissions,
	provinces,
	productTypes,
	product,
	standardSizes,
	productParameters,
	orderPlans,
	customerInOrders,
	measureSizes,
	units,
	materialNorms,
	getAllProductInOrder,
	materialSuggests,
	productionCommands,
	delivery,
	quality,
	completeStage,
	transports,
	exchangeHistory,
	conversations,
	orderPlan,
	reports,
	deliveryReport,
	exports,
	deliveryDetailsReport,
	productionReport,
	productionDetailsReport,
	generalReport,
	generalDetailsReport,
	completeReport,
	completeDetailsReport,
};
