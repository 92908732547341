import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Style } from "./style";
import { provincesActions } from "Redux/Actions";
import queryString from "query-string";
import { customerActions } from "Redux/Actions";
import Select from "react-select";
import CONSTANTS from "constant";

const Filter = ({ handleClose, handleFilter, filterRequest }) => {
	const dispatch = useDispatch();
	const { provinces } = useSelector((state) => state.provincesReducer);
	const { customers } = useSelector((state) => state.customerReducer);

	const initialFilter = {
		year: "",
		orderStatus: "",
		provinceId: "",
		customerOrganizationId: "",
	};

	const listStatus = [
		{ status: CONSTANTS.ORDER_STATUS.Init, name: "Khởi tạo" },
		{ status: CONSTANTS.ORDER_STATUS.Processing, name: "Đang tạo" },
		{ status: CONSTANTS.ORDER_STATUS.Done, name: "Hoàn thành" },
		{ status: CONSTANTS.ORDER_STATUS.Cancel, name: "Đã hủy" },
	];
	const [customerSearch, setCustomerSearch] = useState("");
	const [customerValue, setCustomerValue] = useState(null);
	const [provinceValue, setProvinceValue] = useState(null);
	const [statusValue, setStatusValue] = useState(null);
	const [yearValue, setYearValue] = useState(null);
	const [optionFilter, setOptionFilter] = useState(initialFilter);

	useEffect(() => {
		dispatch(
			provincesActions.getProvinces(queryString.stringify({ limit: 100 }))
		);
	}, []);
	useEffect(() => {
		setYearValue(filterRequest.year);
		setCustomerValue(filterRequest.customerOrganizationId);
		setProvinceValue(filterRequest.provinceId);
		setOptionFilter(filterRequest);
	}, [filterRequest]);

	const handleGetCustomers = () => {
		if (customerSearch === "") {
			dispatch(customerActions.getCustomers(queryString.stringify({})));
		} else {
			dispatch(
				customerActions.getCustomers(
					queryString.stringify({ name: customerSearch })
				)
			);
		}
	};

	useEffect(() => {
		handleGetCustomers();
	}, [customerSearch]);

	const clearFilter = () => {
		setCustomerValue(null);
		setProvinceValue(null);
		setStatusValue(null);
		setYearValue(null);
		setOptionFilter(initialFilter);
		handleClose();
	};

	return (
		<Style>
			<Card className={`filter ${false} && "show"}`}>
				<CardHeader>
					<span
						style={{ cursor: "pointer" }}
						className="text-danger font-weight-bold"
						onClick={() => {
							handleClose();
						}}
					>
						Đóng
					</span>
				</CardHeader>
				{/* <hr className="my-3"/> */}
				<CardBody>
					<FormGroup className="row">
						<Label
							className="form-control-label text-sm"
							htmlFor="example-text-input"
							md="5"
						>
							Lọc theo năm
						</Label>
						<Col md="7">
							<Select
								placeholder="Chọn năm"
								isClearable={true}
								value={yearValue}
								onChange={(e) => {
									setYearValue(e);
									setOptionFilter({
										...optionFilter,
										year: !!e ? e : null,
									});
								}}
								options={Array.apply(null, Array(5)).map((item, index) => ({
									label: new Date().getFullYear() - index + "",
									value: new Date().getFullYear() - index + "",
								}))}
							/>
						</Col>
					</FormGroup>
					{/* <FormGroup className="row">
						<Label
							className="form-control-label text-sm"
							htmlFor="example-search-input"
							md="5"
						>
							Lọc theo trạng thái
						</Label>
						<Col md="7">
							<Select
								placeholder="Chọn trạng thái"
								isClearable={true}
								value={statusValue}
								onChange={(e) => {
									setStatusValue(e);
									setOptionFilter({
										...optionFilter,
										status: !!e ? e : null,
									});
								}}
								options={listStatus.map((item) => ({
									label: item.name,
									value: item.status,
								}))}
							/>
						</Col>
					</FormGroup> */}
					<FormGroup className="row">
						<Label
							className="form-control-label text-sm"
							htmlFor="example-email-input"
							md="5"
						>
							Lọc theo tỉnh thành
						</Label>
						<Col md="7">
							<Select
								placeholder="Nhập tên tìm kiếm"
								isClearable={true}
								value={provinceValue}
								onChange={(e) => {
									setProvinceValue(e);
									setOptionFilter({
										...optionFilter,
										provinceId: !!e ? e : null,
									});
								}}
								options={provinces.results.map((item) => ({
									label: item.provinceName + "",
									value: item.id + "",
								}))}
							/>
						</Col>
					</FormGroup>
					<FormGroup>
						<Label
							className="form-control-label text-sm"
							htmlFor="example-email-input"
						>
							Chọn tên khách hàng
						</Label>

						<Select
							placeholder="Nhập tên tìm kiếm"
							isClearable={true}
							value={customerValue}
							onChange={(e) => {
								setCustomerValue(e);
								setOptionFilter({
									...optionFilter,
									customerOrganizationId: !!e ? e : null,
								});
							}}
							options={customers.results.map((item) => ({
								label: item.name + "",
								value: item.id + "",
							}))}
							onInputChange={(value) => {
								setCustomerSearch(value);
							}}
						/>
					</FormGroup>
				</CardBody>
				<CardFooter>
					<Button
						// className="btn btn-secondary btn-md text-sm btn-block"
						block="true"
						onClick={() => {
							clearFilter();
							handleFilter({});
						}}
					>
						Xóa bộ lọc
					</Button>
					<Button
						className="ml-0"
						block={true}
						color="primary"
						onClick={() => {
							handleFilter(optionFilter);
						}}
					>
						Áp dụng bộ lọc
					</Button>
				</CardFooter>
			</Card>
		</Style>
	);
};

export default Filter;
