import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  CardFooter,
} from "reactstrap";
import { provincesActions } from "Redux/Actions";
// import { FilterContext } from "../ListOrder/context";
import queryString from "query-string";
import ReactSelect from "react-select";
import { productActions } from "Redux/Actions";
// import { Style } from "./style";
const Filter = ({ open, toggle, valueFilter, setValueFilter }) => {
  const [optionFilter, setOptionFilter] = useState({});
  const range = (start, step) => {
    const date = new Date();
    const stop = date.getFullYear();
    return Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  };
  const [textSearchProduct, setTextSearchProduct] = useState("");
  const dispatch = useDispatch();
  const { provinces } = useSelector((state) => state.provincesReducer);
  const { products } = useSelector((state) => state.productReducer);
  const [payloadFilter, setPayloadFilter] = useState(valueFilter);

  const handleFindProduct = () => {
    if (textSearchProduct === "") {
      dispatch(productActions.getProducts(queryString.stringify({ limit: 5 })));
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({ limit: 5, name: textSearchProduct })
        )
      );
    }
  };
  useEffect(() => {
    dispatch(
      provincesActions.getProvinces(queryString.stringify({ limit: 100 }))
    );
  }, []);
  useEffect(() => {
    handleFindProduct();
  }, [textSearchProduct]);
  const hanleChange = (e) => {
    setOptionFilter({ ...optionFilter, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setPayloadFilter(valueFilter);
  }, [valueFilter]);
  return (
    <>
      <Card className={`filterOption ${open && "show"}`}>
        <CardHeader>
          <span
            style={{ cursor: "pointer" }}
            className="text-danger"
            onClick={toggle}
          >
            Đóng
          </span>
        </CardHeader>
        {/* <hr className="my-3"/> */}
        <CardBody>
          {/* <FormGroup className="row">
						<Label
							className="form-control-label"
							htmlFor="example-text-input"
							md="5"
						>
							Lọc theo năm
						</Label>
						<Col md="7">
							<Input
								defaultValue="John Snow"
								id="example-text-input"
								type="select"
								name="year"
							>
								<option value="" hidden>
									Chọn năm
								</option>
								{Array.apply(null, Array(5)).map((item, index) => {
									return (
										<option
											value={new Date().getFullYear() - index}
											key={index}
										>
											{new Date().getFullYear() - index}
										</option>
									);
								})}
							</Input>
						</Col>
					</FormGroup>

					<FormGroup className="row">
						<Label
							className="form-control-label"
							htmlFor="example-email-input"
							md="5"
						>
							Lọc theo tỉnh thành
						</Label>
						<Col md="7">
							<Input
								defaultValue="argon@example.com"
								id="example-email-input"
								type="select"
							>
								<option value="">Chọn tỉnh</option>
								{provinces.results.map((item) => {
									return (
										<option key={item.id} value={item.id}>
											{item.provinceName}
										</option>
									);
								})}
							</Input>
						</Col>
					</FormGroup> */}
          <FormGroup>
            <Label className="form-control-label" htmlFor="example-email-input">
              Chọn sản phẩm
            </Label>
            <ReactSelect
              options={products.results.map((item) => {
                return { value: item.id, label: item.name };
              })}
              placeholder="Chọn"
              value={payloadFilter?.productTypeId || null}
              onChange={(e) => {
                setPayloadFilter({ ...payloadFilter, productTypeId: e });
              }}
              onInputChange={(value) => setTextSearchProduct(value)}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            block={true}
            onClick={() => {
              setValueFilter(null);
              setPayloadFilter({});
              toggle();
            }}
          >
            Xóa bộ lọc
          </Button>
          <Button
            block={true}
            className="ml-0"
            color="primary"
            onClick={() => {
              setValueFilter(payloadFilter);
            }}
          >
            Áp dụng bộ lọc
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Filter;
