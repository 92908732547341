import React, { useEffect, useState } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import _ from 'lodash';

const OptionShow = ({ setState, initialColumns = [], columnDafault = [] }) => {
  const [fieldOptionShow, setFieldOptionShow] = useState(initialColumns);
  useEffect(() => {
    setState(_.uniqBy([...columnDafault, ...fieldOptionShow], 'dataField'));
  }, [fieldOptionShow]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        color=""
        role="button"
        size="sm"
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <Label
          style={{ margin: '0rem 1rem 0.5rem' }}
          className="form-control-label text-sm"
        >
          Lựa chọn hiển thị
        </Label>
        <hr className="m-0" />
        {fieldOptionShow.map((item, index) => (
          <div
            key={index}
            style={{ margin: '0.5rem 1rem' }}
            className="custom-control custom-checkbox"
          >
            <input
              className="custom-control-input"
              type="checkbox"
              id={item.dataField}
              checked={item.isChecked}
              onChange={(e) => {
                setFieldOptionShow([
                  ...fieldOptionShow.slice(0, index),
                  {
                    ...fieldOptionShow[index],
                    isChecked: e.target.checked,
                  },
                  ...fieldOptionShow.slice(index + 1),
                ]);
              }}
            />
            <label className="custom-control-label" htmlFor={item.dataField}>
              {item.text}
            </label>
          </div>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default OptionShow;
