import TypeActions from "../TypeActions";

export const getCompleteStages = (query, callback) => {
  return {
    type: TypeActions.GET_COMPLETE_STAGES_REQUEST,
    query,
    callback,
  };
};
export const getCompletePackings = (query, callback) => {
  return {
    type: TypeActions.GET_COMPLETE_PACKINGS_REQUEST,
    query,
    callback,
  };
};
export const getCompleteProductsError = (query, callback) => {
  return {
    type: TypeActions.GET_COMPLETE_PRODUCTS_ERROR_REQUEST,
    query,
    callback,
  };
};
export const getCompleteStageById = (params, query, callback) => {
  return {
    type: TypeActions.GET_COMPLETE_STAGE_BY_ID_REQUEST,
    params,
    query,
    callback,
  };
};

export const createCompleteStage = (body, callback) => {
  return {
    type: TypeActions.CREATE_COMPLETE_STAGE_REQUEST,
    body,
    callback,
  };
};
export const updateCompleteStage = (body, params, callback) => {
  return {
    type: TypeActions.UPDATE_COMPLETE_STAGE_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteCompleteStage = (params, callback) => {
  return {
    type: TypeActions.DELETE_COMPLETE_STAGE_REQUEST,
    params,
    callback,
  };
};

export const getCustomerByBarcodeCompleteStage = (query, callback) => {
  return {
    type: TypeActions.GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_REQUEST,
    query,
    callback,
  };
};

export const transferQCSP = (body, callback) => {
  return {
    type: TypeActions.TRANSFER_QC_SP_REQUEST,
    body,
    callback,
  };
};

export const transferCustomer = (body, callback) => {
  return {
    type: TypeActions.TRANSFER_CUSTOMER_REQUEST,
    body,
    callback,
  };
};

export const getProductsDelivering = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_DELIVERING_REQUEST,
    query,
    callback,
  };
};

export const getProductsDelivered = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_DELIVERED_REQUEST,
    query,
    callback,
  };
};

export default {
  getCompleteStages,
  createCompleteStage,
  updateCompleteStage,
  deleteCompleteStage,
  getCompleteStageById,
  getCustomerByBarcodeCompleteStage,
  transferQCSP,
  getCompletePackings,
  getCompleteProductsError,
  transferCustomer,
  getProductsDelivering,
  getProductsDelivered,
};
