import React, { useState, useEffect, useRef } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  UncontrolledTooltip,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Button,
  CardBody,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import _ from 'lodash';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { notify, checkRole } from 'common';
import { Style } from '../style';
import Filter from './components/Filter';
import { qualityActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import ReactNotificationAlert from 'react-notification-alert';

const QC = ({ location }) => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const { qualities, isGetQualities } = useSelector(
    (state) => state.qualityReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const notificationAlertRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectItems, setSelectItems] = useState([]);
  const [firstCalled, setFirstCalled] = useState(false);
  const [filterBy, setFilterBy] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });
  const [filterByName, setFilterByName] = useState({});
  const [searchValues, setSearchValues] = useState({
    barcode: '',
  });
  const [filterValues, setFilterValues] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });
  const [transportStatus, setTransportStatus] = useState(
    CONSTANTS.TRANSPORT_STATUS.QC
  );
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    transportStatus: transportStatus,
    productQuality: CONSTANTS.PRODUCT_QUALITY.INIT,
    populate: 'customerOrgId.parentId, productId, orderId',
  });

  const columns = [
    {
      dataField: 'barcode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.barcode}
            onChange={(e) =>
              setSearchValues({ ...searchValues, barcode: e.target.value })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',

      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'orderId.name',
      text: 'Đơn hàng',

      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Đơn vị/ phòng ban',
    },
    {
      dataField: 'amount',
      text: `Số lượng (${qualities?.totalResults})`,
      style: { textAlign: 'center' },
      headerAlign: 'center',
      formatter: (cell) => {
        return 1;
      },
    },
    {
      dataField: 'createdAt',
      text: 'Ngày nhận hàng',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: qualities?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > qualities.totalResults
              ? !isNaN(qualities?.totalResults)
                ? qualities.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(qualities?.totalResults) ? qualities.totalResults : 0} bản
            ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetQualities = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      qualityActions.getQualities(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setFirstCalled(true);
          },
        }
      )
    );
  };

  const handleFilter = (values) => {
    setFilterBy({ ...values });
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(values).map((key, index) => {
      if (!_.isEmpty(values[key])) {
        tempFilter[key] = values[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      qualityActions.getQualities(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      qualityActions.getQualities(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    const tempFilter = {};
    Object.keys(filterBy).map((key, index) => {
      if (!_.isEmpty(filterBy[key])) {
        tempFilter[key] = filterBy[key].label;
      }
    });
    setFilterByName(tempFilter);
  }, [filterBy]);

  useEffect(() => {
    handleGetQualities();
  }, [query]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col md="6">
                {_.isEmpty(filterByName) ? (
                  <></>
                ) : (
                  <>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {filterByName?.orderId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Đơn hàng: {filterByName?.orderId};
                      </span>
                    )}
                    {filterByName?.productId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Sản phẩm: {filterByName?.productId};
                      </span>
                    )}
                    {filterByName?.producerId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Nhà gia công: {filterByName?.producerId};
                      </span>
                    )}
                  </>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" md="6">
                {checkRole(currentAccount, {
                  roles: ['manage_transportQC'],
                }) && (
                  <>
                    <LoadingButtonCustom
                      onClick={() => {
                        history.push('/form-qc-qualified', {
                          page,
                          rowsPerPage,
                          route: location.pathname,
                        });
                      }}
                      className="btn-neutral"
                      color="default"
                    >
                      QC SP đạt
                    </LoadingButtonCustom>
                    <LoadingButtonCustom
                      onClick={() => {
                        history.push('/form-qc-unqualified', {
                          page,
                          rowsPerPage,
                          route: location.pathname,
                        });
                      }}
                      className="btn-neutral"
                      color="default"
                    >
                      QC SP không đạt
                    </LoadingButtonCustom>
                  </>
                )}

                <Button
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                  className="btn-neutral"
                  color="default"
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={qualities.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <CardHeader>
                      <Row>
                        <Col className="d-flex align-items-center" md="6">
                          <h3 className="mb-0">Danh sách sản phẩm</h3>
                        </Col>
                        <Col
                          className="d-flex justify-content-end"
                          md="6"
                        ></Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <BootstrapTable
                        {...props.baseProps}
                        noDataIndication={() => {
                          return (
                            <span className="font-weight-bold text-danger">
                              Không có dữ liệu!
                            </span>
                          );
                        }}
                        onTableChange={() => {
                          return <div className="spinner-border text-info" />;
                        }}
                        filter={filterFactory()}
                        pagination={pagination}
                        bordered={false}
                        hover
                        remote
                      />
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {openFilter && (
        <Filter
          openFilter={openFilter}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
        />
      )}
    </Style>
  );
};

export default QC;
