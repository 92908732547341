import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Style } from '../style';
import DialogAddWorkForPlanV2 from './components/DialogAddWorkForPlanV2';
import { useDispatch, useSelector } from 'react-redux';
import { orderPlanActions, orderActions } from 'Redux/Actions';
import ReactNotificationAlert from 'react-notification-alert';
import { notify } from 'common';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import {
  EditSVG,
  WaitSVG,
  DeniedSVG,
  ViewSVG,
  CopySVG,
  ValidateCheckSVG,
} from 'assets/svg';
import Select from 'react-select';
import _ from 'lodash';
import { BinSVG, PaperSVG, DoneSVG, SendMailSVG } from 'assets/svg';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import DetailOrderPlan from './DetailOrderPlan';
import CONSTANTS from 'constant';
import BoxComment from 'views/pages/components/BoxComment';
import InputCustom from 'views/pages/components/InputCustom';
import { checkRole } from 'common';
import { ArrowsRightSVG } from 'assets/svg';

function PlanPendingCreate({ location }) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const { isDeleteOrderPlan, comments, isGetOrderPlans } = useSelector(
    (state) => state.orderPlanReducer
  );
  const history = useHistory();
  const [sectionState, setSectionState] = useState(
    location?.state?.sectionState || 0
  );
  const [orders, setOrders] = useState({ results: [] });
  const [orderSearch, setOrderSearch] = useState('');
  const [orderValue, setOrderValue] = useState(
    location?.state?.orderValue || null
  );
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [query, setQuery] = useState(
    location?.state?.query || {
      page: page,
      limit: rowsPerPage,
      populate:
        'orderId.customerOrganizationId.provinceId,customerOrganizationIds,producers.producerId,productId',
      orderPlanStatus: [CONSTANTS.ORDER_PLAN_STATUS.Init],
    }
  );
  const [queryOrders, setQueryOrders] = useState(
    location?.state?.queryOrders || {
      page: 1,
      limit: 10,
      sortBy: 'createdAt:desc',
      // orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.Init,
      populate: 'products.productTypeId',
    }
  );
  // arrOrderPlanStatus
  const notificationAlertRef = useRef(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [dataOrderForm, setDataOrderForm] = useState({});
  const [formModal, setFormModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [orderPlansData, setOrderPlansData] = useState({ results: [] });
  const [openDetail, setOpenDetail] = useState(false);
  const [comment, setComment] = useState('');
  const handleCreate = (data, arrProducer) => {
    const { status, orderPlanStatus, id, ...payload } = data;
    payload.producers = arrProducer.map((item) => ({
      amount: item?.amount,
      producerId: item?.producerValue?.value,
    }));
    dispatch(
      orderPlanActions.createOrderPlan(
        { ...payload, orderId: orderValue.value },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm công việc thành công!`
            );
            setFormModal(false);
            setIsCopy(false);
            handleGetOrderPlans();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm công việc thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleUpdate = (data, arrProducer) => {
    const { status, id, ...payload } = data;
    payload.producers = arrProducer.map((item) => ({
      amount: item?.amount,
      producerId: item?.producerValue?.value,
    }));
    dispatch(
      orderPlanActions.updateOrderPlan({ ...payload }, dataOrderForm.id, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật công việc thành công!`
          );
          setFormModal(false);
          setIsCopy(false);
          handleGetOrderPlans();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật công việc thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleSendToBrowse = (id) => {
    dispatch(
      orderPlanActions.updateOrderPlan(
        { orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.PendingAcept },
        id,
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Gửi phê duyệt kế hoạch thành công!`
            );
            setFormModal(false);
            setIsCopy(false);
            handleGetOrderPlans();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi phê duyệt kế hoạch thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const boxAction = (cell, row, id, formatExtraData) => {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccount, { roles: ['manage_orderPlan'] }) &&
          (sectionState === 0 || sectionState === 2) && (
            <>
              <button
                className="btn-none"
                onClick={() => {
                  handleSendToBrowse(row.id);
                }}
                id={'send' + id}
              >
                <ArrowsRightSVG color="#00BBF1" />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'send' + id}
              >
                Gửi duyệt kế hoạch
              </UncontrolledTooltip>
            </>
          )}
        {checkRole(currentAccount, { roles: ['get_orderPlan'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                history.push(`detail-aggrement-plan/${row.id}`, {
                  page,
                  rowsPerPage,
                  route: location.pathname,
                  orderValue: orderValue,
                  sectionState: sectionState,
                  queryOrders: queryOrders,
                  query: query,
                });
              }}
              id={'view' + id}
            >
              <ViewSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
              Xem chi tiết kế hoạch
            </UncontrolledTooltip>
          </>
        )}

        {sectionState === 2 &&
          checkRole(currentAccount, { roles: ['manage_orderPlan'] }) && (
            <>
              <button
                className="btn-none"
                onClick={() => {
                  setIsModalAdd(false);
                  setDataOrderForm(row);
                  setFormModal(true);
                  setIsCopy(false);
                }}
                id={'edit' + id}
              >
                <EditSVG />
              </button>
              <UncontrolledTooltip
                delay={1}
                placement="top"
                target={'edit' + id}
              >
                Cập nhật kế hoạch
              </UncontrolledTooltip>
            </>
          )}

        {sectionState === 0 && (
          <>
            {checkRole(currentAccount, { roles: ['manage_orderPlan'] }) && (
              <>
                <button
                  className="btn-none"
                  onClick={() => {
                    setIsModalAdd(true);
                    setIsCopy(true);
                    setDataOrderForm({
                      ...row,
                      code: '',
                      name: '',
                      productId: null,
                      producers: [],
                    });
                    setFormModal(true);
                  }}
                  id={'copy' + id}
                >
                  <CopySVG />
                </button>
                <UncontrolledTooltip
                  delay={1}
                  placement="top"
                  target={'copy' + id}
                >
                  Sao chép kế hoạch
                </UncontrolledTooltip>
                <button
                  className="btn-none"
                  onClick={() => {
                    setIsModalAdd(false);
                    setDataOrderForm(row);
                    setFormModal(true);
                    setIsCopy(false);
                  }}
                  id={'edit' + id}
                >
                  <EditSVG />
                </button>
                <UncontrolledTooltip
                  delay={1}
                  placement="top"
                  target={'edit' + id}
                >
                  Cập nhật kế hoạch
                </UncontrolledTooltip>
              </>
            )}
            {checkRole(currentAccount, { roles: ['delete_orderPlan'] }) &&
              sectionState === 0 && (
                <>
                  <button
                    className="btn-none"
                    onClick={() => {
                      setDataOrderForm(row);
                      setNotificationModal(true);
                    }}
                    id={'delete' + id}
                  >
                    <BinSVG />
                  </button>
                  <UncontrolledTooltip
                    delay={1}
                    placement="top"
                    target={'delete' + id}
                  >
                    Xóa kế hoạch
                  </UncontrolledTooltip>
                </>
              )}
          </>
        )}
      </div>
    );
  };
  const columns = [
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'code',
      text: 'Mã kế hoạch',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'name',
      text: 'Tên kế hoạch',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'orderId.customerOrganizationId.provinceId.provinceName',
      text: 'Tỉnh/TP',
    },
    {
      dataField: 'productId.code',
      text: 'Mã SP',
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',
    },
    {
      dataField: 'productId.gender',
      text: 'Giới tính',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'producers',
      text: 'Số lượng',
      style: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      formatter: (cell) => {
        let sum = 0;
        cell.forEach((item, index) => {
          sum += item.amount;
        });
        return sum;
      },
    },
    {
      dataField: 'producers',
      text: 'Nhà gia công',
      formatter: (cell) => {
        return cell.map((item, index) =>
          cell.length - 1 === index
            ? `${!!item.producerId?.name ? item.producerId.name : ''}.`
            : `${!!item.producerId?.name ? item.producerId.name : ''}, `
        );
      },
      headerStyle: {
        minWidth: 200,
      },
    },
  ];
  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: orderPlansData?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > orderPlansData.totalResults
              ? !isNaN(orderPlansData?.totalResults)
                ? orderPlansData.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(orderPlansData?.totalResults)
              ? orderPlansData.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });
  const handleGetOrderPlans = () => {
    // if (!_.isEmpty(orderValue)) {
    dispatch(
      orderPlanActions.getOrderPlans(
        queryString.stringify(
          {
            ...query,
            orderIds: !_.isEmpty(orderValue) ? [orderValue.value] : [],
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setOrderPlansData(data);
          },
        }
      )
    );
    // } else {
    //   setOrderPlansData({ results: [] });
    // }
  };

  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(
        orderActions.getOrders(
          queryString.stringify(queryOrders, { arrayFormat: 'bracket' }),
          {
            success: (data) => {
              setOrders(data);
            },
          }
        )
      );
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify(
            { ...queryOrders, name: orderSearch },
            { arrayFormat: 'bracket' }
          ),
          {
            success: (data) => {
              setOrders(data);
            },
          }
        )
      );
    }
  };

  const handleDelete = () => {
    dispatch(
      orderPlanActions.deleteOrderPlan(dataOrderForm.id, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa kế hoạch thành công!`
          );
          setNotificationModal(false);
          setDataOrderForm({});
          handleGetOrderPlans();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa kế hoạch thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleGetComments = () => {
    if (
      !_.isEmpty(orderValue) &&
      (sectionState === 1 || sectionState === 2 || sectionState === 3)
    ) {
      dispatch(
        orderPlanActions.getComments(
          queryString.stringify({
            orderId: orderValue.value,
            populate: 'userId',
            sortBy: 'time:desc',
          })
        )
      );
    }
  };
  useEffect(() => {
    handleGetOrderPlans();
    handleGetComments();
    const loop = setInterval(() => {
      handleGetComments();
    }, 3000);
    return () => {
      clearInterval(loop);
    };
  }, [orderValue, query]);

  const handleCreateComment = () => {
    dispatch(
      orderPlanActions.createCommentOrderPlan(
        {
          orderId: orderValue.value,
          comment: comment,
        },
        {
          success: () => {
            handleGetComments();
            setComment('');
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi bình luận thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);

  useEffect(() => {
    history.push(`${location.pathname}`, {
      page: 1,
      rowsPerPage: 10,
      route: location.pathname,
      orderValue: orderValue,
      sectionState: sectionState,
      queryOrders: queryOrders,
      query: query,
    });
  }, [sectionState]);

  return (
    <Style>
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="kế hoạch"
          func={handleDelete}
          isDelete={isDeleteOrderPlan}
        />
      )}
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col md="6">
                <Row className="align-items-center">
                  <Col md="12">
                    <p className="text-info mb-2 font-weight-500">
                      Vui lòng chọn đơn hàng, sau đó thao tác với kế hoạch của
                      đơn hàng đó
                    </p>
                  </Col>
                  <Col md="3" className="pr-0">
                    <h5 className="text-info">Đơn hàng đang chọn: </h5>
                  </Col>
                  <Col md="9">
                    <Select
                      size="sm"
                      placeholder="Chọn đơn hàng"
                      isClearable={true}
                      value={orderValue}
                      onChange={(e) => {
                        setOrderValue(e);
                      }}
                      options={orders.results.map((item) => ({
                        ...item,
                        value: item.id,
                        label: item.name,
                      }))}
                      onInputChange={(value) => {
                        setOrderSearch(value);
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      noOptionsMessage={() => null}
                    />
                  </Col>
                  {/* <div>
                  </div> */}
                </Row>
              </Col>
              <Col md="6">
                {checkRole(currentAccount, {
                  roles: ['manage_orderPlan'],
                }) && (
                  <div className="justify-content-end d-flex">
                    {sectionState === 0 && (
                      <Button
                        onClick={() => {
                          setFormModal(true);
                          setIsModalAdd(true);
                          setIsCopy(false);
                          setDataOrderForm({
                            orderId: {
                              id: orderValue?.id ?? '',
                              customerOrganizationId: {
                                id: orderValue?.customerOrganizationId ?? '',
                              },
                            },
                          });
                        }}
                        disabled={_.isEmpty(orderValue)}
                        className="btn-neutral"
                        color="default"
                      >
                        Thêm mới
                      </Button>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={orderPlansData?.results ?? []}
                keyField="id"
                columns={
                  sectionState !== 2
                    ? columns
                    : [
                        ...columns,
                        {
                          dataField: 'rejectReason',
                          text: 'Lý do',
                          style: {
                            textAlign: 'left',
                          },
                          headerStyle: {
                            textAlign: 'left',
                            minWidth: 200,
                          },
                        },
                      ]
                }
                search
              >
                {(props) => (
                  <>
                    <CardHeader>
                      <Row>
                        <Col md="10">
                          {/* <h3 className="mb-0">Danh sách kế hoạch</h3> */}
                          <Row className="mx-0">
                            <Row
                              className={`align-items-center mx-0 py-3 ${
                                sectionState === 0
                                  ? 'border-bottom-tab-active'
                                  : ''
                              }`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSectionState(0);
                                // setQueryOrders({
                                //   ...queryOrders,
                                //   // orderPlanStatus:
                                //   //   CONSTANTS.ORDER_PLAN_STATUS.Init,
                                // });
                                setQuery({
                                  page: page,
                                  limit: rowsPerPage,
                                  populate:
                                    'orderId.customerOrganizationId.provinceId,customerOrganizationIds,producers.producerId,productId',
                                  orderPlanStatus: [
                                    CONSTANTS.ORDER_PLAN_STATUS.Init,
                                  ],
                                });
                              }}
                            >
                              <PaperSVG />
                              <p
                                className={`h5 mb-0 ml-1 text-uppercase ${
                                  sectionState !== 0 && 'text-muted'
                                }`}
                              >
                                Kế hoạch khởi tạo
                              </p>
                            </Row>
                            <Row
                              className={`align-items-center ml-3 mx-0 py-3 ${
                                sectionState === 1
                                  ? 'border-bottom-tab-active'
                                  : ''
                              }`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSectionState(1);
                                // setQueryOrders({
                                //   ...queryOrders,
                                //   // orderPlanStatus:
                                //   //   CONSTANTS.ORDER_PLAN_STATUS.PendingAcept,
                                // });
                                setQuery({
                                  page: page,
                                  limit: rowsPerPage,
                                  populate:
                                    'orderId.customerOrganizationId.provinceId,customerOrganizationIds,producers.producerId,productId',
                                  orderPlanStatus: [
                                    CONSTANTS.ORDER_PLAN_STATUS.PendingAcept,
                                  ],
                                });
                              }}
                            >
                              <WaitSVG />
                              <p
                                className={`h5 mb-0 ml-1 text-uppercase ${
                                  sectionState !== 1 && 'text-muted'
                                }`}
                              >
                                Kế hoạch đang chờ duyệt
                              </p>
                            </Row>
                            <Row
                              className={`align-items-center ml-3 mx-0 py-3 ${
                                sectionState === 2
                                  ? 'border-bottom-tab-active'
                                  : ''
                              }`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSectionState(2);
                                // setQueryOrders({
                                //   ...queryOrders,
                                //   // orderPlanStatus:
                                //   //   CONSTANTS.ORDER_PLAN_STATUS.Reject,
                                // });
                                setQuery({
                                  page: page,
                                  limit: rowsPerPage,
                                  populate:
                                    'orderId.customerOrganizationId.provinceId,customerOrganizationIds,producers.producerId,productId',
                                  orderPlanStatus: [
                                    CONSTANTS.ORDER_PLAN_STATUS.Reject,
                                  ],
                                });
                              }}
                            >
                              <DeniedSVG />
                              <p
                                className={`h5 mb-0 ml-1 text-uppercase ${
                                  sectionState !== 2 && 'text-muted'
                                }`}
                              >
                                Kế hoạch đã từ chối
                              </p>
                            </Row>
                            <Row
                              className={`align-items-center ml-3 mx-0 py-3 ${
                                sectionState === 3
                                  ? 'border-bottom-tab-active'
                                  : ''
                              }`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSectionState(3);
                                // setQueryOrders({
                                //   ...queryOrders,
                                //   // orderPlanStatus:
                                //   //   CONSTANTS.ORDER_PLAN_STATUS.Accept,
                                // });
                                setQuery({
                                  page: page,
                                  limit: rowsPerPage,
                                  populate:
                                    'orderId.customerOrganizationId.provinceId,customerOrganizationIds,producers.producerId,productId',
                                  orderPlanStatus: [
                                    CONSTANTS.ORDER_PLAN_STATUS.Accept,
                                  ],
                                });
                              }}
                            >
                              <DoneSVG />
                              <p
                                className={`h5 mb-0 ml-1 text-uppercase ${
                                  sectionState !== 3 && 'text-muted'
                                }`}
                              >
                                Kế hoạch đã duyệt
                              </p>
                            </Row>
                          </Row>
                        </Col>
                        <Col
                          className="d-flex justify-content-end align-items-center"
                          md="2"
                        ></Col>
                      </Row>
                    </CardHeader>

                    <div className="pb-3">
                      {isGetOrderPlans ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-info" />
                        </div>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          noDataIndication={() => {
                            return (
                              <span className="font-weight-bold text-danger">
                                Không có dữ liệu!
                              </span>
                            );
                          }}
                          onTableChange={() => {
                            return <div className="spinner-border text-info" />;
                          }}
                          hover
                          remote
                          filter={filterFactory()}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      )}
                    </div>
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
        {!_.isEmpty(orderValue) &&
          (sectionState === 1 || sectionState === 2 || sectionState === 3) && (
            <Row>
              <Col md="12">
                <Card>
                  <CardBody className="py-0">
                    <Row>
                      <Col>
                        <p className="h2 my-3">Lịch sử trao đổi thông tin</p>
                      </Col>
                    </Row>
                    <Row
                      style={{ maxHeight: 500, overflowY: 'auto' }}
                      className="mt-3 px-5 py-3"
                    >
                      <Col>
                        <BoxComment
                          comments={
                            !_.isEmpty(orderValue) ? comments.results : []
                          }
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Row className="mt-3 px-5 py-3">
                      <Col>
                        <InputCustom
                          resize="none"
                          rows="2"
                          type="textarea"
                          placeholder="Nhập yêu cầu thêm"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                      </Col>
                      <Col style={{ maxWidth: '80px' }}>
                        <button
                          className="btn-none"
                          onClick={() => {
                            handleCreateComment();
                          }}
                        >
                          <SendMailSVG />
                        </button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          )}
      </Container>
      {formModal && (
        <DialogAddWorkForPlanV2
          open={formModal}
          setFormModal={setFormModal}
          isModalAdd={isModalAdd}
          dataForm={dataOrderForm}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isCopy={isCopy}
          setIsCopy={setIsCopy}
        />
      )}
      {openDetail && (
        <DetailOrderPlan
          open={openDetail}
          setOpenDetail={setOpenDetail}
          dataForm={dataOrderForm}
        />
      )}
    </Style>
  );
}

export default PlanPendingCreate;
