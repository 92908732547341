import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, Col, Table, Row, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { orderPlanActions, exportActions } from 'Redux/Actions';
import queryString from 'query-string';
import moment from 'moment';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import ReactNotificationAlert from 'react-notification-alert';
import { notify } from 'common';
import FileDownload from 'js-file-download';
const DetailAggregatePlan = ({ location }) => {
  const dispatch = useDispatch();
  const notificationAlertRef = useRef(null);
  const history = useHistory();
  const { orderPlanId } = useParams();
  const [orderPlanById, setOrderPlanById] = useState({});
  const [productValue, setProductValue] = useState(null);

  const [workPlanInfo, setWorkPlanInfo] = useState({
    code: '',
    name: '',
    producers: [],
    producerId: '',
    productSyncDate: moment().format('DD/MM/YYYY'),
    embroiderDate: moment().format('DD/MM/YYYY'),
    produceCutDate: moment().format('DD/MM/YYYY'),
    qcDate: moment().format('DD/MM/YYYY'),
    qcCustomerDate: moment().format('DD/MM/YYYY'),
    warehouseReceipDate: moment().format('DD/MM/YYYY'),
    productionDays: '',
    qcDays: '',
  });

  const handleGetOrderPlanById = () => {
    dispatch(
      orderPlanActions.getOrderPlanById(
        orderPlanId,
        queryString.stringify({
          populate:
            'orderId.customerOrganizationId.provinceId,producers.producerId,productId',
        }),
        {
          success: (data) => {
            setOrderPlanById(data);
          },
        }
      )
    );
  };

  const handleExport = () => {
    dispatch(
      exportActions.exportDetailPlan(orderPlanId, queryString.stringify({}), {
        success: (data) => {
          const filename = `CHI_TIET_KE_HOACH.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất chi tiết kế hoạch thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xuất chi tiết kế hoạch thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  useEffect(() => {
    if (_.isEmpty(orderPlanById)) return;
    setWorkPlanInfo({
      code: orderPlanById.code,
      name: orderPlanById.name,
      producers: orderPlanById?.producers || [],
      productionDays:
        orderPlanById?.productionDays !== undefined
          ? orderPlanById?.productionDays
          : '',
      qcDays: !orderPlanById?.qcDays !== undefined ? orderPlanById.qcDays : '',
      productSyncDate: moment(orderPlanById.productSyncDate).format(
        'DD/MM/YYYY'
      ),
      embroiderDate: moment(orderPlanById.embroiderDate).format('DD/MM/YYYY'),
      produceCutDate: moment(orderPlanById.produceCutDate).format('DD/MM/YYYY'),
      qcDate: moment(orderPlanById.qcDate).format('DD/MM/YYYY'),
      qcCustomerDate: moment(orderPlanById.qcCustomerDate).format('DD/MM/YYYY'),
      warehouseReceipDate: moment(orderPlanById.warehouseReceipDate).format(
        'DD/MM/YYYY'
      ),
    });
    setProductValue({
      value: orderPlanById.productId.id,
      label: orderPlanById.productId.name,
    });
  }, [orderPlanById]);

  useEffect(() => {
    if (!_.isEmpty(orderPlanId)) {
      handleGetOrderPlanById();
    }
  }, [orderPlanId]);

  return (
    <>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <div className="rna-wrapper">
          <ReactNotificationAlert ref={notificationAlertRef} />
        </div>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center pb-4 pt-3">
              <Col md="6"></Col>
              <Col className="mt-3 mt-md-0 text-md-right" md="6">
                <LoadingButtonCustom
                  loading={false}
                  onClick={() => {
                    // setOpenFilter(true);
                    handleExport();
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Xuất chi tiết
                </LoadingButtonCustom>
                <LoadingButtonCustom
                  onClick={() => {
                    history.push(location.state.route, {
                      ...location.state,
                    });
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Quay lại
                </LoadingButtonCustom>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Card className="mb-0 pb-5">
          <CardBody>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Mã kế hoạch</p>
              </Col>
              <Col xs={9}>: {workPlanInfo.code}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Tên kế hoạch</p>
              </Col>
              <Col xs={9}>: {workPlanInfo.name}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Sản phẩm</p>
              </Col>
              <Col xs={9}>: {productValue?.label}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">
                  Danh sách đơn vị gia công
                </p>
              </Col>
              <Col xs={9}>
                <Table
                  style={{ background: 'rgba(0,0,0,.02)' }}
                  className="p-0"
                >
                  <thead>
                    <tr>
                      <th
                        className="h3 font-weight-500 w-25 p-2"
                        style={{ paddingRight: 16 }}
                      >
                        <h5
                          className="mb-0 text-left"
                          style={{
                            padding: 0,
                            paddingLeft: 16,
                          }}
                        >
                          STT
                        </h5>
                      </th>
                      <th className="h3 font-weight-500 p-2">Nhà gia công</th>

                      <th className="h3 font-weight-500 p-2 text-center">
                        Số lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody className="p-0">
                    {workPlanInfo.producers.map((item, index) => {
                      return (
                        <tr key={index} className="p-0">
                          <td className="border-0 w-25">
                            <h5
                              className="mb-0 text-left"
                              style={{
                                padding: 0,
                                paddingLeft: 16,
                              }}
                            >
                              {index + 1}
                            </h5>
                          </td>
                          <td className="py-1 px-2 border-0">
                            {item?.producerId?.name}
                          </td>
                          <td className="py-1 px-2 border-0 text-center">
                            {item?.amount}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <p className="h3 text-sm font-weight-600">Mốc thời gian</p>
            <Row>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày đồng bộ NPL: {workPlanInfo.productSyncDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày thêu: {workPlanInfo.embroiderDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày cắt SX: {workPlanInfo.produceCutDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày giao GC: {workPlanInfo.qcDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày QC KH: {workPlanInfo.qcCustomerDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày NK KH: {workPlanInfo.warehouseReceipDate}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col xs={4}>
                <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                  Số ngày SX: {workPlanInfo.productionDays}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                  Số ngày QC: {workPlanInfo.qcDays}
                </p>
              </Col>
              <Col xs={4}></Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default DetailAggregatePlan;
