import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import InputCustom from 'views/pages/components/InputCustom';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { AddSVG, BinSVG } from 'assets/svg';
import { Formik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';
import Error from 'views/pages/components/Error';
import RequireCustom from 'views/pages/components/RequireCustom';
import { customerActions } from 'Redux/Actions';
import { producerActions } from 'Redux/Actions';
import moment from 'moment';
import _ from 'lodash';
import makeAnimated from 'react-select/animated';
import { materialNormActions } from 'Redux/Actions';
import DatePicker from 'reactstrap-date-picker';
const animatedComponents = makeAnimated();

const DialogAddWorkForPlanV2 = ({
  open,
  setFormModal,
  handleCreate,
  isModalAdd,
  dataForm,
  handleUpdate,
  isCopy,
  setIsCopy,
}) => {
  const dispatch = useDispatch();

  const workPlanSchema = yup.object().shape({
    code: yup.string().required('Mã kế hoạch không được để trống!'),
    name: yup.string().required('Tên kế hoạch không được để trống!'),
    productId: yup.string().required('Vui lòng chọn sản phẩm!'),
    productionDays: yup
      .number()
      .moreThan(0, 'Số ngày phải lớn hơn 0')
      .required('Số ngày sản xuất không được để trống!'),
    qcDays: yup
      .number()
      .moreThan(0, 'Số ngày phải lớn hơn 0')
      .required('Số ngày QC không được để trống!'),
  });

  const [productValue, setProductValue] = useState(null);
  const [producerName, setProducerName] = useState('');
  const [errorProducer, setErrorProducer] = useState(false);
  const [arrProducer, setArrProducer] = useState([
    {
      producerValue: null,
      amount: '',
    },
  ]);
  const { allProductInOrder } = useSelector(
    (state) => state.materialNormReducer
  );
  const { producers } = useSelector((state) => state.producerReducer);
  useEffect(() => {
    setProductValue(null);
  }, [open]);

  const [workPlanInfo, setWorkPlanInfo] = useState({
    code: '',
    name: '',
    productId: '',
    productSyncDate: moment().toISOString(),
    embroiderDate: moment().toISOString(),
    produceCutDate: moment().toISOString(),
    qcDate: moment().toISOString(),
    qcCustomerDate: moment().toISOString(),
    warehouseReceipDate: moment().toISOString(),
    productionDays: '0',
    qcDays: '0',
  });
  const [focused, setFocused] = useState({
    orderId: false,
    productId: false,
  });

  const handleGetProducers = () => {
    if (producerName === '') {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ limit: 10, page: 1 })
        )
      );
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ limit: 10, page: 1, name: producerName })
        )
      );
    }
  };

  const handleGetAllChildOfCustomer = () => {
    dispatch(
      customerActions.getAllChildOfCustomer(
        dataForm.orderId.customerOrganizationId.id,
        queryString.stringify({ populate: 'parentId' })
      )
    );
  };
  const handleGetCustomerById = () => {
    dispatch(
      customerActions.getCustomerById(
        dataForm.orderId.customerOrganizationId.id,
        queryString.stringify({})
      )
    );
  };
  const handleGetProductInOrder = () => {
    dispatch(
      materialNormActions.getAllProductInOrder(
        dataForm.orderId.id,
        queryString.stringify({ sortBy: 'name:desc' })
      )
    );
  };

  useEffect(() => {
    handleGetProducers();
  }, [producerName]);

  useEffect(() => {
    if (!_.isEmpty(dataForm)) {
      handleGetCustomerById();
      handleGetAllChildOfCustomer();
      handleGetProductInOrder();
    }
  }, [dataForm]);

  useEffect(() => {
    if (!isModalAdd || isCopy) {
      setWorkPlanInfo({
        code: dataForm.code,
        name: dataForm.name,
        productId: !_.isEmpty(dataForm?.productId) ? dataForm.productId.id : '',
        productionDays:
          dataForm?.productionDays !== undefined
            ? dataForm?.productionDays
            : '0',
        qcDays: !dataForm?.qcDays !== undefined ? dataForm.qcDays : '0',
        productSyncDate: moment(dataForm.productSyncDate).format('YYYY-MM-DD'),
        embroiderDate: moment(dataForm.embroiderDate).format('YYYY-MM-DD'),
        produceCutDate: moment(dataForm.produceCutDate).format('YYYY-MM-DD'),
        qcDate: moment(dataForm.qcDate).format('YYYY-MM-DD'),
        qcCustomerDate: moment(dataForm.qcCustomerDate).format('YYYY-MM-DD'),
        warehouseReceipDate: moment(dataForm.warehouseReceipDate).format(
          'YYYY-MM-DD'
        ),
      });
      !_.isEmpty(dataForm?.productId)
        ? setProductValue({
            value: dataForm.productId.id,
            label: dataForm.productId.name,
          })
        : setProductValue(null);
      !_.isEmpty(dataForm?.producers)
        ? setArrProducer(
            dataForm.producers.map((item) => ({
              producerValue: {
                label: item?.producerId?.name,
                value: item?.producerId?.id,
              },
              amount: item.amount,
            }))
          )
        : setArrProducer([
            {
              producerValue: null,
              amount: '',
            },
          ]);
    }
  }, [dataForm]);

  const onSubmit = (values) => {
    if (checkProducers()) return;
    isModalAdd
      ? handleCreate(values, arrProducer)
      : handleUpdate(values, arrProducer);
  };

  const checkProducers = () => {
    let check = false;
    arrProducer.forEach((item) => {
      if (item.producerValue === null || item.amount === '') {
        check = true;
      }
    });
    setErrorProducer(check);
    return check;
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={open}
        toggle={() => {
          setFormModal(false);
          setIsCopy(false);
        }}
      >
        <Formik
          initialValues={workPlanInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={workPlanSchema}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            handleChange,
            resetForm,
            handleBlur,
          }) => {
            return (
              <>
                <Card className="mb-0 pb-5">
                  <CardHeader className="modal-header">
                    <p className="h2 text-uppercase">
                      Kế hoạch triển khai sản xuất cho đơn hàng
                    </p>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => {
                        setFormModal(false);
                        setIsCopy(false);
                      }}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Mã kế hoạch
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <InputCustom
                          className="max-height-input-custom"
                          style={{ flex: 1 }}
                          invalid={errors.code && touched.code}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="code"
                          value={values.code}
                          messageInvalid={errors.code}
                          type="text"
                          placeholder="Nhập mã kế hoạch"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Tên kế hoạch
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <InputCustom
                          className="max-height-input-custom"
                          style={{ flex: 1 }}
                          invalid={errors.name && touched.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="name"
                          value={values.name}
                          messageInvalid={errors.name}
                          type="text"
                          placeholder="Nhập mã kế hoạch"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <p className="h3 text-sm font-weight-500">
                          Sản phẩm
                          <RequireCustom />
                        </p>
                      </Col>
                      <Col xs={9}>
                        <Select
                          placeholder="Chọn sản phẩm"
                          isClearable={true}
                          value={productValue}
                          onChange={(e) => {
                            setProductValue(e);
                            setFieldValue('productId', e ? e.value : '');
                          }}
                          options={
                            !_.isEmpty(allProductInOrder.results)
                              ? allProductInOrder.results.map((item) => ({
                                  value: item?._id,
                                  label: item?.name,
                                }))
                              : []
                          }
                          // onInputChange={(value) => {
                          // 	setProductName(value);
                          // }}
                          onFocus={() => {
                            setFocused({
                              ...focused,
                              productId: true,
                            });
                          }}
                        />
                        {errors.productId && focused.productId && (
                          <Error messageInvalid={errors.productId} />
                        )}
                      </Col>
                    </Row>
                    {arrProducer.map((item, index) => {
                      return (
                        <Row key={index} className="mb-3">
                          <Col md={3}>
                            <p className="h3 text-sm font-weight-500">
                              Đơn vị gia công
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md={4}>
                            <Select
                              placeholder="Lựa chọn"
                              isClearable={true}
                              value={item.producerValue}
                              id="producerId"
                              name="producerId"
                              onChange={(e) => {
                                setArrProducer([
                                  ...arrProducer.slice(0, index),
                                  {
                                    ...arrProducer[index],
                                    producerValue: e,
                                  },
                                  ...arrProducer.slice(index + 1),
                                ]);
                              }}
                              options={producers.results.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              onInputChange={(value) => {
                                setProducerName(value);
                              }}
                              onFocus={() => {
                                setFocused({
                                  ...focused,
                                  producerId: true,
                                });
                              }}
                            />
                          </Col>
                          <Col md={2}>
                            <p className="h3 text-sm font-weight-500 text-right">
                              Số lượng
                              <RequireCustom />
                            </p>
                          </Col>
                          <Col md={2}>
                            <InputCustom
                              className="max-height-input-custom"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setArrProducer([
                                  ...arrProducer.slice(0, index),
                                  {
                                    ...arrProducer[index],
                                    amount:
                                      e.target.value === ''
                                        ? ''
                                        : Number(e.target.value) < 0
                                        ? 0
                                        : Number(e.target.value),
                                  },
                                  ...arrProducer.slice(index + 1),
                                ]);
                              }}
                              name="amount"
                              value={item.amount}
                              type="number"
                              placeholder="Nhập"
                            />
                          </Col>
                          <Col className="d-flex justify-content-end" md="1">
                            {arrProducer.length === 1 ? (
                              <span></span>
                            ) : (
                              <span
                                onClick={() => {
                                  setArrProducer([
                                    ...arrProducer.slice(0, index),
                                    ...arrProducer.slice(index + 1),
                                  ]);
                                }}
                                style={{ cursor: 'pointer' }}
                              >
                                <BinSVG />
                              </span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    <Row>
                      <Col md="11"></Col>
                      <Col md="1">
                        <div className="text-md-right">
                          <span
                            onClick={() => {
                              setArrProducer([
                                ...arrProducer,
                                {
                                  producerValue: null,
                                  amount: '',
                                },
                              ]);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <AddSVG />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {errorProducer && (
                          <Error messageInvalid="Vui lòng nhập đầy đủ thông tin đơn vị gia công!" />
                        )}
                      </Col>
                    </Row>
                    <p className="h3 text-sm font-weight-500">Mốc thời gian</p>
                    <Row>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0">
                          1. Ngày đồng bộ NPL
                          <RequireCustom />
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.productSyncDate}
                          maxDate={values.qcCustomerDate}
                          onChange={(v, f) => {
                            const productSyncDate = moment(v).startOf('day');
                            const qcCustomerDate = moment(
                              values.qcCustomerDate
                            ).startOf('day');
                            if (qcCustomerDate - productSyncDate >= 0) {
                              setFieldValue('productSyncDate', v);
                              const productionDays =
                                Math.abs(qcCustomerDate - productSyncDate) /
                                (1000 * 3600 * 24);
                              setFieldValue('productionDays', productionDays);
                            }
                          }}
                        />
                      </Col>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0">
                          2. Ngày thêu
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.embroiderDate}
                          onChange={(v, f) => {
                            setFieldValue('embroiderDate', v);
                          }}
                        />
                      </Col>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0">
                          3. Ngày cắt SX
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.produceCutDate}
                          onChange={(v, f) => {
                            setFieldValue('produceCutDate', v);
                          }}
                        />
                      </Col>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0 mt-2">
                          4. Ngày giao GC
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.qcDate}
                          onChange={(v, f) => {
                            // const qcDate = moment(v).startOf("day");
                            // const warehouseReceipDate = moment(
                            //   values.warehouseReceipDate
                            // ).startOf("day");
                            // if (warehouseReceipDate - qcDate >= 0) {
                            //   const productionDays =
                            //   Math.abs(warehouseReceipDate - qcDate) /
                            //   (1000 * 3600 * 24);
                            //   setFieldValue("productionDays", productionDays);
                            // }
                            setFieldValue('qcDate', v);
                          }}
                        />
                      </Col>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0 mt-2">
                          5. Ngày QC KH
                          <RequireCustom />
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.qcCustomerDate}
                          minDate={values.productSyncDate}
                          maxDate={values.warehouseReceipDate}
                          onChange={(v, f) => {
                            const warehouseReceipDate = moment(
                              values.warehouseReceipDate
                            ).startOf('day');
                            const productSyncDate = moment(
                              values.productSyncDate
                            ).startOf('day');
                            const qcCustomerDate = moment(v).startOf('day');
                            if (
                              warehouseReceipDate - qcCustomerDate >= 0 &&
                              qcCustomerDate - productSyncDate >= 0
                            ) {
                              setFieldValue('qcCustomerDate', v);
                              const qcDays =
                                Math.abs(warehouseReceipDate - qcCustomerDate) /
                                (1000 * 3600 * 24);
                              setFieldValue('qcDays', qcDays);
                              const productionDays =
                                Math.abs(qcCustomerDate - productSyncDate) /
                                (1000 * 3600 * 24);
                              setFieldValue('productionDays', productionDays);
                            }
                          }}
                        />
                      </Col>
                      <Col xs={4}>
                        <p className="h5 text-sm font-weight-400 mb-0 mt-2">
                          6. Ngày NK KH
                          <RequireCustom />
                        </p>
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.warehouseReceipDate}
                          minDate={values.qcCustomerDate}
                          onChange={(v, f) => {
                            // const qcDate = moment(values.qcDate).startOf("day");
                            const qcCustomerDate = moment(
                              values.qcCustomerDate
                            ).startOf('day');
                            const warehouseReceipDate =
                              moment(v).startOf('day');
                            if (
                              // warehouseReceipDate - qcDate >= 0 &&
                              warehouseReceipDate - qcCustomerDate >=
                              0
                            ) {
                              setFieldValue('warehouseReceipDate', v);

                              // const productionDays =
                              //   Math.abs(warehouseReceipDate - qcDate) /
                              //   (1000 * 3600 * 24);

                              const qcDays =
                                Math.abs(warehouseReceipDate - qcCustomerDate) /
                                (1000 * 3600 * 24);
                              // setFieldValue("productionDays", productionDays);
                              setFieldValue('qcDays', qcDays);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-between">
                      <Col xs={5}>
                        <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                          Số ngày SX
                        </p>
                        <InputCustom
                          className="max-height-input-custom"
                          style={{ flex: 1 }}
                          invalid={
                            errors.productionDays && touched.productionDays
                          }
                          disabled={true}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="productionDays"
                          value={values.productionDays}
                          messageInvalid={errors.productionDays}
                          type="text"
                          placeholder="số"
                        />
                      </Col>
                      <Col xs={5}>
                        <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                          Số ngày QC
                        </p>
                        <InputCustom
                          className="max-height-input-custom"
                          style={{ flex: 1 }}
                          type="text"
                          placeholder="số"
                          invalid={errors.qcDays && touched.qcDays}
                          disabled={true}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="qcDays"
                          value={values.qcDays}
                          messageInvalid={errors.qcDays}
                        />
                      </Col>
                    </Row>
                    <Row></Row>
                  </CardBody>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={() => {
                        setFormModal(false);
                        setIsCopy(false);
                      }}
                    >
                      Hủy bỏ
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        setFocused({
                          orderId: true,
                          productId: true,
                        });
                        checkProducers();
                        handleSubmit();
                      }}
                    >
                      {isModalAdd ? 'Thêm mới' : 'Lưu lại'}
                    </Button>
                  </div>
                </Card>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default DialogAddWorkForPlanV2;
