import React, { useState, useEffect, useRef } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  DropdownItem,
  CardBody,
  Input,
} from 'reactstrap';
import { BinSVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { notify, generateDocxBarcode } from 'common';
import InputCustom from 'views/pages/components/InputCustom';
import RequireCustom from 'views/pages/components/RequireCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { Formik } from 'formik';
import * as yup from 'yup';
import ReactNotificationAlert from 'react-notification-alert';
import { qualityActions } from 'Redux/Actions';
import { useHistory } from 'react-router-dom';
import CONSTANTS from 'constant';
import Select from 'react-select';
import { manageDataProducts, NAME_DATA } from 'utils/checkDataLocalStorage';
import SelectBarcode from 'views/pages/components/SelectBarcode';

const RowTable = ({ item, setProducts, products, indexP }) => {
  return (
    <tr>
      <td>{item?.barcode}</td>
      <td>{item?.productId?.name}</td>
      <td>{item?.customerName}</td>
      <td>{item?.customerOrgId?.name}</td>
      <td>
        <Input
          type="number"
          className="max-height-input-custom"
          style={{ maxWidth: 100 }}
          value={item?.quota}
          onChange={(e) => {
            let tempProducts = _.cloneDeep(products);
            const index = tempProducts.findIndex(
              (val) => val.barcode === item.barcode
            );
            if (index !== -1) {
              tempProducts[index] = {
                ...item,
                quota:
                  e.target.value === ''
                    ? ''
                    : Number(e.target.value) < 0
                    ? 0
                    : Number(e.target.value),
              };
              setProducts([...tempProducts]);
            }
          }}
          placeholder="Nhập"
        />
      </td>
      <td>{item?.qcRequire}</td>
      <td>{item?.qcNotes}</td>
      <td className="d-flex justify-content-center">
        <button
          onClick={() => {
            const newProducts = products.filter((val) => val.key !== item.key);
            setProducts(newProducts);
            manageDataProducts.set({
              name: NAME_DATA.FORM_QC_UNQUALIFIED,
              data: newProducts,
            });
          }}
          className="btn-none"
          id={'delete' + indexP}
        >
          <BinSVG />
        </button>
        <UncontrolledTooltip
          delay={1}
          placement="top"
          target={'delete' + indexP}
        >
          Xóa sản phẩm tiếp nhận
        </UncontrolledTooltip>
      </td>
    </tr>
  );
};

const FormQCUnqualified = ({ location }) => {
  const { customerByBarcodeQuality, isGetCustomerByBarcodeQuality } =
    useSelector((state) => state.qualityReducer);
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const history = useHistory();
  const [barcode, setBarcode] = useState('');
  // QLTT_CHUCTV_001-QLTTAGCP-QLTTAG01-TPNU02
  const qcInfoSchema = yup.object().shape({
    qcNotes: yup.string().required('Vui lòng nhập ghi chú!'),
    qcRequire: yup.string().required('Vui lòng nhập yêu cầu!'),
  });
  const [qcInfo, setQcInfo] = useState({
    qcNotes: '',
    qcRequire: CONSTANTS.REQUIRES[0].value,
  });
  const [dataCustomerInfo, setDataCustomerInfo] = useState({});
  const [requireValue, setRequireValue] = useState(CONSTANTS.REQUIRES[0]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [products, setProducts] = useState([]);
  const notificationAlertRef = useRef(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataCustomerByBarcode, setDataCustomerByBarcode] = useState({});
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate: 'productId.productParameterIds',
  });

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: !isNaN(products?.length) ? products.length : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage >
            products.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
              .length
              ? !isNaN(products?.length)
                ? products.length
                : 0
              : page * rowsPerPage}{' '}
            trong số {!isNaN(products?.length) ? products.length : 0} bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleAddProduct = (values, actions) => {
    generateDocxBarcode({ ...dataCustomerInfo, ...values });
    const temp = { ...dataCustomerInfo };
    let tempProducts = _.cloneDeep(products);
    let checkValidProduct = false;
    products.every((item, index) => {
      if (item.barcode === temp.barcode) {
        if (typeof tempProducts[index].quota === 'number') {
          tempProducts[index] = {
            ...item,
            quota: tempProducts[index].quota + 1,
          };
        } else {
          tempProducts[index] = {
            ...item,
            quota: 1,
          };
        }
        checkValidProduct = true;
        return false;
      }
      return true;
    });
    if (!checkValidProduct) {
      // tempProducts.push({ ...temp, quota: 1 });
      tempProducts = [
        {
          ...temp,
          quota: 1,
          qcNotes: values.qcNotes,
          qcRequire: values.qcRequire,
        },
        ...tempProducts,
      ];
    }
    setProducts([...tempProducts]);
    // setProducts([...products, temp]);
    setCustomerInfo({});
    setDataCustomerInfo({});
    // setBarcode('');
    actions.resetForm();

    manageDataProducts.set({
      name: NAME_DATA.FORM_QC_UNQUALIFIED,
      dataCustomerByBarcode: tempProducts,
    });
  };

  const handleGetCustomerByBarcodeQuality = (barcode) => {
    if (barcode === '' || _.isEmpty(barcode)) {
      // notify(
      // 	notificationAlertRef,
      // 	"warning",
      // 	"Thông báo",
      // 	`Vui điền mã sản phẩm!`
      // );
      return;
    }
    dispatch(
      qualityActions.getCustomerByBarcodeQuality(
        queryString.stringify({
          barcode: barcode?.label || barcode,
          populate:
            'customerOrgId,productId,orderId, productionCommandId.producerCutId producerProductionId',
          transportStatus: CONSTANTS.TRANSPORT_STATUS.QC,
          productQuality: CONSTANTS.PRODUCT_QUALITY.INIT,
        }),
        {
          success: (data) => {
            setDataCustomerByBarcode(data);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lấy thông tin sản phẩm thất bại. Lỗi: ${mess}!`
            );
            setCustomerInfo({});
            setDataCustomerInfo({});
            setDataCustomerByBarcode({});
          },
        }
      )
    );
  };

  useEffect(() => {
    if (!_.isEmpty(customerByBarcodeQuality)) {
      const tmp = {
        ...customerByBarcodeQuality,
        key: `${new Date().getTime()}`,
      };
      setCustomerInfo(tmp);
      setDataCustomerInfo(tmp);
    }
  }, [customerByBarcodeQuality]);

  // useEffect(() => {
  // 	if (!!inputRef?.current) {
  // 		//inputRef.current.focus();
  // 	}
  // }, [inputRef]);

  const onSubmit = () => {
    const body = [];
    products.forEach((item) => {
      Array.apply(
        null,
        Array(typeof item.quota === 'number' ? item.quota : 0)
      ).forEach(() => {
        body.push({
          barcode: item.barcode,
          qcNotes: item.qcNotes,
          qcRequire: item.qcRequire,
        });
      });
    });
    if (body.length === 0) {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng nhập số lượng sản phẩm!`
      );
      return;
    }

    dispatch(
      qualityActions.qcProductUnachieved(body, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `QC sản phẩm không đạt thành công!`
          );
          setProducts([]);
          setCustomerInfo({});
          setDataCustomerInfo({});
          setRequireValue(CONSTANTS.REQUIRES[0]);
          // setBarcode('');
          setQcInfo({ qcNotes: '', qcRequire: CONSTANTS.REQUIRES[0].value });

          manageDataProducts.remove(NAME_DATA.FORM_QC_UNQUALIFIED);
          setTimeout(() => {
            history.push('/pendding-qc');
          }, 2000);
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `QC sản phẩm không đạt thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  useEffect(() => {
    try {
      setCustomerInfo({});
      setProducts(manageDataProducts.get(NAME_DATA?.FORM_QC_UNQUALIFIED || ''));
      setDataCustomerInfo({});
    } catch (error) {}
  }, [history]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center pb-4 pt-3">
              <Col md="6">
                <h4 className="text-info mb-0">
                  Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin tiếp
                  theo form
                </h4>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" md="6">
                <LoadingButtonCustom
                  onClick={() => {
                    history.push(location.state.route, {
                      ...location.state,
                    });
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Quay lại
                </LoadingButtonCustom>
                <LoadingButtonCustom
                  onClick={() => {
                    if (!_.isEmpty(products)) {
                      onSubmit();
                    } else {
                      notify(
                        notificationAlertRef,
                        'warning',
                        'Thông báo',
                        `Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin!`
                      );
                    }
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Lưu và thoát
                </LoadingButtonCustom>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Formik
        initialValues={qcInfo}
        enableReinitialize
        onSubmit={handleAddProduct}
        validationSchema={qcInfoSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          resetForm,
          handleBlur,
        }) => {
          return (
            <>
              <Container className="mt--6" fluid>
                <Card className="px-4 py-3">
                  <Row className="justify-content-center">
                    <Col md="12">
                      <h3>SẢN PHẨM</h3>
                      <DropdownItem divider />

                      <Row>
                        <Col
                          style={{ borderRight: '1px solid #e9ecef' }}
                          md="6"
                        >
                          <Row className="mt-4">
                            <Col className="mb-3" md="4">
                              <label className="form-control-label">
                                Mã sản phẩm
                                <RequireCustom />
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              <SelectBarcode
                                transportStatus={CONSTANTS.TRANSPORT_STATUS.QC}
                                productQuality={CONSTANTS.PRODUCT_QUALITY.INIT}
                                handleGetCustomerByBarcode={
                                  handleGetCustomerByBarcodeQuality
                                }
                              />
                            </Col>
                            <Col md="12">
                              <h4>Sản phẩm vừa được quét</h4>
                            </Col>
                            <Col className="mb-2" md="4">
                              <label className="form-control-label">
                                Mã SP KH
                              </label>
                            </Col>
                            <Col className="mb-2" md="8">
                              : {customerInfo?.barcode}
                            </Col>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label">
                                Tên sản phẩm
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              : {customerInfo?.productId?.name}
                            </Col>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label">
                                Mã khách hàng
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              : {customerInfo?.customerCode}
                            </Col>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label">
                                Tên khách hàng
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              : {customerInfo?.customerName}
                            </Col>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label">
                                Tên đơn hàng
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              : {customerInfo?.orderId?.name}
                            </Col>
                            <Col className="mb-3" md="4">
                              <label
                                className="form-control-label"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Đơn vị phòng ban
                              </label>
                            </Col>
                            <Col className="mb-3" md="8">
                              : {customerInfo?.customerOrgId?.name}
                            </Col>
                          </Row>
                        </Col>
                        <Col md="6" className="pl-5 pr-5">
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                checked={true}
                                onChange={() => {}}
                              />
                              &ensp; Sản phẩm không đạt
                            </label>
                          </div>
                          <Row className="mt-2">
                            <Col className="mb-3" md="2">
                              <label className="form-control-label">
                                Yêu cầu
                              </label>
                            </Col>
                            <Col md="10">
                              <Select
                                className="mb-2 mt--1 text-sm"
                                placeholder="Chọn"
                                value={requireValue}
                                onChange={(e) => {
                                  setRequireValue(e);
                                  setFieldValue(
                                    'qcRequire',
                                    !!e ? e.value : ''
                                  );
                                }}
                                options={CONSTANTS.REQUIRES}
                                style={{ maxWidth: 300 }}
                              />
                            </Col>
                            <Col className="mb-3" md="2">
                              <label className="form-control-label">
                                Người QC
                              </label>
                            </Col>
                            <Col className="mb-3" md="10">
                              : {currentAccount?.name}
                            </Col>
                            <Col className="mb-3" md="12">
                              <InputCustom
                                label="Ghi chú"
                                placeholder="Nhập"
                                type="textarea"
                                id="qcNotes"
                                rows="5"
                                onBlur={handleBlur}
                                invalid={errors.qcNotes && touched.qcNotes}
                                onChange={(e) => {
                                  setFieldValue('qcNotes', e.target.value);
                                }}
                                messageInvalid={errors.qcNotes}
                                value={values.qcNotes}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-center mt-3">
                        <LoadingButtonCustom
                          onClick={() => {
                            if (!_.isEmpty(customerInfo)) {
                              handleSubmit();

                              //inputRef.current.focus();
                            } else {
                              notify(
                                notificationAlertRef,
                                'warning',
                                'Thông báo',
                                `Vui điền mã sản phẩm!`
                              );
                              //inputRef.current.focus();
                            }
                          }}
                          color="primary"
                          loading={false}
                        >
                          Tiếp nhận
                        </LoadingButtonCustom>
                        <LoadingButtonCustom
                          onClick={() => {
                            if (!_.isEmpty(customerInfo)) {
                              handleSubmit();
                            } else {
                              notify(
                                notificationAlertRef,
                                'warning',
                                'Thông báo',
                                `Vui điền mã sản phẩm!`
                              );
                              //inputRef.current.focus();
                            }
                          }}
                          color="primary"
                          loading={false}
                        >
                          Tiếp nhận & In phiếu sản phẩm
                        </LoadingButtonCustom>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <Row>
                  <Col md="12">
                    <Card className="pb-3">
                      <CardBody className="py-0">
                        <Row>
                          <Col className="py-3" md={12}>
                            <h3>
                              Danh sách sản phẩm QC không đạt(
                              {products.reduce(
                                (preValue, currentValue) =>
                                  preValue + currentValue.quota,
                                0
                              )}
                              )
                              <RequireCustom />
                            </h3>
                            <Table>
                              <thead>
                                <th
                                  className="h3 font-weight-600 p-2"
                                  style={{ paddingRight: 16, minWidth: 200 }}
                                >
                                  Mã SP KH
                                </th>
                                <th
                                  style={{ minWidth: 80 }}
                                  className="h3 font-weight-600 p-2 "
                                >
                                  Tên sản phẩm
                                </th>
                                <th className="h3 font-weight-600 p-2 ">
                                  Tên khách hàng
                                </th>
                                <th className="h3 font-weight-600 p-2 ">
                                  Đơn vị/phòng ban
                                </th>
                                <th className="h3 font-weight-600 p-2 ">
                                  Số lượng
                                </th>
                                <th className="h3 font-weight-600 p-2 ">
                                  Yêu cầu
                                </th>
                                <th className="h3 font-weight-600 p-2 ">
                                  Ghi chú
                                </th>
                                <th className="h3 font-weight-600 p-2 text-center">
                                  Hành động
                                </th>
                              </thead>
                              <tbody>
                                {products.map((item, index) => {
                                  return (
                                    <RowTable
                                      key={index}
                                      item={item}
                                      setProducts={setProducts}
                                      products={products}
                                      indexP={index}
                                    />
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default FormQCUnqualified;
