import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  DropdownItem,
  CardBody,
  Table,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { notify } from 'common';
import InputCustom from 'views/pages/components/InputCustom';
import RequireCustom from 'views/pages/components/RequireCustom';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { Formik } from 'formik';
import * as yup from 'yup';
import ReactNotificationAlert from 'react-notification-alert';
import Select from 'react-select';
import { deliveryActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { BinSVG } from 'assets/svg';
import DatePicker from 'reactstrap-date-picker';
import { manageDataProducts } from 'utils/checkDataLocalStorage';
import { NAME_DATA } from 'utils/checkDataLocalStorage';
import SelectBarcode from 'views/pages/components/SelectBarcode';

const RowTable = ({ item, setProducts, products, indexP }) => {
  return (
    <tr>
      <td>{item?.barcode}</td>
      <td>{item?.productTypeId?.name ?? ''}</td>
      <td>{item?.customerName}</td>
      <td>{item?.customerOrgId?.parentId?.name ?? ''}</td>
      <td>{item?.customerOrgId?.name}</td>
      <td>
        <Input
          type="number"
          className="max-height-input-custom"
          style={{ maxWidth: 100 }}
          value={item?.quota}
          onChange={(e) => {
            let tempProducts = _.cloneDeep(products);
            const index = tempProducts.findIndex(
              (val) => val.barcode === item.barcode
            );
            if (index !== -1) {
              const quota =
                e.target.value.trim() === ''
                  ? ''
                  : Number(e.target.value) < 0
                  ? 0
                  : Number(e.target.value) >= item.maxQuotaRecieved
                  ? item.maxQuotaRecieved
                  : Number(e.target.value);
              tempProducts = [
                ...tempProducts.slice(0, index),
                {
                  ...tempProducts[index],
                  quota,
                },
                ...tempProducts.slice(index + 1),
              ];
              setProducts(tempProducts);
            }
          }}
          placeholder="Nhập"
        />
      </td>
      <td className="d-flex justify-content-center">
        <button
          onClick={() => {
            const newProducts = products.filter((val) => val.key !== item.key);
            setProducts(newProducts);
            manageDataProducts.set({
              name: NAME_DATA.FORM_RECEIVE_GC,
              data: newProducts,
            });
          }}
          className="btn-none"
          id={'delete' + indexP}
        >
          <BinSVG />
        </button>
        <UncontrolledTooltip
          delay={1}
          placement="top"
          target={'delete' + indexP}
        >
          Xóa sản phẩm tiếp nhận
        </UncontrolledTooltip>
      </td>
    </tr>
  );
};

const FormReceiveGC = ({ location }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const history = useHistory();
  const sendInfoSchema = yup.object().shape({
    sendPerson: yup.string().required('Vui lòng điền thông tin người giao!'),
    no: yup.string().required('Vui lòng điền số phiếu'),
  });
  const [filterValues, setFilterValues] = useState({
    productName: '',
    customerOrgName: '',
  });
  const [sendInfo, setSendInfo] = useState({
    sendPerson: '',
    no: '',
    sendDate: new Date().toISOString(),
  });
  const [barcode, setBarcode] = useState('');
  const [commandValue, setCommandValue] = useState({
    label: 'Lưu kho tạm',
    value: CONSTANTS.TRANSPORT_STATUS.INIT,
  });
  const [commands, setCommands] = useState([
    {
      label: 'Lưu kho tạm',
      value: CONSTANTS.TRANSPORT_STATUS.INIT,
    },
    {
      label: 'Kiểm tra chất lượng (QC)',
      value: CONSTANTS.TRANSPORT_STATUS.QC,
    },
    {
      label: 'Hoàn thiện công đoạn',
      value: CONSTANTS.TRANSPORT_STATUS.STAGE,
    },
  ]);
  const [products, setProducts] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const notificationAlertRef = useRef(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
  });

  const handleGetCustomerByBarcode = (barcode) => {
    if (barcode === '' || _.isEmpty(barcode)) {
      // notify(
      // 	notificationAlertRef,
      // 	"warning",
      // 	"Thông báo",
      // 	`Vui điền mã sản phẩm vàn nhấn ENTER!`
      // );
      return;
    }
    dispatch(
      deliveryActions.getCustomerByBarcode(
        queryString.stringify({
          barcode: barcode?.label || barcode,
          populate: 'customerOrgId.parentId,productTypeId',
        }),
        {
          success: (data) => {
            const temp = {
              ...data,
              maxQuotaRecieved: data.quota - data.quotaRecieved,
              key: `${new Date().getTime()}`,
            };

            setCustomerInfo({
              ...temp,
            });
            let tempProducts = _.cloneDeep(products);
            let checkValidProduct = false;
            products.every((item, index) => {
              if (item.barcode === temp.barcode) {
                const maxQuotaRecieved = data.quota - data.quotaRecieved;
                if (typeof tempProducts[index].quota === 'number') {
                  tempProducts[index] = {
                    ...item,
                    quota:
                      tempProducts[index].quota < maxQuotaRecieved
                        ? tempProducts[index].quota + 1
                        : tempProducts[index].quota,
                    maxQuotaRecieved,
                  };
                } else {
                  // TH số lượng đang để trống thì mặc định là 1
                  tempProducts[index] = {
                    ...item,
                    quota: 1,
                    maxQuotaRecieved: data.quota - data.quotaRecieved,
                  };
                }
                checkValidProduct = true;
                return false;
              }
              return true;
            });
            if (!checkValidProduct) {
              // tempProducts.push(temp);
              tempProducts = [{ ...temp, quota: 1 }, ...tempProducts];
            }
            console.log(tempProducts);
            setProducts([...tempProducts]);
            // setProducts([...products, temp]);
            setBarcode('');

            manageDataProducts.set({
              name: NAME_DATA.FORM_RECEIVE_GC,
              data: tempProducts,
            });
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lấy thông tin sản phẩm thất bại. Lỗi: ${mess}!`
            );
            setCustomerInfo({});
          },
        }
      )
    );
  };

  // useEffect(() => {
  // 	if (!!inputRef?.current) {
  // 		//inputRef.current.focus();
  // 	}
  // }, [inputRef]);

  const onSubmit = (values, actions) => {
    const barcodes = [];
    products.forEach((item) => {
      Array.apply(
        null,
        Array(typeof item.quota === 'number' ? item.quota : 0)
      ).forEach(() => {
        barcodes.push(item.barcode);
      });
    });
    if (barcodes.length === 0) {
      notify(
        notificationAlertRef,
        'warning',
        'Thông báo',
        `Vui lòng nhập số lượng sản phẩm!`
      );
      return;
    }
    location.state.type === 0
      ? dispatch(
          deliveryActions.receiveFormGC(
            {
              barcodes,
              transportStatus: commandValue.value,
              sendPerson: values.sendPerson,
              sendDate: values.sendDate,
              no: values.no,
            },
            {
              success: () => {
                notify(
                  notificationAlertRef,
                  'success',
                  'Thông báo',
                  `Tiếp nhận sản phẩm thành công!`
                );
                setProducts([]);
                setCustomerInfo({});
                setBarcode('');
                setCommandValue({
                  label: 'Lưu kho tạm',
                  value: CONSTANTS.TRANSPORT_STATUS.INIT,
                });
                actions.resetForm();
                manageDataProducts.remove(NAME_DATA.FORM_RECEIVE_GC);
                setTimeout(() => {
                  if (commandValue.value === CONSTANTS.TRANSPORT_STATUS.INIT) {
                    history.push('/receive-gc');
                  } else if (
                    commandValue.value === CONSTANTS.TRANSPORT_STATUS.QC
                  ) {
                    history.push('/pendding-qc');
                  } else {
                    history.push('/complete-stage');
                  }
                }, 2000);
              },
              failed: (mess) => {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Tiếp nhận sản phẩm thất bại. Lỗi: ${mess}!`
                );
              },
            }
          )
        )
      : dispatch(
          deliveryActions.receiveFromProducer(
            {
              barcodes,
              transportStatus: commandValue.value,
              sendPerson: values.sendPerson,
              sendDate: values.sendDate,
            },
            {
              success: () => {
                notify(
                  notificationAlertRef,
                  'success',
                  'Thông báo',
                  `Tiếp nhận sản phẩm thành công!`
                );
                setProducts([]);
                setCustomerInfo({});
                setBarcode('');
                setCommandValue({
                  label: 'Lưu kho tạm',
                  value: CONSTANTS.TRANSPORT_STATUS.INIT,
                });
                actions.resetForm();
                setTimeout(() => {
                  if (commandValue.value === CONSTANTS.TRANSPORT_STATUS.INIT) {
                    history.push('/receive-gc');
                  } else if (
                    commandValue.value === CONSTANTS.TRANSPORT_STATUS.QC
                  ) {
                    history.push('/pendding-qc');
                  } else {
                    history.push('/complete-stage');
                  }
                }, 2000);
              },
              failed: (mess) => {
                notify(
                  notificationAlertRef,
                  'danger',
                  'Thông báo',
                  `Tiếp nhận sản phẩm thất bại. Lỗi: ${mess}!`
                );
              },
            }
          )
        );
  };

  useEffect(() => {
    setCustomerInfo({});
    setProducts(manageDataProducts.get(NAME_DATA.FORM_RECEIVE_GC));
  }, [history]);

  return (
    <Formik
      initialValues={sendInfo}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={sendInfoSchema}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        resetForm,
        handleBlur,
      }) => {
        return (
          <>
            <div className="rna-wrapper">
              <ReactNotificationAlert ref={notificationAlertRef} />
            </div>

            <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
              <Container fluid>
                <div className="header-body">
                  <Row className="align-items-center pb-4 pt-3">
                    <Col md="6">
                      <h4 className="text-info mb-0">
                        Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin
                        tiếp theo form
                      </h4>
                    </Col>
                    <Col className="mt-3 mt-md-0 text-md-right" md="6">
                      <LoadingButtonCustom
                        onClick={() => {
                          history.push(location.state.route, {
                            ...location.state,
                          });
                        }}
                        className="btn-neutral"
                        color="default"
                        size="md"
                      >
                        Quay lại
                      </LoadingButtonCustom>
                      <LoadingButtonCustom
                        onClick={() => {
                          if (!_.isEmpty(products)) {
                            handleSubmit();
                          } else {
                            notify(
                              notificationAlertRef,
                              'warning',
                              'Thông báo',
                              `Vui lòng sử dụng máy đọc mã vạch để tiếp nhận thông tin!`
                            );
                          }
                        }}
                        className="btn-neutral"
                        color="default"
                        size="md"
                      >
                        Lưu và thoát
                      </LoadingButtonCustom>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <Container className="mt--6" fluid>
              <Row className="justify-content-center">
                <Col md="7">
                  <Card style={{ minHeight: 351 }} className="px-4 py-3">
                    <h3>Thông tin sản phẩm</h3>
                    <DropdownItem divider />

                    <Row className="mt-4">
                      <Col md="8">
                        <Row>
                          <Col className="mb-3" md="4">
                            <label className="form-control-label">
                              Mã sản phẩm
                              <RequireCustom />
                            </label>
                          </Col>
                          <Col className="mb-3" md="8">
                            <SelectBarcode
                              handleGetCustomerByBarcode={
                                handleGetCustomerByBarcode
                              }
                            />
                          </Col>
                          <Col md="12">
                            <h4>Sản phẩm vừa được quét</h4>
                          </Col>
                          <Col className="mb-2" md="4">
                            <label className="form-control-label">
                              Mã SP KH
                            </label>
                          </Col>
                          <Col className="mb-2" md="8">
                            : {customerInfo?.barcode}
                          </Col>
                          <Col className="mb-2" md="4">
                            <label className="form-control-label">
                              Số lượng SP
                            </label>
                          </Col>
                          <Col className="mb-2" md="8">
                            : {customerInfo?.quota}
                          </Col>
                          <Col className="mb-2" md="4">
                            <label className="form-control-label">
                              SL đã tiếp nhận
                            </label>
                          </Col>
                          <Col className="mb-2" md="8">
                            : {customerInfo?.quotaRecieved}
                          </Col>
                          <Col className="mb-2" md="4">
                            <label className="form-control-label">
                              Tên khách hàng
                            </label>
                          </Col>
                          <Col className="mb-2" md="8">
                            : {customerInfo?.customerName}
                          </Col>
                          <Col className="mb-2" md="4">
                            <label
                              className="form-control-label"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Đơn vị phòng ban
                            </label>
                          </Col>
                          <Col className="mb-2" md="8">
                            : {customerInfo?.customerOrgId?.name}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md="5">
                  <Card style={{ minHeight: 351 }} className="px-4 py-3">
                    <h3 className="">Thông tin bên giao</h3>
                    <DropdownItem divider />
                    <Row className="mt-4">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label">
                          Tên người giao
                          <RequireCustom />
                        </label>
                      </Col>
                      <Col className="mb-3" md="8">
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập"
                          type="text"
                          id="sendPerson"
                          name="sendPerson"
                          onBlur={handleBlur}
                          invalid={errors.sendPerson && touched.sendPerson}
                          onChange={(e) => {
                            setFieldValue('sendPerson', e.target.value);
                          }}
                          messageInvalid={errors.sendPerson}
                          value={values.sendPerson}
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label">
                          Số phiếu
                          <RequireCustom />
                        </label>
                      </Col>
                      <Col className="mb-3" md="8">
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập"
                          type="text"
                          id="no"
                          name="no"
                          onBlur={handleBlur}
                          invalid={errors.no && touched.no}
                          onChange={(e) => {
                            setFieldValue('no', e.target.value);
                          }}
                          messageInvalid={errors.no}
                          value={values.no}
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label">Ngày giao</label>
                      </Col>
                      <Col className="mb-3" md="8">
                        <DatePicker
                          dateFormat="DD/MM/YYYY"
                          calendarPlacement="bottom"
                          value={values.sendDate}
                          onChange={(v, f) => {
                            setFieldValue('sendDate', v);
                          }}
                        />
                      </Col>
                    </Row>
                    <h3 className="">Thông tin điều phối thực hiện</h3>
                    <DropdownItem divider />
                    <Row>
                      <Col className="mb-3 mt-3" md="4">
                        <label className="form-control-label">
                          Lựa chọn lệnh
                          <RequireCustom />
                        </label>
                      </Col>
                      <Col className="mb-3 mt-3" md="8">
                        <Select
                          className="select-custom"
                          size="sm"
                          placeholder="Chọn lệnh"
                          isClearable={true}
                          value={commandValue}
                          onChange={(e) => {
                            setCommandValue(e);
                          }}
                          options={commands}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardBody className="py-0">
                      <Row>
                        <Col className="py-3" md={12}>
                          <h3>
                            Danh sách sản phẩm đã tiếp nhận, tổng số (
                            {products.reduce(
                              (preValue, currentValue) =>
                                preValue + currentValue.quota,
                              0
                            )}
                            )<RequireCustom />
                          </h3>
                          <Table>
                            <thead>
                              <th
                                className="h3 font-weight-600 p-2"
                                style={{ paddingRight: 16, minWidth: 200 }}
                              >
                                Mã SP KH
                              </th>
                              <th
                                style={{ minWidth: 80 }}
                                className="h3 font-weight-600 p-2 "
                              >
                                {/* Tên sản phẩm */}
                                <Input
                                  key="input"
                                  type="search"
                                  className="border-bottom-search"
                                  value={filterValues.productName}
                                  style={{
                                    border: 'none',
                                    borderBottom: `1px solid rgba(0, 0, 0, 0.2) !important`,
                                    boxShadow: `none !important`,
                                    maxHeight: 32,
                                    padding: 0,
                                    borderRadius: 'none',
                                  }}
                                  onChange={(e) => {
                                    setFilterValues({
                                      ...filterValues,
                                      productName: e.target.value,
                                    });
                                  }}
                                  placeholder="Tên sản phẩm"
                                />
                              </th>
                              <th className="h3 font-weight-600 p-2 ">
                                Tên khách hàng
                              </th>
                              <th className="h3 font-weight-600 p-2 ">
                                <Input
                                  key="input"
                                  type="search"
                                  className="border-bottom-search"
                                  value={filterValues.customerOrgName}
                                  style={{
                                    border: 'none',
                                    borderBottom: `1px solid rgba(0, 0, 0, 0.2) !important`,
                                    boxShadow: `none !important`,
                                    maxHeight: 32,
                                    padding: 0,
                                    borderRadius: 'none',
                                  }}
                                  onChange={(e) => {
                                    setFilterValues({
                                      ...filterValues,
                                      customerOrgName: e.target.value,
                                    });
                                  }}
                                  placeholder="Chi nhánh"
                                />
                              </th>
                              <th className="h3 font-weight-600 p-2 ">
                                Đơn vị/phòng ban
                              </th>
                              <th className="h3 font-weight-600 p-2 ">
                                Số lượng
                              </th>
                              <th className="h3 font-weight-600 p-2 text-center">
                                Hành động
                              </th>
                            </thead>
                            <tbody>
                              {products
                                .filter((item) => {
                                  if (filterValues.productName === '') {
                                    return (
                                      item?.customerOrgId?.parentId?.name ?? ''
                                    ).includes(filterValues?.customerOrgName);
                                  } else if (
                                    filterValues.customerOrgName === ''
                                  ) {
                                    return (
                                      item?.productTypeId?.name ?? ''
                                    ).includes(filterValues?.productName);
                                  } else if (
                                    filterValues?.customerOrgName !== '' &&
                                    filterValues?.productName !== ''
                                  ) {
                                    return (
                                      (
                                        item?.productTypeId?.name ?? ''
                                      ).includes(filterValues?.productName) ||
                                      (
                                        item?.customerOrgId?.parentId?.name ??
                                        ''
                                      ).includes(filterValues?.customerOrgName)
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .map((item, index) => {
                                  return (
                                    <RowTable
                                      key={index}
                                      item={item}
                                      setProducts={setProducts}
                                      products={products}
                                      indexP={index}
                                    />
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        );
      }}
    </Formik>
  );
};

export default FormReceiveGC;
