import TypeActions from "../TypeActions";

export const getMeasureSizes = (query, callback) => {
	return {
		type: TypeActions.GET_MEASURE_SIZES_REQUEST,
		query,
		callback,
	};
};
export const getCommentsMeasureSizes = (query, callback) => {
	return {
		type: TypeActions.GET_COMMENTS_MEASURE_REQUEST,
		query,
		callback,
	};
};
export const getMeasureSizeById = (params, query, callback) => {
	return {
		type: TypeActions.GET_MEASURE_SIZE_BY_ID_REQUEST,
		params,
		query,
		callback,
	};
};

export const createMeasureSize = (body, callback) => {
	return {
		type: TypeActions.CREATE_MEASURE_SIZE_REQUEST,
		body,
		callback,
	};
};
export const createCommentMeasureSize = (body, callback) => {
	return {
		type: TypeActions.CREATE_COMMENT_MEASURE_REQUEST,
		body,
		callback,
	};
};
export const updateMeasureSize = (body, params, callback) => {
	return {
		type: TypeActions.UPDATE_MEASURE_SIZE_REQUEST,
		body,
		params,
		callback,
	};
};
export const deleteMeasureSize = (params, callback) => {
	return {
		type: TypeActions.DELETE_MEASURE_SIZE_REQUEST,
		params,
		callback,
	};
};
export const uploadMeasureSize = (file, params, callback) => {
	return {
		type: TypeActions.UPLOAD_MEASURE_SIZE_REQUEST,
		file,
		params,
		callback,
	};
};

export const extractMeasureSize = (params, query, callback) => {
	return {
		type: TypeActions.EXPORT_MEASURE_SIZE_REQUEST,
		params,
		query,
		callback,
	};
};
export const getTemplateMeasureSize = (params, callback) => {
	return {
		type: TypeActions.GET_TEMPLATE_MEASURE_SIZE_REQUEST,
		params,
		callback,
	};
};
export default {
	getMeasureSizes,
	createMeasureSize,
	updateMeasureSize,
	deleteMeasureSize,
	getMeasureSizeById,
	uploadMeasureSize,
	extractMeasureSize,
	getTemplateMeasureSize,
	getCommentsMeasureSizes,
	createCommentMeasureSize,
};
