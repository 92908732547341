import { UserProfileSVG } from 'assets/svg';
import { PaperSVG } from 'assets/svg';
import React, { useEffect, useRef, useState } from 'react';
import { Card, CardHeader, Container, Row, CardBody, Button } from 'reactstrap';
import ListResultMeasure from './components/ListResultMeasure';
// import IconComponent from "utils/createSVG";

import Header from './components/Header';
import { Style } from '../style';
import Filter from './components/Filter';
import ListConverted from './components/ListConverted';
import DialogFormMeasure from './components/DialogFormMeasure';
import DialogExtractMeasure from './components/DialogExtractMeasure';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import _ from 'lodash';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import constant from 'constant';
import { checkRole } from 'common';
// import {orderActions} from ''
const UpdateMeasurePage = () => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const notificationAlertRef = useRef();
  const [sectionState, setSectionState] = useState(0);
  const [currentOrders, setCurrentOrders] = useState('');
  const [valueFilter, setValueFilter] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openDialogFormMeasure, setOpenDialogFormMeasure] = useState(false);
  const [openExtractModal, setOpenExtractModal] = useState(false);
  const { ordersConvert } = useSelector((state) => state.orderReducer);

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };
  const toggleDialogFormMeasure = () => {
    setOpenDialogFormMeasure(!openDialogFormMeasure);
  };
  const toggleDialogExtractModal = () => {
    setOpenExtractModal(!openExtractModal);
  };
  const acceptOrder = () => {
    if (!currentOrders) {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Bạn chưa chọn đơn hàng nào!`
      );
      return;
    }
    dispatch(
      orderActions.approveAllCustomerInOrder({}, currentOrders, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Gửi duyệt số đo thành công`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Gửi duyệt số đo thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  useEffect(() => {
    setValueFilter({});
  }, [sectionState]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Header
        name=""
        toggle={toggleFilter}
        toggleFormMeasure={toggleDialogFormMeasure}
        toggleDialogExtractModal={toggleDialogExtractModal}
        setCurrentOrders={setCurrentOrders}
        isListResult={sectionState === 0}
        options={valueFilter}
      />
      <Container fluid className="mt--6">
        <Card className="p-0">
          <CardHeader className="py-0">
            <Row className="mx-0">
              <Row
                className={`align-items-center py-3 mx-0 ${
                  sectionState === 0 ? 'borderBottomActive' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSectionState(0);
                }}
              >
                <PaperSVG />
                <p
                  className={`h5 mb-0 ml-3 text-uppercase ${
                    sectionState !== 0 && 'text-muted'
                  }`}
                >
                  Danh sách kết quả đo
                </p>
              </Row>
              <Row
                className={`align-items-center py-3 ml-5 mx-0 ${
                  sectionState === 1 ? 'borderBottomActive' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSectionState(1);
                }}
              >
                <UserProfileSVG />
                <p
                  className={`h5 mb-0 ml-3 text-uppercase ${
                    sectionState !== 1 && 'text-muted'
                  }`}
                >
                  Danh sách chuyển đổi
                </p>
              </Row>
              {sectionState === 1 &&
                checkRole(currentAccount, {
                  roles: ['approve_customerInOrder'],
                }) && (
                  <div
                    className="d-flex  py-2"
                    style={{ justifyContent: 'flex-end', flex: '1' }}
                  >
                    <Button
                      onClick={acceptOrder}
                      color="danger"
                      disabled={
                        ordersConvert.results.filter(
                          (x) =>
                            x.customerInOrderStatus ===
                            constant.CUSTOMER_IN_ORDER_STATUS.PendingToSennd
                        ).length === 0
                      }
                    >
                      Gửi duyệt
                    </Button>
                  </div>
                )}
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            {sectionState === 0 ? (
              <ListResultMeasure
                currentOrders={currentOrders}
                filter={valueFilter}
                setOpenFilter={setOpenFilter}
              />
            ) : (
              <ListConverted
                currentOrders={currentOrders}
                filter={valueFilter}
                setOpenFilter={setOpenFilter}
              />
            )}
          </CardBody>
        </Card>
      </Container>
      {openFilter && (
        <Filter
          open={openFilter}
          toggle={toggleFilter}
          valueFilter={valueFilter}
          setValueFilter={setValueFilter}
        />
      )}

      <DialogFormMeasure
        open={openDialogFormMeasure}
        toggle={toggleDialogFormMeasure}
        currentOrders={currentOrders}
      />
      <DialogExtractMeasure
        open={openExtractModal}
        toggle={toggleDialogExtractModal}
      />
    </Style>
  );
};

export default UpdateMeasurePage;
