import { FileClipboardBlockSVG } from "assets/svg";
import { FileClipboardCheckSVG } from "assets/svg";
import { FileClipboardTextSVG } from "assets/svg";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, Container } from "reactstrap";
import { Style } from "../style";
import Filter from "./components/Filter";
import Header from "./components/Header";
import ListOwn from "./components/ListOwn";
import ListResolved from "./components/ListResolved";

const AggregateMeasure = () => {
  const [sectionState, setSectionState] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [valueFilter, setValueFilter] = useState({});
  const [currentOrder, setCurrentOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [allowClick, setAllowClick] = useState(false);
  const { unusualList } = useSelector((state) => state.orderReducer);

  const changeSectionState = (number) => {
    setSectionState(number);
    setValueFilter({});
  };
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    if (unusualList.results.length > 0) {
      setAllowClick(true);
    } else {
      setAllowClick(false);
    }
  }, [unusualList]);

  return (
    <Style>
      <Header
        name=""
        toggle={toggleOpenFilter}
        setCurrentOrders={setCurrentOrder}
        options={valueFilter}
        sectionState={sectionState}
        currentOrder={currentOrder}
        allowClick={allowClick}
      />
      <Container fluid className="mt--6">
        <Card>
          <CardHeader className="py-0">
            <div className="d-flex">
              <div
                onClick={() => {
                  changeSectionState(0);
                }}
                className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                  sectionState === 0 ? "nav-active" : ""
                }`}
              >
                <FileClipboardCheckSVG />
                <p
                  className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                    sectionState !== 0 ? "text-muted" : ""
                  }`}
                >
                  Danh sách đã quy size{" "}
                  {sectionState === 0 && `(${unusualList.totalResults || 0})`}
                </p>
              </div>
              <div
                onClick={() => {
                  changeSectionState(1);
                }}
                className={`d-flex sidenav-toggler py-3 align-items-center mr-4 ${
                  sectionState === 1 ? "nav-active" : ""
                }`}
              >
                <FileClipboardTextSVG />
                <p
                  className={`h5 text-uppercase font-weight-500 mb-0 ml-2 ${
                    sectionState !== 1 ? "text-muted" : ""
                  }`}
                >
                  Danh sách may riêng{" "}
                  {sectionState === 1 && `(${unusualList.totalResults || 0})`}
                </p>
              </div>
            </div>
          </CardHeader>
          {sectionState === 0 ? (
            <ListResolved
              currentOrder={currentOrder}
              loading={loading}
              setLoading={setLoading}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
            />
          ) : (
            <ListOwn
              currentOrder={currentOrder}
              loading={loading}
              setLoading={setLoading}
              filter={valueFilter}
              setOpenFilter={setOpenFilter}
            />
          )}
        </Card>
      </Container>
      <Filter
        open={openFilter}
        toggle={toggleOpenFilter}
        valueFilter={valueFilter}
        setValueFilter={setValueFilter}
      />
    </Style>
  );
};

export default AggregateMeasure;
