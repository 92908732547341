import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import queryString from 'query-string';
import { CardHeader, Row, Col, Badge, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { orderPlanActions, orderActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import moment from 'moment';
import { ViewSVG, DownloadSVG } from 'assets/svg';
import DetailOrderPlan from '../../PlanPendingCreate/DetailOrderPlan';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import { checkRole } from 'common';
import { useHistory } from 'react-router-dom';

function TableData({
  queryFilter,
  setFilterRequest,
  setOpenFilter,
  location,
  handleExport,
}) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const notificationAlertRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { isGetOrderPlans } = useSelector((state) => state.orderPlanReducer);
  const [firstCalled, setFirstCalled] = useState(false);
  const [orderPlansData, setOrderPlansData] = useState({ results: [] });
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );

  // const [dataOrderForm, setDataOrderForm] = useState({});
  // const [openDetail, setOpenDetail] = useState(false);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    sortBy: 'createdAt:desc',
    populate:
      'orderId.customerOrganizationId.provinceId,producers.producerId,productId',
    orderPlanStatus: [
      CONSTANTS.ORDER_PLAN_STATUS.Init,
      CONSTANTS.ORDER_PLAN_STATUS.PendingAcept,
      CONSTANTS.ORDER_PLAN_STATUS.Accept,
      CONSTANTS.ORDER_PLAN_STATUS.Reject,
    ],
  });
  const boxAction = (cell, row, id) => {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccount, { roles: ['get_orderPlan'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                history.push(`detail-aggrement-plan/${row.id}`, {
                  page,
                  rowsPerPage,
                  route: location.pathname,
                });
              }}
              id={'view' + id}
            >
              <ViewSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
              Xem chi tiết kế hoạch
            </UncontrolledTooltip>
            <button
              className="btn-none"
              onClick={() => {
                handleExport(row.id);
              }}
              id={'download' + id}
            >
              <DownloadSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'download' + id}
            >
              Xuất chi tiết
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  };
  const columns = [
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: {
        background: '#fff',
        zIndex: 100,
      },
    },
    {
      dataField: 'orderPlanStatus',
      text: 'Trạng thái',
      formatter: (cell) => {
        if (cell === CONSTANTS.ORDER_PLAN_STATUS.Init)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-warning" />
              <span className="status">Khởi tạo</span>
            </Badge>
          );
        else if (cell === CONSTANTS.ORDER_PLAN_STATUS.PendingAcept)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-info" />
              <span className="status">Chờ duyệt</span>
            </Badge>
          );
        else if (cell === CONSTANTS.ORDER_PLAN_STATUS.Accept)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-success" />
              <span className="status">Đã duyệt</span>
            </Badge>
          );
        else if (cell === CONSTANTS.ORDER_PLAN_STATUS.Reject)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-danger" />
              <span className="status">Đã hủy</span>
            </Badge>
          );
        else return <>Chưa rõ</>;
      },
    },
    {
      dataField: 'orderId.name',
      text: 'Tên đơn hàng',
      headerStyle: {
        textAlign: 'left',
        minWidth: 150,
      },
      style: {
        textAlign: 'left',
      },
    },

    {
      dataField: 'name',
      text: 'Tên kế hoạch',
    },
    {
      dataField: 'orderId.customerOrganizationId.provinceId.provinceName',
      text: 'Tỉnh/TP',
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productId.code',
      text: 'Mã SP',
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',
    },
    {
      dataField: 'productId.gender',
      text: 'Giới tính',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'producers',
      text: 'Số lượng',
      style: {
        textAlign: 'center',
      },
      formatter: (cell) => {
        let sum = 0;
        cell.forEach((item, index) => {
          sum += item.amount;
        });
        return sum;
      },
    },
    {
      dataField: 'producers',
      text: 'Nhà gia công',
      formatter: (cell) => {
        return cell.map((item, index) =>
          cell.length - 1 === index
            ? `${!!item.producerId?.name ? item.producerId.name : ''}.`
            : `${!!item.producerId?.name ? item.producerId.name : ''}, `
        );
      },
      headerStyle: {
        minWidth: 200,
      },
    },
    {
      dataField: 'createdAt',
      text: 'Ngày khởi tạo KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'acceptedDate',
      text: 'Ngày duyệt KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'productSyncDate',
      text: 'Ngày đồng bộ NPL',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'embroiderDate',
      text: 'Ngày thêu',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'produceCutDate',
      text: 'Ngày cắt SX',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'qcDate',
      text: 'Ngày giao GC',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'qcCustomerDate',
      text: 'Ngày QC KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'warehouseReceipDate',
      text: 'Ngày NK KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'productionDays',
      text: 'Số ngày SX',

      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'qcDays',
      text: 'Số ngày QC',

      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ];
  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: orderPlansData?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col>
          <p>
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > orderPlansData.totalResults
              ? !isNaN(orderPlansData?.totalResults)
                ? orderPlansData.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(orderPlansData?.totalResults)
              ? orderPlansData.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });
  const handleGetOrderPlans = () => {
    dispatch(
      orderPlanActions.getOrderPlans(
        queryString.stringify(query, { arrayFormat: 'bracket' }),
        {
          success: (data) => {
            setOrderPlansData(data);
            setFirstCalled(true);
          },
        }
      )
    );
  };

  const handleFilter = () => {
    let payload = _.cloneDeep(queryFilter);
    payload.orderIds = payload?.orderIds?.map((item) => item.value);
    if (!_.isEmpty(payload)) {
      Object.keys(payload).map((key, index) => {
        if (_.isEmpty(payload[key]) && typeof payload[key] !== 'number') {
          delete payload[key];
        }
      });
      dispatch(
        orderPlanActions.getOrderPlans(
          queryString.stringify(
            { ...query, ...payload, page: 1 },
            { arrayFormat: 'bracket' }
          ),
          {
            success: (data) => {
              setPage(1);
              setOrderPlansData(data);
              setFilterRequest(queryFilter);
              setOpenFilter(false);
            },
            failed: (err) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Tìm kiếm thất bại!! Lỗi :  ` + err.message
              );
            },
          }
        )
      );
    } else {
      handleGetOrderPlans();
    }
  };

  useEffect(() => {
    handleGetOrderPlans();
  }, [query]);

  useEffect(() => {
    firstCalled && handleFilter();
  }, [queryFilter]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <ToolkitProvider
        data={orderPlansData.results}
        keyField="id"
        columns={columns}
        search
      >
        {(props) => (
          <>
            <CardHeader>
              <Row>
                <Col md="6">
                  <h3 className="mb-0">Bảng tổng hợp kế hoạch đơn hàng</h3>
                </Col>
                <Col md="6">
                  <div className="mb-0 d-flex justify-content-end">
                    <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
                      Hiển thị{' '}
                    </p>
                    {
                      <select
                        value={rowsPerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm mx-2"
                        style={{ maxWidth: 60 }}
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    }{' '}
                    <p className="mb-0">dòng</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <BootstrapTable
              {...props.baseProps}
              noDataIndication={() => {
                return (
                  <span className="font-weight-bold text-danger">
                    Không có dữ liệu!
                  </span>
                );
              }}
              onTableChange={() => {
                return <div className="spinner-border text-info" />;
              }}
              hover
              remote
              filter={filterFactory()}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
            />
          </>
        )}
      </ToolkitProvider>
      {/* {openDetail && (
        <DetailOrderPlan
          open={openDetail}
          setOpenDetail={setOpenDetail}
          dataForm={dataOrderForm}
        />
      )} */}
    </>
  );
}

export default TableData;
