import TypeActions from "../TypeActions";

export const getDeliveries = (params, callback) => {
  return {
    type: TypeActions.GET_DELIVERIES_REQUEST,
    params,
    callback,
  };
};
export const getProductErrorFromCustomerDeliveries = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_REQUEST,
    query,
    callback,
  };
};

export const getProductsFaultPaidCustomer = (params, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_REQUEST,
    params,
    callback,
  };
};

export const getDeliveryById = (params, query, callback) => {
  return {
    type: TypeActions.GET_DELIVERY_BY_ID_REQUEST,
    params,
    query,
    callback,
  };
};

export const createDelivery = (body, callback) => {
  return {
    type: TypeActions.CREATE_DELIVERY_REQUEST,
    body,
    callback,
  };
};
export const updateDelivery = (body, params, callback) => {
  return {
    type: TypeActions.UPDATE_DELIVERY_REQUEST,
    body,
    params,
    callback,
  };
};
export const deleteDelivery = (params, callback) => {
  return {
    type: TypeActions.DELETE_DELIVERY_REQUEST,
    params,
    callback,
  };
};

export const getCustomerByBarcode = (query, callback) => {
  return {
    type: TypeActions.GET_CUSTOMER_BY_BARCODE_REQUEST,
    query,
    callback,
  };
};

export const coordinatorDelivery = (body, callback) => {
  return {
    type: TypeActions.COORDINATOR_DELIVERY_REQUEST,
    body,
    callback,
  };
};

export const receiveFormGC = (body, callback) => {
  return {
    type: TypeActions.RECEIVE_FORM_GC_REQUEST,
    body,
    callback,
  };
};

export const receiveFormCustomer = (body, callback) => {
  return {
    type: TypeActions.RECEIVE_FROM_CUSTOMER_REQUEST,
    body,
    callback,
  };
};

export const receiveFromProducer = (body, callback) => {
  return {
    type: TypeActions.RECEIVE_FROM_CUSTOMER_REQUEST,
    body,
    callback,
  };
};
export const getProductFaultPaidCustomerByBarcode = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_REQUEST,
    query,
    callback,
  };
};
export default {
  getDeliveries,
  createDelivery,
  updateDelivery,
  deleteDelivery,
  getDeliveryById,
  getCustomerByBarcode,
  coordinatorDelivery,
  receiveFormGC,
  receiveFromProducer,
  getProductErrorFromCustomerDeliveries,
  receiveFormCustomer,
  getProductFaultPaidCustomerByBarcode,
};
