const constant = {
  ACCOUNT_LOGIN_REQUEST: 'ACCOUNT_LOGIN_REQUEST',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
  ACCOUNT_LOGIN_FAILED: 'ACCOUNT_LOGIN_FAILED',

  ACCOUNT_LOGOUT_REQUEST: 'ACCOUNT_LOGOUT_REQUEST',
  ACCOUNT_LOGOUT_SUCCESS: 'ACCOUNT_LOGOUT_SUCCESS',
  ACCOUNT_LOGOUT_FAILED: 'ACCOUNT_LOGOUT_FAILED',

  ACCOUNT_FORGOT_PASSWORD_REQUEST: 'ACCOUNT_FORGOT_PASSWORD_REQUEST',
  ACCOUNT_FORGOT_PASSWORD_SUCCESS: 'ACCOUNT_FORGOT_PASSWORD_SUCCESS',
  ACCOUNT_FORGOT_PASSWORD_FAILED: 'ACCOUNT_FORGOT_PASSWORD_FAILED',

  ACCOUNT_RESET_PASSWORD_REQUEST: 'ACCOUNT_RESET_PASSWORD_REQUEST',
  ACCOUNT_RESET_PASSWORD_SUCCESS: 'ACCOUNT_RESET_PASSWORD_SUCCESS',
  ACCOUNT_RESET_PASSWORD_FAILED: 'ACCOUNT_RESET_PASSWORD_FAILED',

  GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILED: 'GET_ACCOUNTS_FAILED',

  GET_CURRENT_ACCOUNT_REQUEST: 'GET_CURRENT_ACCOUNT_REQUEST',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILED: 'GET_CURRENT_ACCOUNT_FAILED',

  GET_ACCOUNT_BY_ID_REQUEST: 'GET_ACCOUNT_BY_ID_REQUEST',
  GET_ACCOUNT_BY_ID_SUCCESS: 'GET_ACCOUNT_BY_ID_SUCCESS',
  GET_ACCOUNT_BY_ID_FAILED: 'GET_ACCOUNT_BY_ID_FAILED',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILED: 'CREATE_ACCOUNT_FAILED',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILED: 'UPDATE_ACCOUNT_FAILED',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: 'DELETE_ACCOUNT_FAILED',

  CONFIG_PASSWORD_ACCOUNT_REQUEST: 'CONFIG_PASSWORD_ACCOUNT_REQUEST',
  CONFIG_PASSWORD_ACCOUNT_SUCCESS: 'CONFIG_PASSWORD_ACCOUNT_SUCCESS',
  CONFIG_PASSWORD_ACCOUNT_FAILED: 'CONFIG_PASSWORD_ACCOUNT_FAILED',

  ACCOUNT_CHANGE_PASSWORD_REQUEST: 'ACCOUNT_CHANGE_PASSWORD_REQUEST',
  ACCOUNT_CHANGE_PASSWORD_SUCCESS: 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  ACCOUNT_CHANGE_PASSWORD_FAILED: 'ACCOUNT_CHANGE_PASSWORD_FAILED',

  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILED: 'GET_ROLES_FAILED',

  GET_ROLE_BY_ID_REQUEST: 'GET_ROLE_BY_ID_REQUEST',
  GET_ROLE_BY_ID_SUCCESS: 'GET_ROLE_BY_ID_SUCCESS',
  GET_ROLE_BY_ID_FAILED: 'GET_ROLE_BY_ID_FAILED',

  GET_ROLE_PERMISSION_REQUEST: 'GET_ROLE_PERMISSION_REQUEST',
  GET_ROLE_PERMISSION_SUCCESS: 'GET_ROLE_PERMISSION_SUCCESS',
  GET_ROLE_PERMISSION_FAILED: 'GET_ROLE_PERMISSION_FAILED',

  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',

  UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',

  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',

  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED: 'GET_CUSTOMERS_FAILED',

  GET_ALL_CHILD_OF_CUSTOMER_REQUEST: 'GET_ALL_CHILD_OF_CUSTOMER_REQUEST',
  GET_ALL_CHILD_OF_CUSTOMER_SUCCESS: 'GET_ALL_CHILD_OF_CUSTOMER_SUCCESS',
  GET_ALL_CHILD_OF_CUSTOMER_FAILED: 'GET_ALL_CHILD_OF_CUSTOMER_FAILED',

  GET_CUSTOMER_BY_ID_REQUEST: 'GET_CUSTOMER_BY_ID_REQUEST',
  GET_CUSTOMER_BY_ID_SUCCESS: 'GET_CUSTOMER_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILED: 'GET_CUSTOMER_BY_ID_FAILED',

  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',

  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED: 'UPDATE_CUSTOMER_FAILED',

  DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED: 'DELETE_CUSTOMER_FAILED',

  GET_PRODUCERS_REQUEST: 'GET_PRODUCERS_REQUEST',
  GET_PRODUCERS_SUCCESS: 'GET_PRODUCERS_SUCCESS',
  GET_PRODUCERS_FAILED: 'GET_PRODUCERS_FAILED',

  GET_PRODUCER_BY_ID_REQUEST: 'GET_PRODUCER_BY_ID_REQUEST',
  GET_PRODUCER_BY_ID_SUCCESS: 'GET_PRODUCER_BY_ID_SUCCESS',
  GET_PRODUCER_BY_ID_FAILED: 'GET_PRODUCER_BY_ID_FAILED',

  CREATE_PRODUCER_REQUEST: 'CREATE_PRODUCER_REQUEST',
  CREATE_PRODUCER_SUCCESS: 'CREATE_PRODUCER_SUCCESS',
  CREATE_PRODUCER_FAILED: 'CREATE_PRODUCER_FAILED',

  UPDATE_PRODUCER_REQUEST: 'UPDATE_PRODUCER_REQUEST',
  UPDATE_PRODUCER_SUCCESS: 'UPDATE_PRODUCER_SUCCESS',
  UPDATE_PRODUCER_FAILED: 'UPDATE_PRODUCER_FAILED',

  DELETE_PRODUCER_REQUEST: 'DELETE_PRODUCER_REQUEST',
  DELETE_PRODUCER_SUCCESS: 'DELETE_PRODUCER_SUCCESS',
  DELETE_PRODUCER_FAILED: 'DELETE_PRODUCER_FAILED',

  GET_NPLS_REQUEST: 'GET_NPLS_REQUEST',
  GET_NPLS_SUCCESS: 'GET_NPLS_SUCCESS',
  GET_NPLS_FAILED: 'GET_NPLS_FAILED',

  GET_NPL_BY_ID_REQUEST: 'GET_NPL_BY_ID_REQUEST',
  GET_NPL_BY_ID_SUCCESS: 'GET_NPL_BY_ID_SUCCESS',
  GET_NPL_BY_ID_FAILED: 'GET_NPL_BY_ID_FAILED',

  CREATE_NPL_REQUEST: 'CREATE_NPL_REQUEST',
  CREATE_NPL_SUCCESS: 'CREATE_NPL_SUCCESS',
  CREATE_NPL_FAILED: 'CREATE_NPL_FAILED',

  UPDATE_NPL_REQUEST: 'UPDATE_NPL_REQUEST',
  UPDATE_NPL_SUCCESS: 'UPDATE_NPL_SUCCESS',
  UPDATE_NPL_FAILED: 'UPDATE_NPL_FAILED',

  DELETE_NPL_REQUEST: 'DELETE_NPL_REQUEST',
  DELETE_NPL_SUCCESS: 'DELETE_NPL_SUCCESS',
  DELETE_NPL_FAILED: 'DELETE_NPL_FAILED',

  GET_NPL_GROUPS_REQUEST: 'GET_NPL_GROUPS_REQUEST',
  GET_NPL_GROUPS_SUCCESS: 'GET_NPL_GROUPS_SUCCESS',
  GET_NPL_GROUPS_FAILED: 'GET_NPL_GROUPS_FAILED',

  GET_NPL_GROUP_BY_ID_REQUEST: 'GET_NPL_GROUP_BY_ID_REQUEST',
  GET_NPL_GROUP_BY_ID_SUCCESS: 'GET_NPL_GROUP_BY_ID_SUCCESS',
  GET_NPL_GROUP_BY_ID_FAILED: 'GET_NPL_GROUP_BY_ID_FAILED',

  CREATE_NPL_GROUP_REQUEST: 'CREATE_NPL_GROUP_REQUEST',
  CREATE_NPL_GROUP_SUCCESS: 'CREATE_NPL_GROUP_SUCCESS',
  CREATE_NPL_GROUP_FAILED: 'CREATE_NPL_GROUP_FAILED',

  UPDATE_NPL_GROUP_REQUEST: 'UPDATE_NPL_GROUP_REQUEST',
  UPDATE_NPL_GROUP_SUCCESS: 'UPDATE_NPL_GROUP_SUCCESS',
  UPDATE_NPL_GROUP_FAILED: 'UPDATE_NPL_GROUP_FAILED',

  DELETE_NPL_GROUP_REQUEST: 'DELETE_NPL_GROUP_REQUEST',
  DELETE_NPL_GROUP_SUCCESS: 'DELETE_NPL_GROUP_SUCCESS',
  DELETE_NPL_GROUP_FAILED: 'DELETE_NPL_GROUP_FAILED',

  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILED: 'GET_ORDERS_FAILED',

  GET_ORDER_BY_ID_REQUEST: 'GET_ORDER_BY_ID_REQUEST',
  GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILED: 'GET_ORDER_BY_ID_FAILED',

  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILED: 'CREATE_ORDER_FAILED',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILED: 'UPDATE_ORDER_FAILED',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILED: 'DELETE_ORDER_FAILED',

  APPROVE_ORDER_REQUEST: 'APPROVE_ORDER_REQUEST',
  APPROVE_ORDER_SUCCESS: 'APPROVE_ORDER_SUCCESS',
  APPROVE_ORDER_FAILED: 'APPROVE_ORDER_FAILED',

  GET_CUSTOMERS_IN_ORDER_REQUEST: 'GET_CUSTOMERS_IN_ORDER_REQUEST',
  GET_CUSTOMERS_IN_ORDER_SUCCESS: 'GET_CUSTOMERS_IN_ORDER_SUCCESS',
  GET_CUSTOMERS_IN_ORDER_FAILED: 'GET_CUSTOMERS_IN_ORDER_FAILED',

  EXPORT_CUSTOMERS_IN_ORDER_REQUEST: 'EXPORT_CUSTOMERS_IN_ORDER_REQUEST',
  EXPORT_CUSTOMERS_IN_ORDER_SUCCESS: 'EXPORT_CUSTOMERS_IN_ORDER_SUCCESS',
  EXPORT_CUSTOMERS_IN_ORDER_FAILED: 'EXPORT_CUSTOMERS_IN_ORDER_FAILED',

  GET_CUSTOMER_IN_ORDER_REQUEST: 'GET_CUSTOMER_IN_ORDER_REQUEST',
  GET_CUSTOMER_IN_ORDER_SUCCESS: 'GET_CUSTOMER_IN_ORDER_SUCCESS',
  GET_CUSTOMER_IN_ORDER_FAILED: 'GET_CUSTOMER_IN_ORDER_FAILED',

  // GET_CUSTOMER_IN_ORDER_REQUEST: "GET_CUSTOMER_IN_ORDER_REQUEST",
  // GET_CUSTOMER_IN_ORDER_SUCCESS: "GET_CUSTOMER_IN_ORDER_SUCCESS",
  // GET_CUSTOMER_IN_ORDER_FAILED: "GET_CUSTOMER_IN_ORDER_FAILED",

  UPLOAD_CUSTOMER_ORDER_REQUEST: 'UPLOAD_CUSTOMER_ORDER_REQUEST',
  UPLOAD_CUSTOMER_ORDER_SUCCESS: 'UPLOAD_CUSTOMER_ORDER_SUCCESS',
  UPLOAD_CUSTOMER_ORDER_FAILED: 'UPLOAD_CUSTOMER_ORDER_FAILED',

  GET_TEMPLATE_CUSTOMER_ORDER_REQUEST: 'GET_TEMPLATE_CUSTOMER_ORDER_REQUEST',
  GET_TEMPLATE_CUSTOMER_ORDER_SUCCESS: 'GET_TEMPLATE_CUSTOMER_ORDER_SUCCESS',
  GET_TEMPLATE_CUSTOMER_ORDER_FAILED: 'GET_TEMPLATE_CUSTOMER_ORDER_FAILED',

  CREATE_PRODUCT_TYPE_REQUEST: 'CREATE_PRODUCT_TYPE_REQUEST',
  CREATE_PRODUCT_TYPE_SUCCESS: 'CREATE_PRODUCT_TYPE_SUCCESS',
  CREATE_PRODUCT_TYPE_FAILED: 'CREATE_PRODUCT_TYPE_FAILED',

  UPDATE_PRODUCT_TYPE_REQUEST: 'UPDATE_PRODUCT_TYPE_REQUEST',
  UPDATE_PRODUCT_TYPE_SUCCESS: 'UPDATE_PRODUCT_TYPE_SUCCESS',
  UPDATE_PRODUCT_TYPE_FAILED: 'UPDATE_PRODUCT_TYPE_FAILED',

  DELETE_PRODUCT_TYPE_REQUEST: 'DELETE_PRODUCT_TYPE_REQUEST',
  DELETE_PRODUCT_TYPE_SUCCESS: 'DELETE_PRODUCT_TYPE_SUCCESS',
  DELETE_PRODUCT_TYPE_FAILED: 'DELETE_PRODUCT_TYPE_FAILED',

  GET_PRODUCT_TYPES_REQUEST: 'GET_PRODUCT_TYPES_REQUEST',
  GET_PRODUCT_TYPES_SUCCESS: 'GET_PRODUCT_TYPES_SUCCESS',
  GET_PRODUCT_TYPES_FAILED: 'GET_PRODUCT_TYPES_FAILED',

  CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILED: 'CREATE_PRODUCT_FAILED',

  UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',

  DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILED: 'DELETE_PRODUCT_FAILED',

  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',

  GET_PRODUCT_BY_ID_REQUEST: 'GET_PRODUCT_BY_ID_REQUEST',
  GET_PRODUCT_BY_ID_SUCCESS: 'GET_PRODUCT_BY_ID_SUCCESS',
  GET_PRODUCT_BY_ID_FAILED: 'GET_PRODUCT_BY_ID_FAILED',

  GET_MEASUREMENT_STANDARDS_REQUEST: 'GET_MEASUREMENT_STANDARDS_REQUEST',
  GET_MEASUREMENT_STANDARDS_SUCCESS: 'GET_MEASUREMENT_STANDARDS_SUCCESS',
  GET_MEASUREMENT_STANDARDS_FAILED: 'GET_MEASUREMENT_STANDARDS_FAILED',

  GET_MEASUREMENT_STANDARD_BY_ID_REQUEST:
    'GET_MEASUREMENT_STANDARD_BY_ID_REQUEST',
  GET_MEASUREMENT_STANDARD_BY_ID_SUCCESS:
    'GET_MEASUREMENT_STANDARD_BY_ID_SUCCESS',
  GET_MEASUREMENT_STANDARD_BY_ID_FAILED:
    'GET_MEASUREMENT_STANDARD_BY_ID_FAILED',

  CREATE_MEASUREMENT_STANDARD_REQUEST: 'CREATE_MEASUREMENT_STANDARD_REQUEST',
  CREATE_MEASUREMENT_STANDARD_SUCCESS: 'CREATE_MEASUREMENT_STANDARD_SUCCESS',
  CREATE_MEASUREMENT_STANDARD_FAILED: 'CREATE_MEASUREMENT_STANDARD_FAILED',

  UPDATE_MEASUREMENT_STANDARD_REQUEST: 'UPDATE_MEASUREMENT_STANDARD_REQUEST',
  UPDATE_MEASUREMENT_STANDARD_SUCCESS: 'UPDATE_MEASUREMENT_STANDARD_SUCCESS',
  UPDATE_MEASUREMENT_STANDARD_FAILED: 'UPDATE_MEASUREMENT_STANDARD_FAILED',

  DELETE_MEASUREMENT_STANDARD_REQUEST: 'DELETE_MEASUREMENT_STANDARD_REQUEST',
  DELETE_MEASUREMENT_STANDARD_SUCCESS: 'DELETE_MEASUREMENT_STANDARD_SUCCESS',
  DELETE_MEASUREMENT_STANDARD_FAILED: 'DELETE_MEASUREMENT_STANDARD_FAILED',

  GET_TEMPLATE_MEASURE_SIZE_REQUEST: 'GET_TEMPLATE_MEASURE_SIZE_REQUEST',
  GET_TEMPLATE_MEASURE_SIZE_SUCCESS: 'GET_TEMPLATE_MEASURE_SIZE_SUCCESS',
  GET_TEMPLATE_MEASURE_SIZE_FAILED: 'GET_TEMPLATE_MEASURE_SIZE_FAILED',

  GET_PRODUCT_PARAMETERS_REQUEST: 'GET_PRODUCT_PARAMETERS_REQUEST',
  GET_PRODUCT_PARAMETERS_SUCCESS: 'GET_PRODUCT_PARAMETERS_SUCCESS',
  GET_PRODUCT_PARAMETERS_FAILED: 'GET_PRODUCT_PARAMETERS_FAILED',

  GET_PROVINCES_REQUEST: 'GET_PROVINCES_REQUEST',
  GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
  GET_PROVINCES_FAILED: 'GET_PROVINCES_FAILED',

  GET_PROVINCE_BY_ID_REQUEST: 'GET_PROVINCE_BY_ID_REQUEST',
  GET_PROVINCE_BY_ID_SUCCESS: 'GET_PROVINCE_BY_ID_SUCCESS',
  GET_PROVINCE_BY_ID_FAILED: 'GET_PROVINCE_BY_ID_FAILED',

  GET_ORDER_PLANS_REQUEST: 'GET_ORDER_PLANS_REQUEST',
  GET_ORDER_PLANS_SUCCESS: 'GET_ORDER_PLANS_SUCCESS',
  GET_ORDER_PLANS_FAILED: 'GET_ORDER_PLANS_FAILED',

  GET_ORDER_PLAN_BY_ID_REQUEST: 'GET_ORDER_PLAN_BY_ID_REQUEST',
  GET_ORDER_PLAN_BY_ID_SUCCESS: 'GET_ORDER_PLAN_BY_ID_SUCCESS',
  GET_ORDER_PLAN_BY_ID_FAILED: 'GET_ORDER_PLAN_BY_ID_FAILED',

  CREATE_ORDER_PLAN_REQUEST: 'CREATE_ORDER_PLAN_REQUEST',
  CREATE_ORDER_PLAN_SUCCESS: 'CREATE_ORDER_PLAN_SUCCESS',
  CREATE_ORDER_PLAN_FAILED: 'CREATE_ORDER_PLAN_FAILED',

  UPDATE_ORDER_PLAN_REQUEST: 'UPDATE_ORDER_PLAN_REQUEST',
  UPDATE_ORDER_PLAN_SUCCESS: 'UPDATE_ORDER_PLAN_SUCCESS',
  UPDATE_ORDER_PLAN_FAILED: 'UPDATE_ORDER_PLAN_FAILED',

  DELETE_ORDER_PLAN_REQUEST: 'DELETE_ORDER_PLAN_REQUEST',
  DELETE_ORDER_PLAN_SUCCESS: 'DELETE_ORDER_PLAN_SUCCESS',
  DELETE_ORDER_PLAN_FAILED: 'DELETE_ORDER_PLAN_FAILED',

  GET_MEASURE_SIZES_REQUEST: 'GET_MEASURE_SIZES_REQUEST',
  GET_MEASURE_SIZES_SUCCESS: 'GET_MEASURE_SIZES_SUCCESS',
  GET_MEASURE_SIZES_FAILED: 'GET_MEASURE_SIZES_FAILED',

  GET_MEASURE_SIZE_BY_ID_REQUEST: 'GET_MEASURE_SIZE_BY_ID_REQUEST',
  GET_MEASURE_SIZE_BY_ID_SUCCESS: 'GET_MEASURE_SIZE_BY_ID_SUCCESS',
  GET_MEASURE_SIZE_BY_ID_FAILED: 'GET_MEASURE_SIZE_BY_ID_FAILED',

  CREATE_MEASURE_SIZE_REQUEST: 'CREATE_MEASURE_SIZE_REQUEST',
  CREATE_MEASURE_SIZE_SUCCESS: 'CREATE_MEASURE_SIZE_SUCCESS',
  CREATE_MEASURE_SIZE_FAILED: 'CREATE_MEASURE_SIZE_FAILED',

  UPDATE_MEASURE_SIZE_REQUEST: 'UPDATE_MEASURE_SIZE_REQUEST',
  UPDATE_MEASURE_SIZE_SUCCESS: 'UPDATE_MEASURE_SIZE_SUCCESS',
  UPDATE_MEASURE_SIZE_FAILED: 'UPDATE_MEASURE_SIZE_FAILED',

  DELETE_MEASURE_SIZE_REQUEST: 'DELETE_MEASURE_SIZE_REQUEST',
  DELETE_MEASURE_SIZE_SUCCESS: 'DELETE_MEASURE_SIZE_SUCCESS',
  DELETE_MEASURE_SIZE_FAILED: 'DELETE_MEASURE_SIZE_FAILED',

  CONVERT_MEASURE_SIZE_REQUEST: 'CONVERT_MEASURE_SIZE_REQUEST',
  CONVERT_MEASURE_SIZE_SUCCESS: 'CONVERT_MEASURE_SIZE_SUCCESS',
  CONVERT_MEASURE_SIZE_FAILED: 'CONVERT_MEASURE_SIZE_FAILED',

  ADD_SEW_MEASURE_SIZE_REQUEST: 'ADD_SEW_MEASURE_SIZE_REQUEST',
  ADD_SEW_MEASURE_SIZE_SUCCESS: 'ADD_SEW_MEASURE_SIZE_SUCCESS',
  ADD_SEW_MEASURE_SIZE_FAILED: 'ADD_SEW_MEASURE_SIZE_FAILED',

  UPDATE_CUSTOMER_IN_ORDER_REQUEST: 'UPDATE_CUSTOMER_IN_ORDER_REQUEST',
  UPDATE_CUSTOMER_IN_ORDER_SUCCESS: 'UPDATE_CUSTOMER_IN_ORDER_SUCCESS',
  UPDATE_CUSTOMER_IN_ORDER_FAILED: 'UPDATE_CUSTOMER_IN_ORDER_FAILED',

  GET_ORDERS_CONVERT_REQUEST: 'GET_ORDERS_CONVERT_REQUEST',
  GET_ORDERS_CONVERT_SUCCESS: 'GET_ORDERS_CONVERT_SUCCESS',
  GET_ORDERS_CONVERT_FAILED: 'GET_ORDERS_CONVERT_FAILED',

  GET_UNITS_REQUEST: 'GET_UNITS_REQUEST',
  GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
  GET_UNITS_FAILED: 'GET_UNITS_FAILED',

  GET_UNIT_BY_ID_REQUEST: 'GET_UNIT_BY_ID_REQUEST',
  GET_UNIT_BY_ID_SUCCESS: 'GET_UNIT_BY_ID_SUCCESS',
  GET_UNIT_BY_ID_FAILED: 'GET_UNIT_BY_ID_FAILED',

  CREATE_UNIT_REQUEST: 'CREATE_UNIT_REQUEST',
  CREATE_UNIT_SUCCESS: 'CREATE_UNIT_SUCCESS',
  CREATE_UNIT_FAILED: 'CREATE_UNIT_FAILED',

  UPDATE_UNIT_REQUEST: 'UPDATE_UNIT_REQUEST',
  UPDATE_UNIT_SUCCESS: 'UPDATE_UNIT_SUCCESS',
  UPDATE_UNIT_FAILED: 'UPDATE_UNIT_FAILED',

  DELETE_UNIT_REQUEST: 'DELETE_UNIT_REQUEST',
  DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAILED: 'DELETE_UNIT_FAILED',

  GET_MATERIAL_NORMS_REQUEST: 'GET_MATERIAL_NORMS_REQUEST',
  GET_MATERIAL_NORMS_SUCCESS: 'GET_MATERIAL_NORMS_SUCCESS',
  GET_MATERIAL_NORMS_FAILED: 'GET_MATERIAL_NORMS_FAILED',

  GET_MATERIAL_NORM_BY_ID_REQUEST: 'GET_MATERIAL_NORM_BY_ID_REQUEST',
  GET_MATERIAL_NORM_BY_ID_SUCCESS: 'GET_MATERIAL_NORM_BY_ID_SUCCESS',
  GET_MATERIAL_NORM_BY_ID_FAILED: 'GET_MATERIAL_NORM_BY_ID_FAILED',

  CREATE_MATERIAL_NORM_REQUEST: 'CREATE_MATERIAL_NORM_REQUEST',
  CREATE_MATERIAL_NORM_SUCCESS: 'CREATE_MATERIAL_NORM_SUCCESS',
  CREATE_MATERIAL_NORM_FAILED: 'CREATE_MATERIAL_NORM_FAILED',

  UPDATE_MATERIAL_NORM_REQUEST: 'UPDATE_MATERIAL_NORM_REQUEST',
  UPDATE_MATERIAL_NORM_SUCCESS: 'UPDATE_MATERIAL_NORM_SUCCESS',
  UPDATE_MATERIAL_NORM_FAILED: 'UPDATE_MATERIAL_NORM_FAILED',

  DELETE_MATERIAL_NORM_REQUEST: 'DELETE_MATERIAL_NORM_REQUEST',
  DELETE_MATERIAL_NORM_SUCCESS: 'DELETE_MATERIAL_NORM_SUCCESS',
  DELETE_MATERIAL_NORM_FAILED: 'DELETE_MATERIAL_NORM_FAILED',

  GET_ALL_PRODUCT_IN_ORDER_REQUEST: 'GET_ALL_PRODUCT_IN_ORDER_REQUEST',
  GET_ALL_PRODUCT_IN_ORDER_SUCCESS: 'GET_ALL_PRODUCT_IN_ORDER_SUCCESS',
  GET_ALL_PRODUCT_IN_ORDER_FAILED: 'GET_ALL_PRODUCT_IN_ORDER_FAILED',

  GET_NPL_ORDERS_REQUEST: 'GET_NPL_ORDERS_REQUEST',
  GET_NPL_ORDERS_SUCCESS: 'GET_NPL_ORDERS_SUCCESS',
  GET_NPL_ORDERS_FAILED: 'GET_NPL_ORDERS_FAILED',

  GET_NPL_ORDER_BY_ID_REQUEST: 'GET_NPL_ORDER_BY_ID_REQUEST',
  GET_NPL_ORDER_BY_ID_SUCCESS: 'GET_NPL_ORDER_BY_ID_SUCCESS',
  GET_NPL_ORDER_BY_ID_FAILED: 'GET_NPL_ORDER_BY_ID_FAILED',

  CREATE_NPL_ORDER_REQUEST: 'CREATE_NPL_ORDER_REQUEST',
  CREATE_NPL_ORDER_SUCCESS: 'CREATE_NPL_ORDER_SUCCESS',
  CREATE_NPL_ORDER_FAILED: 'CREATE_NPL_ORDER_FAILED',

  UPDATE_NPL_ORDER_REQUEST: 'UPDATE_NPL_ORDER_REQUEST',
  UPDATE_NPL_ORDER_SUCCESS: 'UPDATE_NPL_ORDER_SUCCESS',
  UPDATE_NPL_ORDER_FAILED: 'UPDATE_NPL_ORDER_FAILED',

  DELETE_NPL_ORDER_REQUEST: 'DELETE_NPL_ORDER_REQUEST',
  DELETE_NPL_ORDER_SUCCESS: 'DELETE_NPL_ORDER_SUCCESS',
  DELETE_NPL_ORDER_FAILED: 'DELETE_NPL_ORDER_FAILED',

  GET_UNUSUAL_LIST_REQUEST: 'GET_UNUSUAL_LIST_REQUEST',
  GET_UNUSUAL_LIST_SUCCESS: 'GET_UNUSUAL_LIST_SUCCESS',
  GET_UNUSUAL_LIST_FAILED: 'GET_UNUSUAL_LIST_FAILED',

  // GET_ALL_PRODUCT_IN_ORDER_REQUEST: "GET_ALL_PRODUCT_IN_ORDER_REQUEST",
  // GET_ALL_PRODUCT_IN_ORDER_SUCCESS: "GET_ALL_PRODUCT_IN_ORDER_SUCCESS",
  // GET_ALL_PRODUCT_IN_ORDER_FAILED: "GET_ALL_PRODUCT_IN_ORDER_FAILED",

  CHECK_UNUSUAL_SIZE_REQUEST: 'CHECK_UNUSUAL_SIZE_REQUEST',
  CHECK_UNUSUAL_SIZE_SUCCESS: 'CHECK_UNUSUAL_SIZE_SUCCESS',
  CHECK_UNUSUAL_SIZE_FAILED: 'CHECK_UNUSUAL_SIZE_FAILED',

  APPROVE_ALL_CUSTOMER_IN_ORDER_REQUEST:
    'APPROVE_ALL_CUSTOMER_IN_ORDER_REQUEST',
  APPROVE_ALL_CUSTOMER_IN_ORDER_SUCCESS:
    'APPROVE_ALL_CUSTOMER_IN_ORDER_SUCCESS',
  APPROVE_ALL_CUSTOMER_IN_ORDER_FAILED: 'APPROVE_ALL_CUSTOMER_IN_ORDER_FAILED',

  APPROVE_MANY_CUSTOMER_IN_ORDER_REQUEST:
    'APPROVE_MANY_CUSTOMER_IN_ORDER_REQUEST',
  APPROVE_MANY_CUSTOMER_IN_ORDER_SUCCESS:
    'APPROVE_MANY_CUSTOMER_IN_ORDER_SUCCESS',
  APPROVE_MANY_CUSTOMER_IN_ORDER_FAILED:
    'APPROVE_MANY_CUSTOMER_IN_ORDER_FAILED',

  GET_PRODUCTION_COMMANDS_REQUEST: 'GET_PRODUCTION_COMMANDS_REQUEST',
  GET_PRODUCTION_COMMANDS_SUCCESS: 'GET_PRODUCTION_COMMANDS_SUCCESS',
  GET_PRODUCTION_COMMANDS_FAILED: 'GET_PRODUCTION_COMMANDS_FAILED',

  GET_PRODUCTION_COMMANDS_ERROR_REQUEST:
    'GET_PRODUCTION_COMMANDS_ERROR_REQUEST',
  GET_PRODUCTION_COMMANDS_ERROR_SUCCESS:
    'GET_PRODUCTION_COMMANDS_ERROR_SUCCESS',
  GET_PRODUCTION_COMMANDS_ERROR_FAILED: 'GET_PRODUCTION_COMMANDS_ERROR_FAILED',

  GET_PRODUCTION_COMMAND_BY_ID_REQUEST: 'GET_PRODUCTION_COMMAND_BY_ID_REQUEST',
  GET_PRODUCTION_COMMAND_BY_ID_SUCCESS: 'GET_PRODUCTION_COMMAND_BY_ID_SUCCESS',
  GET_PRODUCTION_COMMAND_BY_ID_FAILED: 'GET_PRODUCTION_COMMAND_BY_ID_FAILED',

  CREATE_PRODUCTION_COMMAND_REQUEST: 'CREATE_PRODUCTION_COMMAND_REQUEST',
  CREATE_PRODUCTION_COMMAND_SUCCESS: 'CREATE_PRODUCTION_COMMAND_SUCCESS',
  CREATE_PRODUCTION_COMMAND_FAILED: 'CREATE_PRODUCTION_COMMAND_FAILED',

  DELETE_PRODUCTION_COMMAND_REQUEST: 'DELETE_PRODUCTION_COMMAND_REQUEST',
  DELETE_PRODUCTION_COMMAND_SUCCESS: 'DELETE_PRODUCTION_COMMAND_SUCCESS',
  DELETE_PRODUCTION_COMMAND_FAILED: 'DELETE_PRODUCTION_COMMAND_FAILED',

  UPDATE_PRODUCTION_COMMAND_REQUEST: 'UPDATE_PRODUCTION_COMMAND_REQUEST',
  UPDATE_PRODUCTION_COMMAND_SUCCESS: 'UPDATE_PRODUCTION_COMMAND_SUCCESS',
  UPDATE_PRODUCTION_COMMAND_FAILED: 'UPDATE_PRODUCTION_COMMAND_FAILED',

  EXCLUDE_CUSTOMER_COMMAND_REQUEST: 'EXCLUDE_CUSTOMER_COMMAND_REQUEST',
  EXCLUDE_CUSTOMER_COMMAND_SUCCESS: 'EXCLUDE_CUSTOMER_COMMAND_SUCCESS',
  EXCLUDE_CUSTOMER_COMMAND_FAILED: 'EXCLUDE_CUSTOMER_COMMAND_FAILED',

  UPLOAD_MEASURE_SIZE_REQUEST: 'UPLOAD_MEASURE_SIZE_REQUEST',
  UPLOAD_MEASURE_SIZE_SUCCESS: 'UPLOAD_MEASURE_SIZE_SUCCESS',
  UPLOAD_MEASURE_SIZE_FAILED: 'UPLOAD_MEASURE_SIZE_FAILED',

  EXPORT_MEASURE_SIZE_REQUEST: 'EXPORT_MEASURE_SIZE_REQUEST',
  EXPORT_MEASURE_SIZE_SUCCESS: 'EXPORT_MEASURE_SIZE_SUCCESS',
  EXPORT_MEASURE_SIZE_FAILED: 'EXPORT_MEASURE_SIZE_FAILED',

  AUTO_ASSIGN_SIZE_REQUEST: 'AUTO_ASSIGN_SIZE_REQUEST',
  AUTO_ASSIGN_SIZE_SUCCESS: 'AUTO_ASSIGN_SIZE_SUCCESS',
  AUTO_ASSIGN_SIZE_FAILED: 'AUTO_ASSIGN_SIZE_FAILED',

  CONVERT_OWN_DESIGN_REQUEST: 'CONVERT_OWN_DESIGN_REQUEST',
  CONVERT_OWN_DESIGN_SUCCESS: 'CONVERT_OWN_DESIGN_SUCCESS',
  CONVERT_OWN_DESIGN_FAILED: 'CONVERT_OWN_DESIGN_FAILED',

  CONVERT_OWN_DESIGNS_REQUEST: 'CONVERT_OWN_DESIGNS_REQUEST',
  CONVERT_OWN_DESIGNS_SUCCESS: 'CONVERT_OWN_DESIGNS_SUCCESS',
  CONVERT_OWN_DESIGNS_FAILED: 'CONVERT_OWN_DESIGNS_FAILED',

  GET_DELIVERIES_REQUEST: 'GET_DELIVERIES_REQUEST',
  GET_DELIVERIES_SUCCESS: 'GET_DELIVERIES_SUCCESS',
  GET_DELIVERIES_FAILED: 'GET_DELIVERIES_FAILED',

  GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_REQUEST:
    'GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_REQUEST',
  GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_SUCCESS:
    'GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_SUCCESS',
  GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_FAILED:
    'GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_FAILED',

  GET_PRODUCTS_FAULT_PAID_CUSTOMER_REQUEST:
    'GET_PRODUCTS_FAULT_PAID_CUSTOMER_REQUEST',
  GET_PRODUCTS_FAULT_PAID_CUSTOMER_SUCCESS:
    'GET_PRODUCTS_FAULT_PAID_CUSTOMER_SUCCESS',
  GET_PRODUCTS_FAULT_PAID_CUSTOMER_FAILED:
    'GET_PRODUCTS_FAULT_PAID_CUSTOMER_FAILED',

  GET_DELIVERY_BY_ID_REQUEST: 'GET_DELIVERY_BY_ID_REQUEST',
  GET_DELIVERY_BY_ID_SUCCESS: 'GET_DELIVERY_BY_ID_SUCCESS',
  GET_DELIVERY_BY_ID_FAILED: 'GET_DELIVERY_BY_ID_FAILED',

  UPDATE_DELIVERY_REQUEST: 'UPDATE_DELIVERY_REQUEST',
  UPDATE_DELIVERY_SUCCESS: 'UPDATE_DELIVERY_SUCCESS',
  UPDATE_DELIVERY_FAILED: 'UPDATE_DELIVERY_FAILED',

  DELETE_DELIVERY_REQUEST: 'DELETE_DELIVERY_REQUEST',
  DELETE_DELIVERY_SUCCESS: 'DELETE_DELIVERY_SUCCESS',
  DELETE_DELIVERY_FAILED: 'DELETE_DELIVERY_FAILED',

  CREATE_DELIVERY_REQUEST: 'CREATE_DELIVERY_REQUEST',
  CREATE_DELIVERY_SUCCESS: 'CREATE_DELIVERY_SUCCESS',
  CREATE_DELIVERY_FAILED: 'CREATE_DELIVERY_FAILED',

  GET_CUSTOMER_BY_BARCODE_REQUEST: 'GET_CUSTOMER_BY_BARCODE_REQUEST',
  GET_CUSTOMER_BY_BARCODE_SUCCESS: 'GET_CUSTOMER_BY_BARCODE_SUCCESS',
  GET_CUSTOMER_BY_BARCODE_FAILED: 'GET_CUSTOMER_BY_BARCODE_FAILED',

  GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_REQUEST:
    'GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_REQUEST',
  GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_SUCCESS:
    'GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_SUCCESS',
  GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_FAILED:
    'GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_FAILED',

  COORDINATOR_DELIVERY_REQUEST: 'COORDINATOR_DELIVERY_REQUEST',
  COORDINATOR_DELIVERY_SUCCESS: 'COORDINATOR_DELIVERY_SUCCESS',
  COORDINATOR_DELIVERY_FAILED: 'COORDINATOR_DELIVERY_FAILED',

  RECEIVE_FORM_GC_REQUEST: 'RECEIVE_FORM_GC_REQUEST',
  RECEIVE_FORM_GC_SUCCESS: 'RECEIVE_FORM_GC_SUCCESS',
  RECEIVE_FORM_GC_FAILED: 'RECEIVE_FORM_GC_FAILED',

  GET_QUALITIES_REQUEST: 'GET_QUALITIES_REQUEST',
  GET_QUALITIES_SUCCESS: 'GET_QUALITIES_SUCCESS',
  GET_QUALITIES_FAILED: 'GET_QUALITIES_FAILED',

  GET_QUALITY_BY_ID_REQUEST: 'GET_QUALITY_BY_ID_REQUEST',
  GET_QUALITY_BY_ID_SUCCESS: 'GET_QUALITY_BY_ID_SUCCESS',
  GET_QUALITY_BY_ID_FAILED: 'GET_QUALITY_BY_ID_FAILED',

  UPDATE_QUALITY_REQUEST: 'UPDATE_QUALITY_REQUEST',
  UPDATE_QUALITY_SUCCESS: 'UPDATE_QUALITY_SUCCESS',
  UPDATE_QUALITY_FAILED: 'UPDATE_QUALITY_FAILED',

  DELETE_QUALITY_REQUEST: 'DELETE_QUALITY_REQUEST',
  DELETE_QUALITY_SUCCESS: 'DELETE_QUALITY_SUCCESS',
  DELETE_QUALITY_FAILED: 'DELETE_QUALITY_FAILED',

  CREATE_QUALITY_REQUEST: 'CREATE_QUALITY_REQUEST',
  CREATE_QUALITY_SUCCESS: 'CREATE_QUALITY_SUCCESS',
  CREATE_QUALITY_FAILED: 'CREATE_QUALITY_FAILED',

  RETURN_PRODUCER_REQUEST: 'RETURN_PRODUCER_REQUEST',
  RETURN_PRODUCER_SUCCESS: 'RETURN_PRODUCER_SUCCESS',
  RETURN_PRODUCER_FAILED: 'RETURN_PRODUCER_FAILED',

  GET_CUSTOMER_BY_BARCODE_QUALITY_REQUEST:
    'GET_CUSTOMER_BY_BARCODE_QUALITY_REQUEST',
  GET_CUSTOMER_BY_BARCODE_QUALITY_SUCCESS:
    'GET_CUSTOMER_BY_BARCODE_QUALITY_SUCCESS',
  GET_CUSTOMER_BY_BARCODE_QUALITY_FAILED:
    'GET_CUSTOMER_BY_BARCODE_QUALITY_FAILED',

  GET_COMPLETE_STAGES_REQUEST: 'GET_COMPLETE_STAGES_REQUEST',
  GET_COMPLETE_STAGES_SUCCESS: 'GET_COMPLETE_STAGES_SUCCESS',
  GET_COMPLETE_STAGES_FAILED: 'GET_COMPLETE_STAGES_FAILED',

  GET_COMPLETE_PACKINGS_REQUEST: 'GET_COMPLETE_PACKINGS_REQUEST',
  GET_COMPLETE_PACKINGS_SUCCESS: 'GET_COMPLETE_PACKINGS_SUCCESS',
  GET_COMPLETE_PACKINGS_FAILED: 'GET_COMPLETE_PACKINGS_FAILED',

  GET_COMPLETE_PRODUCTS_ERROR_REQUEST: 'GET_COMPLETE_PRODUCTS_ERROR_REQUEST',
  GET_COMPLETE_PRODUCTS_ERROR_SUCCESS: 'GET_COMPLETE_PRODUCTS_ERROR_SUCCESS',
  GET_COMPLETE_PRODUCTS_ERROR_FAILED: 'GET_COMPLETE_PRODUCTS_ERROR_FAILED',

  GET_COMPLETE_STAGE_BY_ID_REQUEST: 'GET_COMPLETE_STAGE_BY_ID_REQUEST',
  GET_COMPLETE_STAGE_BY_ID_SUCCESS: 'GET_COMPLETE_STAGE_BY_ID_SUCCESS',
  GET_COMPLETE_STAGE_BY_ID_FAILED: 'GET_COMPLETE_STAGE_BY_ID_FAILED',

  UPDATE_COMPLETE_STAGE_REQUEST: 'UPDATE_COMPLETE_STAGE_REQUEST',
  UPDATE_COMPLETE_STAGE_SUCCESS: 'UPDATE_COMPLETE_STAGE_SUCCESS',
  UPDATE_COMPLETE_STAGE_FAILED: 'UPDATE_COMPLETE_STAGE_FAILED',

  DELETE_COMPLETE_STAGE_REQUEST: 'DELETE_COMPLETE_STAGE_REQUEST',
  DELETE_COMPLETE_STAGE_SUCCESS: 'DELETE_COMPLETE_STAGE_SUCCESS',
  DELETE_COMPLETE_STAGE_FAILED: 'DELETE_COMPLETE_STAGE_FAILED',

  CREATE_COMPLETE_STAGE_REQUEST: 'CREATE_COMPLETE_STAGE_REQUEST',
  CREATE_COMPLETE_STAGE_SUCCESS: 'CREATE_COMPLETE_STAGE_SUCCESS',
  CREATE_COMPLETE_STAGE_FAILED: 'CREATE_COMPLETE_STAGE_FAILED',

  QC_PRODUCT_ACHIEVED_REQUEST: 'QC_PRODUCT_ACHIEVED_REQUEST',
  QC_PRODUCT_ACHIEVED_SUCCESS: 'QC_PRODUCT_ACHIEVED_SUCCESS',
  QC_PRODUCT_ACHIEVED_FAILED: 'QC_PRODUCT_ACHIEVED_FAILED',

  QC_PRODUCT_UNACHIEVED_REQUEST: 'QC_PRODUCT_UNACHIEVED_REQUEST',
  QC_PRODUCT_UNACHIEVED_SUCCESS: 'QC_PRODUCT_UNACHIEVED_SUCCESS',
  QC_PRODUCT_UNACHIEVED_FAILED: 'QC_PRODUCT_UNACHIEVED_FAILED',

  GET_PRODUCTS_ERROR_QC_REQUEST: 'GET_PRODUCTS_ERROR_QC_REQUEST',
  GET_PRODUCTS_ERROR_QC_SUCCESS: 'GET_PRODUCTS_ERROR_QC_SUCCESS',
  GET_PRODUCTS_ERROR_QC_FAILED: 'GET_PRODUCTS_ERROR_QC_FAILED',

  QC_COMPLETE_PRODUCTS_ERROR_QC_REQUEST:
    'QC_COMPLETE_PRODUCTS_ERROR_QC_REQUEST',
  QC_COMPLETE_PRODUCTS_ERROR_QC_SUCCESS:
    'QC_COMPLETE_PRODUCTS_ERROR_QC_SUCCESS',
  QC_COMPLETE_PRODUCTS_ERROR_QC_FAILED: 'QC_COMPLETE_PRODUCTS_ERROR_QC_FAILED',

  GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_REQUEST:
    'GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_REQUEST',
  GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_SUCCESS:
    'GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_SUCCESS',
  GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_FAILED:
    'GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_FAILED',

  TRANSFER_QC_SP_REQUEST: 'TRANSFER_QC_SP_REQUEST',
  TRANSFER_QC_SP_SUCCESS: 'TRANSFER_QC_SP_SUCCESS',
  TRANSFER_QC_SP_FAILED: 'TRANSFER_QC_SP_FAILED',

  TRANSFER_CUSTOMER_REQUEST: 'TRANSFER_CUSTOMER_REQUEST',
  TRANSFER_CUSTOMER_SUCCESS: 'TRANSFER_CUSTOMER_SUCCESS',
  TRANSFER_CUSTOMER_FAILED: 'TRANSFER_CUSTOMER_FAILED',

  RECEIVE_FROM_CUSTOMER_REQUEST: 'RECEIVE_FROM_CUSTOMER_REQUEST',
  RECEIVE_FROM_CUSTOMER_SUCCESS: 'RECEIVE_FROM_CUSTOMER_SUCCESS',
  RECEIVE_FROM_CUSTOMER_FAILED: 'RECEIVE_FROM_CUSTOMER_FAILED',

  GET_ALL_EXCHANGE_HISTORY_REQUEST: 'GET_ALL_EXCHANGE_HISTORY_REQUEST',
  GET_ALL_EXCHANGE_HISTORY_SUCCESS: 'GET_ALL_EXCHANGE_HISTORY_SUCCESS',
  GET_ALL_EXCHANGE_HISTORY_FAILED: 'GET_ALL_EXCHANGE_HISTORY_FAILED',

  GET_EXCHANGE_HISTORY_BY_ID_REQUEST: 'GET_EXCHANGE_HISTORY_BY_ID_REQUEST',
  GET_EXCHANGE_HISTORY_BY_ID_SUCCESS: 'GET_EXCHANGE_HISTORY_BY_ID_SUCCESS',
  GET_EXCHANGE_HISTORY_BY_ID_FAILED: 'GET_EXCHANGE_HISTORY_BY_ID_REQUEST',

  CREATE_EXCHANGE_HISTORY_REQUEST: 'CREATE_EXCHANGE_HISTORY_REQUEST',
  CREATE_EXCHANGE_HISTORY_SUCCESS: 'CREATE_EXCHANGE_HISTORY_SUCCESS',
  CREATE_EXCHANGE_HISTORY_FAILED: 'CREATE_EXCHANGE_HISTORY_REQUEST',

  UPDATE_EXCHANGE_HISTORY_REQUEST: 'UPDATE_EXCHANGE_HISTORY_REQUEST',
  UPDATE_EXCHANGE_HISTORY_SUCCESS: 'UPDATE_EXCHANGE_HISTORY_SUCCESS',
  UPDATE_EXCHANGE_HISTORY_FAILED: 'UPDATE_EXCHANGE_HISTORY_REQUEST',

  DELETE_EXCHANGE_HISTORY_REQUEST: 'DELETE_EXCHANGE_HISTORY_REQUEST',
  DELETE_EXCHANGE_HISTORY_SUCCESS: 'DELETE_EXCHANGE_HISTORY_SUCCESS',
  DELETE_EXCHANGE_HISTORY_FAILED: 'DELETE_EXCHANGE_HISTORY_REQUEST',

  GET_COMMENTS_ORDER_PLAN_REQUEST: 'GET_COMMENTS_ORDER_PLAN_REQUEST',
  GET_COMMENTS_ORDER_PLAN_SUCCESS: 'GET_COMMENTS_ORDER_PLAN_SUCCESS',
  GET_COMMENTS_ORDER_PLAN_FAILED: 'GET_COMMENTS_ORDER_PLAN_FAILED',

  CREATE_COMMENT_ORDER_PLAN_REQUEST: 'CREATE_COMMENT_ORDER_PLAN_REQUEST',
  CREATE_COMMENT_ORDER_PLAN_SUCCESS: 'CREATE_COMMENT_ORDER_PLAN_SUCCESS',
  CREATE_COMMENT_ORDER_PLAN_FAILED: 'CREATE_COMMENT_ORDER_PLAN_FAILED',

  GET_COMMENTS_MEASURE_REQUEST: 'GET_COMMENTS_MEASURE_REQUEST',
  GET_COMMENTS_MEASURE_SUCCESS: 'GET_COMMENTS_MEASURE_SUCCESS',
  GET_COMMENTS_MEASURE_FAILED: 'GET_COMMENTS_MEASURE_FAILED',

  CREATE_COMMENT_MEASURE_REQUEST: 'CREATE_COMMENT_MEASURE_REQUEST',
  CREATE_COMMENT_MEASURE_SUCCESS: 'CREATE_COMMENT_MEASURE_SUCCESS',
  CREATE_COMMENT_MEASURE_FAILED: 'CREATE_COMMENT_MEASURE_FAILED',

  GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILED: 'GET_DASHBOARD_FAILED',

  GET_PRODUCTS_DELIVERING_REQUEST: 'GET_PRODUCTS_DELIVERING_REQUEST',
  GET_PRODUCTS_DELIVERING_SUCCESS: 'GET_PRODUCTS_DELIVERING_SUCCESS',
  GET_PRODUCTS_DELIVERING_FAILED: 'GET_PRODUCTS_DELIVERING_FAILED',

  GET_PRODUCTS_DELIVERED_REQUEST: 'GET_PRODUCTS_DELIVERED_REQUEST',
  GET_PRODUCTS_DELIVERED_SUCCESS: 'GET_PRODUCTS_DELIVERED_SUCCESS',
  GET_PRODUCTS_DELIVERED_FAILED: 'GET_PRODUCTS_DELIVERED_FAILED',

  EXPORT_MEASURE_TEMPLATE_REQUEST: 'EXPORT_MEASURE_TEMPLATE_REQUEST',
  EXPORT_MEASURE_TEMPLATE_SUCCESS: 'EXPORT_MEASURE_TEMPLATE_SUCCESS',
  EXPORT_MEASURE_TEMPLATE_FAILED: 'EXPORT_MEASURE_TEMPLATE_FAILED',

  EXPORT_SEW_GENERAL_PRIVATE_REQUEST: 'EXPORT_SEW_GENERAL_PRIVATE_REQUEST',
  EXPORT_SEW_GENERAL_PRIVATE_SUCCESS: 'EXPORT_SEW_GENERAL_PRIVATE_SUCCESS',
  EXPORT_SEW_GENERAL_PRIVATE_FAILED: 'EXPORT_SEW_GENERAL_PRIVATE_FAILED',

  EXPORT_SUGGGEST_REQUEST: 'EXPORT_SUGGGEST_REQUEST',
  EXPORT_SUGGGEST_SUCCESS: 'EXPORT_SUGGGEST_SUCCESS',
  EXPORT_SUGGGEST_FAILED: 'EXPORT_SUGGGEST_FAILED',

  EXPORT_PRODUCTION_COMMAND_REQUEST: 'EXPORT_PRODUCTION_COMMAND_REQUEST',
  EXPORT_PRODUCTION_COMMAND_SUCCESS: 'EXPORT_PRODUCTION_COMMAND_SUCCESS',
  EXPORT_PRODUCTION_COMMAND_FAILED: 'EXPORT_PRODUCTION_COMMAND_FAILED',

  EXPORT_PRODUCT_SEND_CUSTOMER_REQUEST: 'EXPORT_PRODUCT_SEND_CUSTOMER_REQUEST',
  EXPORT_PRODUCT_SEND_CUSTOMER_SUCCESS: 'EXPORT_PRODUCT_SEND_CUSTOMER_SUCCESS',
  EXPORT_PRODUCT_SEND_CUSTOMER_FAILED: 'EXPORT_PRODUCT_SEND_CUSTOMER_FAILED',

  GET_MAX_PRODUCT_REQUEST: 'GET_MAX_PRODUCT_REQUEST',
  GET_MAX_PRODUCT_SUCCESS: 'GET_MAX_PRODUCT_SUCCESS',
  GET_MAX_PRODUCT_FAILED: 'GET_MAX_PRODUCT_FAILED',

  CREATE_CUSTOMER_IN_ORDER_REQUEST: 'CREATE_CUSTOMER_IN_ORDER_REQUEST',
  CREATE_CUSTOMER_IN_ORDER_SUCCESS: 'CREATE_CUSTOMER_IN_ORDER_SUCCESS',
  CREATE_CUSTOMER_IN_ORDER_FAILED: 'CREATE_CUSTOMER_IN_ORDER_FAILED',

  GET_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST:
    'GET_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST',
  GET_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS:
    'GET_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS',
  GET_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED:
    'GET_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED',

  GET_SYNTHETIC_FORWARDING_PROCESSING_REQUEST:
    'GET_SYNTHETIC_FORWARDING_PROCESSING_REQUEST',
  GET_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS:
    'GET_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS',
  GET_SYNTHETIC_FORWARDING_PROCESSING_FAILED:
    'GET_SYNTHETIC_FORWARDING_PROCESSING_FAILED',

  EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST',
  EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS',
  EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED',

  EXPORT_SYNTHETIC_FORWARDING_PROCESSING_REQUEST:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSING_REQUEST',
  EXPORT_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS',
  EXPORT_SYNTHETIC_FORWARDING_PROCESSING_FAILED:
    'EXPORT_SYNTHETIC_FORWARDING_PROCESSING_FAILED',
  // inventory
  GET_SYNTHETIC_INVENTORYS_REQUEST: 'GET_SYNTHETIC_INVENTORYS_REQUEST',
  GET_SYNTHETIC_INVENTORYS_SUCCESS: 'GET_SYNTHETIC_INVENTORYS_SUCCESS',
  GET_SYNTHETIC_INVENTORYS_FAILED: 'GET_SYNTHETIC_INVENTORYS_FAILED',

  GET_SYNTHETIC_INVENTORY_REQUEST: 'GET_SYNTHETIC_INVENTORY_REQUEST',
  GET_SYNTHETIC_INVENTORY_SUCCESS: 'GET_SYNTHETIC_INVENTORY_SUCCESS',
  GET_SYNTHETIC_INVENTORY_FAILED: 'GET_SYNTHETIC_INVENTORY_FAILED',

  EXPORT_SYNTHETIC_INVENTORYS_REQUEST: 'EXPORT_SYNTHETIC_INVENTORYS_REQUEST',
  EXPORT_SYNTHETIC_INVENTORYS_SUCCESS: 'EXPORT_SYNTHETIC_INVENTORYS_SUCCESS',
  EXPORT_SYNTHETIC_INVENTORYS_FAILED: 'EXPORT_SYNTHETIC_INVENTORYS_FAILED',

  EXPORT_SYNTHETIC_INVENTORY_REQUEST: 'EXPORT_SYNTHETIC_INVENTORY_REQUEST',
  EXPORT_SYNTHETIC_INVENTORY_SUCCESS: 'EXPORT_SYNTHETIC_INVENTORY_SUCCESS',
  EXPORT_SYNTHETIC_INVENTORY_FAILED: 'EXPORT_SYNTHETIC_INVENTORY_FAILED',

  // producer
  GET_SYNTHETIC_PRODUCERS_REQUEST: 'GET_SYNTHETIC_PRODUCERS_REQUEST',
  GET_SYNTHETIC_PRODUCERS_SUCCESS: 'GET_SYNTHETIC_PRODUCERS_SUCCESS',
  GET_SYNTHETIC_PRODUCERS_FAILED: 'GET_SYNTHETIC_PRODUCERS_FAILED',

  GET_SYNTHETIC_PRODUCER_REQUEST: 'GET_SYNTHETIC_PRODUCER_REQUEST',
  GET_SYNTHETIC_PRODUCER_SUCCESS: 'GET_SYNTHETIC_PRODUCER_SUCCESS',
  GET_SYNTHETIC_PRODUCER_FAILED: 'GET_SYNTHETIC_PRODUCER_FAILED',

  EXPORT_SYNTHETIC_PRODUCERS_REQUEST: 'EXPORT_SYNTHETIC_PRODUCERS_REQUEST',
  EXPORT_SYNTHETIC_PRODUCERS_SUCCESS: 'EXPORT_SYNTHETIC_PRODUCERS_SUCCESS',
  EXPORT_SYNTHETIC_PRODUCERS_FAILED: 'EXPORT_SYNTHETIC_PRODUCERS_FAILED',

  EXPORT_SYNTHETIC_PRODUCER_REQUEST: 'EXPORT_SYNTHETIC_PRODUCER_REQUEST',
  EXPORT_SYNTHETIC_PRODUCER_SUCCESS: 'EXPORT_SYNTHETIC_PRODUCER_SUCCESS',
  EXPORT_SYNTHETIC_PRODUCER_FAILED: 'EXPORT_SYNTHETIC_PRODUCER_FAILED',

  // complete
  GET_SYNTHETIC_COMPLETES_REQUEST: 'GET_SYNTHETIC_COMPLETES_REQUEST',
  GET_SYNTHETIC_COMPLETES_SUCCESS: 'GET_SYNTHETIC_COMPLETES_SUCCESS',
  GET_SYNTHETIC_COMPLETES_FAILED: 'GET_SYNTHETIC_COMPLETES_FAILED',

  GET_SYNTHETIC_COMPLETE_REQUEST: 'GET_SYNTHETIC_COMPLETE_REQUEST',
  GET_SYNTHETIC_COMPLETE_SUCCESS: 'GET_SYNTHETIC_COMPLETE_SUCCESS',
  GET_SYNTHETIC_COMPLETE_FAILED: 'GET_SYNTHETIC_COMPLETE_FAILED',

  EXPORT_SYNTHETIC_COMPLETES_REQUEST: 'EXPORT_SYNTHETIC_COMPLETES_REQUEST',
  EXPORT_SYNTHETIC_COMPLETES_SUCCESS: 'EXPORT_SYNTHETIC_COMPLETES_SUCCESS',
  EXPORT_SYNTHETIC_COMPLETES_FAILED: 'EXPORT_SYNTHETIC_COMPLETES_FAILED',

  EXPORT_SYNTHETIC_COMPLETE_REQUEST: 'EXPORT_SYNTHETIC_COMPLETE_REQUEST',
  EXPORT_SYNTHETIC_COMPLETE_SUCCESS: 'EXPORT_SYNTHETIC_COMPLETE_SUCCESS',
  EXPORT_SYNTHETIC_COMPLETE_FAILED: 'EXPORT_SYNTHETIC_COMPLETE_FAILED',

  EXPORT_AGGREMENT_PLAN_REQUEST: 'EXPORT_AGGREMENT_PLAN_REQUEST',
  EXPORT_AGGREMENT_PLAN_SUCCESS: 'EXPORT_AGGREMENT_PLAN_SUCCESS',
  EXPORT_AGGREMENT_PLAN_FAILED: 'EXPORT_AGGREMENT_PLAN_FAILED',

  EXPORT_DETAIL_PLAN_REQUEST: 'EXPORT_DETAIL_PLAN_REQUEST',
  EXPORT_DETAIL_PLAN_SUCCESS: 'EXPORT_DETAIL_PLAN_SUCCESS',
  EXPORT_DETAIL_PLAN_FAILED: 'EXPORT_DETAIL_PLAN_FAILED',
  // QcCompleteProductsError
  EXPORT_QC_COMPLETE_PROD_ERROR_REQUEST:
    'EXPORT_QC_COMPLETE_PROD_ERROR_REQUEST',
  EXPORT_QC_COMPLETE_PROD_ERROR_SUCCESS:
    'EXPORT_QC_COMPLETE_PROD_ERROR_SUCCESS',
  EXPORT_QC_COMPLETE_PROD_ERROR_FAILED: 'EXPORT_QC_COMPLETE_PROD_ERROR_FAILED',

  EXPORT_TEMPLATE_ORDER_PRINT_REQUEST: 'EXPORT_TEMPLATE_ORDER_PRINT_REQUEST',
  EXPORT_TEMPLATE_ORDER_PRINT_SUCCESS: 'EXPORT_TEMPLATE_ORDER_PRINT_SUCCESS',
  EXPORT_TEMPLATE_ORDER_PRINT_FAILED: 'EXPORT_TEMPLATE_ORDER_PRINT_FAILED',

  EXPORT_ORDER_DETAIL_REQUEST: 'EXPORT_ORDER_DETAIL_REQUEST',
  EXPORT_ORDER_DETAIL_SUCCESS: 'EXPORT_ORDER_DETAIL_SUCCESS',
  EXPORT_ORDER_DETAIL_FAILED: 'EXPORT_ORDER_DETAIL_FAILED',
};

export default constant;
