import React, { useState, useRef } from 'react';
import { Card, Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Style } from '../style';
import Header from './components/Header';
import TableData from './components/TableData';
import Filter from '../components/Filter';
import { exportActions } from 'Redux/Actions';
import queryString from 'query-string';
import ReactNotificationAlert from 'react-notification-alert';
import { notify } from 'common';
import FileDownload from 'js-file-download';
import _ from 'lodash';
import DialogSelectFieldExport from './components/DialogSelectFieldExport';
const AggregatePlan = ({ location }) => {
  const dispatch = useDispatch();
  const [opentFilter, setOpenFilter] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [filterRequest, setFilterRequest] = useState({});
  const notificationAlertRef = useRef(null);
  const [filterValues, setFilterValues] = useState({
    orderIds: [],
    provinceId: '',
    year: '',
    month: '',
  });
  const [queryFilter, setQueryFilter] = useState({});
  const handleFilter = () => {
    setQueryFilter(filterValues);
  };

  const handleExportAggrementPlan = (selected) => {
    dispatch(
      exportActions.exportAggrementPlan(
        '',
        queryString.stringify(
          {
            fieldNames: selected.map((item) => item.fieldNames),
            fieldVariables: selected.map((item) => item.fieldVariables),
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            const filename = `DANH_SACH_KE_HOACH_TONG_HOP.xlsx`;
            FileDownload(data, filename);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xuất danh sách kế hoạch tổng hợp thành công!`
            );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xuất danh sách kế hoạch tổng hợp thất bại. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  const handleExportDetailPlan = (id) => {
    dispatch(
      exportActions.exportDetailPlan(id, queryString.stringify({}), {
        success: (data) => {
          const filename = `CHI_TIET_KE_HOACH.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất chi tiết kế hoạch thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xuất chi tiết kế hoạch thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Header
        setOpenFilter={setOpenFilter}
        handleExport={() => {
          setFormModal(true);
        }}
        options={filterRequest}
      />
      <DialogSelectFieldExport
        formModal={formModal}
        setFormModal={setFormModal}
        handleExport={handleExportAggrementPlan}
      />
      <Container fluid className="mt--6">
        {opentFilter && (
          <Filter
            opentFilter={opentFilter}
            handleClose={() => setOpenFilter(false)}
            handleFilter={handleFilter}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setQueryFilter={setQueryFilter}
            filterRequest={filterRequest}
          />
        )}
        <Card>
          <TableData
            handleExport={handleExportDetailPlan}
            setOpenFilter={setOpenFilter}
            queryFilter={queryFilter}
            setFilterRequest={setFilterRequest}
            location={location}
          />
        </Card>
      </Container>
    </Style>
  );
};

export default AggregatePlan;
