import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Input } from 'reactstrap';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import {
  customerActions,
  productionCommandActions,
  materialNormActions,
  producerActions,
} from 'Redux/Actions';
import * as yup from 'yup';

import { Formik } from 'formik';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import { Style } from '../style';
import moment from 'moment';
import Error from 'views/pages/components/Error';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';

import { useParams, useHistory } from 'react-router-dom';
import FileDownload from 'js-file-download';
import DialogPrintBarCode from '../components/DialogPrintBarCode';
// import { generateDocxBarcodeForCustomer } from 'common';
import { exportActions } from 'Redux/Actions';

const DetailProductionCommand = ({ location }) => {
  const notificationAlertRef = useRef();
  const dispatch = useDispatch();
  const { productionCommandId } = useParams();
  const history = useHistory();
  const { productionCommandById } = useSelector(
    (state) => state.productionCommandReducer
  );
  const [producerCutValue, setProducerCutValue] = useState(null);
  const [producerProductionValue, setProducerProductionValue] = useState(null);
  const [typePrint, setTypePrint] = useState('general');
  const [sewTypes, setSewTypes] = useState({
    general: false,
    private1: false,
    private2: false,
    private3: false,
    private4: false,
  });
  const [customersInOrderGeneral, setCustomersInOrderGeneral] = useState({
    results: [],
  });
  const [customersInOrderPrivate, setCustomersInOrderPrivate] = useState({
    results: [],
  });

  const [isExportCustomersInOrderGeneral, setIsExportCustomersInOrderGeneral] =
    useState(false);

  const [isExportCustomersInOrderPrivate, setIsExportCustomersInOrderPrivate] =
    useState(false);

  // schema
  const productionCommandSchema = yup.object().shape({
    code: yup.string().required('Số lệnh không được để trống!'),
    name: yup.string().required('Tên lệnh SX không được để trống!'),
    orderId: yup.string().required('Vui lòng chọn đơn hàng!'),
    productId: yup.string().required('Vui lòng chọn sản phẩm!'),
    producerId: yup.string().required('Vui lòng chọn nhà SX gia công!'),
  });

  const [sew, setSew] = useState({ general: true, private: true });
  // const [productionCommandId, setProductionCommandId] = useState("");
  const [errorCustomer, setErrorCustomer] = useState(false);

  const [
    getCustomerInOrderSucuessGeneral,
    setGetCustomerInOrderSucuessGeneral,
  ] = useState(false);
  const [
    getCustomerInOrderSucuessPrivate,
    setGetCustomerInOrderSucuessPrivate,
  ] = useState(false);
  // value select
  const [orderValue, setOrderValue] = useState(null);
  const [productValue, setProductValue] = useState(null);
  const [producerValue, setProducerValue] = useState(null);
  const [customerValues, setCustomerValues] = useState(null);
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [dataBarcode, setDataBarcode] = useState([]);
  const [nameFile, setNameFile] = useState('');

  const [calledGetCustomerInOrderGeneral, setCalledGetCustomerInOrderGeneral] =
    useState(false);
  const [calledGetCustomerInOrderPrivate, setCalledGetCustomerInOrderPrivate] =
    useState(false);

  const [valueFilterGeneral, setValueFilterGeneral] = useState({
    customerCode: '',
    customerOrgName: '',
  });
  const [valueFilterPrivate, setValueFilterPrivate] = useState({
    customerCode: '',
    customerOrgName: '',
  });

  const [productionCommandInfo, setProductionCommandInfo] = useState({
    code: '',
    name: '',
    orderId: '',
    productId: '',
    producerCutId: '',
    producerProductionId: '',
    customerOrgIds: [],
    productionCommanDate: new Date().toISOString(),
    productSyncDate: new Date().toISOString(),
    embroiderDate: new Date().toISOString(),
    warehouseReceipDate: new Date().toISOString(),
    productReceiveDate: new Date().toISOString(),
    produceCutDate: new Date().toISOString(),
    qcDate: new Date().toISOString(),
    qcCustomerDate: new Date().toISOString(),
    notes: '',
    sewTypes: [],
    productionInfos: [],
  });
  // page
  const [pageCusomersInOrder, setPageCusomersInOrder] = useState(1);
  const [rowsPerCustomersInOrder, setRowsPerPageCusomerInOrder] = useState(10);
  const [pageCusomersInOrderGeneral, setPageCusomersInOrderGeneral] =
    useState(1);
  const [rowsPerCustomersInOrderGeneral, setRowsPerPageCusomerInOrderGeneral] =
    useState(10);
  const [pageCusomersInOrderPrivate, setPageCusomersInOrderPrivate] =
    useState(1);
  const [rowsPerCustomersInOrderPrivate, setRowsPerPageCusomerInOrderPrivate] =
    useState(10);

  // search
  const [productSearch, setProductSearch] = useState('');
  const [orderSearch, setOrderSearch] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [customerSearch, setCustomerSearch] = useState('');
  // const [allChildOfCustomer, setAllChildOfCustomer] = useState({ results: [] });

  // query
  const [queryOrder, setQueryOrder] = useState({
    limit: 10,
    page: 1,
  });

  const [queryProducer, setQueryProducer] = useState({
    limit: 10,
    page: 1,
  });
  const [customerQuery, setCustomerQuery] = useState({
    limit: 10,
    page: 1,
  });
  const [queryCustomersInOrder, setQueryCustomersInOrder] = useState({
    page: pageCusomersInOrder,
    limit: rowsPerCustomersInOrder,
    populate:
      'productTypeId, customerOrgId, customerSizeId, standardSizeId,customerOrgId.provinceId,sizes.productParameterId',
  });

  const [queryCustomersInOrderGeneral, setQueryCustomersInOrderGeneral] =
    useState({
      page: pageCusomersInOrderGeneral,
      limit: rowsPerCustomersInOrderGeneral,
      sortBy: 'customerName:asc',
      populate:
        'productTypeId, customerOrgId, customerSizeId, standardSizeId,customerOrgId.provinceId,sizes.productParameterId',
    });
  const [queryCustomersInOrderPrivate, setQueryCustomersInOrderPrivate] =
    useState({
      page: pageCusomersInOrderPrivate,
      limit: rowsPerCustomersInOrderPrivate,
      sortBy: 'customerName:asc',
      populate:
        'productTypeId, customerOrgId, customerSizeId, standardSizeId,customerOrgId.provinceId,sizes.productParameterId',
    });

  const columnsGeneral = [
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterGeneral.customerCode}
            onChange={(e) => {
              setValueFilterGeneral({
                ...valueFilterGeneral,
                customerCode: e.target.value,
              });
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Họ và tên',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterGeneral.customerOrgName}
            onChange={(e) => {
              setValueFilterGeneral({
                ...valueFilterGeneral,
                customerOrgName: e.target.value,
              });
            }}
            placeholder="Phòng ban"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productTypeId.code',
      text: 'Mã SP KH',
    },
    {
      dataField: 'barcode',
      text: 'Mã barcode',
    },
    {
      dataField: 'quota',
      text: 'Số lượng SX',
      headerStyle: {
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'standardSizeId.name',
      text: 'Size',
    },
    {
      dataField: 'customerSizeId.wearType',
      text: 'Mặc',
    },
    // {
    //   dataField: "customerSizeId.notes",
    //   text: "Ghi chú",
    //   headerStyle: {
    //     textAlign: "center",
    //   },
    //   style: {
    //     textAlign: "left",
    //   },
    // },
  ];
  const columnsPrivate = [
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterPrivate.customerCode}
            onChange={(e) => {
              setValueFilterPrivate({
                ...valueFilterPrivate,
                customerCode: e.target.value,
              });
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Họ và tên',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilterPrivate.customerOrgName}
            onChange={(e) => {
              setValueFilterPrivate({
                ...valueFilterPrivate,
                customerOrgName: e.target.value,
              });
            }}
            placeholder="Phòng ban"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productTypeId.code',
      text: 'Mã SP KH',
    },
    {
      dataField: 'barcode',
      text: 'Mã barcode',
    },
    {
      dataField: 'quota',
      text: 'Số lượng SX',
      headerStyle: {
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'standardSizeId.name',
      text: 'Size',
    },
    {
      dataField: 'customerSizeId.wearType',
      text: 'Mặc',
    },
    // {
    //   dataField: "customerSizeId.notes",
    //   text: "Ghi chú",
    //   headerStyle: {
    //     textAlign: "center",
    //   },
    //   style: {
    //     textAlign: "left",
    //   },
    // },
  ];

  const onSizePerPageChangeGeneral = (value) => {
    // setValueFilterGeneral({
    //   customerCode: "",
    //   customerOrgName: "",
    // });
    setRowsPerPageCusomerInOrderGeneral(value);
    setPageCusomersInOrderGeneral(1);

    setQueryCustomersInOrderGeneral({
      ...queryCustomersInOrderGeneral,
      page: 1,
      limit: value,
    });
  };

  const onSizePerPageChangePrivate = (value) => {
    // setValueFilterPrivate({
    //   customerCode: "",
    //   customerOrgName: "",
    // });
    setRowsPerPageCusomerInOrderPrivate(value);
    setPageCusomersInOrderPrivate(1);

    setQueryCustomersInOrderPrivate({
      ...queryCustomersInOrderPrivate,
      page: 1,
      limit: value,
    });
  };

  const paginationCustomersInOrderGeneral = paginationFactory({
    page: pageCusomersInOrderGeneral,
    onPageChange: (value) => {
      // setValueFilterGeneral({
      //   customerCode: "",
      //   customerOrgName: "",
      // });
      setPageCusomersInOrderGeneral(value);
      setQueryCustomersInOrderGeneral({
        ...queryCustomersInOrderGeneral,
        page: value,
      });
    },
    sizePerPage: rowsPerCustomersInOrderGeneral,
    totalSize: !isNaN(customersInOrderGeneral?.totalResults)
      ? customersInOrderGeneral.totalResults
      : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerCustomersInOrderGeneral}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChangeGeneral(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ{' '}
            {(pageCusomersInOrderGeneral - 1) * rowsPerCustomersInOrderGeneral +
              1}{' '}
            đến{' '}
            {pageCusomersInOrderGeneral * rowsPerCustomersInOrderGeneral >
            customersInOrderGeneral.totalResults
              ? !isNaN(customersInOrderGeneral?.totalResults)
                ? customersInOrderGeneral.totalResults
                : 0
              : pageCusomersInOrderGeneral *
                rowsPerCustomersInOrderGeneral}{' '}
            trong số{' '}
            {!isNaN(customersInOrderGeneral?.totalResults)
              ? customersInOrderGeneral.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const paginationCustomersInOrderPrivate = paginationFactory({
    page: pageCusomersInOrderPrivate,
    onPageChange: (value) => {
      // setValueFilterPrivate({
      //   customerCode: "",
      //   customerOrgName: "",
      // });
      setPageCusomersInOrderPrivate(value);
      setQueryCustomersInOrderPrivate({
        ...queryCustomersInOrderPrivate,
        page: value,
      });
    },
    sizePerPage: rowsPerCustomersInOrderPrivate,
    totalSize: !isNaN(customersInOrderPrivate?.totalResults)
      ? customersInOrderPrivate.totalResults
      : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerCustomersInOrderPrivate}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChangePrivate(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ{' '}
            {(pageCusomersInOrderPrivate - 1) * rowsPerCustomersInOrderPrivate +
              1}{' '}
            đến{' '}
            {pageCusomersInOrderPrivate * rowsPerCustomersInOrderPrivate >
            customersInOrderPrivate.totalResults
              ? !isNaN(customersInOrderPrivate?.totalResults)
                ? customersInOrderPrivate.totalResults
                : 0
              : pageCusomersInOrderPrivate *
                rowsPerCustomersInOrderPrivate}{' '}
            trong số{' '}
            {!isNaN(customersInOrderPrivate?.totalResults)
              ? customersInOrderPrivate.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  // handle get
  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(orderActions.getOrders(queryString.stringify(queryOrder)));
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrder, name: orderSearch })
        )
      );
    }
  };

  const handleGetAllProductInOrder = () => {
    if (!_.isEmpty(orderValue)) {
      if (productSearch) {
        dispatch(
          materialNormActions.getAllProductInOrder(
            orderValue.value,
            queryString.stringify({ sortBy: 'name' })
          )
        );
      } else {
        dispatch(
          materialNormActions.getAllProductInOrder(
            orderValue.value,
            queryString.stringify({ sortBy: 'name', name: productSearch })
          )
        );
      }
    }
  };
  const handleExportProductionCommand = () => {
    // const id =
    dispatch(
      exportActions.exportProductionCommand(productionCommandId, '', {
        success: (data) => {
          const filename = `CHI_TIET_LENH_SAN_XUAT_${productionCommandById.name.toUpperCase()}.xlsx`;
          FileDownload(data, filename);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xuất phiếu lệnh sản xuất thành công!`
          );
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xuất phiếu lệnh sản xuất thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };
  const handleGetProducers = () => {
    if (producerSearch === '') {
      dispatch(
        producerActions.getProducers(queryString.stringify(queryProducer))
      );
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ ...queryProducer, name: producerSearch })
        )
      );
    }
  };

  const handleGetCustomers = () => {
    if (customerSearch === '') {
      dispatch(
        customerActions.getCustomers(queryString.stringify(customerQuery))
      );
    } else {
      dispatch(
        customerActions.getCustomers(
          queryString.stringify({ ...customerQuery, name: customerSearch })
        )
      );
    }
  };

  const handleGetCustomersInOrderGeneral = () => {
    const tempQuerySearch = _.cloneDeep(valueFilterGeneral);
    if (tempQuerySearch.customerCode === '') {
      delete tempQuerySearch.customerCode;
    }
    if (tempQuerySearch.customerOrgName === '') {
      delete tempQuerySearch.customerOrgName;
    }
    if (_.isEmpty(productionCommandId) || !sewTypes.general) {
      setCustomersInOrderGeneral({ results: [] });
      return;
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderGeneral,
            productionCommandId: productionCommandId,
            sewTypes: [-1],
            ...tempQuerySearch,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setCalledGetCustomerInOrderGeneral(true);
            setCustomersInOrderGeneral(data);
            setGetCustomerInOrderSucuessGeneral(true);
          },
          failed: () => {
            setCustomersInOrderGeneral({ results: [] });
            setGetCustomerInOrderSucuessGeneral(false);
          },
        }
      )
    );
  };

  const handleGetCustomersInOrderPrivate = () => {
    const tempQuerySearch = _.cloneDeep(valueFilterPrivate);
    if (tempQuerySearch.customerCode === '') {
      delete tempQuerySearch.customerCode;
    }
    if (tempQuerySearch.customerOrgName === '') {
      delete tempQuerySearch.customerOrgName;
    }
    const tempSewTypes = [];
    if (
      _.isEmpty(productionCommandId) ||
      (!sewTypes.private1 &&
        !sewTypes.private2 &&
        !sewTypes.private3 &&
        !sewTypes.private4)
    ) {
      setCustomersInOrderPrivate({ results: [] });
      return;
    } else if (sewTypes.private1) {
      tempSewTypes.push(1);
    } else if (sewTypes.private2) {
      tempSewTypes.push(2);
    } else if (sewTypes.private3) {
      tempSewTypes.push(3);
    } else if (sewTypes.private4) {
      tempSewTypes.push(0);
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderPrivate,
            productionCommandId: productionCommandId,
            sewTypes: tempSewTypes,
            ...tempQuerySearch,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setCalledGetCustomerInOrderPrivate(true);
            setCustomersInOrderPrivate(data);
            setGetCustomerInOrderSucuessPrivate(true);
          },
          failed: () => {
            setCustomersInOrderPrivate({ results: [] });
            setGetCustomerInOrderSucuessPrivate(false);
          },
        }
      )
    );
  };

  const handleGetProductionCommandById = () => {
    if (!_.isEmpty(productionCommandId)) {
      dispatch(
        productionCommandActions.getProductionCommandById(
          productionCommandId,
          queryString.stringify({
            populate:
              'orderId.customerOrganizationId,size.productParameterId, customerOrgIds, productId, producerCutId, producerProductionId, productionInfos.standardSizeId, orderPlanId,customerOrgId.provinceId,sizes.productParameterId',
          })
        )
      );
    }
  };

  const handleGetAllChildOfCustomer = () => {
    if (!_.isEmpty(orderValue?.customerOrganizationId)) {
      if (customerSearch === '') {
        dispatch(
          customerActions.getAllChildOfCustomer(
            orderValue?.customerOrganizationId,
            queryString.stringify({})
          )
        );
      } else {
        dispatch(
          customerActions.getAllChildOfCustomer(
            orderValue?.customerOrganizationId,
            queryString.stringify({ name: customerSearch })
          )
        );
      }
    }
  };

  const handleExportCustomersInOrderGeneral = (limit) => {
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderGeneral,
            productionCommandId: productionCommandId,
            limit: limit,
            sewTypes: [-1],
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setGetCustomerInOrderSucuessGeneral(true);
            setNameFile(
              'Danh_SachKH_So_Do_Chung_' +
                moment().format('DD-MM-YYYY_hh-mm-ss')
            );
            setDataBarcode(data.results);
            setIsOpenReview(true);
            // generateDocxBarcodeForCustomer(
            // 	data.results,
            // 	"Danh_SachKH_So_Do_Chung_" +
            // 		moment().format("DD-MM-YYYY_hh-mm-ss")
            // );
            // notify(
            //   notificationAlertRef,
            //   'success',
            //   'Thông báo',
            //   `Tải mã barcode số đo chung thành công!`
            // );
          },
          failed: (mess) => {
            setGetCustomerInOrderSucuessGeneral(false);
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tải mã barcode số đo chung thất bại. Lỗi: ${mess}`
            );
          },
        }
      )
    );
  };

  const handleExportCustomersInOrderPrivate = (limit) => {
    const tempSewTypes = [];
    if (
      _.isEmpty(productionCommandId) ||
      (!sewTypes.private1 &&
        !sewTypes.private2 &&
        !sewTypes.private3 &&
        !sewTypes.private4)
    ) {
      setCustomersInOrderPrivate({ results: [] });
      return;
    } else if (sewTypes.private1) {
      tempSewTypes.push(1);
    } else if (sewTypes.private2) {
      tempSewTypes.push(2);
    } else if (sewTypes.private3) {
      tempSewTypes.push(3);
    } else if (sewTypes.private4) {
      tempSewTypes.push(0);
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify(
          {
            ...queryCustomersInOrderPrivate,
            productionCommandId: productionCommandId,
            limit: limit,
            sewTypes: tempSewTypes,
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setGetCustomerInOrderSucuessPrivate(true);
            setNameFile(
              'Danh_SachKH_So_Do_Rieng_' +
                moment().format('DD-MM-YYYY_hh-mm-ss')
            );
            // generateDocxBarcodeForCustomer(
            // 	data.results,
            // 	"Danh_SachKH_So_Do_Rieng_" +
            // 		moment().format("DD-MM-YYYY_hh-mm-ss")
            // );
            setDataBarcode(data.results);
            setIsOpenReview(true);
            // notify(
            //   notificationAlertRef,
            //   'success',
            //   'Thông báo',
            //   `Tải mã barcode số đo riêng thành công!`
            // );
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tải mã barcode số đo riêng thất bại. Lỗi: ${mess}`
            );
            setGetCustomerInOrderSucuessPrivate(false);
          },
        }
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      calledGetCustomerInOrderGeneral && handleGetCustomersInOrderGeneral();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [valueFilterGeneral]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      calledGetCustomerInOrderPrivate && handleGetCustomersInOrderPrivate();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [valueFilterPrivate]);

  // useEffect
  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);

  useEffect(() => {
    handleGetAllProductInOrder();
  }, [productSearch, orderValue]);

  useEffect(() => {
    handleGetProducers();
  }, [producerSearch]);

  useEffect(() => {
    handleGetCustomers();
  }, [customerSearch]);

  useEffect(() => {
    handleGetAllChildOfCustomer();
  }, [orderValue]);

  useEffect(() => {
    setGetCustomerInOrderSucuessGeneral(false);
    handleGetCustomersInOrderGeneral();
  }, [queryCustomersInOrderGeneral, sewTypes]);
  useEffect(() => {
    setGetCustomerInOrderSucuessPrivate(false);
    handleGetCustomersInOrderPrivate();
  }, [queryCustomersInOrderPrivate, sewTypes]);

  useEffect(() => {
    handleGetProductionCommandById();
  }, [productionCommandId]);

  useEffect(() => {
    if (!_.isEmpty(productionCommandById)) {
      setProductionCommandInfo({
        code: productionCommandById?.code,
        name: productionCommandById?.name,
        orderId: productionCommandById?.orderId?.id,
        productId: productionCommandById?.productId?.id,
        producerCutId: productionCommandById?.producerCutId?.id,
        producerProductionId: productionCommandById?.producerProductionId?.id,
        customerOrgIds: productionCommandById?.customerOrgIds?.map(
          (item) => item.id
        ),
        productionCommanDate: productionCommandById?.productionCommanDate,
        productSyncDate: productionCommandById?.productSyncDate,
        embroiderDate: productionCommandById?.embroiderDate,
        warehouseReceipDate: productionCommandById?.warehouseReceipDate,
        productReceiveDate: productionCommandById?.productReceiveDate,
        produceCutDate: productionCommandById?.produceCutDate,
        qcDate: productionCommandById?.qcDate,
        qcCustomerDate: productionCommandById?.qcCustomerDate,
        notes: productionCommandById?.notes,
        productionInfos: productionCommandById.productionInfos,
      });

      if (!_.isEmpty(productionCommandById?.sewTypes)) {
        setSewTypes({
          general: productionCommandById?.sewTypes.includes(-1) ? true : false,
          private1: productionCommandById?.sewTypes.includes(1) ? true : false,
          private2: productionCommandById?.sewTypes.includes(2) ? true : false,
          private3: productionCommandById?.sewTypes.includes(3) ? true : false,
          private4: productionCommandById?.sewTypes.includes(0) ? true : false,
        });
      }
      if (!_.isEmpty(productionCommandById?.producerProductionId)) {
        setProducerProductionValue({
          label: productionCommandById?.producerProductionId?.name,
          value: productionCommandById?.producerProductionId?.id,
        });
      }
      if (!_.isEmpty(productionCommandById?.producerCutId)) {
        setProducerCutValue({
          label: productionCommandById?.producerCutId?.name,
          value: productionCommandById?.producerCutId?.id,
        });
      }
      setOrderValue({
        label: productionCommandById?.orderId?.name,
        value: productionCommandById?.orderId?.id,
        customerOrganizationId:
          productionCommandById?.orderId?.customerOrganizationId?.id,
        customerOrganizationName:
          productionCommandById?.orderId?.customerOrganizationId?.name,
      });
      setProductValue({
        label: productionCommandById?.productId?.name,
        value: productionCommandById?.productId?.id,
      });
      setProducerValue({
        label: productionCommandById?.producerId?.name,
        value: productionCommandById?.producerId?.id,
      });
      setCustomerValues(
        productionCommandById?.customerOrgIds?.map((item) => ({
          value: item.id,
          label: `${item.code}-${item.name}`,
        }))
      );
      // handleGetCustomersInOrderGeneral();
      // handleGetCustomersInOrderPrivate();
    }
  }, [productionCommandById]);

  const onSubmit = (values, actions) => {};

  const handleExportCustomersInOrder = (type = 'general') => {
    if (_.isEmpty(productionCommandId)) return;
    dispatch(
      orderActions.exportCustomersInOrder(
        {},
        productionCommandId,
        queryString.stringify({
          customerInOrderSizeStatus: type === 'general' ? 2 : 3,
        }),
        {
          success: (data) => {
            const filename = `DANH_SACH_KH_MAY_DO_${
              type === 'general' ? 'CHUNG' : 'RIENG'
            }.xlsx`;
            type === 'general'
              ? setIsExportCustomersInOrderGeneral(false)
              : setIsExportCustomersInOrderPrivate(false);
            FileDownload(data, filename);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xuất báo cáo khách hàng may đo ${
                type === 'general' ? 'chung' : 'riêng'
              } thành công!`
            );
          },
          failed: (mess) => {
            type === 'general'
              ? setIsExportCustomersInOrderGeneral(false)
              : setIsExportCustomersInOrderPrivate(false);
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xuất báo cáo khách hàng may đo ${
                type === 'general' ? 'chung' : 'riêng'
              } thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <Style>
      {isOpenReview && (
        <DialogPrintBarCode
          formModal={isOpenReview}
          setFormModal={setIsOpenReview}
          data={dataBarcode}
          nameFile={nameFile}
          sewTypes={sewTypes}
          typePrint={typePrint}
          productionCommandId={productionCommandId}
          totalResults={
            typePrint === 'general'
              ? customersInOrderGeneral?.totalResults
              : customersInOrderPrivate?.totalResults
          }
        />
      )}
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Chi tiết lệnh sản xuất
                </h6>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  // size="sm"
                  onClick={() => {
                    history.push(location.state.route, { ...location.state });
                  }}
                >
                  Quay lại
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid className="mt--6">
        <Formik
          initialValues={productionCommandInfo}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={productionCommandSchema}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
          }) => {
            return (
              <>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6" style={{ borderRight: '1px solid #DADADA' }}>
                        <Row className="mb-2">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Số lệnh
                            </p>
                          </Col>
                          <Col md="9">: {values.code}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Tên lệnh SX
                            </p>
                          </Col>
                          <Col md="9">: {values.name}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Tên đơn hàng
                            </p>
                          </Col>
                          <Col md="9">: {orderValue?.label}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Kế hoạch SX
                            </p>
                          </Col>
                          <Col md="9">
                            : {productionCommandById?.orderPlanId?.name}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Sản phẩm
                            </p>
                          </Col>
                          <Col md="9">: {productValue?.label}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Nhà gia công may
                            </p>
                          </Col>
                          <Col md="9">: {producerProductionValue?.label}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-center" md="3">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              Nhà gia công cắt
                            </p>
                          </Col>
                          <Col md="9">: {producerCutValue?.label}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={4}
                          >
                            <input
                              id="save-general"
                              type="checkbox"
                              checked={sewTypes.general}
                              onChange={(e) => {}}
                            />
                            <h5 className="mb-0 ml-2">Chọn may số đo chung</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private1}
                              onChange={(e) => {}}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 1</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private2}
                              onChange={(e) => {}}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 2</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private3}
                              onChange={(e) => {}}
                            />
                            <h5 className="mb-0 ml-2">May riêng VIP 3</h5>
                          </Col>
                          <Col
                            className="d-flex justify-content-center align-items-center"
                            md={2}
                          >
                            <input
                              id="save-private"
                              type="checkbox"
                              checked={sewTypes.private4}
                              onChange={(e) => {}}
                            />
                            <h5 className="mb-0 ml-2">May riêng khác</h5>
                          </Col>
                          <Col md="12">
                            {errors.sewTypes && (
                              <Error messageInvalid={errors.sewTypes} />
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-start" md="3">
                            <p
                              style={{ whiteSpace: 'nowrap' }}
                              className="text-sm font-weight-500 h3 mb-0"
                            >
                              Chi nhánh-Phòng ban
                            </p>
                          </Col>
                          <Col md="9">
                            :{' '}
                            {!_.isEmpty(customerValues) &&
                              customerValues.map((item, index) =>
                                index === customerValues.length - 1
                                  ? `${item.label}.`
                                  : `${item.label};  `
                              )}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="d-flex align-items-start" md="3">
                            <p
                              style={{ whiteSpace: 'nowrap' }}
                              className="text-sm font-weight-500 h3 mb-0"
                            >
                              Kích thức-Số lượng
                            </p>
                          </Col>
                          <Col md="9">
                            :{' '}
                            {!_.isEmpty(values.productionInfos) &&
                              values.productionInfos.map((item, index) =>
                                index === values.productionInfos.length - 1
                                  ? `${item.standardSizeId.name}(${item.amount}).`
                                  : `${item.standardSizeId.name}(${item.amount}),  `
                              )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            {errorCustomer && (
                              <Error messageInvalid="Vui lòng nhập ít nhất một Chi nhánh/phòng ban!" />
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col className="mb-3" md="12">
                            <h5 className="fullcalendar-title text-muted text-uppercase d-inline-block mb-0">
                              Thiết lập thời gian sản xuất
                            </h5>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              1. Ngày cấp lệnh NPL:&emsp;
                              <span className="font-weight-400">
                                {moment(values.productionCommanDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              2. Ngày đồng bộ NPL:&emsp;
                              <span className="font-weight-400">
                                {moment(values.productSyncDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              3. Ngày cắt SX:&emsp;
                              <span className="font-weight-400">
                                {moment(values.produceCutDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>

                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              4. Ngày thêu:&emsp;
                              <span className="font-weight-400">
                                {moment(values.embroiderDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              5. Ngày QC KH:&emsp;
                              <span className="font-weight-400">
                                {moment(values.qcCustomerDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              6. Ngày nhận sản phẩm:&emsp;
                              <span className="font-weight-400">
                                {moment(values.productReceiveDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              7. Ngày giao QC:&emsp;
                              <span className="font-weight-400">
                                {moment(values.qcDate).format('DD/MM/YYYY')}
                              </span>
                            </p>
                          </Col>
                          <Col md="6">
                            <p className="text-sm font-weight-500 h3 mb-0">
                              8. Ngày NK KH:&emsp;
                              <span className="font-weight-400">
                                {moment(values.warehouseReceipDate).format(
                                  'DD/MM/YYYY'
                                )}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex" md="12">
                            <label className="form-control-label">
                              Những lưu ý khi sản xuất/ KSC:
                            </label>
                            {values.notes}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12" className="d-flex justify-content-end">
                        <LoadingButtonCustom
                          outline={true}
                          size="sm"
                          onClick={() => {
                            if (productionCommandById) {
                              handleExportProductionCommand();
                            }
                          }}
                          loading={false}
                          style={{ border: '1px solid #4385b1' }}
                        >
                          Xuất phiếu lệnh sản xuất
                        </LoadingButtonCustom>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ borderRight: '1px solid #DADADA' }} md="6">
                        <Row>
                          <Col md="12">
                            <h4>Thống kê tổng hợp may số đo chung</h4>
                          </Col>
                          {!_.isEmpty(
                            _.get(
                              productionCommandById,
                              'productionCommandInfos',
                              []
                            ).filter((item) => item.sewType === -1)
                          ) ? (
                            productionCommandById.productionCommandInfos
                              .filter((item) => item.sewType === -1)
                              .map((item) => (
                                <>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-end"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      Sản phẩm:
                                    </p>
                                  </Col>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-start"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      {productionCommandById?.productId?.name} (
                                      <b>size</b>: {item?.standardSizeName}) (
                                      <b>SL</b>: {item?.totalProduct})
                                    </p>
                                  </Col>
                                </>
                              ))
                          ) : (
                            <Col
                              md="12"
                              className="d-flex justify-content-cênter"
                            >
                              Không có sản phẩm may chung!
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col md="12">
                            <h4>Thống kê tổng hợp may số đo riêng</h4>
                          </Col>
                          {!_.isEmpty(
                            _.get(
                              productionCommandById,
                              'productionCommandInfos',
                              []
                            ).filter((item) => item.sewType !== -1)
                          ) ? (
                            productionCommandById.productionCommandInfos
                              .filter((item) => item.sewType !== -1)
                              .map((item) => (
                                <>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-end"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      Sản phẩm:
                                    </p>
                                  </Col>
                                  <Col
                                    md="6"
                                    className="d-flex justify-content-start"
                                  >
                                    <p className="h3 text-muted text-sm font-weight-500">
                                      {productionCommandById?.productId?.name} (
                                      <b>size</b>: {item?.standardSizeName}) (
                                      <b>SL</b>: {item?.totalProduct})
                                    </p>
                                  </Col>
                                </>
                              ))
                          ) : (
                            <Col
                              md="12"
                              className="d-flex justify-content-center"
                            >
                              Không có sản phẩm may riêng!
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Row className="mb-2">
                          <Col md="6">
                            <h4>Danh sách khách hàng may số đo chung</h4>
                          </Col>
                          <Col className="d-flex justify-content-end" md="6">
                            <LoadingButtonCustom
                              outline={true}
                              size="sm"
                              onClick={() => {
                                setTypePrint('general');
                                setIsOpenReview(true);
                                // handleExportCustomersInOrderGeneral(50);
                              }}
                              loading={false}
                              style={{ border: '1px solid #4385b1' }}
                            >
                              In tem mẫu
                            </LoadingButtonCustom>
                            {customersInOrderGeneral.results.length > 0 && (
                              <LoadingButtonCustom
                                outline={true}
                                size="sm"
                                onClick={() => {
                                  setIsExportCustomersInOrderGeneral(true);
                                  handleExportCustomersInOrder('general');
                                }}
                                loading={isExportCustomersInOrderGeneral}
                                style={{ border: '1px solid #4385b1' }}
                              >
                                Xuất danh sách
                              </LoadingButtonCustom>
                            )}
                          </Col>
                        </Row>
                        <ToolkitProvider
                          data={
                            sewTypes.general
                              ? customersInOrderGeneral.results
                              : []
                          }
                          keyField="id"
                          columns={columnsGeneral}
                          bootstrap4={true}
                          search
                        >
                          {(props) => (
                            <>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={() => {
                                  return (
                                    <span className="font-weight-bold text-danger">
                                      Không có dữ liệu!
                                    </span>
                                  );
                                }}
                                onTableChange={() => {
                                  return (
                                    <div className="spinner-border text-info" />
                                  );
                                }}
                                filter={filterFactory()}
                                pagination={paginationCustomersInOrderGeneral}
                                // selectRow={selectRowGeneral}
                                bordered={false}
                                hover
                                remote
                              />
                            </>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Row className="mb-2">
                          <Col md="6">
                            <h4>Danh sách khách hàng may số đo riêng</h4>
                          </Col>
                          <Col className="d-flex justify-content-end" md="6">
                            <LoadingButtonCustom
                              outline={true}
                              size="sm"
                              onClick={() => {
                                setTypePrint('private');
                                setIsOpenReview(true);
                                // handleExportCustomersInOrderPrivate(50);
                              }}
                              loading={false}
                              style={{ border: '1px solid #4385b1' }}
                            >
                              In tem mẫu
                            </LoadingButtonCustom>
                            {customersInOrderPrivate.results.length > 0 && (
                              <LoadingButtonCustom
                                outline={true}
                                size="sm"
                                onClick={() => {
                                  setIsExportCustomersInOrderPrivate(true);
                                  handleExportCustomersInOrder('private');
                                }}
                                loading={isExportCustomersInOrderPrivate}
                                style={{ border: '1px solid #4385b1' }}
                              >
                                Xuất danh sách
                              </LoadingButtonCustom>
                            )}
                          </Col>
                        </Row>
                        <ToolkitProvider
                          data={
                            sewTypes.private1 ||
                            sewTypes.private2 ||
                            sewTypes.private3 ||
                            sewTypes.private4
                              ? customersInOrderPrivate.results
                              : []
                          }
                          keyField="id"
                          columns={columnsPrivate}
                          bootstrap4={true}
                          search
                        >
                          {(props) => (
                            <>
                              <BootstrapTable
                                {...props.baseProps}
                                noDataIndication={() => {
                                  return (
                                    <span className="font-weight-bold text-danger">
                                      Không có dữ liệu!
                                    </span>
                                  );
                                }}
                                onTableChange={() => {
                                  return (
                                    <div className="spinner-border text-info" />
                                  );
                                }}
                                filter={filterFactory()}
                                pagination={paginationCustomersInOrderPrivate}
                                // selectRow={selectRowPrivate}
                                bordered={false}
                                hover
                                remote
                              />
                            </>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            );
          }}
        </Formik>
      </Container>
    </Style>
  );
};

export default DetailProductionCommand;
