import TypeActions from "../TypeActions";

export const getSyntheticForwardingProcessings = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST,
		query,
		callback,
	};
};
export const getSyntheticForwardingProcessing = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticForwardingProcessings = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticForwardingProcessing = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_REQUEST,
		query,
		callback,
	};
};

// inventory
export const getSyntheticInventorys = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_INVENTORYS_REQUEST,
		query,
		callback,
	};
};
export const getSyntheticInventory = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_INVENTORY_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticInventorys = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_INVENTORYS_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticInventory = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_INVENTORY_REQUEST,
		query,
		callback,
	};
};

// producers
export const getSyntheticProducers = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_PRODUCERS_REQUEST,
		query,
		callback,
	};
};
export const getSyntheticProducer = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_PRODUCER_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticProducers = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_PRODUCERS_REQUEST,
		query,
		callback,
	};
};
export const exportSyntheticProducer = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_PRODUCER_REQUEST,
		query,
		callback,
	};
};

// complete

export const getSyntheticCompletes = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_COMPLETES_REQUEST,
		query,
		callback,
	};
};
export const getSyntheticComplete = (query, callback) => {
	return {
		type: TypeActions.GET_SYNTHETIC_COMPLETE_REQUEST,

		query,
		callback,
	};
};

export const exportSyntheticCompletes = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_COMPLETES_REQUEST,
		query,
		callback,
	};
};

export const exportSyntheticComplete = (query, callback) => {
	return {
		type: TypeActions.EXPORT_SYNTHETIC_COMPLETE_REQUEST,
		query,
		callback,
	};
};

export default {
	getSyntheticForwardingProcessings,
	getSyntheticForwardingProcessing,
	exportSyntheticForwardingProcessings,
	exportSyntheticForwardingProcessing,
	getSyntheticInventorys,
	getSyntheticInventory,
	exportSyntheticInventorys,
	exportSyntheticInventory,
	getSyntheticProducers,
	getSyntheticProducer,
	exportSyntheticProducers,
	getSyntheticCompletes,
	getSyntheticComplete,
	exportSyntheticCompletes,
	exportSyntheticComplete,
};
