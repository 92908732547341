import React, { useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardBody,
  Button,
  Col,
  Row,
} from 'reactstrap';
import _ from 'lodash';

import CONSTANT from 'constant';

const DialogSelectFieldExport = ({ formModal, setFormModal, handleExport }) => {
  const [selected, setSelected] = useState([]);
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={formModal}
      toggle={() => setFormModal(false)}
    >
      <div className="modal-body p-0">
        <Card className="bg-white border-0 mb-0">
          <CardHeader className="bg-transparent pb-2 modal-header">
            <h2 className="mb-0">Chọn trường dữ liệu để xuất</h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFormModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <Row>
              {CONSTANT.EXPORT_FIELD_ORDER_PLAN.map((item) => {
                return (
                  <Col className="mb-2" xs={4}>
                    <div className="d-flex">
                      <input
                        id={item.fieldVariables}
                        type="checkbox"
                        checked={selected.find(
                          (s) => s.fieldVariables === item.fieldVariables
                        )}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            setSelected((prev) =>
                              prev.filter(
                                (p) => p.fieldVariables !== item.fieldVariables
                              )
                            );
                          } else {
                            setSelected((prev) =>
                              _.uniqBy([...prev, item], 'fieldVariables')
                            );
                          }
                        }}
                      />
                      <h5 className="mb-0 ml-2">{item.fieldNames}</h5>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
          <div className="px-lg-5 py-lg-3 d-flex justify-content-end">
            <Button
              onClick={() => {
                setFormModal(false);
              }}
              color=""
              size="md"
              type="button"
            >
              Hủy
            </Button>
            <Button
              onClick={() => {
                handleExport(selected);
              }}
              color="primary"
              size="md"
              type="button"
            >
              Xuất danh sách
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default DialogSelectFieldExport;
