import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Container, Row, Col } from "reactstrap";
import _ from "lodash";
import ReactNotificationAlert from "react-notification-alert";

function Header({ name, toggle, options }) {
	const notificationAlertRef = useRef();

	return (
		<>
			<div className="rna-wrapper">
				<ReactNotificationAlert ref={notificationAlertRef} />
			</div>
			<div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
				<Container fluid>
					<div className="header-body">
						<Row className="align-items-end py-4">
							<Col md="5"></Col>
							<Col className="mt-3 mt-md-0 text-md-right pl-0" md="7">
								<Button
									onClick={toggle}
									className="btn-neutral"
									color="default"
									size="md"
								>
									Lọc hiển thị
								</Button>
							</Col>
						</Row>
						{_.isEmpty(options) ||
						Object.values(options).every(
							(item) => _.isEmpty(item) || item === null
						) ? (
							<></>
						) : (
							<>
								<Row className="mb-3">
									<Col xs={12}>
										<span className="font-weight-600 text-info">
											Đang lọc theo :
										</span>
										{options.orderId && (
											<span className="font-weight-600 text-info">
												&ensp;Đơn hàng: {options.orderId.label};
											</span>
										)}
										{options.status && (
											<span className="font-weight-600 text-info">
												&ensp;Trạng thái: {options.status};
											</span>
										)}
										{options.name && (
											<span className="font-weight-600 text-info">
												&ensp;Tên: {options.name};
											</span>
										)}
										{options.year && (
											<span className="font-weight-600 text-info">
												&ensp;Năm: {options.year.label || options.year};
											</span>
										)}
										{options.month && (
											<span className="font-weight-600 text-info">
												&ensp;Tháng: {options.month};
											</span>
										)}
										{options.customerOrganizationId && (
											<span className="font-weight-600 text-info">
												&ensp;Khách hàng: {options.customerOrganizationId.label}
												;
											</span>
										)}
										{options.provinceId && (
											<span className="font-weight-600 text-info">
												&ensp;Thành phố: {options.provinceId.label};
											</span>
										)}
										{options.product && (
											<span className="font-weight-600 text-info">
												&ensp;Sản phẩm: {options.product.label};
											</span>
										)}
										{options.producerId && (
											<span className="font-weight-600 text-info">
												&ensp;Nhà gia công: {options.producerId.label};
											</span>
										)}
									</Col>
								</Row>
							</>
						)}
					</div>
				</Container>
			</div>
		</>
	);
}

Header.propTypes = {
	name: PropTypes.string,
	parentName: PropTypes.string,
};

export default Header;
