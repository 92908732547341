import TypeActions from "../TypeActions";

const initialState = {
	syntheticForwardingProcessings: {
		results: [],
	},
	isGetSyntheticForwardingProcessings: false,
	syntheticForwardingProcessing: {
		results: [],
	},
	isGetSyntheticForwardingProcessing: false,
	isExportSyntheticForwardingProcessings: false,
	isExportSyntheticForwardingProcessing: false,

	// inventory

	syntheticInventorys: {
		results: [],
	},
	isGetSyntheticInventorys: false,
	syntheticInventory: { results: [] },
	isGetSyntheticInventory: false,
	isExportSyntheticInventorys: false,

	// producer
	syntheticProducers: {
		results: [],
	},
	isGetSyntheticProducers: false,
	syntheticProducer: { results: [] },
	isGetSyntheticProducer: false,
	isExportSyntheticProducers: false,

	// complete
	syntheticCompletes: {
		results: [],
	},
	isGetSyntheticCompletes: false,
	syntheticComplete: { results: [] },
	isGetSyntheticComplete: false,
	isExportSyntheticCompletes: false,
	isExportSyntheticComplete: false,

	errors: {
		getSyntheticForwardingProcessings: "",
		getSyntheticForwardingProcessing: "",
		exportSyntheticForwardingProcessings: "",
		exportSyntheticForwardingProcessing: "",
		// inventory
		getSyntheticInventorys: "",
		getSyntheticInventory: "",
		exportSyntheticInventorys: "",
		// producer
		getSyntheticProducers: "",
		getSyntheticProducer: "",
		exportSyntheticProducers: "",
		// complete
		getSyntheticCompletes: "",
		getSyntheticComplete: "",
		exportSyntheticCompletes: "",
	},
};

export const syntheticReducer = (state = initialState, actions) => {
	switch (actions.type) {
		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST:
			return {
				...state,
				isGetSyntheticForwardingProcessings: true,
				errors: {
					...state.errors,
					getSyntheticForwardingProcessings: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS:
			return {
				...state,
				syntheticForwardingProcessings: actions.data || { results: [] },
				isGetSyntheticForwardingProcessings: false,
				errors: {
					...state.errors,
					getSyntheticForwardingProcessings: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED:
			return {
				...state,
				isGetSyntheticForwardingProcessings: false,
				syntheticForwardingProcessings: { results: [] },
				errors: {
					...state.errors,
					getSyntheticForwardingProcessings: actions.error,
				},
			};

		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_REQUEST:
			return {
				...state,
				isGetSyntheticForwardingProcessing: true,
				errors: {
					...state.errors,
					getSyntheticForwardingProcessing: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS:
			return {
				...state,
				syntheticForwardingProcessing: actions.data || { results: [] },
				isGetSyntheticForwardingProcessing: false,
				errors: {
					...state.errors,
					getSyntheticForwardingProcessing: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_FAILED:
			return {
				...state,
				isGetSyntheticForwardingProcessing: false,
				syntheticForwardingProcessing: { results: [] },
				errors: {
					...state.errors,
					getSyntheticForwardingProcessing: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST:
			return {
				...state,
				isExportSyntheticForwardingProcessings: true,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessings: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS:
			return {
				...state,
				isExportSyntheticForwardingProcessings: false,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessings: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED:
			return {
				...state,
				isExportSyntheticForwardingProcessings: false,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessings: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_REQUEST:
			return {
				...state,
				isExportSyntheticForwardingProcessing: true,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessing: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS:
			return {
				...state,
				isExportSyntheticForwardingProcessing: false,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessing: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_FAILED:
			return {
				...state,
				isExportSyntheticForwardingProcessing: false,
				errors: {
					...state.errors,
					exportSyntheticForwardingProcessing: actions.error,
				},
			};
		// Inventory

		case TypeActions.GET_SYNTHETIC_INVENTORYS_REQUEST:
			return {
				...state,
				isGetSyntheticInventorys: true,
				errors: {
					...state.errors,
					getSyntheticInventorys: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_INVENTORYS_SUCCESS:
			return {
				...state,
				syntheticInventorys: actions.data || { results: [] },
				isGetSyntheticInventorys: false,
				errors: {
					...state.errors,
					getSyntheticInventorys: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_INVENTORYS_FAILED:
			return {
				...state,
				isGetSyntheticInventorys: false,
				syntheticInventorys: { results: [] },
				errors: {
					...state.errors,
					getSyntheticInventorys: actions.error,
				},
			};

		case TypeActions.GET_SYNTHETIC_INVENTORY_REQUEST:
			return {
				...state,
				isGetSyntheticInventory: true,
				errors: {
					...state.errors,
					getSyntheticInventory: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_INVENTORY_SUCCESS:
			return {
				...state,
				syntheticInventory: actions.data || { results: [] },
				isGetSyntheticInventory: false,
				errors: {
					...state.errors,
					getSyntheticInventory: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_INVENTORY_FAILED:
			return {
				...state,
				isGetSyntheticInventory: false,
				syntheticInventory: { results: [] },
				errors: {
					...state.errors,
					getSyntheticInventory: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_INVENTORYS_REQUEST:
			return {
				...state,
				isExportSyntheticInventorys: true,
				errors: {
					...state.errors,
					exportSyntheticInventorys: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_INVENTORYS_SUCCESS:
			return {
				...state,
				isExportSyntheticInventorys: false,
				errors: {
					...state.errors,
					exportSyntheticInventorys: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_INVENTORYS_FAILED:
			return {
				...state,
				isExportSyntheticInventorys: false,
				errors: {
					...state.errors,
					exportSyntheticInventorys: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_INVENTORY_REQUEST:
			return {
				...state,
				isExportSyntheticInventory: true,
				errors: {
					...state.errors,
					exportSyntheticInventory: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_INVENTORY_SUCCESS:
			return {
				...state,
				isExportSyntheticInventory: false,
				errors: {
					...state.errors,
					exportSyntheticInventory: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_INVENTORY_FAILED:
			return {
				...state,
				isExportSyntheticInventory: false,
				errors: {
					...state.errors,
					exportSyntheticInventory: actions.error,
				},
			};

		// producers
		case TypeActions.GET_SYNTHETIC_PRODUCERS_REQUEST:
			return {
				...state,
				isGetSyntheticProducers: true,
				errors: {
					...state.errors,
					getSyntheticProducers: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_PRODUCERS_SUCCESS:
			return {
				...state,
				syntheticProducers: actions.data || { results: [] },
				isGetSyntheticProducers: false,
				errors: {
					...state.errors,
					getSyntheticProducers: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_PRODUCERS_FAILED:
			return {
				...state,
				isGetSyntheticProducers: false,
				syntheticProducers: { results: [] },
				errors: {
					...state.errors,
					getSyntheticProducers: actions.error,
				},
			};

		case TypeActions.GET_SYNTHETIC_PRODUCER_REQUEST:
			return {
				...state,
				isGetSyntheticProducer: true,
				errors: {
					...state.errors,
					getSyntheticProducer: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_PRODUCER_SUCCESS:
			return {
				...state,
				syntheticProducer: actions.data || {},
				isGetSyntheticProducer: false,
				errors: {
					...state.errors,
					getSyntheticProducer: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_PRODUCER_FAILED:
			return {
				...state,
				isGetSyntheticProducer: false,
				syntheticProducer: {},
				errors: {
					...state.errors,
					getSyntheticProducer: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_PRODUCERS_REQUEST:
			return {
				...state,
				isExportSyntheticProducers: true,
				errors: {
					...state.errors,
					exportSyntheticProducers: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_PRODUCERS_SUCCESS:
			return {
				...state,
				isExportSyntheticProducers: false,
				errors: {
					...state.errors,
					exportSyntheticProducers: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_PRODUCERS_FAILED:
			return {
				...state,
				isExportSyntheticProducers: false,
				errors: {
					...state.errors,
					exportSyntheticProducers: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_PRODUCER_REQUEST:
			return {
				...state,
				isExportSyntheticProducer: true,
				errors: {
					...state.errors,
					exportSyntheticProducer: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_PRODUCER_SUCCESS:
			return {
				...state,
				isExportSyntheticProducer: false,
				errors: {
					...state.errors,
					exportSyntheticProducer: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_PRODUCER_FAILED:
			return {
				...state,
				isExportSyntheticProducer: false,
				errors: {
					...state.errors,
					exportSyntheticProducer: actions.error,
				},
			};

		// complete
		case TypeActions.GET_SYNTHETIC_COMPLETES_REQUEST:
			return {
				...state,
				isGetSyntheticCompletes: true,
				errors: {
					...state.errors,
					getSyntheticCompletes: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_COMPLETES_SUCCESS:
			return {
				...state,
				syntheticCompletes: actions.data || { results: [] },
				isGetSyntheticCompletes: false,
				errors: {
					...state.errors,
					getSyntheticCompletes: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_COMPLETES_FAILED:
			return {
				...state,
				isGetSyntheticCompletes: false,
				syntheticCompletes: { results: [] },
				errors: {
					...state.errors,
					getSyntheticCompletes: actions.error,
				},
			};

		case TypeActions.GET_SYNTHETIC_COMPLETE_REQUEST:
			return {
				...state,
				isGetSyntheticComplete: true,
				errors: {
					...state.errors,
					getSyntheticComplete: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_COMPLETE_SUCCESS:
			return {
				...state,
				syntheticComplete: actions.data || {},
				isGetSyntheticComplete: false,
				errors: {
					...state.errors,
					getSyntheticComplete: "",
				},
			};
		case TypeActions.GET_SYNTHETIC_COMPLETE_FAILED:
			return {
				...state,
				isGetSyntheticComplete: false,
				syntheticComplete: {},
				errors: {
					...state.errors,
					getSyntheticComplete: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_COMPLETES_REQUEST:
			return {
				...state,
				isExportSyntheticCompletes: true,
				errors: {
					...state.errors,
					exportSyntheticCompletes: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_COMPLETES_SUCCESS:
			return {
				...state,
				isExportSyntheticCompletes: false,
				errors: {
					...state.errors,
					exportSyntheticCompletes: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_COMPLETES_FAILED:
			return {
				...state,
				isExportSyntheticCompletes: false,
				errors: {
					...state.errors,
					exportSyntheticCompletes: actions.error,
				},
			};

		case TypeActions.EXPORT_SYNTHETIC_COMPLETE_REQUEST:
			return {
				...state,
				isExportSyntheticComplete: true,
				errors: {
					...state.errors,
					exportSyntheticComplete: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_COMPLETE_SUCCESS:
			return {
				...state,
				isExportSyntheticComplete: false,
				errors: {
					...state.errors,
					exportSyntheticComplete: "",
				},
			};
		case TypeActions.EXPORT_SYNTHETIC_COMPLETE_FAILED:
			return {
				...state,
				isExportSyntheticComplete: false,
				errors: {
					...state.errors,
					exportSyntheticComplete: actions.error,
				},
			};

		default:
			return {
				...state,
			};
	}
};

export default syntheticReducer;
