import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GETFILE } from 'Services/ServiceBase';
import { DELETE, GET, PATCH, POST, POSTFILE } from 'Services/ServiceBase';
import ServiceURL from 'Services/ServiceURL';
import TypeActions from '../TypeActions';

export function* getMeasureSizes(data) {
  const url = ServiceURL.measureSizes + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_MEASURE_SIZES_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_MEASURE_SIZES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_MEASURE_SIZES_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getCommentMeasureSizeSaga(data) {
  const url = ServiceURL.conversations + '/measure' + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_COMMENTS_MEASURE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_COMMENTS_MEASURE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_COMMENTS_MEASURE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getMeasureSizeById(data) {
  const url = ServiceURL.measureSizes + '/' + data.params + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_MEASURE_SIZE_BY_ID_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_MEASURE_SIZE_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_MEASURE_SIZE_BY_ID_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* createMeasureSize(data) {
  const url = ServiceURL.measureSizes;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_MEASURE_SIZE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_MEASURE_SIZE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}
export function* createCommentMeasureSize(data) {
  const url = ServiceURL.conversations + '/measure';
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_COMMENT_MEASURE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_COMMENT_MEASURE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_COMMENT_MEASURE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* updateMeasureSize(data) {
  const url = ServiceURL.measureSizes + '/' + data.body.id;
  const callback = data.callback;
  let payload = { ...data.body };
  delete payload['id'];
  try {
    const res = yield call(PATCH, url, payload);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_MEASURE_SIZE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_MEASURE_SIZE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteMeasureSize(data) {
  const url = ServiceURL.measureSizes + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.DELETE_MEASURE_SIZE_SUCCESS,
      });

      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_MEASURE_SIZE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* uploadMeasureSize(data) {
  const formData = new FormData();
  formData.append('fileUpload', data.file);
  const url = ServiceURL.measureSizes + '/uploads/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POSTFILE, url, formData);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPLOAD_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPLOAD_MEASURE_SIZE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPLOAD_MEASURE_SIZE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* extractMeasureSize(data) {
  const url =
    ServiceURL.measureSizes + '/exports/' + data.params + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_MEASURE_SIZE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.EXPORT_MEASURE_SIZE_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export function* getTemplateMeasureSize(data) {
  const url =
    ServiceURL.customerInOrders + '/export/measureTemplate/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GETFILE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_TEMPLATE_MEASURE_SIZE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_TEMPLATE_MEASURE_SIZE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    const text = yield error?.response?.data.text();
    yield put({
      type: TypeActions.GET_TEMPLATE_MEASURE_SIZE_FAILED,
      error: JSON.parse(text)?.message,
    });
    !!callback?.failed && callback.failed(JSON.parse(text)?.message);
  }
}

export default function* measureSizeSaga() {
  yield takeLatest(TypeActions.GET_MEASURE_SIZES_REQUEST, getMeasureSizes);
  yield takeLatest(
    TypeActions.GET_MEASURE_SIZE_BY_ID_REQUEST,
    getMeasureSizeById
  );
  yield takeLatest(TypeActions.CREATE_MEASURE_SIZE_REQUEST, createMeasureSize);
  yield takeLatest(TypeActions.UPDATE_MEASURE_SIZE_REQUEST, updateMeasureSize);
  yield takeLatest(TypeActions.DELETE_MEASURE_SIZE_REQUEST, deleteMeasureSize);
  yield takeLatest(TypeActions.UPLOAD_MEASURE_SIZE_REQUEST, uploadMeasureSize);
  yield takeLatest(TypeActions.EXPORT_MEASURE_SIZE_REQUEST, extractMeasureSize);
  yield takeLatest(
    TypeActions.GET_TEMPLATE_MEASURE_SIZE_REQUEST,
    getTemplateMeasureSize
  );
  yield takeLatest(
    TypeActions.GET_COMMENTS_MEASURE_REQUEST,
    getCommentMeasureSizeSaga
  );
  yield takeLatest(
    TypeActions.CREATE_COMMENT_MEASURE_REQUEST,
    createCommentMeasureSize
  );
}
