import styled from "styled-components";

export const Style = styled.div`
	/* .filter {
		position: fixed;
		top: 0;
		right: -400px;
		width: 400px;
		height: 100vh;
		transition: 0.3s;
		&.show {
			right: 0px;
		}
	} */
	.filter {
		position: fixed;
		top: 0;
		right: 00px;
		width: 400px;
		height: 100vh;
		z-index: 10;
		transition: 0.3s;
		animation: animated 0.3s;
		@keyframes animated {
			0% {
				right: -400px;
			}
			100% {
				right: 0px;
			}
		}
	}
	table {
		thead {
			th {
				text-transform: inherit;
				background-color: transparent;
				padding-left: 0.5rem !important;
				padding-right: 0.5rem !important;
			}
		}
		td {
			padding-left: 0.5rem !important;
			padding-right: 0.5rem !important;
		}
		input {
			background-color: transparent !important;
			box-shadow: none !important;
			border: none;
			border-bottom: 1px solid #000;
			border-radius: 0px;
			padding: 0;
			height: 2rem;
			/* font-size: 1rem; */
		}
		th {
			vertical-align: inherit !important;
		}
	}
`;
