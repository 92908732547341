import React, { useState, useEffect, useRef } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  CardBody,
} from 'reactstrap';
import OptionShow from '../components/OptionShow';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { useHistory } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import { notify } from 'common';
import { syntheticActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import Header from 'views/pages/Statistical/components/Header';
import Filter from 'views/pages/Statistical/components/Filter';
import { Style } from './style';
import { useCallback } from 'react';

const SyntheticComplete = ({ location }) => {
  const {
    syntheticCompletes,
    isGetSyntheticCompletes,
    isExportSyntheticCompletes,
  } = useSelector((state) => state.syntheticReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationAlertRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(
    location?.state?.filterValues || {
      year: null,
      orderId: null,
      producerProductionIds: [],
      customerOrgId: null,
      productId: null,
    }
  );
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );

  const [query, setQuery] = useState(
    location?.state?.query || {
      page: page,
      limit: rowsPerPage,
    }
  );

  const columnToDetail = {
    dataField: 'producerName',
    text: 'Tên đơn vị gia công',
    formatter: (cell, row) => {
      return (
        <button
          style={{
            padding: 0,
            border: 'none',
            marginRight: '.5rem',
            background: 'none',
            fontWeight: '600',
            color: '#0B29D8',
          }}
          onClick={() => {
            toDetail(
              row?.productionCommandId,
              row?.customerOrgId,
              row?.producerName,
              row?.producerCode
            );
          }}
        >
          {cell}
        </button>
      );
    },
    isChecked: false,
    isDefault: true,
  };
  const [columns, setColumns] = useState([
    {
      dataField: 'stt',
      text: 'STT',
      style: {
        textAlign: 'left',
      },
      isChecked: false,
      isDefault: true,
    },
    {
      dataField: 'producerCode',
      text: 'Mã ĐVGC',
      isChecked: false,
      isDefault: true,
    },
    {
      dataField: 'customerOrgName',
      text: 'Chi nhánh',
      isChecked: false,
      isDefault: true,
    },
    {
      dataField: 'productName',
      text: 'Sản phẩm',
      isChecked: false,
      isDefault: true,
    },
    {
      dataField: 'productionCommanDate',
      text: 'Ngày cấp LSX',
      formatter: (cell) => {
        return cell && moment(cell).format('DD/MM/YYYY');
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      isChecked: false,
      isDefault: true,
    },
    {
      dataField: 'productReceiveDate',
      text: 'Ngày yêu cầu KT',
      formatter: (cell) => {
        return cell && moment(cell).format('DD/MM/YYYY');
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      isChecked: true,
      isDefault: false,
    },
    {
      dataField: 'productionEndDate',
      text: 'Ngày kết thúc',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : 'Chưa có dữ liệu!';
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      isChecked: true,
      isDefault: false,
    },
    {
      dataField: 'productionEndDate-productionCommanDate',
      text: 'Số ngày SX',
      formatter: (cell, row) => {
        return row?.productionEndDate
          ? moment(row?.productionEndDate || new Date()).diff(
              moment(row?.productionCommanDate || new Date()),
              'days'
            )
          : 'Chưa có dữ liệu!';
      },
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      isChecked: true,
      isDefault: false,
    },
    {
      dataField: 'totalProduction',
      text: 'Số lượng SX',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalQc',
      text: 'Số lượng giao GC',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalReceiveProduct',
      text: 'Số lượng nhận',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalQcPass',
      text: 'Số lượng đạt',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalQcFailed',
      text: 'Số lượng lỗi',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalNotQc',
      text: 'Số lượng chưa QC',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'totalComplete',
      text: 'Số lượng hoàn thiện',
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'qcPercent',
      text: 'Tỷ lệ QC đạt %',
      formatter: (cell) => {
        return cell;
      },
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'completePercent',
      text: 'Tỷ lệ hoàn thành %',
      formatter: (cell) => {
        return cell;
      },
      isChecked: false,
      isDefault: false,
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ]);

  const toDetail = (
    productionCommandId,
    customerOrgId,
    producerName,
    producerCode
  ) => {
    history.push(
      `/synthetic-complete/${productionCommandId}?customerOrgId=${customerOrgId}&&orderName=${filterValues.orderId?.label}&&producerName=${producerName}&&producerCode=${producerCode}`,
      {
        filterValues: filterValues,
        page: page,
        rowsPerPage: rowsPerPage,
        query: query,
        route: location.pathname,
      }
    );
  };

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: syntheticCompletes?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > syntheticCompletes.totalResults
              ? !isNaN(syntheticCompletes?.totalResults)
                ? syntheticCompletes.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(syntheticCompletes?.totalResults)
              ? syntheticCompletes.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleOpenFilter = (value = true) => {
    setOpenFilter(value);
  };

  const handleGetSyntheticCompletes = () => {
    if (
      new Date(filterValues.toDate).getTime() <
      new Date(filterValues.fromDate).getTime()
    ) {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Ngày kết thúc phải lớn hơn ngày bắt đầu !`
      );
      return;
    }
    if (_.isEmpty(filterValues.orderId)) {
      dispatch(
        syntheticActions.getSyntheticCompletes(
          queryString.stringify({ ...query }),
          {
            success: () => {
              handleOpenFilter(false);
            },
          }
        )
      );
      return;
    }
    const tempFilter = {};

    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key]) && key !== 'year') {
        if (Array.isArray(filterValues[key])) {
          tempFilter[key] = filterValues[key].map((item) => item.value);
        } else {
          tempFilter[key] = filterValues[key]?.value || filterValues[key];
        }
      }
    });
    dispatch(
      syntheticActions.getSyntheticCompletes(
        queryString.stringify(
          { ...query, ...tempFilter },
          { arrayFormat: 'bracket' }
        ),
        {
          success: () => {
            handleOpenFilter(false);
          },
        }
      )
    );
  };

  const handleExport = () => {
    if (_.isEmpty(filterValues.orderId)) {
      return;
    }
    const tempFilter = {};

    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key]) && key !== 'year') {
        if (Array.isArray(filterValues[key])) {
          tempFilter[key] = filterValues[key].map((item) => item.value);
        } else {
          tempFilter[key] = filterValues[key]?.value || filterValues[key];
        }
      }
    });
    dispatch(
      syntheticActions.exportSyntheticCompletes(
        queryString.stringify({ ...tempFilter }, { arrayFormat: 'bracket' }),
        {
          success: (data) => {
            const filename = `Bao_Cao_TH_Hoan_Thien.xlsx`;
            FileDownload(data, filename);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xuất danh sách thất bại!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    handleGetSyntheticCompletes();
  }, [filterValues, query]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <Header
        showBtnFilter={true}
        showBtnExport={true}
        isDisable={_.isEmpty(filterValues.orderId)}
        handleOpenFilter={handleOpenFilter}
        handleExport={handleExport}
        isExport={isExportSyntheticCompletes}
        filterValues={filterValues}
        screen="list"
      />

      {openFilter && (
        <Filter
          openFilter={openFilter}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setOpenFilter={setOpenFilter}
          handleFilter={() => {}}
          handleOpenFilter={handleOpenFilter}
          isGetting={isGetSyntheticCompletes}
        />
      )}

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <>
              <Card>
                {isGetSyntheticCompletes ? (
                  <div className="d-flex justify-content-center p-5">
                    <div className="spinner-border text-info" />
                  </div>
                ) : (
                  <ToolkitProvider
                    data={syntheticCompletes.results}
                    keyField="id"
                    columns={[
                      ...columns
                        .filter((item) => item.isChecked || item.isDefault)
                        .slice(0, 2),
                      columnToDetail,
                      ...columns
                        .filter((item) => item.isChecked || item.isDefault)
                        .slice(3),
                    ]}
                    search
                    bootstrap4
                  >
                    {(props) => (
                      <>
                        <CardHeader>
                          <Row>
                            <Col className="d-flex align-items-center" md="8">
                              <h3 className="mb-0">
                                Tổng hợp(
                                {syntheticCompletes?.totalProducts ?? 0})
                              </h3>
                            </Col>
                            <Col className="d-flex justify-content-end" md="4">
                              <OptionShow
                                setState={setColumns}
                                initialColumns={[
                                  ...columns.slice(0, 2),
                                  columnToDetail,
                                  ...columns.slice(2),
                                ].filter((item) => item.isDefault === false)}
                                columnDafault={[
                                  ...columns.slice(0, 2),
                                  columnToDetail,
                                  ...columns.slice(2),
                                ].filter((item) => item.isDefault === true)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody className="pt-0">
                          <BootstrapTable
                            {...props.baseProps}
                            noDataIndication={() => {
                              return (
                                <span className="font-weight-bold text-danger">
                                  Không có dữ liệu!
                                </span>
                              );
                            }}
                            onTableChange={() => {
                              return (
                                <div className="spinner-border text-info" />
                              );
                            }}
                            filter={filterFactory()}
                            pagination={pagination}
                            bordered={false}
                            hover
                            remote
                          />
                        </CardBody>
                      </>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </>
          </div>
        </Row>
      </Container>
    </Style>
  );
};

export default SyntheticComplete;
