import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import orderActions from 'Redux/Actions/orderActions';
import { notify } from 'common';
function Header({
  name,
  toggle,
  setCurrentOrders,
  handleCheckUnusual,
  handleAutoAssignSize,
  options,
  handleExportFile,
  selectItems,
  setIsOpenConfirmModal,
}) {
  const dispatch = useDispatch();
  const [orderSearch, setOrderSearch] = useState('');
  const [orderValue, setOrderValue] = useState(null);
  const { orders, isAutoAssignSize, isCheckUnusualList, isConvertOwnDesigns } =
    useSelector((state) => state.orderReducer);
  const { isExportSewGeneralPrivate } = useSelector(
    (state) => state.exportReducer
  );
  const [firstLoading, setFirstLoading] = useState(true);
  const [queryOrders, setQueryOrders] = useState({
    page: 1,
    limit: 10,
    sortBy: 'createdAt:desc',
  });
  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(
        orderActions.getOrders(queryString.stringify(queryOrders), {
          success: (data) => {
            if (firstLoading && data.results.length > 0) {
              // setOrderValue({
              //   value: data.results[0].id,
              //   label: data.results[0].name,
              // });
              // setCurrentOrders(data.results[0].id);
              setFirstLoading(false);
            }
          },
          failed: (err) => {},
        })
      );
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrders, name: orderSearch }),
          {
            success: (data) => {
              if (firstLoading && data.results.length > 0) {
                // setOrderValue({
                //   value: data.results[0].id,
                //   label: data.results[0].name,
                // });
                // setCurrentOrders(data.results[0].id);

                setFirstLoading(false);
              }
            },
            failed: (err) => {},
          }
        )
      );
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleGetOrders();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderSearch]);
  return (
    <>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-end py-4">
              <Col lg="6" xs="7">
                <Row className="align-items-center">
                  <Col md="12">
                    <p className="text-info mb-2 font-weight-500">
                      Vui lòng chọn đơn hàng, sau đó tiến hành quy size cho đơn
                      hàng đó
                    </p>
                  </Col>
                  <Col md="3" className="pr-0">
                    <h5 className="text-info">Đơn hàng đang chọn:</h5>
                  </Col>
                  <Col md="9">
                    <ReactSelect
                      className="select-custom"
                      size="sm"
                      placeholder="Chọn đơn hàng"
                      isClearable={true}
                      value={orderValue}
                      onChange={(e) => {
                        setOrderValue(e);
                        setCurrentOrders(e?.value ?? '');
                      }}
                      options={orders.results.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      onInputChange={(value) => {
                        setOrderSearch(value);
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      noOptionsMessage={() => null}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                <Button
                  outline={true}
                  className="text-white"
                  size="md"
                  onClick={handleExportFile}
                  disabled={isExportSewGeneralPrivate}
                >
                  {!isExportSewGeneralPrivate
                    ? 'Xuất danh sách'
                    : 'Đang kiểm tra ....'}
                </Button>

                {handleCheckUnusual && (
                  <Button
                    outline={true}
                    className="text-white"
                    size="md"
                    onClick={handleCheckUnusual}
                    disabled={isCheckUnusualList}
                  >
                    {!isCheckUnusualList
                      ? 'Kiểm tra bất thường'
                      : 'Đang kiểm tra ....'}
                  </Button>
                )}
                {handleAutoAssignSize && (
                  <Button
                    outline={true}
                    className="text-white"
                    size="md"
                    onClick={handleAutoAssignSize}
                    disabled={isAutoAssignSize}
                  >
                    {!isAutoAssignSize
                      ? 'Quy size tự động'
                      : 'Đang quy size....'}
                  </Button>
                )}
                <Button
                  outline={true}
                  className="text-white"
                  size="md"
                  onClick={() => {
                    setIsOpenConfirmModal(true);
                  }}
                  disabled={selectItems.length === 0 || isConvertOwnDesigns}
                >
                  May riêng
                </Button>
                <Button
                  onClick={toggle}
                  className="btn-neutral m-2"
                  color="default"
                  size="md"
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
            {_.isEmpty(options) ? (
              <></>
            ) : (
              <>
                <Row className="mb-3">
                  <Col xs={12}>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {options.status && (
                      <span className="font-weight-600 text-info">
                        &ensp;Trạng thái: {options.status};
                      </span>
                    )}
                    {options.name && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tên: {options.name};
                      </span>
                    )}
                    {options.year && (
                      <span className="font-weight-600 text-info">
                        &ensp;Năm: {options.year.label};
                      </span>
                    )}
                    {options.month && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tháng: {options.month};
                      </span>
                    )}
                    {options.customerOrganizationId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Khách hàng: {options.customerOrganizationId.label}
                        ;
                      </span>
                    )}
                    {options.provinceId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Thành phố: {options.provinceId.label};
                      </span>
                    )}
                    {options.productTypeId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Sản phẩm: {options.productTypeId.label};
                      </span>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Header;
