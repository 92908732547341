import React, { useEffect } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import InputCustom from 'views/pages/components/InputCustom';
import * as yup from 'yup';
import Select from 'react-select';
import { Formik } from 'formik';
import Error from 'views/pages/components/Error';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from 'Redux/Actions';
import { useState } from 'react';
import _ from 'lodash';
import { useRef } from 'react';
import { orderActions } from 'Redux/Actions';
import { useParams } from 'react-router';

const ModalAddCustomer = ({
  isOpen,
  toggle,
  handleAddCustomer,
  handleAlert,
}) => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { orderById } = useSelector((state) => state.orderReducer);
  const departmentSelect = useRef();
  const [listParmeters, setListParmeters] = useState([]);
  const initialCustomer = {
    stt: '',
    workbook: '',
    staffCode: '',
    customerName: '',
    customerCode: '',
    gender: '',
    age: '',
    wearType: '',
    branch: '', //chi nhanh
    department: '', //phong ban
    notes: '',
  };
  const customerSchema = yup.object().shape({
    stt: yup.string().required('Vui lòng nhập số thứ tự cho nhân viên'),
    workbook: yup.string().required('Vui lòng điền mã sổ'),
    staffCode: yup.string().required('Vui lòng điền mã nhân viên'),
    customerName: yup.string().required('Vui lòng điền tên khách hàng'),
    customerCode: yup.string().required('Vui lòng điền mã khách hàng'),
    gender: yup
      .string()
      .required('Vui lòng điền giới tính')
      .oneOf(['Nam', 'Nữ'], 'GIới tính chỉ có Nam hoặc Nữ'),
    age: yup
      .number()
      .min(1, 'Tuổi phải lớn hơn 1')
      .typeError('Bạn phải điền kiểu số')
      .required('Vui lòng điền tuổi'),
    wearType: yup.string().required('Vui lòng điền kích thước mặc'),
    branch: yup.string().required('Vui lòng chọn chi nhánh'), //chi nhanh
    department: yup.string().required('Vui lòng chọn phòng ban'),
    notes: yup.string(),
  });

  const [branchOptions, setBranchOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const onSubmit = (values, { resetForm }) => {
    for (let item of listParmeters.values()) {
      if (_.isEmpty(item.size) || item.size < 0) {
        handleAlert(
          'danger',
          'Thất bại',
          'Thêm khách hàng thất bại.Lỗi : Thông tin vị trí đo phải được điền đầy đủ'
        );
        return;
      }
    }
    const sizes = listParmeters
      .filter((item) => !_.isEmpty(item.size) && item.size > 0)
      .map((item) => {
        return {
          productParameterId: item.id,
          size: item.size,
        };
      });

    const { branch, branchName, department, departmentName, ...payload } = {
      ...values,
      orderId: orderId,
      customerOrgId: values.department || values.branch,
      sizes: sizes,
    };
    delete payload.realTotalProducts;
    dispatch(
      orderActions.createCustomerInOrder(payload, {
        success: () => {
          handleAlert('success', 'Thành công', 'Thêm khách hàng thành công');
          resetForm();
          toggle();
          handleAddCustomer(values);
        },
        failed: (message) => {
          handleAlert(
            'danger',
            'Thất bại',
            'Thêm khách hàng thất bại.Lỗi : ' + message
          );
        },
      })
    );
    //resetForm();
  };

  const handleGetAllChildOrg = (id) => {
    dispatch(
      customerActions.getAllChildOfCustomer(id, '', {
        success: (data) => {
          setDepartmentOptions(
            data.results.map((item) => ({ value: item.id, label: item.name }))
          );
        },
        failed: (data) => {},
      })
    );
  };

  useEffect(() => {
    setListParmeters(
      orderById?.products
        ?.map((item) => item.productTypeId)
        ?.reduce((init, cur) => init.concat(cur.productParameterIds), [])
        ?.map((item) => ({ id: item.id, name: item.name }))
        ?.filter(
          (item, idx, arr) => idx === arr.findIndex((x) => x.id === item.id)
        )
    );
    setBranchOptions(
      orderById?.customerOrganizationId?.childrentIds
        .map((item) => ({ value: item.id, label: item.name }))
        .concat({
          value: orderById?.customerOrganizationId.id,
          label: orderById?.customerOrganizationId.name,
        })
    );
  }, [orderById, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <Formik
        initialValues={initialCustomer}
        onSubmit={onSubmit}
        validationSchema={customerSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          touched,
          errors,
          setFieldError,
          resetForm,
          isValid,
        }) => {
          return (
            <>
              <ModalHeader>
                <p className="text-uppercase h2">Thêm mới khách hàng</p>
              </ModalHeader>
              <ModalBody>
                <Row className="mb-3 ">
                  <Col md={2}>
                    <p className="text-md-right font-weight-500 mb-0 h4 required mb-0">
                      Số thứ tự
                    </p>
                  </Col>
                  <Col md={4}>
                    <InputCustom
                      className="max-height-input-custom"
                      name="stt"
                      id="stt"
                      placeholder="Nhập"
                      onChange={handleChange}
                      invalid={errors.stt && touched.stt}
                      value={values.stt}
                      messageInvalid={errors.stt}
                    />
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={6}>
                    <Row className="">
                      <Col md={4}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Mã sổ
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập mã sổ"
                          name="workbook"
                          id="workbook"
                          onChange={handleChange}
                          invalid={errors.workbook && touched.workbook}
                          value={values.workbook}
                          messageInvalid={errors.workbook}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="">
                      <Col md={5}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Mã nhân viên đo
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập mã số"
                          name="staffCode"
                          id="staffCode"
                          onChange={handleChange}
                          invalid={errors.staffCode && touched.staffCode}
                          value={values.staffCode}
                          messageInvalid={errors.staffCode}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={6}>
                    <Row className="">
                      <Col md={4}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Tên khách hàng
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập tên khách hàng"
                          name="customerName"
                          id="customerName"
                          onChange={handleChange}
                          invalid={errors.customerName && touched.customerName}
                          value={values.customerName}
                          messageInvalid={errors.customerName}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row className="">
                      <Col md={5}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Mã khách hàng
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          placeholder="Nhập mã KH"
                          name="customerCode"
                          id="customerCode"
                          onChange={handleChange}
                          invalid={errors.customerCode && touched.customerCode}
                          value={values.customerCode}
                          messageInvalid={errors.customerCode}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={4}>
                    <Row className="">
                      <Col md={6}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Giới tính
                        </p>
                      </Col>
                      <Col md={6}>
                        <InputCustom
                          className="max-height-input-custom"
                          type="select"
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: 36,
                          }}
                          name="gender"
                          id="gender"
                          placeholder="Nhập"
                          onChange={handleChange}
                          invalid={errors.gender && touched.gender}
                          value={values.gender}
                          messageInvalid={errors.gender}
                        >
                          <option hidden value="">
                            Chọn
                          </option>
                          <option value="Nam">Nam</option>
                          <option value="Nữ">Nữ</option>
                        </InputCustom>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row className="">
                      <Col md={5}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Tuổi
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          name="age"
                          type="number"
                          min="1"
                          id="age"
                          placeholder="Nhập"
                          onChange={handleChange}
                          invalid={errors.age && touched.age}
                          value={values.age}
                          messageInvalid={errors.age}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <Row className="">
                      <Col md={5}>
                        <p className="text-md-right font-weight-500 mb-0 h4 required">
                          Mặc
                        </p>
                      </Col>
                      <Col md={7}>
                        <InputCustom
                          className="max-height-input-custom"
                          name="wearType"
                          id="wearType"
                          placeholder="Nhập"
                          onChange={handleChange}
                          invalid={errors.wearType && touched.wearType}
                          value={values.wearType}
                          messageInvalid={errors.wearType}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={2}>
                    <p className="text-md-right font-weight-500 mb-0 h4 required">
                      Chi nhánh
                    </p>
                  </Col>
                  <Col md={6}>
                    <Select
                      id="branch"
                      name="branch"
                      placeholder="Lựa chọn"
                      isClearable={true}
                      onChange={(e) => {
                        if (e == null) return;
                        setFieldValue('branch', e ? e.value : '');
                        setFieldValue('branchName', e ? e.label : '');
                        handleGetAllChildOrg(e.value);
                        departmentSelect.current.clearValue();
                        setFieldValue('department', '');
                      }}
                      value={
                        branchOptions
                          ? branchOptions.find(
                              (option) => option.value === values.branch.value
                            )
                          : ''
                      }
                      options={branchOptions}
                    />
                    {values.branch === '' && touched.branch && (
                      <Error messageInvalid={errors.branch} />
                    )}
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={2}>
                    <p className="text-md-right font-weight-500 mb-0 h4 required">
                      Phòng ban
                    </p>
                  </Col>
                  <Col md={6}>
                    <Select
                      ref={departmentSelect}
                      id="department"
                      name="department"
                      isDisabled={_.isEmpty(values.branch)}
                      placeholder="Lựa chọn"
                      isClearable={true}
                      onChange={(e) => {
                        setFieldValue('department', e ? e.value : '');
                        setFieldValue('departmentName', e ? e.label : '');
                      }}
                      value={
                        departmentOptions
                          ? departmentOptions.find(
                              (option) =>
                                option.value === values.department.value
                            )
                          : ''
                      }
                      options={departmentOptions}
                    />
                    {values.department === '' && touched.department && (
                      <Error messageInvalid={errors.department} />
                    )}
                  </Col>
                </Row>
                <Row className="mb-3 ">
                  <Col md={2}>
                    <p className="text-md-right font-weight-500 mb-0 h4">
                      Ghi chú
                    </p>
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      className="max-height-input-custom"
                      name="notes"
                      id="notes"
                      placeholder="Nhập"
                      onChange={handleChange}
                      invalid={errors.notes && touched.notes}
                      value={values.notes}
                      messageInvalid={errors.notes}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalHeader>
                <h2>Thông tin vị trí đo</h2>
              </ModalHeader>
              <ModalBody>
                <Row>
                  {listParmeters.length > 0 &&
                    listParmeters.map((item, idx) => (
                      <Col key={idx} md={6}>
                        <Row className=" mb-3">
                          <Col md={4}>
                            <p className="text-md-right font-weight-500 mb-0 h4 required">
                              {item.name}
                            </p>
                          </Col>
                          <Col md={7}>
                            <InputCustom
                              className="max-height-input-custom"
                              placeholder="Nhập số đo"
                              value={item.size}
                              type="number"
                              onChange={(e) => {
                                setListParmeters((data) =>
                                  data.map((x) =>
                                    x.id === item.id
                                      ? { ...x, size: e.target.value }
                                      : x
                                  )
                                );
                              }}
                              invalid={item.size <= 0}
                              messageInvalid={'Số đo phải lớn hơn 0'}
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                </Row>
              </ModalBody>
              <div className="text-md-center mb-5">
                <Button
                  color="secondary"
                  size="lg"
                  onClick={() => {
                    resetForm();
                    toggle();
                  }}
                >
                  Hủy bỏ
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Thêm mới
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalAddCustomer;
