import _ from "lodash";
import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Services/ServiceBase";
import ServiceURL from "Services/ServiceURL";
import TypeActions from "../TypeActions";

export function* getDeliveries(data) {
  const url = ServiceURL.transports + "?" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DELIVERIES_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_DELIVERIES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DELIVERIES_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProductErrorFromCustomerDeliveries(data) {
  const url = ServiceURL.transports + "?" + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProductsFaultPaidCustomer(data) {
  const url = ServiceURL.transports + "?" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getDeliveryById(data) {
  const url = ServiceURL.transports + "/" + data.params + "?" + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DELIVERY_BY_ID_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_DELIVERY_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DELIVERY_BY_ID_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* createDelivery(data) {
  const url = ServiceURL.transports;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_DELIVERY_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_DELIVERY_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_DELIVERY_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* updateDelivery(data) {
  const url = ServiceURL.transports + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_DELIVERY_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_DELIVERY_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_DELIVERY_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteDelivery(data) {
  const url = ServiceURL.transports + "/" + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_DELIVERY_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.DELETE_DELIVERY_SUCCESS,
      });

      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_DELIVERY_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getCustomerByBarcode(data) {
  const url =
    ServiceURL.transports + "/customerInOrderByBarcode" + "?" + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_CUSTOMER_BY_BARCODE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_CUSTOMER_BY_BARCODE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_CUSTOMER_BY_BARCODE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProductFaultPaidCustomerByBarcode(data) {
  const url = ServiceURL.transports + "/1" + "?" + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* coordinatorDelivery(data) {
  const url = ServiceURL.transports + "/coordination";
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.COORDINATOR_DELIVERY_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.COORDINATOR_DELIVERY_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.COORDINATOR_DELIVERY_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* sendCompleteStage(data) {
  const url = ServiceURL.transports + "/completeStage";
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.SEND_COMPLETE_STAGE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.SEND_COMPLETE_STAGE_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.SEND_COMPLETE_STAGE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* receiveFormGC(data) {
  const url = ServiceURL.transports + "/receiveFromProducer";
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RECEIVE_FORM_GC_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.RECEIVE_FORM_GC_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.RECEIVE_FORM_GC_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* receiveFromCustomer(data) {
  const url = ServiceURL.transports + "/receiveFromCustomer";
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.RECEIVE_FROM_CUSTOMER_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.RECEIVE_FROM_CUSTOMER_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.RECEIVE_FROM_CUSTOMER_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export default function* customerSaga() {
  yield takeLatest(TypeActions.GET_DELIVERIES_REQUEST, getDeliveries);
  yield takeLatest(
    TypeActions.GET_PRODUCTS_FAULT_PAID_CUSTOMER_REQUEST,
    getProductsFaultPaidCustomer
  );
  yield takeLatest(TypeActions.GET_DELIVERY_BY_ID_REQUEST, getDeliveryById);
  yield takeLatest(TypeActions.CREATE_DELIVERY_REQUEST, createDelivery);
  yield takeLatest(TypeActions.UPDATE_DELIVERY_REQUEST, updateDelivery);
  yield takeLatest(TypeActions.DELETE_DELIVERY_REQUEST, deleteDelivery);
  yield takeLatest(
    TypeActions.GET_CUSTOMER_BY_BARCODE_REQUEST,
    getCustomerByBarcode
  );
  yield takeLatest(
    TypeActions.GET_PRODUCT_FAULT_PAID_CUSTOMER_BY_BARCODE_REQUEST,
    getProductFaultPaidCustomerByBarcode
  );
  yield takeLatest(
    TypeActions.COORDINATOR_DELIVERY_REQUEST,
    coordinatorDelivery
  );
  yield takeLatest(TypeActions.RECEIVE_FORM_GC_REQUEST, receiveFormGC);
  yield takeLatest(
    TypeActions.RECEIVE_FROM_CUSTOMER_REQUEST,
    receiveFromCustomer
  );
  yield takeLatest(
    TypeActions.GET_PRODUCTS_ERROR_FROM_CUSTOMER_DELIVERIES_REQUEST,
    getProductErrorFromCustomerDeliveries
  );
}
