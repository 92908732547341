import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from 'Redux/Actions';
import { Formik } from 'formik';
import * as yup from 'yup';
import queryString from 'query-string';
import moment from 'moment';
import { orderActions } from 'Redux/Actions';
import _ from 'lodash';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const DetailOrderPlan = ({ open, setOpenDetail, dataForm }) => {
  const dispatch = useDispatch();
  const { customersInOrder } = useSelector((state) => state.orderReducer);
  const [productValue, setProductValue] = useState(null);
  const [customerOrganizationIds, setCustomerOrganizationIds] = useState([]);
  const [customerOrganValue, setCustomerOrganValue] = useState(null);
  const [producerValue, setProducerValue] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
  });

  const columns = [
    {
      dataField: 'customerCode',
      text: 'Mã khách hàng',
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
    },
    {
      dataField: 'productSyncDate',
      text: 'Ngày đồng bộ NPL',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'embroiderDate',
      text: 'Ngày thêu',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'produceCutDate',
      text: 'Ngày cắt SX',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'qcDate',
      text: 'Ngày giao GC',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'qcCustomerDate',
      text: 'Ngày QC KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'warehouseReceipDate',
      text: 'Ngày NK KH',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'productionDays',
      text: 'Số ngày SX',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: 'qcDays',
      text: 'Số ngày QC',
      formatter: (cell) => {
        return !!cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
  ];

  const [workPlanInfo, setWorkPlanInfo] = useState({
    // orderId: "",
    code: '',
    name: '',
    customerOrganizationIds: [],
    productTypeId: '',
    producerId: '',
    productSyncDate: moment().format('DD/MM/YYYY'),
    embroiderDate: moment().format('DD/MM/YYYY'),
    produceCutDate: moment().format('DD/MM/YYYY'),
    qcDate: moment().format('DD/MM/YYYY'),
    qcCustomerDate: moment().format('DD/MM/YYYY'),
    warehouseReceipDate: moment().format('DD/MM/YYYY'),
    productionDays: '',
    qcDays: '',
  });

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: customersInOrder?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p style={{ fontSize: 14 }} className="mb-0">
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p style={{ fontSize: 14 }} className="mb-0">
              dòng.
            </p>
          </div>
          <p style={{ fontSize: 14 }} className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > customersInOrder.totalResults
              ? !isNaN(customersInOrder?.totalResults)
                ? customersInOrder.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(customersInOrder?.totalResults)
              ? customersInOrder.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetCustomersInOrder = () => {
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({
          ...query,
          orderId: dataForm.orderId.id,
          customerOrgId: customerOrganValue.value,
        })
      )
    );
  };

  useEffect(() => {
    setWorkPlanInfo({
      // orderId: dataForm.orderId.id,
      code: dataForm.code,
      name: dataForm.name,
      customerOrganizationIds: dataForm.customerOrganizationIds.map(
        (item) => item.id
      ),
      productTypeId: dataForm.productTypeId.id,
      producerId: !_.isEmpty(dataForm?.producerId?.id)
        ? dataForm.producerId.id
        : '',
      productionDays:
        dataForm?.productionDays !== undefined ? dataForm?.productionDays : '',
      qcDays: !dataForm?.qcDays !== undefined ? dataForm.qcDays : '',
      productSyncDate: moment(dataForm.productSyncDate).format('DD/MM/YYYY'),
      embroiderDate: moment(dataForm.embroiderDate).format('DD/MM/YYYY'),
      produceCutDate: moment(dataForm.produceCutDate).format('DD/MM/YYYY'),
      qcDate: moment(dataForm.qcDate).format('DD/MM/YYYY'),
      qcCustomerDate: moment(dataForm.qcCustomerDate).format('DD/MM/YYYY'),
      warehouseReceipDate: moment(dataForm.warehouseReceipDate).format(
        'DD/MM/YYYY'
      ),
    });
    setProductValue({
      value: dataForm.productTypeId.id,
      label: dataForm.productTypeId.name,
    });
    setCustomerOrganizationIds(
      dataForm.customerOrganizationIds.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
    dataForm.customerOrganizationIds.length > 0 &&
      setCustomerOrganValue({
        value: dataForm.customerOrganizationIds[0].id,
        label: dataForm.customerOrganizationIds[0].name,
      });
    if (!_.isEmpty(dataForm?.producerId?.id)) {
      setProducerValue({
        value: dataForm.producerId.id,
        label: dataForm.producerId.name,
      });
    }
  }, [dataForm]);

  useEffect(() => {
    if (
      !_.isEmpty(dataForm?.orderId?.id) &&
      !_.isEmpty(customerOrganValue?.value)
    ) {
      handleGetCustomersInOrder();
    }
  }, [customerOrganValue, query]);

  return (
    <>
      <Modal
        // size="lg"
        style={{ minWidth: 1024 }}
        isOpen={open}
        toggle={() => {
          setOpenDetail(false);
        }}
      >
        <Card className="mb-0 pb-5">
          <CardHeader className="modal-header">
            <p className="h2 text-uppercase">
              Chi tiết kế hoạch triển khai sản xuất cho đơn hàng
            </p>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setOpenDetail(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Mã kế hoạch</p>
              </Col>
              <Col xs={9}>: {workPlanInfo.code}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Tên kế hoạch</p>
              </Col>
              <Col xs={9}>: {workPlanInfo.name}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Sản phẩm</p>
              </Col>
              <Col xs={9}>: {productValue?.label}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">Đơn vị/Phòng ban</p>
              </Col>
              <Col xs={9}>
                :{' '}
                {customerOrganizationIds.map((item, index) =>
                  index === customerOrganizationIds.length - 1
                    ? `${item.label}.`
                    : `${item.label}, `
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <p className="h3 text-sm font-weight-500">
                  Đơn vị gia công/Nhà cung cấp
                </p>
              </Col>
              <Col xs={9}>: {producerValue?.label}</Col>
            </Row>
            <p className="h3 text-sm font-weight-600">Mốc thời gian</p>
            <Row>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày đồng bộ NPL: {workPlanInfo.productSyncDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày thêu: {workPlanInfo.embroiderDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0">
                  Ngày cắt SX: {workPlanInfo.produceCutDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày giao GC: {workPlanInfo.qcDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày QC KH: {workPlanInfo.qcCustomerDate}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h5 text-sm font-weight-500 mb-0 mt-2">
                  Ngày NK KH: {workPlanInfo.warehouseReceipDate}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col xs={4}>
                <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                  Số ngày SX: {workPlanInfo.productionDays}
                </p>
              </Col>
              <Col xs={4}>
                <p className="h3 text-sm font-weight-500 mr-3 mb-0 mt-2">
                  Số ngày QC: {workPlanInfo.qcDays}
                </p>
              </Col>
              <Col xs={4}></Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <label className="form-control-label">
                  Danh sách khách hàng của đơn vị/phòng ban:
                </label>
                <Select
                  placeholder="Lựa chọn"
                  isClearable={false}
                  value={customerOrganValue}
                  onChange={(e) => {
                    setCustomerOrganValue(e);
                  }}
                  options={dataForm.customerOrganizationIds.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  onInputChange={(value) => {
                    // setProductSearch(value);
                  }}
                />
              </Col>
              <Col className="mt-2" md="12">
                <ToolkitProvider
                  data={customersInOrder.results}
                  keyField="id"
                  columns={columns}
                  search
                >
                  {(props) => (
                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      hover
                      remote
                      filter={filterFactory()}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                setOpenDetail(false);
              }}
            >
              Đóng
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default DetailOrderPlan;
