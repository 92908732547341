import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col } from 'reactstrap';
import _ from 'lodash';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import Select from 'react-select';
function Header({ setOpenFilter, options, handleExport, ...rest }) {
  return (
    <>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col md="8">
                {Object.values(options ?? {}).every((value) =>
                  _.isEmpty(value)
                ) ? (
                  <></>
                ) : (
                  <>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {options.status && (
                      <span className="font-weight-600 text-info">
                        &ensp;Trạng thái: {options.status};
                      </span>
                    )}
                    {options.name && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tên: {options.name};
                      </span>
                    )}
                    {options.year && (
                      <span className="font-weight-600 text-info">
                        &ensp;Năm: {options.year.label || options.year};
                      </span>
                    )}
                    {options.month && (
                      <span className="font-weight-600 text-info">
                        &ensp;Tháng: {parseInt(options.month) + 1};
                      </span>
                    )}
                    {options.customerOrganizationId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Khách hàng: {options.customerOrganizationId.label}
                      </span>
                    )}
                    {options.provinceId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Thành phố: {options.provinceId.label};
                      </span>
                    )}
                    {options.orderIds &&
                      Object.values(options.orderIds).some(
                        (value) => !_.isEmpty(value)
                      ) && (
                        <span className="font-weight-600 text-info">
                          &ensp;Đơn hàng:{' '}
                          {options.orderIds
                            .map((item) => item.label)
                            .join(', ')}
                          ;
                        </span>
                      )}
                  </>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" md="4">
                <LoadingButtonCustom
                  loading={false}
                  onClick={() => {
                    // setOpenFilter(true);
                    handleExport();
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Xuất danh sách
                </LoadingButtonCustom>
                <Button
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default Header;
