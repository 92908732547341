import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'Services/ServiceBase';
import ServiceURL from 'Services/ServiceURL';
import TypeActions from '../TypeActions';

export function* getProductionCommands(data) {
  const url = ServiceURL.productionCommands + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMANDS_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMANDS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTION_COMMANDS_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProductionCommandById(data) {
  const url =
    ServiceURL.productionCommands + '/' + data.params + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMAND_BY_ID_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMAND_BY_ID_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTION_COMMAND_BY_ID_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* createProductionCommand(data) {
  const url = ServiceURL.productionCommands;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_PRODUCTION_COMMAND_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_PRODUCTION_COMMAND_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_PRODUCTION_COMMAND_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* updateProductionCommand(data) {
  const url = ServiceURL.productionCommands + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_PRODUCTION_COMMAND_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_PRODUCTION_COMMAND_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_PRODUCTION_COMMAND_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}
export function* deleteProductionCOmmand(data) {
  const url = ServiceURL.productionCommands + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_PRODUCTION_COMMAND_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.DELETE_PRODUCTION_COMMAND_SUCCESS,
      });

      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_PRODUCTION_COMMAND_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* excludeCustomerCommand(data) {
  const url = ServiceURL.productionCommands + '/excludeCustomer/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXCLUDE_CUSTOMER_COMMAND_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.EXCLUDE_CUSTOMER_COMMAND_SUCCESS,
      });
      !!callback?.success && callback.success();
    }
  } catch (error) {
    yield put({
      type: TypeActions.EXCLUDE_CUSTOMER_COMMAND_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProductionCommandsError(data) {
  const url = ServiceURL.transports + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMANDS_ERROR_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTION_COMMANDS_ERROR_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTION_COMMANDS_ERROR_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getMaxProduct(data) {
  const url =
    ServiceURL.customerInOrders +
    '/maxProductCustomerInOrder' +
    '?' +
    data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_MAX_PRODUCT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCERS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_MAX_PRODUCT_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export default function* productionCommandSaga() {
  yield takeLatest(
    TypeActions.GET_PRODUCTION_COMMANDS_REQUEST,
    getProductionCommands
  );
  yield takeLatest(
    TypeActions.GET_PRODUCTION_COMMAND_BY_ID_REQUEST,
    getProductionCommandById
  );
  yield takeLatest(
    TypeActions.CREATE_PRODUCTION_COMMAND_REQUEST,
    createProductionCommand
  );
  yield takeLatest(
    TypeActions.UPDATE_PRODUCTION_COMMAND_REQUEST,
    updateProductionCommand
  );
  yield takeLatest(
    TypeActions.DELETE_PRODUCTION_COMMAND_REQUEST,
    deleteProductionCOmmand
  );
  yield takeLatest(
    TypeActions.EXCLUDE_CUSTOMER_COMMAND_REQUEST,
    excludeCustomerCommand
  );
  yield takeLatest(
    TypeActions.GET_PRODUCTION_COMMANDS_ERROR_REQUEST,
    getProductionCommandsError
  );
  yield takeLatest(TypeActions.GET_MAX_PRODUCT_REQUEST, getMaxProduct);
}
