import React, { useState, useEffect, useRef } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  CardBody,
  Button,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import { notify } from 'common';
import FileDownload from 'js-file-download';
import { syntheticActions } from 'Redux/Actions';
import Header from 'views/pages/Statistical/components/Header';
import { Style } from '../style';

const DetailSyntheticForwardingProcessing = ({ location }) => {
  const {
    syntheticForwardingProcessing,
    isExportSyntheticForwardingProcessing,
    isGetSyntheticForwardingProcessing,
  } = useSelector((state) => state.syntheticReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const customerOrgId = new URLSearchParams(search).get('customerOrgId');
  const { id } = useParams();
  const notificationAlertRef = useRef(null);
  const [enableSeacrh, setEnableSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    barcode: '',
    customerCode: '',
    customerName: '',
  });

  const columns = [
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={query.customerCode}
            onChange={(e) =>
              setQuery({
                ...query,
                customerCode: e.target.value,
              })
            }
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'customerName',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={query.customerName}
            onChange={(e) =>
              setQuery({
                ...query,
                customerName: e.target.value,
              })
            }
            placeholder="Tên khách hàng"
          />
        );
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'barcode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={query.barcode}
            onChange={(e) =>
              setQuery({
                ...query,
                barcode: e.target.value,
              })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'productName',
      text: 'Tên sản phẩm',
    },
    {
      dataField: 'quota',
      text: 'Số lượng gia công',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'receive',
      text: 'Số lượng nhận',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'error',
      text: 'Số lượng lỗi',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'notReceive',
      text: 'Số lượng chưa giao',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };
  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: syntheticForwardingProcessing?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > syntheticForwardingProcessing.totalResults
              ? !isNaN(syntheticForwardingProcessing?.totalResults)
                ? syntheticForwardingProcessing.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(syntheticForwardingProcessing?.totalResults)
              ? syntheticForwardingProcessing.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleExport = () => {
    if (!_.isEmpty(customerOrgId) && !_.isEmpty(id)) {
      dispatch(
        syntheticActions.exportSyntheticForwardingProcessing(
          queryString.stringify({
            productionCommandId: id,
            customerOrgId: customerOrgId,
          }),
          {
            success: (data) => {
              const filename = `Bao_Cao_Chi_Tiet_Giao_Nhan_Gia_Cong.xlsx`;
              FileDownload(data, filename);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                'danger',
                'Thông báo',
                `Xuất danh sách thất bại!`
              );
            },
          }
        )
      );
    }
  };

  const handleGetSyntheticForwardingProcessing = () => {
    const querySearch = _.cloneDeep(query);
    Object.keys(querySearch).map((key, index) => {
      if (typeof querySearch[key] === 'string' && querySearch[key] === '') {
        delete querySearch[key];
      }
    });
    dispatch(
      syntheticActions.getSyntheticForwardingProcessing(
        queryString.stringify({
          ...querySearch,
          productionCommandId: id,
          customerOrgId: customerOrgId,
        }),
        {
          success: () => {
            setEnableSearch(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    if (!_.isEmpty(customerOrgId) && !_.isEmpty(id)) {
      const delayDebounceFn = setTimeout(
        () => {
          handleGetSyntheticForwardingProcessing();
        },
        enableSeacrh ? 1000 : 0
      );
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [query]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <Header
        showBtnFilter={false}
        showBtnExport={true}
        handleExport={handleExport}
        isDisable={_.isEmpty(customerOrgId) || _.isEmpty(id)}
        isExport={isExportSyntheticForwardingProcessing}
        screen="detail"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {isGetSyntheticForwardingProcessing ? (
                <div className="d-flex justify-content-center p-5">
                  <div className="spinner-border text-info" />
                </div>
              ) : (
                <ToolkitProvider
                  data={syntheticForwardingProcessing.results}
                  keyField="id"
                  columns={columns}
                  search
                  bootstrap4
                >
                  {(props) => (
                    <>
                      <CardHeader>
                        <Row>
                          <Col className="d-flex align-items-center" md="8">
                            <h3 className="mb-0">Báo cáo chi tiết giao nhận</h3>
                          </Col>
                          <Col className="d-flex justify-content-end" md="4">
                            <Button
                              onClick={() => {
                                history.push(location.state.route, {
                                  ...location.state,
                                });
                              }}
                              // className="btn-neutral"
                              color="primary"
                              size="md"
                            >
                              Quay lại
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>

                      <CardBody className="pt-0">
                        <BootstrapTable
                          {...props.baseProps}
                          noDataIndication={() => {
                            return (
                              <span className="font-weight-bold text-danger">
                                Không có dữ liệu!
                              </span>
                            );
                          }}
                          onTableChange={() => {
                            return <div className="spinner-border text-info" />;
                          }}
                          filter={filterFactory()}
                          pagination={pagination}
                          bordered={false}
                          hover
                          remote
                        />
                      </CardBody>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </Style>
  );
};

export default DetailSyntheticForwardingProcessing;
