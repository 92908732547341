import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  CardFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { provincesActions } from "Redux/Actions";
import queryString from "query-string";
import { producerActions } from "Redux/Actions";
import Select from "react-select";
import * as moment from "moment";
import DatePicker from "reactstrap-date-picker";

const Filter = ({ handleClose, handleFilter, filterRequest }) => {
  const dispatch = useDispatch();
  const { provinces } = useSelector((state) => state.provincesReducer);
  const { producers } = useSelector((state) => state.producerReducer);

  const initialFilter = {
    startDate: null,
    endDate: null,
    producerId: "",
  };

  const [producerSearch, setProducerSearch] = useState("");
  const [producerValue, setProducerValue] = useState(null);

  const [optionFilter, setOptionFilter] = useState(filterRequest);

  useEffect(() => {
    setProducerValue(filterRequest.producerId);
  }, [filterRequest]);

  const handleGetCustomers = () => {
    if (producerSearch === "") {
      dispatch(producerActions.getProducers(queryString.stringify({})));
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ name: producerSearch })
        )
      );
    }
  };

  useEffect(() => {
    handleGetCustomers();
  }, [producerSearch]);

  const clearFilter = () => {
    setProducerValue(null);

    setOptionFilter(initialFilter);
    handleClose();
  };

  return (
    <>
      <Card className={`filter ${false} && "show"}`}>
        <CardHeader>
          <span
            style={{ cursor: "pointer" }}
            className="text-danger font-weight-bold"
            onClick={() => {
              handleClose();
            }}
          >
            Đóng
          </span>
        </CardHeader>
        {/* <hr className="my-3"/> */}
        <CardBody>
          <FormGroup>
            <Label
              className="form-control-label text-sm"
              htmlFor="example-email-input"
            >
              Chọn tên nhà gia công
            </Label>

            <Select
              placeholder="Nhập tên tìm kiếm"
              isClearable={true}
              value={producerValue}
              onChange={(e) => {
                // const tempProducer = {};
                // if (e.type === CONSTANTS.PRODUCER_TYPE.ALL) {
                //   tempProducer.producerCutId = e;
                //   tempProducer.producerProductionId = e;
                // } else if (e.type === CONSTANTS.PRODUCER_TYPE.CUT) {
                //   tempProducer.producerCutId = e;
                // } else if (e.type === CONSTANTS.PRODUCER_TYPE.PRODUCTION) {
                //   tempProducer.producerProductionId = e;
                // }
                setProducerValue(e);
                setOptionFilter({
                  ...optionFilter,
                  producerId: !!e ? e : null,
                  // ...tempProducer,
                });
              }}
              options={producers.results.map((item) => ({
                label: item.name,
                value: item.id,
                type: item.type,
              }))}
              onInputChange={(value) => {
                setProducerSearch(value);
              }}
            />
          </FormGroup>

          <FormGroup className="row">
            <Label
              className="form-control-label text-sm"
              htmlFor="example-text-input"
              md="12"
            >
              Lọc theo khoảng thời gian
            </Label>

            <Row className="mx-1">
              <Col className="mb-3" md={4}>
                Từ
              </Col>
              <Col className="mb-3" md={8}>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  calendarPlacement="bottom"
                  value={optionFilter.startDate}
                  maxDate={optionFilter.endDate}
                  onChange={(v, f) => {
                    setOptionFilter({ ...optionFilter, startDate: v });
                  }}
                />
              </Col>
              <Col md={4}>Đến</Col>
              <Col md={8}>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  calendarPlacement="bottom"
                  value={optionFilter.endDate}
                  minDate={optionFilter.startDate}
                  onChange={(v, f) => {
                    setOptionFilter({ ...optionFilter, endDate: v });
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button
            // className="btn btn-secondary btn-md text-sm btn-block"
            block="true"
            onClick={() => {
              clearFilter();
              handleFilter({});
            }}
          >
            Xóa bộ lọc
          </Button>
          <Button
            className="ml-0"
            block={true}
            color="primary"
            onClick={() => {
              handleFilter(optionFilter);
            }}
          >
            Áp dụng bộ lọc
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Filter;
