import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import queryString from 'query-string';
import {
  CardHeader,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { notify } from 'common';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { orderPlanActions, orderActions } from 'Redux/Actions';
import ReactNotificationAlert from 'react-notification-alert';
import CONSTANTS from 'constant';
import { checkRole } from 'common';
import { SendMailSVG, ValidateCheckSVG, DeniedSVG } from 'assets/svg';
import BoxComment from 'views/pages/components/BoxComment';
import InputCustom from 'views/pages/components/InputCustom';
import Error from 'views/pages/components/Error';
import { ViewSVG } from 'assets/svg';
import { useHistory } from 'react-router';

function TableData({ setOrders, orderValue, orderSearch, location }) {
  const history = useHistory();
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const { isGetOrderPlans, comments } = useSelector(
    (state) => state.orderPlanReducer
  );
  const [dialogComment, setDialogComment] = useState({ open: false, id: '' });
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate:
      'orderId.customerOrganizationId.provinceId,producers.producerId,productId',
    orderPlanStatus: [CONSTANTS.ORDER_PLAN_STATUS.PendingAcept],
  });
  const [queryOrders, setQueryOrders] = useState({
    page: 1,
    limit: 10,
    sortBy: 'createdAt:desc',
    // orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.PendingAcept,
  });

  const notificationAlertRef = useRef(null);
  const [comment, setComment] = useState('');
  const [orderPlansData, setOrderPlansData] = useState({ results: [] });

  const boxAction = (cell, row, id, formatExtraData) => {
    return (
      <div className="d-flex justify-content-center">
        {checkRole(currentAccount, { roles: ['approve_orderPlan'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                setDialogComment({ open: true, id: row.id });
              }}
              id={'reject' + id}
            >
              <DeniedSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'reject' + id}
            >
              Từ chối kế hoạch
            </UncontrolledTooltip>
            <button
              className="btn-none"
              onClick={() => {
                handleApprove(row.id);
              }}
              id={'approve' + id}
            >
              <ValidateCheckSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'approve' + id}
            >
              Duyệt kế hoạch
            </UncontrolledTooltip>
          </>
        )}
        <button
          className="btn-none"
          onClick={() => {
            history.push(`detail-aggrement-plan/${row.id}`, {
              page,
              rowsPerPage,
              route: location.pathname,
              orderValue: orderValue,
              queryOrders: queryOrders,
            });
          }}
          id={'view' + id}
        >
          <ViewSVG />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
          Xem chi tiết kế hoạch
        </UncontrolledTooltip>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'code',
      text: 'Mã kế hoạch',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'name',
      text: 'Tên kế hoạch',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'orderId.customerOrganizationId.provinceId.provinceName',
      text: 'Tỉnh/TP',
    },
    {
      dataField: 'productId.code',
      text: 'Mã SP',
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',
    },
    {
      dataField: 'productId.gender',
      text: 'Giới tính',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'producers',
      text: 'Số lượng',
      style: {
        textAlign: 'center',
      },
      formatter: (cell) => {
        let sum = 0;
        cell.forEach((item, index) => {
          sum += item.amount;
        });
        return sum;
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'producers',
      text: 'Nhà gia công',
      formatter: (cell) => {
        return cell.map((item, index) =>
          cell.length - 1 === index
            ? `${!!item.producerId?.name ? item.producerId.name : ''}.`
            : `${!!item.producerId?.name ? item.producerId.name : ''}, `
        );
      },
      headerStyle: {
        minWidth: 200,
      },
    },
  ];
  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: orderPlansData?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > orderPlansData.totalResults
              ? !isNaN(orderPlansData?.totalResults)
                ? orderPlansData.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(orderPlansData?.totalResults)
              ? orderPlansData.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetOrderPlans = () => {
    // if () {
    dispatch(
      orderPlanActions.getOrderPlans(
        queryString.stringify(
          {
            ...query,
            orderIds: !_.isEmpty(orderValue) ? [orderValue.value] : [],
          },
          { arrayFormat: 'bracket' }
        ),
        {
          success: (data) => {
            setOrderPlansData(data);
          },
        }
      )
    );
    // } else {
    //   setOrderPlansData({ results: [] });
    // }
  };

  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(
        orderActions.getOrders(
          queryString.stringify(queryOrders, { arrayFormat: 'bracket' }),
          {
            success: (data) => {
              setOrders(data);
            },
          }
        )
      );
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify(
            { ...queryOrders, name: orderSearch },
            { arrayFormat: 'bracket' }
          ),
          {
            success: (data) => {
              setOrders(data);
            },
          }
        )
      );
    }
  };

  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);

  const handleApprove = (id) => {
    dispatch(
      orderPlanActions.updateOrderPlan(
        { orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.Accept },
        id,
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Duyệt kế hoạch thành công!`
            );
            handleGetOrderPlans();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Duyệt kế hoạch thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleReject = (id, comment) => {
    dispatch(
      orderPlanActions.updateOrderPlan(
        {
          orderPlanStatus: CONSTANTS.ORDER_PLAN_STATUS.Reject,
          rejectReason: comment,
        },
        id,
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Từ chối kế hoạch thành công!`
            );
            handleGetOrderPlans();
            setDialogComment({ id: '', open: false });
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Từ chối kế hoạch thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleCreateComment = () => {
    dispatch(
      orderPlanActions.createCommentOrderPlan(
        {
          orderId: orderValue.value,
          comment: comment,
        },
        {
          success: () => {
            handleGetComments();
            setComment('');
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Gửi bình luận thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleGetComments = () => {
    if (!_.isEmpty(orderValue)) {
      dispatch(
        orderPlanActions.getComments(
          queryString.stringify({
            orderId: orderValue.value,
            populate: 'userId',
            sortBy: 'time:desc',
          })
        )
      );
    }
  };

  useEffect(() => {
    handleGetOrderPlans();
    handleGetComments();
    const loop = setInterval(() => {
      handleGetComments();
    }, 3000);
    return () => {
      clearInterval(loop);
    };
  }, [orderValue]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <DialogComment
        open={dialogComment.open}
        submit={(comment) => handleReject(dialogComment.id, comment)}
        close={() =>
          setDialogComment({
            id: '',
            open: false,
          })
        }
      />
      <Card>
        <ToolkitProvider
          data={orderPlansData.results}
          keyField="id"
          columns={columns}
          search
        >
          {(props) => (
            <>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <h3 className="mb-0">Danh sách kế hoạch chờ duyệt</h3>
                  </Col>
                </Row>
              </CardHeader>

              {isGetOrderPlans ? (
                <Row className="align-items-center ">
                  <Col md="12" className="d-flex justify-content-center p-5">
                    <div className="spinner-border text-info" />
                  </Col>
                </Row>
              ) : (
                <div className="pb-3">
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication={() => {
                      return (
                        <span className="font-weight-bold text-danger">
                          Không có dữ liệu!
                        </span>
                      );
                    }}
                    onTableChange={() => {
                      return <div className="spinner-border text-info" />;
                    }}
                    hover
                    remote
                    filter={filterFactory()}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                  />
                </div>
              )}
            </>
          )}
        </ToolkitProvider>
      </Card>
      {!_.isEmpty(orderValue) && (
        <Row>
          <Col md="12">
            <Card>
              <CardBody className="py-0">
                <Row>
                  <Col>
                    <p className="h2 my-3">Lịch sử trao đổi thông tin</p>
                  </Col>
                </Row>
                <Row
                  style={{ maxHeight: 500, overflowY: 'auto' }}
                  className="mt-3 px-5 py-3"
                >
                  <Col>
                    <BoxComment
                      comments={!_.isEmpty(orderValue) ? comments.results : []}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row className="mt-3 px-5 py-3">
                  <Col>
                    <InputCustom
                      resize="none"
                      rows="2"
                      type="textarea"
                      placeholder="Nhập yêu cầu thêm"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                  </Col>
                  <Col style={{ maxWidth: '80px' }}>
                    <button
                      className="btn-none"
                      onClick={() => {
                        handleCreateComment();
                      }}
                    >
                      <SendMailSVG />
                    </button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

const DialogComment = ({ open, submit, close }) => {
  const [comment, setComment] = useState('');
  const [isError, setIsError] = useState('');
  return (
    <Modal isOpen={open} toggle={close} size="md">
      <ModalHeader className="pb-0" toggle={close}>
        <p className="h2 text-uppercase">Nhập lý do từ chối</p>
      </ModalHeader>
      <ModalBody className="pt-0">
        <Row className="mb-2">
          <Col xs={12}>
            <InputCustom
              resize="none"
              rows="5"
              type="textarea"
              placeholder="Nhập lý do"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            {isError && <Error messageInvalid="Vui lòng nhập lý do!" />}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button onClick={close}>Hủy bỏ</Button>

        <Button
          color="primary"
          onClick={() => {
            if (comment !== '') {
              setIsError(false);
              submit(comment);
            } else {
              setIsError(true);
            }
          }}
        >
          Xác nhận
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TableData;
