import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { EditSVG } from 'assets/svg';
import { BinSVG } from 'assets/svg';
import { Style } from '../style';
import queryString from 'query-string';
import BootstrapTable from 'react-bootstrap-table-next';
import SimpleHeader from 'components/Headers/SimpleHeader';
import InputCustom from 'views/pages/components/InputCustom';
import RequireCustom from 'views/pages/components/RequireCustom';
import { productTypesActions } from 'Redux/Actions';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import { useDispatch, useSelector } from 'react-redux';
import ReactNotificationAlert from 'react-notification-alert';
import { notify, checkRole } from 'common';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import DialogFormUpdateProductType from './components/DialogFormUpdateProductType';
import _ from 'lodash';
const TypeProduct = () => {
  const notificationAlertRef = useRef(null);
  const { accounts, isDeleteAccount, isGetAccounts, currentAccount } =
    useSelector((state) => state.accountReducer);
  const [roleManage, setRoleManage] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [dataFormModal, setDataFormModal] = useState({});
  const [notificationModal, setNotificationModal] = useState(false);
  const [dataProductType, setDataProductType] = useState({
    code: '',
    name: '',
    // notes: "",
  });
  const [changed, setChanged] = useState({
    name: false,
    code: false,
    // notes: false,
  });
  const { productTypes, isGetProductTypes } = useSelector(
    (state) => state.productTypesReducer
  );
  const toggleFormModal = () => {
    setOpenFormModal(!openFormModal);
  };
  const handleChangeInputProductType = (e) => {
    setChanged({ ...changed, [e.target.name]: true });
    setDataProductType({ ...dataProductType, [e.target.name]: e.target.value });
  };
  const [idProductType, setIdProductType] = useState('');

  const openModalUpdate = (data) => {
    setDataFormModal(data);
    toggleFormModal();
  };
  const handleUpdateProductType = (data) => {
    delete data.price;
    dispatch(
      productTypesActions.updateProductType(
        {
          code: data.code,
          name: data.name,
          notes: data.notes,
          id: dataFormModal.id,
        },
        {},
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Cập nhật dòng sản phẩm thành công!`
            );
            toggleFormModal();
            handleGetProductTypes();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Cập nhật dòng sản phẩm thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleDelete = () => {
    dispatch(
      productTypesActions.deleteProductType(idProductType, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa dòng sản phẩm thành công!`
          );
          handleGetProductTypes();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa dòng sản phẩm thất bại. Lỗi: ${mess}`
          );
        },
      })
    );
  };
  const boxAction = (cell, row, id, formatExtraData) => {
    return (
      <>
        {roleManage && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                openModalUpdate(row);
              }}
              id={'edit' + id}
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Cập nhật dòng sản phẩm
            </UncontrolledTooltip>
          </>
        )}
        {roleDelete && (
          <>
            <button
              onClick={() => {
                setIdProductType(row.id);
                setNotificationModal(true);
              }}
              className="btn-none"
              id={'delete' + id}
            >
              <BinSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + id}
            >
              Xóa dòng sản phẩm
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const [columns, setColumns] = useState([
    {
      dataField: 'code',
      text: 'Mã số',
      style: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'name',
      text: 'Tên dòng',
    },
    {
      dataField: 'notes',
      text: 'Mô tả',
    },

    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
  ]);

  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    // populate: "parentId",
    // level: "0",
    // name: "",
    // status: "active",
  });

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: productTypes?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col>
          <p>
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > productTypes.totalResults
              ? !isNaN(productTypes?.totalResults)
                ? productTypes.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(productTypes?.totalResults) ? productTypes.totalResults : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });
  const handleGetProductTypes = () => {
    let payload = { ...query };
    // if (payload.name.length === 0) delete payload["name"];
    dispatch(
      productTypesActions.getProductTypes(queryString.stringify(payload))
    );
  };
  const handleAddProductType = () => {
    setChanged({
      name: true,
      code: true,
      // notes: true,
    });
    if (
      dataProductType.name.length > 0 &&
      dataProductType.code.length > 0
      // &&
      // dataProductType.notes.length > 0
    )
      dispatch(
        productTypesActions.createProductType(dataProductType, {
          success: () => {
            // setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Thêm dòng sản phẩm thành công!`
            );
            handleGetProductTypes();
            setDataProductType({
              code: '',
              name: '',
              notes: '',
            });
            setChanged({
              code: false,
              name: false,
              // notes: false,
            });
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Thêm dòng sản phẩm thất bại. Lỗi ${mess}!`
            );
          },
        })
      );
  };

  useEffect(() => {
    handleGetProductTypes();
  }, [query]);

  useEffect(() => {
    if (!_.isEmpty(currentAccount)) {
      if (checkRole(currentAccount, { roles: ['manage_productType'] })) {
        setRoleManage(true);
      }
      if (checkRole(currentAccount, { roles: ['delete_productType'] })) {
        setRoleDelete(true);
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    if (!roleManage && !roleDelete) {
      const index = columns.findIndex((item) => item.dataField === 'actions');
      if (index !== -1)
        setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    } else {
      setColumns([
        {
          dataField: 'code',
          text: 'Mã số',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'name',
          text: 'Tên dòng',
        },
        {
          dataField: 'notes',
          text: 'Mô tả',
        },

        {
          dataField: 'actions',
          text: 'Hành động',
          formatter: boxAction,
          style: { textAlign: 'center' },
        },
      ]);
    }
  }, [roleManage, roleDelete]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="dòng sản phẩm"
          func={handleDelete}
        />
      )}
      <SimpleHeader name="" />
      <Container fluid className="mt--6">
        <Row>
          {roleManage && (
            <Col xs={4}>
              <Card>
                <CardHeader>
                  <p className="h3 font-weight-500">Dòng sản phẩm</p>
                </CardHeader>
                <CardBody>
                  <FormGroup className="mb-2">
                    <p className="h3 text-sm font-weight-500">
                      Mã dòng sản phẩm
                      <RequireCustom />
                    </p>
                    <InputCustom
                      className="max-height-input-custom"
                      placeholder="Nhập mã dòng sản phẩm"
                      type="text"
                      messageInvalid={'Vui lòng nhập mã dòng sản phẩm!'}
                      valid={false}
                      invalid={
                        dataProductType.code.length === 0 && changed.code
                      }
                      onChange={handleChangeInputProductType}
                      name="code"
                      value={dataProductType.code}
                      // size="sm"
                    />
                  </FormGroup>
                  <FormGroup className="mb-2">
                    <p className="h3 text-sm font-weight-500">
                      Tên dòng
                      <RequireCustom />
                    </p>
                    <InputCustom
                      className="max-height-input-custom"
                      placeholder="Nhập thể loại dòng sản phẩm"
                      type="text"
                      valid={false}
                      invalid={
                        dataProductType.name.length === 0 && changed.name
                      }
                      name="name"
                      messageInvalid={'Vui lòng nhập tên dòng sản phẩm!'}
                      value={dataProductType.name}
                      onChange={handleChangeInputProductType}
                      // disabled={disabled}

                      // size="sm"
                    />
                  </FormGroup>
                  <FormGroup className="mb-2">
                    <p className="h3 text-sm font-weight-500">Mô tả</p>
                    <InputCustom
                      placeholder="Nhập mô tả"
                      type="textarea"
                      rows={3}
                      // valid={false}
                      // invalid={
                      //   dataProductType.notes.length === 0 && changed.notes
                      // }
                      // messageInvalid={"Ghi chú không được để trống"}
                      name="notes"
                      value={dataProductType.notes}
                      onChange={handleChangeInputProductType}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter className="d-flex justify-content-center">
                  <Button
                    onClick={() => {
                      setDataProductType({
                        code: '',
                        name: '',
                        notes: '',
                      });
                      setChanged({
                        code: false,
                        name: false,
                        // notes: false,
                      });
                    }}
                  >
                    Hủy bỏ
                  </Button>
                  <Button color="primary" onClick={handleAddProductType}>
                    Thêm mới
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          )}
          <Col xs={roleManage ? 8 : 12}>
            <Card>
              <CardHeader>
                <p className="h3 font-weight-500">Danh sách dòng sản phẩm</p>
              </CardHeader>
              <ToolkitProvider
                data={[...productTypes.results]}
                keyField="id"
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      hover
                      remote
                      filter={filterFactory()}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
      {openFormModal && (
        <DialogFormUpdateProductType
          toggle={toggleFormModal}
          open={openFormModal}
          handleUpdateProductType={handleUpdateProductType}
          dataFormModal={dataFormModal}
        />
      )}
    </Style>
  );
};

export default TypeProduct;
