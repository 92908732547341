import _ from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST, GETFILE } from "Services/ServiceBase";
import ServiceURL from "Services/ServiceURL";
import TypeActions from "../TypeActions";

export function* getSyntheticForwardingProcessings(data) {
	const url =
		ServiceURL.reports + "/" + ServiceURL.deliveryReport + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getSyntheticForwardingProcessing(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.deliveryDetailsReport +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* exportSyntheticForwardingProcessings(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.deliveryReport +
		"/" +
		ServiceURL.exports +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

export function* exportSyntheticForwardingProcessing(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.deliveryDetailsReport +
		"/" +
		ServiceURL.exports +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

// inventory
export function* getSyntheticInventorys(data) {
	const url =
		ServiceURL.reports + "/" + ServiceURL.generalReport + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_INVENTORYS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_INVENTORYS_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_INVENTORYS_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getSyntheticInventory(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.generalDetailsReport +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_INVENTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_INVENTORY_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_INVENTORY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* exportSyntheticInventorys(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.generalReport +
		"/" +
		"export" +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_INVENTORYS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_INVENTORYS_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_INVENTORYS_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

export function* exportSyntheticInventory(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.generalDetailsReport +
		"/" +
		ServiceURL.exports +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_INVENTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_INVENTORY_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_INVENTORY_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

// producer
export function* getSyntheticProducers(data) {
	const url =
		ServiceURL.reports + "/" + ServiceURL.productionReport + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_PRODUCERS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_PRODUCERS_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_PRODUCERS_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* exportSyntheticProducers(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.productionReport +
		"/" +
		"export" +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_PRODUCERS_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_PRODUCERS_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_PRODUCERS_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

export function* getSyntheticProducer(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.productionDetailsReport +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_PRODUCER_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_PRODUCER_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_PRODUCER_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* exportSyntheticProducer(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.productionDetailsReport +
		"/" +
		ServiceURL.exports +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_PRODUCER_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_PRODUCER_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_PRODUCER_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

// complete
export function* getSyntheticCompletes(data) {
	const url =
		ServiceURL.reports + "/" + ServiceURL.completeReport + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_COMPLETES_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_COMPLETES_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_COMPLETES_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getSyntheticComplete(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.completeDetailsReport +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_SYNTHETIC_COMPLETE_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_SYNTHETIC_COMPLETE_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_SYNTHETIC_COMPLETE_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* exportSyntheticCompletes(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.completeReport +
		"/" +
		"export" +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_COMPLETES_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_COMPLETES_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_COMPLETES_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

export function* exportSyntheticComplete(data) {
	const url =
		ServiceURL.reports +
		"/" +
		ServiceURL.completeDetailsReport +
		"/" +
		ServiceURL.exports +
		"?" +
		data.query;
	const callback = data.callback;
	try {
		const res = yield call(GETFILE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_COMPLETE_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.EXPORT_SYNTHETIC_COMPLETE_SUCCESS,
				// data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		const text = yield error?.response?.data.text();
		yield put({
			type: TypeActions.EXPORT_SYNTHETIC_COMPLETE_FAILED,
			error: JSON.parse(text)?.message,
		});
		!!callback?.failed && callback.failed(JSON.parse(text)?.message);
	}
}

export default function* syntheticSaga() {
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST,
		getSyntheticForwardingProcessings
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSINGS_REQUEST,
		exportSyntheticForwardingProcessings
	);
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_INVENTORYS_REQUEST,
		getSyntheticInventorys
	);
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_INVENTORY_REQUEST,
		getSyntheticInventory
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_INVENTORYS_REQUEST,
		exportSyntheticInventorys
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_INVENTORY_REQUEST,
		exportSyntheticInventory
	);
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_PRODUCERS_REQUEST,
		getSyntheticProducers
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_PRODUCERS_REQUEST,
		exportSyntheticProducers
	);

	yield takeLatest(
		TypeActions.GET_SYNTHETIC_PRODUCER_REQUEST,
		getSyntheticProducer
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_PRODUCER_REQUEST,
		exportSyntheticProducer
	);

	yield takeLatest(
		TypeActions.GET_SYNTHETIC_COMPLETES_REQUEST,
		getSyntheticCompletes
	);
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_COMPLETE_REQUEST,
		getSyntheticComplete
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_COMPLETES_REQUEST,
		exportSyntheticCompletes
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_COMPLETE_REQUEST,
		exportSyntheticComplete
	);
	yield takeLatest(
		TypeActions.EXPORT_SYNTHETIC_FORWARDING_PROCESSING_REQUEST,
		exportSyntheticForwardingProcessing
	);
	yield takeLatest(
		TypeActions.GET_SYNTHETIC_FORWARDING_PROCESSING_REQUEST,
		getSyntheticForwardingProcessing
	);
}
