import React, { useState, useEffect, useRef } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  UncontrolledTooltip,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Button,
  CardBody,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import { notify } from 'common';
import { Style } from '../style';
import Filter from '../components/Filter';
import { completeStageActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import Header from '../components/Header';
import { checkRole } from 'common';

const CompleteStage = ({ location }) => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const { completeStages, isGetCompleteStages } = useSelector(
    (state) => state.completeStageReducer
  );

  const [filterValues, setFilterValues] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const [currentOrders, setCurrentOrders] = useState({});
  const notificationAlertRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [firstCalled, setFirstCalled] = useState(false);
  const [filterBy, setFilterBy] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });
  const [filterByName, setFilterByName] = useState({});
  const [valueFilter, setValueFilter] = useState({
    barcode: '',
  });
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [transportStatus, setTransportStatus] = useState(
    CONSTANTS.TRANSPORT_STATUS.STAGE
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    transportStatus: transportStatus,
    populate:
      'customerOrgId, productId, orderId.customerOrganizationId, producerId, productionCommandId.producerCutId producerProductionId',
  });

  const columns = [
    {
      dataField: 'barcode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.barcode}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, barcode: e.target.value })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'productId.name',
      text: 'Tên sản phẩm',
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'orderId.name',
      text: 'Đơn hàng',
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
    },
    {
      dataField: 'amount',
      text: `Số lượng (${completeStages?.totalResults})`,
      style: { textAlign: 'center' },
      headerAlign: 'center',
      formatter: (cell) => {
        return 1;
      },
    },
    {
      dataField: 'productionCommandId.producerCutId',
      text: 'Nhà gia công',
      formatter: (cell, row) => {
        return !!cell?.name ? (
          <>
            <span className="font-weight-600">Cắt: </span>
            {cell.name}, <span className="font-weight-600">May: </span>{' '}
            {row?.productionCommandId?.producerProductionId?.name}
          </>
        ) : null;
      },
    },
    {
      dataField: 'sendDate',
      text: 'Ngày nhận SP',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: completeStages?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > completeStages.totalResults
              ? !isNaN(completeStages?.totalResults)
                ? completeStages.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(completeStages?.totalResults)
              ? completeStages.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleFilter = (values) => {
    setFilterBy({ ...values });
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(values).map((key, index) => {
      if (!_.isEmpty(values[key])) {
        tempFilter[key] = values[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getCompleteStages(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleGetCompleteStages = () => {
    let payload = _.cloneDeep(query);
    if (!_.isEmpty(currentOrders)) {
      payload = { ...payload, orderId: currentOrders };
    }
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getCompleteStages(
        queryString.stringify({ ...payload, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setFirstCalled(true);
          },
        }
      )
    );
  };
  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...valueFilter };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      completeStageActions.getCompleteStages(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };
  const handleTransportOC = () => {
    history.push('/form-transfer-qc/0', {
      route: location.pathname,
      page,
      rowsPerPage,
    });
  };
  useEffect(() => {
    const tempFilter = {};
    Object.keys(filterBy).map((key, index) => {
      if (!_.isEmpty(filterBy[key])) {
        tempFilter[key] = filterBy[key].label;
      }
    });
    setFilterByName(tempFilter);
  }, [filterBy]);

  useEffect(() => {
    handleGetCompleteStages();
  }, [query]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [valueFilter]);
  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Header
        toggle={() => {
          setOpenFilter(true);
        }}
        options={filterValues}
        handleTransportOC={
          checkRole(currentAccount, { roles: ['manage_transportHT'] }) &&
          handleTransportOC
        }
        filterByName={filterByName}
        setCurrentOrders={setCurrentOrders}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={completeStages.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <CardHeader>
                      <Row>
                        <Col className="d-flex align-items-center" md="4">
                          <h3 className="mb-0">
                            Danh sách sản phẩm hoàn thiện công đoạn
                          </h3>
                        </Col>
                        <Col
                          className="d-flex justify-content-end"
                          md="8"
                        ></Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <BootstrapTable
                        {...props.baseProps}
                        noDataIndication={() => {
                          return (
                            <span className="font-weight-bold text-danger">
                              Không có dữ liệu!
                            </span>
                          );
                        }}
                        onTableChange={() => {
                          return <div className="spinner-border text-info" />;
                        }}
                        filter={filterFactory()}
                        pagination={pagination}
                        bordered={false}
                        hover
                        remote
                      />
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>

      {openFilter && (
        <Filter
          openFilter={openFilter}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
        />
      )}
    </Style>
  );
};

export default CompleteStage;
