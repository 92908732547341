import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import queryString from 'query-string';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Style } from '../style';
import filterFactory from 'react-bootstrap-table2-filter';
import { orderActions, customerActions } from 'Redux/Actions';
import moment from 'moment';
import _ from 'lodash';
import Select from 'react-select';
import { currencyFormat } from 'common';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';

const DetailOrder = ({ location }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const { customersInOrder } = useSelector((state) => state.orderReducer);
  const [customerOrganValue, setCustomerOrganValue] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);
  const [branchValue, setBranchValue] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [listSize, setListSizes] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listCustomer, setListCustomer] = useState({ results: [] });
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
  });
  const [queryOrg, setQueryOrg] = useState({
    page: page,
    limit: rowsPerPage,
    populate:
      'customerOrgId.parentId,customerSizeId.sizes.productParameterId,productTypeId',
  });
  const [query, setQuery] = useState({
    populate:
      'customerOrganizationId.childrentIds,manager,products.productTypeId, orderDetails.listProduct.productTypeId,customerSizeId,productTypeId',
  });
  const { orderById } = useSelector((state) => state.orderReducer);

  const columns = [
    {
      dataField: 'customerSizeId.workbook',
      text: 'Mã sổ',
    },
    {
      dataField: 'customerSizeId.staffCode',
      text: 'Mã nhân viên đo',
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
      formatter: (cell, row) => {
        return !!cell ? cell : row.customerOrgId.name;
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Phòng ban',
    },
    {
      dataField: 'customerCode',
      text: 'Mã khách hàng',
      headerFormatter: () => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) => {
              setSearchValues({
                ...searchValues,
                customerCode: e.target.value,
              });
              setPage(1);
              setRowsPerPage(10);
              setQueryOrg({
                ...queryOrg,
                page: 1,
                limit: 10,
              });
            }}
            style={{
              border: 'none',
              borderRadius: 0,
              padding: 0,
              height: '2rem',
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: 'Tên khách hàng',
      headerFormatter: () => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) => {
              setSearchValues({
                ...searchValues,
                customerName: e.target.value,
              });
              setPage(1);
              setRowsPerPage(10);
              setQueryOrg({
                ...queryOrg,
                page: 1,
                limit: 10,
              });
            }}
            style={{
              border: 'none',
              borderRadius: 0,
              padding: 0,
              height: '2rem',
              // minWidth: 120,
            }}
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'age',
      text: 'Tuổi',
    },
    {
      dataField: 'gender',
      text: 'Giới tính',
    },
    {
      dataField: 'productTypeId.name',
      text: 'Sản phẩm may',
    },
    ...listSize.map((item) => {
      return {
        dataField: item.code,
        text: item.name,
        headerStyle: {
          maxWidth: 55,
          whiteSpace: 'initial',
          textAlign: 'center',
        },
        formatter: (__, row) => {
          const sd =
            row?.customerSizeId?.sizes?.find(
              (value) => value.productParameterId.code === item.code
            )?.size || '';

          return <span>{sd}</span>;
        },
        style: { textAlign: 'center' },
      };
    }),
    {
      dataField: 'customerSizeId.notes',
      text: 'Ghi chú',
      headerStyle: {
        minWidth: 200,
        textAlign: 'center',
      },
      style: { minWidth: 200, textAlign: 'justify' },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQueryOrg({ ...queryOrg, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQueryOrg({ ...queryOrg, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: customersInOrder?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p style={{ fontSize: 14, whiteSpace: 'nowrap' }} className="mb-0">
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p style={{ fontSize: 14 }} className="mb-0">
              dòng.
            </p>
          </div>
          <p style={{ fontSize: 14 }} className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > customersInOrder.totalResults
              ? !isNaN(customersInOrder?.totalResults)
                ? customersInOrder.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(customersInOrder?.totalResults)
              ? customersInOrder.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  useEffect(() => {
    dispatch(orderActions.getOrderById(id, queryString.stringify(query)));
  }, [query]);

  const handleGetCustomersInOrder = () => {
    if (!branchValue) return;
    const tmp = _.cloneDeep({
      ...queryOrg,
      orderId: id,
    });
    const tempSearch = _.cloneDeep({ ...searchValues });
    Object.keys(tempSearch).map((key) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    if (branchValue?.value !== 'all') {
      tmp.customerOrgId = customerOrganValue.value;
    }
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({
          ...tmp,
          ...tempSearch,
        }),
        {
          success: (data) => {
            setListCustomer(data);
            const list = data.results.map((item) => {
              const arr1 = item.sizes.map((i) => {
                return {
                  name: i.productParameterId.name,
                  id: i.productParameterId.id,
                  code: i.productParameterId.code,
                };
              });
              const arr2 =
                item?.customerSizeId?.sizes?.map((i) => {
                  return {
                    name: i.productParameterId.name,
                    id: i.productParameterId.id,
                    code: i.productParameterId.code,
                  };
                }) || [];

              return arr1.concat(arr2);
            });
            let results = [];
            list.forEach((item) => {
              results = results.concat(item);
            });
            results = results.filter(
              (v, i, a) => a.findIndex((item) => _.isEqual(item, v)) === i
            );

            setListSizes(results);
          },
        }
      )
    );
  };

  useEffect(() => {
    if (!_.isEmpty(id) && !_.isEmpty(customerOrganValue?.value)) {
      setQueryOrg({
        ...queryOrg,
        page: 1,
        limit: 10,
      });
      setPage(1);
      setRowsPerPage(10);
    }
  }, [customerOrganValue]);

  useEffect(() => {
    if (!_.isEmpty(orderById?.customerOrganizationId)) {
      const arr = orderById?.customerOrganizationId?.childrentIds
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
        .concat({
          value: orderById?.customerOrganizationId?.id,
          label: orderById?.customerOrganizationId?.name,
        });
      arr.unshift({
        value: 'all',
        label: 'Tất cả',
      });
      setBranchOptions(arr);
      setBranchValue({
        //   {
        //   value:
        //     orderById?.customerOrganizationId?.childrentIds[0]?.id ||
        //     orderById?.customerOrganizationId?.id,
        //   label:
        //     orderById?.customerOrganizationId?.childrentIds[0]?.name ||
        //     orderById?.customerOrganizationId?.name,
        // }
        ...arr[0],
      });
      // handleGetAllChildOrg(
      //   orderById?.customerOrganizationId?.childrentIds[0]?.id ||
      //     orderById?.customerOrganizationId?.id
      // );
    }
  }, [orderById]);

  const handleGetAllChildOrg = (id) => {
    if (id === undefined) return;
    dispatch(
      customerActions.getAllChildOfCustomer(id, '', {
        success: (data) => {
          setDepartmentOptions(
            data?.results.map((item) => ({
              label: item?.name,
              value: item?.id,
            })) || []
          );
          setCustomerOrganValue({
            value: data?.results[0]?.id || orderById?.customerOrganizationId.id,
            label:
              data?.results[0]?.name || orderById?.customerOrganizationId.name,
          });

          // if (!_.isEmpty(data?.results)) {
          //   setCustomerOrganValue({
          //     value: data?.results[0]?.id,
          //     label: data?.results[0]?.name,
          //   });
          // }
        },
        failed: (mess) => {},
      })
    );
  };

  useEffect(() => {
    if (branchValue?.value === 'all') {
      handleGetCustomersInOrder();
    }
  }, [branchValue]);

  useEffect(() => {
    handleGetCustomersInOrder();
  }, [queryOrg]);

  return (
    <Style>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Xem chi tiết thông tin
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid className="mt--6">
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center justify-content-between">
              <p className="h3 text-uppercase">Thông tin đơn hàng</p>
              <LoadingButtonCustom
                outline={true}
                size="md"
                onClick={() => {}}
                loading={false}
                style={{ border: '1px solid #4385b1' }}
              >
                Xuất mẫu in
              </LoadingButtonCustom>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={7} style={{ borderRight: '1px solid #BABCBE' }}>
                <Row>
                  <Col xs={3} className="h4 font-weight-400 ">
                    <label className="form-control-label">Mã đơn hàng</label>
                  </Col>
                  <Col xs={9}>: {orderById?.code}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <label className="form-control-label">Tên đơn hàng</label>
                  </Col>
                  <Col xs={9}>: {orderById?.name}</Col>
                </Row>
                <Row className="">
                  <Col xs={3} style={{ whiteSpace: 'nowrap' }}>
                    <label className="form-control-label">
                      Chủ nhiệm quản lý
                    </label>
                  </Col>
                  <Col xs={9}>: {orderById?.manager?.name}</Col>
                </Row>
                <Row className="">
                  <Col xs={3}>
                    <label className="form-control-label">Đơn vị</label>
                  </Col>
                  <Col xs={9}>: {orderById?.customerOrganizationId?.name}</Col>
                </Row>
                <Row className="">
                  <Col xs={3}>
                    <label className="form-control-label">Chủ nhiệm</label>
                  </Col>
                  <Col md={9}>: {orderById?.manager?.name}</Col>
                </Row>
                <Row className="">
                  <Col xs={3}>
                    <label className="form-control-label">Ghi chú</label>
                  </Col>
                  <Col md={9}>: {orderById?.notes}</Col>
                </Row>
                <Row className="">
                  <Col xs={3}>
                    <label className="form-control-label">
                      Tổng SL sản phẩm
                    </label>
                  </Col>
                  <Col md={9}>: {orderById?.totalProducts ?? ''}</Col>
                </Row>
                <Row className="">
                  <Col xs={3}>
                    <label className="form-control-label">SL thực tế</label>
                  </Col>
                  <Col md={9}>: {orderById?.realTotalProducts ?? ''}</Col>
                </Row>
                <Row style={{ borderBottom: '1px solid #999' }}>
                  <Col xs={6}>
                    <label className="form-control-label">Sản phẩm</label>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-end">
                    <label className="form-control-label">Đơn giá</label>
                  </Col>
                  <Col xs={3} className="d-flex justify-content-end">
                    <label className="form-control-label">
                      Số lượng may/người
                    </label>
                  </Col>
                </Row>
                {!_.isEmpty(orderById?.products) &&
                  orderById.products.map((item, index) => {
                    return (
                      <Row
                        key={index}
                        style={{ borderBottom: '1px solid #999' }}
                      >
                        <Col xs={6}>{item?.productTypeId?.name}</Col>
                        <Col xs={3} className="d-flex justify-content-end">
                          {currencyFormat(item.price)}
                        </Col>
                        <Col xs={3} className="d-flex justify-content-end">
                          {item.amount}
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
              <Col xs={5}>
                <p className=" h5 text-muted text-uppercase">Thời gian</p>
                <Row className="mb-3">
                  <Col xs={6}>
                    <label className="form-control-label">
                      Thời gian bắt đầu đi đo
                    </label>
                    <Input
                      type="date"
                      disabled={true}
                      value={moment(orderById?.startDate).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        // setFieldValue("startDate", e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <label className="form-control-label">
                      Thời gian trả hàng
                    </label>
                    <Input
                      type="date"
                      disabled={true}
                      value={moment(orderById?.endDate).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        // setFieldValue("endDate", e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <label className="form-control-label">
                      Thời gian bảo hành
                    </label>
                    <Input
                      type="date"
                      disabled={true}
                      value={moment(orderById?.guaranteeDate).format(
                        'YYYY-MM-DD'
                      )}
                      onChange={(e) => {
                        // setFieldValue("guaranteeDate", e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <label className="form-control-label">
                      Thời gian hoàn thành chỉnh sửa
                    </label>
                    <Input
                      type="date"
                      disabled={true}
                      value={moment(orderById?.actualDate).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        // setFieldValue("actualDate", e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3 p-3">
          <CardBody>
            <Row className="mt-3">
              <Col md="12">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <label className="form-control-label">
                      Danh sách khách hàng của đơn vị/phòng ban:
                    </label>
                    <div className="mb-3 d-flex align-items-center">
                      <span className="text-md-right font-weight-500 mb-0 h4 mr-3">
                        Chi nhánh:{'  '}
                      </span>
                      <div style={{ minWidth: 250 }}>
                        <Select
                          id="branch"
                          name="branch"
                          placeholder="Lựa chọn"
                          onChange={(e) => {
                            if (e === null) return;
                            if (branchValue.value !== e.value) {
                              if (e.value !== 'all') {
                                handleGetAllChildOrg(e.value);
                              }
                              setBranchValue(e);
                            }
                          }}
                          value={branchValue}
                          options={branchOptions}
                        />
                      </div>
                      {branchValue?.value !== 'all' && (
                        <>
                          <span className="text-md-right font-weight-500 mb-0 h4 mx-3">
                            Phòng ban:
                          </span>
                          <div style={{ minWidth: 250 }}>
                            <Select
                              id="department"
                              name="department"
                              isDisabled={_.isEmpty(branchValue)}
                              placeholder="Lựa chọn"
                              onChange={(e) => {
                                if (e === null) return;
                                if (e.value !== customerOrganValue.value)
                                  setCustomerOrganValue(e);
                              }}
                              value={customerOrganValue}
                              options={departmentOptions}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <LoadingButtonCustom
                    outline={true}
                    size="md"
                    onClick={() => {}}
                    loading={false}
                    style={{ border: '1px solid #4385b1' }}
                  >
                    Xuất danh sách
                  </LoadingButtonCustom>
                </div>
              </Col>
              <Col className="mt-2" md="12">
                <ToolkitProvider
                  data={listCustomer.results}
                  keyField="id"
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <div>
                        <BootstrapTable
                          {...props.baseProps}
                          noDataIndication={() => {
                            return (
                              <span className="font-weight-bold text-danger">
                                Không có dữ liệu!
                              </span>
                            );
                          }}
                          onTableChange={() => {
                            return <div className="spinner-border text-info" />;
                          }}
                          hover
                          remote
                          filter={filterFactory()}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="text-md-right">
          <Button
            onClick={() => {
              history.push('/order-manage', { ...location?.state });
            }}
          >
            Quay lại
          </Button>
        </div>
      </Container>
    </Style>
  );
};

export default DetailOrder;
