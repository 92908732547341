import React, { useState, useEffect, useRef } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  UncontrolledTooltip,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Button,
} from 'reactstrap';
import Header from '../components/Header';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Style } from '../style';
import BootstrapTable from 'react-bootstrap-table-next';
import { EditSVG } from 'assets/svg';
import { ViewSVG } from 'assets/svg';
import { BinSVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, productionCommandActions } from 'Redux/Actions';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import Select from 'react-select';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import { notify } from 'common';
import Filter from '../components/Filter';
import { checkRole } from 'common';
// import { productionCommandActions } from "Redux/Actions";
import CONSTANTS from 'constant';

const ListProductionCommand = ({ location }) => {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const { productionCommands, isGetProductionCommands } = useSelector(
    (state) => state.productionCommandReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentOrders, setCurrentOrders] = useState(
    location?.state?.currentOrders || null
  );
  const notificationAlertRef = useRef(null);
  const [notificationModal, setNotificationModal] = useState(false);
  const [filterRequest, setFilterRequest] = useState(
    location?.state?.filterRequest || {}
  );
  const [firstCalled, setFirstCalled] = useState(false);
  const [searchValues, setSearchValues] = useState({
    code: '',
  });
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate:
      'orderId.customerOrganizationId,orderPlanId.productTypeId,producerCutId,producerProductionId,productId',
  });
  const [productionCommandCurrent, setProductionCommandCurrent] = useState({});
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const boxAction = (cell, row, id) => {
    return (
      <>
        <button
          onClick={() => {
            history.push(`/detail-production-command/${row.id}`, {
              route: '/list-product-command',
              page,
              rowsPerPage,
              currentOrders,
              filterRequest,
            });
          }}
          className="btn-none"
          id={'view' + id}
        >
          <ViewSVG />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
          Xem lệnh sản xuất
        </UncontrolledTooltip>
        {checkRole(currentAccount, { roles: ['manage_productionCommand'] }) && (
          <>
            <button
              onClick={() => {
                history.push(`/update-production-command/${row.id}`, {
                  route: '/list-product-command',
                  page,
                  rowsPerPage,
                  currentOrders,
                  filterRequest,
                });
              }}
              className="btn-none"
              id={'edit' + id}
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Sửa lệnh sản xuất
            </UncontrolledTooltip>
          </>
        )}
        {/* {checkRole(currentAccount, { roles: ["delete_productionCommand"] }) && (
					<>
						<button
							className="btn-none"
							onClick={() => {
								setProductionCommandCurrent(row);
								setNotificationModal(true);
							}}
							id={"delete" + id}
						>
							<BinSVG />
						</button>
						<UncontrolledTooltip
							delay={1}
							placement="top"
							target={"delete" + id}
						>
							Xóa lệnh sản xuất
						</UncontrolledTooltip>
					</>
				)} */}
      </>
    );
  };
  const columns = [
    {
      dataField: 'code',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.code}
            onChange={(e) =>
              setSearchValues({ ...searchValues, code: e.target.value })
            }
            placeholder="Số lệnh"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 200,
      },
    },
    {
      dataField: 'productId.name',
      text: 'Sản phẩm',

      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'orderId.name',
      text: 'Đơn hàng',
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'producerCutId',
      text: 'Nhà gia công',
      formatter: (cell, row) => {
        return !!cell?.name ? (
          <>
            <span className="font-weight-600">Cắt: </span>
            {cell.name}, <span className="font-weight-600">May: </span>{' '}
            {row.producerProductionId.name}
          </>
        ) : (
          ''
        );
      },
    },
    {
      dataField: 'createdAt',
      text: 'Thời gian cấp lệnh',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },
    {
      dataField: 'productionCommandInfos',
      text: 'Số lượng SX',
      headerStyle: {
        textAlign: 'center',
      },
      style: { textAlign: 'center' },
      formatter: (cell) => {
        return totalProducts(cell);
      },
    },
    {
      dataField: 'productReceiveDate',
      text: 'Ngày giao hàng',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      headerStyle: { textAlign: 'center' },
      style: { textAlign: 'center' },
    },

    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
        minWidth: 100,
      },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: productionCommands?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center mb-3">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > productionCommands.totalResults
              ? !isNaN(productionCommands?.totalResults)
                ? productionCommands.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(productionCommands?.totalResults)
              ? productionCommands.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const getProductionCommands = () => {
    if (_.isEmpty(query?.orderId)) {
      delete query.orderId;
    }
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    // !_.isEmpty(filterRequest) &&
    //   Object.keys(filterRequest).map((key, index) => {
    //     if (!_.isEmpty(filterRequest[key])) {
    //       tempFilter[key] = filterRequest[key].value;
    //     }
    //   });
    const tempProducer = {};
    !_.isEmpty(filterRequest) &&
      Object.keys(filterRequest).map((key, index) => {
        if (!_.isEmpty(filterRequest[key])) {
          if (key === 'producerId') {
            if (filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.ALL) {
              tempProducer.producerCutId = filterRequest[key].value;
              tempProducer.producerProductionId = filterRequest[key].value;
            } else if (
              filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.CUT
            ) {
              tempProducer.producerCutId = filterRequest[key].value;
            } else if (
              filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.PRODUCTION
            ) {
              tempProducer.producerProductionId = filterRequest[key].value;
            }
          } else {
            tempFilter[key] = filterRequest[key].value;
          }
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      productionCommandActions.getProductionCommands(
        queryString.stringify({
          ...query,
          ...tempFilter,
          ...tempProducer,
          ...tempSearch,
        }),
        {
          success: () => {
            setFirstCalled(true);
          },
        }
      )
    );
  };

  const handleDelete = () => {
    if (_.isEmpty(productionCommandCurrent)) return;
    dispatch(
      productionCommandActions.deleteProductionCommand(
        productionCommandCurrent.id,
        {
          success: () => {
            setNotificationModal(false);
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Xóa Lệnh sản xuất thành công!`
            );
            getProductionCommands();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Xóa Lệnh sản xuất thất bại. Lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    getProductionCommands();
  }, [query]);

  useEffect(() => {
    setQuery({
      ...query,
      orderId: currentOrders?.value ?? '',
    });
  }, [currentOrders]);

  const handleFilter = (optionFilter) => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    const tempProducer = {};
    if (!_.isEmpty(optionFilter))
      Object.keys(optionFilter).map((key, index) => {
        if (!_.isEmpty(optionFilter[key]) || optionFilter[key]) {
          if (key === 'producerId') {
            if (optionFilter[key].type === CONSTANTS.PRODUCER_TYPE.ALL) {
              tempProducer.producerCutId = optionFilter[key].value;
              tempProducer.producerProductionId = optionFilter[key].value;
            } else if (optionFilter[key].type === CONSTANTS.PRODUCER_TYPE.CUT) {
              tempProducer.producerCutId = optionFilter[key].value;
            } else if (
              optionFilter[key].type === CONSTANTS.PRODUCER_TYPE.PRODUCTION
            ) {
              tempProducer.producerProductionId = optionFilter[key].value;
            }
          } else {
            tempFilter[key] = optionFilter[key].value || optionFilter[key];
          }
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      productionCommandActions.getProductionCommands(
        queryString.stringify({
          ...query,
          ...tempFilter,
          ...tempProducer,
          ...tempSearch,
        }),
        {
          success: () => {
            setIsOpenFilter(false);
            setFilterRequest(optionFilter);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    // !_.isEmpty(filterRequest) &&
    //   Object.keys(filterRequest).map((key, index) => {
    //     if (!_.isEmpty(filterRequest[key])) {
    //       tempFilter[key] = filterRequest[key].value;
    //     }
    //   });
    const tempProducer = {};
    !_.isEmpty(filterRequest) &&
      Object.keys(filterRequest).map((key, index) => {
        if (!_.isEmpty(filterRequest[key])) {
          if (key === 'producerId') {
            if (filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.ALL) {
              tempProducer.producerCutId = filterRequest[key].value;
              tempProducer.producerProductionId = filterRequest[key].value;
            } else if (
              filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.CUT
            ) {
              tempProducer.producerCutId = filterRequest[key].value;
            } else if (
              filterRequest[key].type === CONSTANTS.PRODUCER_TYPE.PRODUCTION
            ) {
              tempProducer.producerProductionId = filterRequest[key].value;
            }
          } else {
            tempFilter[key] = filterRequest[key].value;
          }
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      productionCommandActions.getProductionCommands(
        queryString.stringify({
          ...query,
          ...tempFilter,
          ...tempProducer,
          ...tempSearch,
        }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  const totalProducts = (productionCommandInfos) => {
    return productionCommandInfos.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.totalProduct,
      0
    );
  };
  return (
    <Style>
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="đề xuất"
          func={handleDelete}
        />
      )}
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Header
        toggle={() => {
          setIsOpenFilter(true);
        }}
        currentOrders={currentOrders}
        setCurrentOrders={setCurrentOrders}
        options={filterRequest}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={productionCommands.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">
                            Danh sách lệnh sản xuất cho đơn hàng
                          </h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      filter={filterFactory()}
                      pagination={pagination}
                      bordered={false}
                      hover
                      remote
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {/* {!_.isEmpty(productionCommandCurrent) && (
        <DialogDetailProductionCommand
          open={formModalDetail}
          toggle={() => setFormModalDetail(false)}
          data={productionCommandCurrent}
        />
      )}
      {!_.isEmpty(productionCommandCurrent) && (
        <DialogUpdateProductionCommand
          open={formModal}
          toggle={() => setFormModal(false)}
          data={productionCommandCurrent}
          handleUpdateCommand={handleUpdateProductionCommand}
        />
      )} */}
      {isOpenFilter && (
        <Filter
          handleClose={() => {
            setIsOpenFilter(false);
          }}
          handleFilter={handleFilter}
          filterRequest={filterRequest}
        />
      )}
    </Style>
  );
};

export default ListProductionCommand;
