import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Filter from '../components/Filter';
import { ViewSVG } from 'assets/svg';
import { EditSVG } from 'assets/svg';
import { BinSVG } from 'assets/svg';
import { Style } from '../style';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import moment from 'moment';
import ReactNotificationAlert from 'react-notification-alert';
import { notify } from 'common';
import _ from 'lodash';
import { checkRole } from 'common';

function ListOrder({ location }) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const notificationAlertRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const history = useHistory();
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();
  const { isGetOrders, orders } = useSelector((state) => state.orderReducer);
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.rowsPerPage || 10
  );
  const [idOrderSelect, setIdOrderSelect] = useState(null);
  const [filterRequest, setFilterRequest] = useState(
    location?.state?.filterRequest || {}
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate: 'customerOrganizationId,products.productTypeId',
    sortBy: 'createdAt:desc',
  });
  const [valueFilter, setValueFilter] = useState({
    code: '',
    name: '',
    customerOrganizationId: '',
  });
  const [textSearch, setTextSearch] = useState('');

  const toggleOpenFilter = (type = 'close') => {
    if (type === 'clear') {
      dispatch(
        orderActions.getOrders(queryString.stringify({ ...query, page: 1 }))
      );
    }
    setOpenFilter(!openFilter);
  };
  const handleView = (id) => {
    history.push(`/order-infor/${id}`, { page, rowsPerPage, filterRequest });
  };

  const handleDelete = () => {
    dispatch(
      orderActions.deleteOrder(idOrderSelect, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa đơn hàng thành công!`
          );
          handleGetOrders();
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa đơn hàng thất bại!`
          );
        },
      })
    );
  };
  const boxAction = (cell, row, id, formatExtraData) => {
    return (
      <>
        {checkRole(currentAccount, { roles: ['get_order'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                handleView(row.id);
              }}
              id={'view' + id}
            >
              <ViewSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
              Xem chi tiết
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccount, { roles: ['manage_order'] }) && (
          <>
            <button
              className="btn-none"
              onClick={() => {
                history.push(`/update-order/${row.id}`, {
                  page,
                  rowsPerPage,
                  filterRequest,
                });
              }}
              id={'edit' + id}
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Sửa đơn hàng
            </UncontrolledTooltip>
          </>
        )}
        {checkRole(currentAccount, { roles: ['delete_order'] }) && (
          <>
            <button
              onClick={() => {
                setNotificationModal(true);
                setIdOrderSelect(row.id);
              }}
              className="btn-none"
              id={'delete' + id}
            >
              <BinSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + id}
            >
              Xóa đơn hàng
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: 'code',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.code}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, code: e.target.value })
            }
            placeholder="Mã đơn hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.name}
            onChange={(e) =>
              setValueFilter({ ...valueFilter, name: e.target.value })
            }
            placeholder="Tên đơn hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'customerOrganizationId.name',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={valueFilter.customerOrganizationId}
            onChange={(e) =>
              setValueFilter({
                ...valueFilter,
                customerOrganizationId: e.target.value,
              })
            }
            placeholder="Đơn vị thực hiện"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        width: 250,
      },
    },
    {
      dataField: 'startDate',
      text: 'Thời gian bắt đầu',
      formatter: (cell) => {
        return (
          <p className="mb-0 text-md-center h5 font-weight-400">
            {moment(new Date(cell)).format('DD/MM/YYYY')}
          </p>
        );
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    localStorage.setItem('pageOrder', JSON.stringify(1));
    localStorage.setItem('rowsPerPageOrder', JSON.stringify(value));
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      // setValueFilter({
      //   code: "",
      //   name: "",
      //   organ: "",
      // });
      setPage(value);
      localStorage.setItem('pageOrder', JSON.stringify(value));
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: !isNaN(orders?.totalResults) ? orders.totalResults : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > orders.totalResults
              ? !isNaN(orders?.totalResults)
                ? orders.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số {!isNaN(orders?.totalResults) ? orders.totalResults : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetOrders = () => {
    const tempQuery = _.cloneDeep(valueFilter);
    Object.keys(tempQuery).map((key, index) => {
      if (_.isEmpty(tempQuery[key])) {
        delete tempQuery[key];
      }
    });

    if (textSearch) {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...query, name: textSearch })
        )
      );
    } else {
      dispatch(orderActions.getOrders(queryString.stringify({ ...query })));
    }
  };
  const handleAdd = () => {
    history.push('/add-order');
  };
  useEffect(() => {
    handleGetOrders();
  }, [query]);

  useEffect(() => {
    if (!firstRender) {
      const delay = setTimeout(() => {
        const tempQuery = { ...valueFilter };
        Object.keys(tempQuery).map((key, index) => {
          if (_.isEmpty(tempQuery[key])) {
            delete tempQuery[key];
          }
        });
        localStorage.setItem('pageOrder', JSON.stringify(1));
        setPage(1);
        if (!_.isEmpty(tempQuery)) {
          setQuery({
            ...tempQuery,
            page: 1,
            limit: rowsPerPage,
            populate: 'customerOrganizationId,products.productTypeId',
          });
        } else {
          setQuery({
            page: 1,
            limit: rowsPerPage,
            populate: 'customerOrganizationId,products.productTypeId',
          });
        }
      }, 1000);
      return () => {
        clearTimeout(delay);
      };
    } else {
      setFirstRender(false);
    }
  }, [valueFilter]);

  const handleFilter = (optionFilter) => {
    const payload = _.cloneDeep(optionFilter);

    Object.keys(optionFilter).map((key, index) => {
      if (
        _.isEmpty(optionFilter[key]) &&
        typeof optionFilter[key] !== 'number'
      ) {
        delete optionFilter[key];
      } else {
        if (optionFilter[key].value) {
          optionFilter[key] = optionFilter[key].value;
        }
      }
    });

    setFilterRequest(payload);
    dispatch(
      orderActions.getOrders(
        queryString.stringify({ ...query, page: 1, ...optionFilter }),
        {
          success: () => {
            setPage(1);
            setOpenFilter(false);
          },
        }
      )
    );
  };

  // useEffect(() => {
  // 	const pageOrder = JSON.parse(localStorage.getItem("pageOrder"));
  // 	const rowsPerPageOrder = JSON.parse(
  // 		localStorage.getItem("rowsPerPageOrder")
  // 	);
  // 	if (
  // 		!pageOrder ||
  // 		pageOrder === "" ||
  // 		!rowsPerPageOrder ||
  // 		rowsPerPageOrder === ""
  // 	) {
  // 		setPage(1);
  // 		setRowsPerPage(10);
  // 		localStorage.setItem("pageOrder", JSON.stringify(1));
  // 		localStorage.setItem("rowsPerPageOrder", JSON.stringify(10));
  // 	} else {
  // 		setPage(pageOrder);
  // 		setRowsPerPage(rowsPerPageOrder);
  // 		setQuery({
  // 			...query,
  // 			page: pageOrder,
  // 			limit: rowsPerPageOrder,
  // 		});
  // 	}
  // }, [history]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="đơn hàng"
          func={handleDelete}
        />
      )}
      <SimpleHeader
        name=""
        parentName="Quản lý"
        handleAdd={
          checkRole(currentAccount, { roles: ['manage_order'] }) && handleAdd
        }
        handleFilter={toggleOpenFilter}
        options={filterRequest}
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                // bootstrap4
                data={orders.results}
                keyField="id"
                columns={columns}
                bootstrap4={true}
                search
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">Danh sách đơn hàng</h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      filter={filterFactory()}
                      pagination={pagination}
                      bordered={false}
                      hover
                      remote
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {openFilter && (
        <Filter
          handleClose={toggleOpenFilter}
          handleFilter={handleFilter}
          filterRequest={filterRequest}
        />
      )}
    </Style>
  );
}

export default ListOrder;
