import { AddSVG } from 'assets/svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { productActions } from 'Redux/Actions';
import queryString from 'query-string';
import * as yup from 'yup';
import { orderActions } from 'Redux/Actions';
import InputCustom from 'views/pages/components/InputCustom';
import { Formik } from 'formik';
const DialogUpdateResult = ({
  open,
  toggle,
  data,
  handleUpdate,
  isUpdateMeasureSize = false,
}) => {
  const [productId, setProductId] = useState({});
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productReducer);

  const [textSearchProducts, setTextSearchProducts] = useState('');
  const [payload, setPayload] = useState({
    price: '',
    quota: '',
    productTypeId: '',
    // barcode: data.barCode,
  });
  const [changed, setChanged] = useState({
    productTypeId: false,
  });
  const payloadSchema = yup.object().shape({
    price: yup
      .number()
      .moreThan(0, 'Đơn giá phải lớn hơn 0')
      .required('Trường này không được để trống'),
    quota: yup
      .number()
      .moreThan(0, 'Số lượng phải lớn hơn 0')
      .max(data.quota, 'Số lượng phải nhỏ hơn hoặc bằng  ban đầu')
      .required('Trường này không được để trống'),
    productTypeId: yup.string().required('Trường này không được để trống'),
  });
  const payloadSchema2 = yup.object().shape({
    price: yup.string().required('Trường này không được để trống'),
    quota: yup
      .number()

      .required('Trường này không được để trống'),
  });

  const handleGetProducts = () => {
    if (textSearchProducts === '') {
      dispatch(
        productActions.getProducts(queryString.stringify({ limit: 10 }))
      );
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({ limit: 10, name: textSearchProducts })
        )
      );
    }
  };
  useEffect(() => {
    handleGetProducts();
  }, [textSearchProducts]);
  const handleChangeInput = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setProductId({
      value: data?.productTypeId?.id,
      label: data?.productTypeId?.name,
    });
    setPayload({
      ...payload,
      // barcode: data?.barcode,
      productTypeId: data?.productTypeId?.id,
    });
  }, [data]);
  return (
    <Modal isOpen={open} toggle={toggle} size="lg">
      <Formik
        initialValues={payload}
        enableReinitialize
        onSubmit={(values) => {
          handleUpdate(values, data.id);
        }}
        validationSchema={payloadSchema}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          errors,
          touched,
          resetForm,
          handleBlur,
          setFieldTouched,
        }) => {
          return (
            <>
              <ModalHeader>
                <p className="h3 text-uppercase">
                  Cập nhật trạng thái chuyển đổi
                </p>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs={3}>
                    <p className="text-sm font-weight-500 h5 mb-3">Mã SP KH</p>
                  </Col>
                  <Col xs={9}>
                    <small>{data?.productTypeId?.code}</small>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <p className="text-sm font-weight-500 h5 mb-3">
                      Mã khách hàng
                    </p>
                  </Col>
                  <Col xs={9}>
                    <small>{data.customerCode}</small>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <p className="text-sm font-weight-500 h5 mb-3">
                      Tên khách hàng
                    </p>
                  </Col>
                  <Col xs={9}>
                    <small>{data.customerName}</small>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p className="text-sm font-weight-500 h5 mb-3">
                      Thông tin chuyển đổi
                    </p>
                  </Col>
                </Row>
                <div className="mx-4">
                  <Row>
                    <Col xs={6} style={{ borderRight: '1px solid #BABCBE' }}>
                      <small className="mb-3 d-block">
                        Chỉ tiêu theo hợp đồng
                      </small>
                      <Row>
                        <Col xs={6}>
                          <p className="text-sm font-weight-500 h5 mb-3">
                            Sản phẩm
                          </p>
                        </Col>
                        <Col xs={6}>
                          <small>{data.productTypeId?.name || 0}</small>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <p className="text-sm font-weight-500 h5 mb-3">
                            Số lượng
                          </p>
                        </Col>
                        <Col xs={6}>
                          <small>{data.quota}</small>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <p className="text-sm font-weight-500 h5 mb-3">
                            Đơn giá
                          </p>
                        </Col>
                        <Col xs={6}>
                          <small>
                            {data.price || data.productTypeId?.price || 0}
                          </small>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <p className="text-sm font-weight-500 h5 mb-3">
                            Thành tiền
                          </p>
                        </Col>
                        <Col xs={6}>
                          <small>
                            {data.price * data.quota ||
                              data.productTypeId?.price * data.quota ||
                              0 * data.quota}
                          </small>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <small className="mb-3 d-block">
                        Chỉ tiêu chuyển đổi
                      </small>

                      <ReactSelect
                        className="mb-2 mt--1 text-sm"
                        placeholder="Nhập tên tìm kiếm"
                        isClearable={true}
                        isDisabled={isUpdateMeasureSize}
                        name="productTypeId"
                        // value=
                        onInputChange={(value) => {
                          setTextSearchProducts(value);
                        }}
                        onBlur={handleBlur}
                        value={productId}
                        onChange={(e) => {
                          setProductId(e);
                          setFieldValue('productTypeId', e ? e.value : '');
                          // setFieldValue("barcode", e ? e.barCode : "");
                          setChanged({ ...changed, productTypeId: true });
                        }}
                        options={products.results.map((item) => ({
                          label: item.name,
                          value: item.id,
                          // barCode: item.code,
                        }))}
                      />
                      {changed.productTypeId &&
                        errors.productTypeId &&
                        values.productTypeId === '' && (
                          <div className="invalid-feedback d-block">
                            {'Trường này không được để trống'}
                          </div>
                        )}
                      <InputCustom
                        className="mb-2 max-height-input-custom"
                        size="sm"
                        type="number"
                        name="quota"
                        placeholder="Số lượng chuyển đổi"
                        invalid={touched.quota && errors.quota}
                        onBlur={handleBlur}
                        value={values.quota}
                        onChange={handleChange}
                        messageInvalid={touched.quota && errors.quota}
                      ></InputCustom>

                      <InputCustom
                        className="mb-2 max-height-input-custom"
                        type="number"
                        size="sm"
                        placeholder="Đơn giá mới"
                        name="price"
                        invalid={touched.price && errors.price}
                        onBlur={handleBlur}
                        value={values.price}
                        onChange={handleChange}
                        messageInvalid={touched.price && errors.price}
                      ></InputCustom>
                    </Col>
                  </Row>
                </div>

                <div className="mt-4">
                  <div className="text-sm font-weight-500 mb-2">Ghi chú</div>
                  <Input type="textarea" name="text" id="exampleText" />
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <Button onClick={toggle}>Hủy bỏ</Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setChanged({ ...changed, productTypeId: true });

                    handleSubmit();
                  }}
                >
                  Cập nhật
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DialogUpdateResult;
