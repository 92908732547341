export const manageDataProducts = {
  set: ({ name, data }) => {
    localStorage.setItem(name, JSON.stringify(data));
  },
  remove: (name) => {
    localStorage.removeItem(name);
  },
  get: (name) => JSON.parse(localStorage.getItem(name)) || [],
};
export const NAME_DATA = {
  FORM_RECEIVE_GC: 'form-receive-gc',
  FORM_RECEIVE_ERROR_GC: 'form-receive-error-gc',
  FORM_QC_QUALIFIED: 'form-qc-qualified',
  FORM_QC_UNQUALIFIED: 'form-qc-unqualified',
  FORM_TRANSFER_QC: 'form-transfer-qc',
  FORM_TRANSFER_QC_ERROR: 'form-transfer-qc-error',
  FORM_TRANSFER_CUSTOMER: 'form-transfer-customer',
};
