import TypeActions from '../TypeActions';

export const exportMeasureTemplate = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_MEASURE_TEMPLATE_REQUEST,
    params,
    query,
    callback,
  };
};

export const exportSewGeneralPrivate = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_SEW_GENERAL_PRIVATE_REQUEST,
    params,
    query,
    callback,
  };
};

export const exportSuggest = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_SUGGGEST_REQUEST,
    params,
    query,
    callback,
  };
};
export const exportProductionCommand = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_PRODUCTION_COMMAND_REQUEST,
    params,
    query,
    callback,
  };
};
export const exportProductSendCustomer = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_PRODUCT_SEND_CUSTOMER_REQUEST,
    params,
    query,
    callback,
  };
};

export const exportAggrementPlan = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_AGGREMENT_PLAN_REQUEST,
    params,
    query,
    callback,
  };
};

export const exportDetailPlan = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_DETAIL_PLAN_REQUEST,
    params,
    query,
    callback,
  };
};

export const exportQcCompleteProductsError = (params, query, callback) => {
  return {
    type: TypeActions.EXPORT_QC_COMPLETE_PROD_ERROR_REQUEST,
    params,
    query,
    callback,
  };
};

const exportTemplateOrderPrint = (param, query, callback) => {
  return {
    type: TypeActions.EXPORT_TEMPLATE_ORDER_PRINT_REQUEST,
    param,
    query,
    callback,
  };
};

const exportOrderDetail = (param, query, callback) => {
  return {
    type: TypeActions.EXPORT_ORDER_DETAIL_REQUEST,
    param,
    query,
    callback,
  };
};

export default {
  exportMeasureTemplate,
  exportSewGeneralPrivate,
  exportSuggest,
  exportProductSendCustomer,
  exportAggrementPlan,
  exportDetailPlan,
  exportQcCompleteProductsError,
  exportTemplateOrderPrint,
  exportOrderDetail,
};
