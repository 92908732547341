import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import filterFactory from 'react-bootstrap-table2-filter';
import { Row, Col, Input, UncontrolledTooltip, CardBody } from 'reactstrap';
import { Edit2SVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import orderActions from 'Redux/Actions/orderActions';
import _ from 'lodash';
import queryString from 'query-string';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import DialogUpdateDetailMeasure from './DialogUpdateDetailMeasure';
import constants from 'constant';
import { LogoutCircle } from 'assets/svg';
import ReactNotificationAlert from 'react-notification-alert';
import ModalConfirm from 'views/pages/components/ModalConfirm';
import { notify } from 'common';
import { checkRole } from 'common';

function ListResidual({
  currentOrder,
  loading,
  setLoading,
  filter,
  setOpenFilter,
  setAllowClick,
  selectItems,
  setSelectItems,
}) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const notificationAlertRef = useRef();
  const dispatch = useDispatch();
  const { unusualList } = useSelector((state) => state.orderReducer);

  const [listSize, setListSizes] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentMeasure, setCurrentMeasure] = useState({});
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const toggleConfirmModal = () => {
    setIsOpenConfirmModal(!isOpenConfirmModal);
  };
  const toggleOpenDialogUpdate = () => {
    setOpenDialogUpdate(!openDialogUpdate);
  };
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    customerInOrderSizeStatus:
      constants.CUSTOMER_IN_ORDER_SIZE_STATUS.FailedAssigned,
    populate:
      'sizes.productParameterId,customerOrgId.parentId,productTypeId,customerSizeId.sizes.productParameterId',
  });
  const [firstCalled, setFirstCalled] = useState(false);
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
  });
  const handleUpdateMeasure = (data, id) => {
    const [...payload] = data;
    dispatch(
      orderActions.updateCustomerInOrder({ sizes: payload }, id, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật số đo thành công!`
          );
          toggleOpenDialogUpdate();
          handleGetUnusualList();
        },
        failed: (err) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật số đo thất bại!!! Lỗi: ${err}`
          );
        },
      })
    );
  };
  const handleConvertOwnDesign = (id, vipValue) => {
    dispatch(
      orderActions.convertOwnDesign(
        id,
        { sewType: vipValue },
        {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Chuyển may thêm thành công!`
            );
            toggleConfirmModal();
            handleGetUnusualList();
          },
          failed: (err) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Chuyển may thêm thất bại!!! Lỗi: ${err}`
            );
          },
        }
      )
    );
  };
  const handleGetUnusualList = () => {
    const payload = { ...query };
    if (payload.orderId == '') {
      delete payload['orderId'];
      return;
    }
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getUnusualList(
        queryString.stringify({ ...payload, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
            setFirstCalled(true);
          },
        }
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(unusualList.results) && _.isEmpty(listSize)) {
      const list = unusualList.results.map((item) => {
        const arr1 = item.sizes.map((i) => {
          return {
            name: i.productParameterId.name,
            id: i.productParameterId.id,
            code: i.productParameterId.code,
          };
        });
        const arr2 =
          item?.customerSizeId?.sizes?.map((i) => {
            return {
              name: i.productParameterId.name,
              id: i.productParameterId.id,
              code: i.productParameterId.code,
            };
          }) || [];

        return arr1.concat(arr2);
      });

      let results = [];
      list.forEach((item) => {
        results = results.concat(item);
      });
      results = results.filter(
        (v, i, a) => a.findIndex((item) => _.isEqual(item, v)) === i
      );
      // results = results.filter((v, i, a) => a.indexOf(v) === i);

      setListSizes(results);
      if (unusualList.results.length > 0) {
        setAllowClick(true);
      } else {
        setAllowClick(false);
      }
    }
  }, [unusualList, listSize]);
  useEffect(() => {
    setListSizes([]);
    handleGetUnusualList();
  }, [query]);
  useEffect(() => {
    if (loading) handleGetUnusualList();
  }, [loading]);

  const onSizePerPageChange = (value) => {
    setSelectItems([]);
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setSelectItems([]);
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: unusualList?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="999999999">Tất cả</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > unusualList.totalResults
              ? !isNaN(unusualList?.totalResults)
                ? unusualList.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(unusualList?.totalResults) ? unusualList.totalResults : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const temp = [...selectItems, row.id];
        setSelectItems(temp);
      } else {
        let temp = [...selectItems];

        const index = selectItems.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
        setSelectItems(temp);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const temp = rows.map((item) => item.id);
        setSelectItems(temp);
      } else {
        setSelectItems([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="text-md-right" style={{ verticalAlign: 'inherit' }}>
        Lựa chọn{' '}
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="d-flex justify-content-end">
        <input type={mode} {...rest} />
      </div>
    ),
    selected: selectItems,
  };

  const boxAction = (cell, row, id) => {
    return (
      checkRole(currentAccount, { roles: ['manage_customerInOrder'] }) && (
        <>
          <button
            className="btn-none"
            id={'own' + id}
            onClick={() => {
              setCurrentMeasure(row);
              toggleConfirmModal();
            }}
          >
            <LogoutCircle />
          </button>
          <UncontrolledTooltip delay={1} placement="top" target={'own' + id}>
            May riêng
          </UncontrolledTooltip>
          <button
            className="btn-none"
            id={'edit' + id}
            onClick={() => {
              toggleOpenDialogUpdate();
              setCurrentMeasure(row);
            }}
          >
            <Edit2SVG />
          </button>
          <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
            Cập nhật số đo
          </UncontrolledTooltip>
        </>
      )
    );
  };
  const columns = [
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center' },
      headerAlign: 'center',
    },
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerCode: e.target.value })
            }
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 160,
        width: 180,
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerName',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerName: e.target.value })
            }
            placeholder="Tên khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 160,
        width: 180,
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'productTypeId.name',
      text: 'Sản phẩm',
      headerStyle: {
        minWidth: 120,
      },
      style: {
        // textAlign: "left",
      },
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
      headerStyle: {
        minWidth: 120,
      },
      style: {
        // textAlign: "left",
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Đơn vị/Phòng ban',
    },
    {
      dataField: 'age',
      text: 'Tuổi',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    ...listSize.map((item) => {
      return {
        dataField: item.code,
        text: item.name,
        headerStyle: {
          maxWidth: 55,
          whiteSpace: 'initial',
          textAlign: 'center',
        },
        formatter: (cell, row) => {
          const sd1 =
            row?.sizes?.find(
              (value) => value.productParameterId.code === item.code
            )?.size || '';
          const sd2 =
            row?.customerSizeId?.sizes?.find(
              (value) => value.productParameterId.code === item.code
            )?.size || '';
          return (
            sd1 && (
              <>
                {sd1 === sd2 ? (
                  <>{sd1 || sd2}</>
                ) : (
                  <>
                    <>{sd1 || sd2}</>
                    {sd1 && sd2 && (
                      <p className="text-danger h5 font-weight-400">{sd2}</p>
                    )}
                  </>
                )}
              </>
            )
          );
        },
        style: { textAlign: 'center' },
      };
    }),
  ];

  useEffect(() => {
    const tempQuery = { ...searchValues };
    Object.keys(tempQuery).map((key, index) => {
      if (_.isEmpty(tempQuery[key])) {
        delete tempQuery[key];
      }
    });
    setPage(1);
    if (_.isEmpty(currentOrder)) {
      setQuery({
        page: 1,
        limit: rowsPerPage,
        customerInOrderSizeStatus:
          constants.CUSTOMER_IN_ORDER_SIZE_STATUS.FailedAssigned,
        populate:
          'sizes.productParameterId,customerOrgId.parentId,productTypeId,customerSizeId.sizes.productParameterId',
      });
      return;
    }

    setQuery({
      page: 1,
      limit: rowsPerPage,
      orderId: currentOrder,
      customerInOrderSizeStatus:
        constants.CUSTOMER_IN_ORDER_SIZE_STATUS.FailedAssigned,
      populate:
        'sizes.productParameterId,customerOrgId.parentId,productTypeId,customerSizeId.sizes.productParameterId',
    });
  }, [currentOrder]);

  const handleFilter = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getUnusualList(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    firstCalled && handleFilter();
  }, [filter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // const tempQuery = { ...searchValues };
      // Object.keys(tempQuery).map((key, index) => {
      // 	if (_.isEmpty(tempQuery[key])) {
      // 		delete tempQuery[key];
      // 	}
      // });
      // setPage(1);
      // setQuery({
      // 	...tempQuery,
      // 	page: 1,
      // 	limit: rowsPerPage,
      // 	orderId: currentOrder,
      // 	customerInOrderSizeStatus:
      // 		constants.CUSTOMER_IN_ORDER_SIZE_STATUS.FailedAssigned,
      // 	populate:
      // 		"sizes.productParameterId,customerOrgId.parentId,productTypeId,customerSizeId.sizes.productParameterId",
      // });
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getUnusualList(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <CardBody className="p-0 pb-3">
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <ModalConfirm
        open={isOpenConfirmModal}
        toggle={toggleConfirmModal}
        name="Bạn chắc chắn muốn chuyển đổi may thêm ? "
        handle={(vipValue) => {
          handleConvertOwnDesign(currentMeasure.id, vipValue);
        }}
      />
      <ToolkitProvider
        data={
          unusualList.results
          // 	.filter((item) => {
          //   return (
          //     item?.customerName
          //       ?.toLowerCase()
          //       .indexOf(searchValues.name.toLowerCase()) !== -1 &&
          //     item?.customerCode
          //       ?.toLowerCase()
          //       .indexOf(searchValues.code.toLowerCase()) !== -1
          //   );
          // })
        }
        keyField="id"
        columns={columns}
        search
      >
        {(props) => (
          <>
            <BootstrapTable
              {...props.baseProps}
              noDataIndication={() => {
                return (
                  <span className="font-weight-bold text-danger">
                    Không có dữ liệu!
                  </span>
                );
              }}
              onTableChange={() => {
                return <div className="spinner-border text-info" />;
              }}
              hover
              filter={filterFactory()}
              bootstrap4={true}
              pagination={pagination}
              bordered={false}
              selectRow={selectRow}
              remote
            />
          </>
        )}
      </ToolkitProvider>
      {!_.isEmpty(currentMeasure) && (
        <DialogUpdateDetailMeasure
          open={openDialogUpdate}
          toggle={toggleOpenDialogUpdate}
          data={currentMeasure}
          isUpdate={true}
          handleUpdate={handleUpdateMeasure}
        />
      )}
      {/* <DialogUpdateResult
				open={isOpenModal}
				data={dataModal}
				toggle={handleCloseModal}
			/> */}
    </CardBody>
  );
}

export default ListResidual;
