import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Row, Col, Input, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ViewSVG } from 'assets/svg';
import DialogUpdateResult from './DialogUpdateResult';
import { Edit2SVG } from 'assets/svg';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions } from 'Redux/Actions';
import _ from 'lodash';
import { AddSVG } from 'assets/svg';
import DialogDetailMeasure from './DialogDetailMeasure';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import DialogUpdateSewMore from './DialogUpdateSewMore';
import moment from 'moment';
import { checkRole } from 'common';
// const { SearchBar } = Search;
function ListResultMeasure({ currentOrders, filter, setOpenFilter }) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const notificationAlertRef = useRef(null);
  const [current, setCurrent] = useState({});
  const [isShowDetailMeasure, setIsShowDetailMeasure] = useState(false);
  const [isShowDialogAddSew, setIsShowDialogAddSew] = useState(false);
  const [firstCalled, setFirstCalled] = useState(false);
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate:
      'customerOrgId.parentId,productTypeId,productTypeId.productTagIds,sizes.productParameterId,customerSizeId.sizes.productParameterId,updatedBy',
  });

  const { customersInOrder, isGetCustomersInOrder } = useSelector(
    (state) => state.orderReducer
  );

  const handleGetCustomerInOrder = () => {
    const payload = { ...query };
    if (payload.orderId == '') {
      delete payload['orderId'];
      return;
    }
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({ ...payload, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
            setFirstCalled(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    handleGetCustomerInOrder();
  }, [query]);
  const toggleShowDetailMeasure = () => {
    setIsShowDetailMeasure(!isShowDetailMeasure);
  };

  const toggleDialogAddSew = () => {
    setIsShowDialogAddSew(!isShowDialogAddSew);
  };

  const handleView = (data) => {
    setCurrent(data);
    toggleShowDetailMeasure();
  };

  const handleEdit = (data) => {
    setIsOpenModal(true);
    setDataModal(data);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setDataModal({});
  };
  const boxAction = (cell, row, id) => {
    return (
      <>
        <button
          className="btn-none"
          id={'view' + id}
          onClick={() => handleView(row)}
        >
          <ViewSVG />
        </button>
        <UncontrolledTooltip delay={1} placement="top" target={'view' + id}>
          Xem chi tiết
        </UncontrolledTooltip>
        {checkRole(currentAccount, { roles: ['manage_customerInOrder'] }) && (
          <>
            <button
              className="btn-none"
              id={'edit' + id}
              onClick={() => {
                handleEdit(row);
              }}
            >
              <Edit2SVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Chuyển đổi số đo
            </UncontrolledTooltip>
            <button
              className="btn-none"
              id={'add' + id}
              onClick={() => {
                toggleDialogAddSew();
                setCurrent(row);
              }}
            >
              <AddSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'add' + id}>
              May thêm
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const columns = [
    {
      dataField: 'actions',
      text: 'Hành động',
      formatter: boxAction,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerCode: e.target.value })
            }
            placeholder="Mã khách hàng"
          />
        );
      },
      style: { textAlign: 'left' },
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerName: e.target.value })
            }
            placeholder="Tên khách hàng"
          />
        );
      },
      style: { textAlign: 'left' },
      headerStyle: {
        minWidth: 120,
        maxWidth: 120,
      },
    },
    {
      dataField: 'age',
      text: 'Tuổi',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'gender',
      text: 'Giới tính',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
      headerStyle: {
        minWidth: 180,
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Đơn vị/phòng ban',
    },
    {
      dataField: 'barcode',
      text: 'Mã SP KH',
      headerStyle: {
        minWidth: 100,
        // textAlign: "center",
      },
    },
    {
      dataField: 'productTypeId.name',
      text: 'Tên sản phẩm',
      headerStyle: {
        minWidth: 160,
        // textAlign: "center",
      },
    },
    {
      dataField: 'quota',
      text: 'SL HĐ',
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'updatedAt',
      text: 'Ngày cập nhật',
      formatter: (cell) => {
        return <>{moment(cell).format('DD/MM/YYYY')}</>;
      },
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      dataField: 'updatedBy.name',
      text: 'Người cập nhật',
    },
  ];

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: !isNaN(customersInOrder?.totalResults)
      ? customersInOrder?.totalResults
      : 0,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > customersInOrder.totalResults
              ? !isNaN(customersInOrder?.totalResults)
                ? customersInOrder.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(customersInOrder?.totalResults)
              ? customersInOrder.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleUpdate = (payload, id) => {
    dispatch(
      orderActions.convertMeasureSize(payload, id, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Cập nhật chuyển đổi thành công!`
          );
          handleGetCustomerInOrder();
          handleCloseModal();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Cập nhật chuyển đổi thất bại. Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleAddSewMeasure = (payload, id) => {
    dispatch(
      orderActions.addSewMeasureSize(payload, id, {
        success: () => {
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Đặt may thêm thành công!`
          );
          handleGetCustomerInOrder();
          toggleDialogAddSew();
        },
        failed: () => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Đặt may thêm thất bại!`
          );
        },
      })
    );
  };

  useEffect(() => {
    if (_.isEmpty(currentOrders)) {
      setQuery({
        page: 1,
        limit: rowsPerPage,
        populate:
          'customerOrgId.parentId,productTypeId,productTypeId.productTagIds,sizes.productParameterId,customerSizeId.sizes.productParameterId,updatedBy',
      });
      return;
    }
    setQuery({
      page: 1,
      limit: rowsPerPage,
      orderId: currentOrders,
      // productTypeId: filter?.product?.value,
      populate:
        'customerOrgId.parentId,productTypeId,productTypeId.productTagIds,sizes.productParameterId,customerSizeId.sizes.productParameterId,updatedBy',
    });
  }, [currentOrders]);

  const handleFilter = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    firstCalled && handleFilter();
  }, [filter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getCustomersInOrder(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <ToolkitProvider
        bootstrap4
        data={customersInOrder.results}
        keyField="id"
        columns={columns}
        search
      >
        {(props) => (
          <>
            <div className="pb-3">
              <BootstrapTable
                {...props.baseProps}
                noDataIndication={() => {
                  return (
                    <span className="font-weight-bold text-danger">
                      Không có dữ liệu!
                    </span>
                  );
                }}
                onTableChange={() => {
                  return <div className="spinner-border text-info" />;
                }}
                hover
                remote
                filter={filterFactory()}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </div>
          </>
        )}
      </ToolkitProvider>

      {isOpenModal && (
        <DialogUpdateResult
          open={isOpenModal}
          data={dataModal}
          toggle={handleCloseModal}
          handleUpdate={handleUpdate}
        />
      )}

      {isShowDetailMeasure && (
        <DialogDetailMeasure
          open={isShowDetailMeasure}
          toggle={toggleShowDetailMeasure}
          data={current}
        />
      )}
      {isShowDialogAddSew && (
        <DialogUpdateSewMore
          open={isShowDialogAddSew}
          toggle={toggleDialogAddSew}
          data={current}
          handleAddSewMeasure={handleAddSewMeasure}
        />
      )}
    </>
  );
}

export default ListResultMeasure;
