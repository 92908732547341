import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  CardBody,
  CardHeader,
  Modal,
} from "reactstrap";
import InputCustom from "views/pages/components/InputCustom";
import LoadingButtonCustom from "views/pages/components/LoadingButtonCustom";
import { Formik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import CONSTANTS from "constant";
import { producerActions } from "Redux/Actions";
import { notify } from "common";
import Error from "views/pages/components/Error";
import RequireCustom from "views/pages/components/RequireCustom";
const FormProducer = ({
  setFormModal,
  formModal,
  isModalAdd,
  producer,
  handleGetProducers,
  notificationAlertRef,
}) => {
  const { isCreateProducer, isUpdateProducer } = useSelector(
    (state) => state.producerReducer
  );
  const dispatch = useDispatch();

  const producerSchema = yup.object().shape({
    code: yup.string().required("Vui lòng nhập mã nhà sản xuất!"),
    name: yup.string().required("Vui lòng nhập tên nhà sản xuất!"),
    address: yup.string().required("Vui lòng nhập địa chỉ nhà sản xuất!"),
    phone: yup
      .string()
      .length(10, "Số điện thoại bao gồm 10 số!")
      .required("Số điện thoại không được để trống!")
      .matches("0[1-9][0-9]{8}", "Vui lòng nhập đúng định dạng số điện thoại!"),
    contactPerson: yup.string().required("Vui lòng nhập tên người liên hệ!"),
    capacity: yup
      .string()
      .required("Vui lòng nhập công xuất gia công nhà sản xuất!"),
  });

  // const notificationAlertRef = useRef(null);
  const [producerInfo, setProducerInfo] = useState({
    code: "",
    name: "",
    address: "",
    phone: "",
    contactPerson: "",
    capacity: "",
    notes: "",
  });

  const [types, setTypes] = useState({
    cut: true,
    sew: false,
  });
  const [errorType, setErrorType] = useState("");

  useEffect(() => {
    if (!_.isEmpty(producer)) {
      setProducerInfo({
        code: producer.code,
        name: producer.name,
        address: producer.address,
        phone: producer.phone,
        contactPerson: producer.contactPerson,
        capacity: producer.capacity,
        notes: producer.notes,
      });
      if (producer.type === CONSTANTS.PRODUCER_TYPE.ALL) {
        setTypes({
          cut: true,
          sew: true,
        });
      } else if (producer.type === CONSTANTS.PRODUCER_TYPE.CUT) {
        setTypes({
          cut: true,
          sew: false,
        });
      } else if (producer.type === CONSTANTS.PRODUCER_TYPE.PRODUCTION) {
        setTypes({
          cut: false,
          sew: true,
        });
      }
    }
  }, [producer]);

  const onSubmit = (values, actions) => {
    if (checkErrorType(types.cut, types.sew)) {
      return;
    }
    const body = _.cloneDeep(values);
    if (types.cut && types.sew) {
      body.type = CONSTANTS.PRODUCER_TYPE.ALL;
    } else if (types.cut) {
      body.type = CONSTANTS.PRODUCER_TYPE.CUT;
    } else if (types.sew) {
      body.type = CONSTANTS.PRODUCER_TYPE.PRODUCTION;
    }
    delete body.id;
    isModalAdd
      ? dispatch(
          producerActions.createProducer(body, {
            success: () => {
              actions.resetForm();
              clearData();
              notify(
                notificationAlertRef,
                "success",
                "Thông báo",
                `Thêm nhà sản xuất thành công!`
              );
              handleGetProducers();
              setFormModal(false);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                "danger",
                "Thông báo",
                `Thêm nhà sản xuất thất bại. Lỗi ${mess}!`
              );
            },
          })
        )
      : dispatch(
          producerActions.updateProducer(body, producer.id, {
            success: () => {
              actions.resetForm();
              clearData();
              notify(
                notificationAlertRef,
                "success",
                "Thông báo",
                `Cập nhật nhà sản xuất thành công!`
              );
              handleGetProducers();
              setFormModal(false);
            },
            failed: (mess) => {
              notify(
                notificationAlertRef,
                "danger",
                "Thông báo",
                `Cập nhật nhà sản xuất thất bại. Lỗi ${mess}!`
              );
            },
          })
        );
  };

  const checkErrorType = (cut, sew) => {
    if (!cut && !sew) {
      setErrorType("Vui lòng chọn thể loại nhà gia công!");
      return true;
    } else {
      setErrorType("");
      return false;
    }
  };

  const clearData = () => {
    setProducerInfo({
      code: "",
      name: "",
      address: "",
      phone: "",
      contactPerson: "",
      capacity: "",
      notes: "",
    });
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={formModal}
        toggle={() => {
          setFormModal(false);
        }}
      >
        <div className="modal-body p-0">
          <Card className="bg-white border-0 mb-0">
            <CardHeader className="bg-transparent pb-2">
              <h2 className="mb-0">
                {isModalAdd
                  ? "Thêm mới nhà sản xuất"
                  : "Cập nhật thông tin nhà sản xuất"}
              </h2>
            </CardHeader>
            <Formik
              initialValues={producerInfo}
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={producerSchema}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
                resetForm,
                handleBlur,
                setFieldTouched,
              }) => {
                return (
                  <>
                    <CardBody className="px-lg-5 py-lg-3">
                      <Form className="needs-validation" noValidate>
                        <Row className="justify-content-center">
                          <Col>
                            <Row className="mt-2">
                              <Col md="6">
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Mã nhà sản xuất"
                                  required={<RequireCustom />}
                                  placeholder="Nhập mã"
                                  type="text"
                                  id="code"
                                  name="code"
                                  invalid={errors.code && touched.code}
                                  messageInvalid={errors.code}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("code", e.target.value);
                                  }}
                                  value={values.code}
                                />
                              </Col>
                              <Col md="6" />
                              <Col md="6">
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Tên nhà sản xuất"
                                  required={<RequireCustom />}
                                  placeholder="Nhập tên"
                                  type="text"
                                  id="name"
                                  name="name"
                                  invalid={errors.name && touched.name}
                                  messageInvalid={errors.name}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("name", e.target.value);
                                  }}
                                  value={values.name}
                                />
                              </Col>
                              <Col md="6">
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Điện thoại"
                                  required={<RequireCustom />}
                                  placeholder="Nhập số điện thoại"
                                  type="text"
                                  name="phone"
                                  id="phone"
                                  invalid={errors.phone && touched.phone}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("phone", e.target.value);
                                  }}
                                  value={values.phone}
                                  messageInvalid={errors.phone}
                                />
                              </Col>
                              <Col md="6">
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Nhười liên hệ"
                                  required={<RequireCustom />}
                                  name="contactPerson"
                                  placeholder="Nhập tên"
                                  type="text"
                                  id="contactPerson"
                                  invalid={
                                    errors.contactPerson &&
                                    touched.contactPerson
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "contactPerson",
                                      e.target.value
                                    );
                                  }}
                                  value={values.contactPerson}
                                  messageInvalid={errors.contactPerson}
                                />
                              </Col>
                              <Col md="6">
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Công xuất gia công"
                                  required={<RequireCustom />}
                                  name="capacity"
                                  placeholder="Nhập công xuất gia công"
                                  type="text"
                                  id="capacity"
                                  invalid={errors.capacity && touched.capacity}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("capacity", e.target.value);
                                  }}
                                  value={values.capacity}
                                  messageInvalid={errors.capacity}
                                />
                              </Col>
                              <Col className="mt-2" md="12">
                                <label className="form-control-label">
                                  Thể loại
                                  <RequireCustom />
                                </label>
                                <div className="d-flex justify-content-center align-items-center">
                                  <input
                                    id="type-cut"
                                    type="checkbox"
                                    checked={types.cut}
                                    onChange={(e) => {
                                      setTypes({
                                        ...types,
                                        cut: e.target.checked,
                                      });
                                      checkErrorType(
                                        e.target.checked,
                                        types.sew
                                      );
                                    }}
                                  />
                                  <h5 className="mb-0 ml-2">
                                    Nhà gia công cắt
                                  </h5>
                                  &emsp;
                                  <input
                                    id="type-sew"
                                    type="checkbox"
                                    checked={types.sew}
                                    onChange={(e) => {
                                      setTypes({
                                        ...types,
                                        sew: e.target.checked,
                                      });
                                      checkErrorType(
                                        types.cut,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  <h5 className="mb-0 ml-2">
                                    Nhà gia công may
                                  </h5>
                                </div>
                              </Col>
                              <Col md="12">
                                {errorType !== "" && (
                                  <Error messageInvalid={errorType} />
                                )}
                              </Col>
                              <Col md={12}>
                                <InputCustom
                                  className="max-height-input-custom"
                                  label="Địa chỉ"
                                  required={<RequireCustom />}
                                  name="address"
                                  placeholder="Nhập địa chỉ"
                                  type="text"
                                  rows="4"
                                  id="address"
                                  invalid={errors.address && touched.address}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue("address", e.target.value);
                                  }}
                                  value={values.address}
                                  messageInvalid={errors.address}
                                />
                              </Col>

                              <Col md="12">
                                <InputCustom
                                  label="Ghi chú"
                                  name="notes"
                                  placeholder="Nhập ghi chú"
                                  type="textarea"
                                  rows="4"
                                  id="notes"
                                  invalid={errors.notes && touched.notes}
                                  onChange={(e) => {
                                    setFieldValue("notes", e.target.value);
                                  }}
                                  value={values.notes}
                                  messageInvalid={errors.notes}
                                />
                              </Col>
                            </Row>
                            {/* </Card> */}
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mt-2">
                          <Button
                            onClick={() => {
                              if (isCreateProducer || isUpdateProducer) {
                                return;
                              }
                              clearData();
                              resetForm();
                              setFormModal(false);
                            }}
                            color=""
                            size="md"
                            type="button"
                          >
                            Hủy
                          </Button>
                          <LoadingButtonCustom
                            loading={isCreateProducer || isUpdateProducer}
                            onClick={handleSubmit}
                            color="primary"
                            size="md"
                            type="button"
                          >
                            Lưu
                          </LoadingButtonCustom>
                        </Row>
                      </Form>
                    </CardBody>
                  </>
                );
              }}
            </Formik>
          </Card>
        </div>
      </Modal>

      {/* </Container> */}
    </>
  );
};

export default FormProducer;
