import TypeActions from "../TypeActions";

const initialState = {
  completeStages: {
    results: [],
  },
  completePackings: {
    results: [],
  },
  completeProductsError: {
    results: [],
  },
  productsDelivering: {
    results: [],
  },
  productsDelivered: {
    results: [],
  },
  completeStageById: {},
  isGetCompleteStages: false,
  isGetCompletePackings: false,
  isGetCompleteProductsError: false,
  isGetCompleteStageById: false,
  isCreateCompleteStage: false,
  isUpdateCompleteStage: false,
  isDeleteCompleteStage: false,
  customerByBarcodeCompleteStage: {},
  isGetCustomerByBarcodeCompleteStage: false,
  isTransferQCSP: false,
  isGetProductsDelivering: false,
  isGetProductsDelivered: false,
  errors: {
    getCompleteStages: "",
    getCompletePackings: "",
    getCompleteProductsError: "",
    getcompleteStageById: "",
    createCompleteStage: "",
    updateCompleteStage: "",
    deleteCompleteStage: "",
    getCustomerByBarcodeCompleteStage: "",
    transferQCSP: "",
    productsDelivering: "",
    productsDelivered: "",
  },
};

export const completeStageReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_COMPLETE_STAGES_REQUEST:
      return {
        ...state,
        // completeStages: { results: [] },
        isGetCompleteStages: true,
        errors: { ...state.errors, getCompleteStages: "" },
      };
    case TypeActions.GET_COMPLETE_STAGES_SUCCESS:
      return {
        ...state,
        completeStages: actions.data || { results: [] },
        isGetCompleteStages: false,
        errors: { ...state.errors, getCompleteStages: "" },
      };
    case TypeActions.GET_COMPLETE_STAGES_FAILED:
      return {
        ...state,
        completeStages: { results: [] },
        isGetCompleteStages: false,
        errors: { ...state.errors, getCompleteStages: actions.error },
      };

    case TypeActions.GET_COMPLETE_STAGE_BY_ID_REQUEST:
      return {
        ...state,
        // completeStageById: {},
        isGetCompleteStageById: true,
        errors: { ...state.errors, getcompleteStageById: "" },
      };
    case TypeActions.GET_COMPLETE_STAGE_BY_ID_SUCCESS:
      return {
        ...state,
        completeStageById: actions.data || {},
        isGetCompleteStageById: false,
        errors: { ...state.errors, getcompleteStageById: "" },
      };
    case TypeActions.GET_COMPLETE_STAGE_BY_ID_FAILED:
      return {
        ...state,
        completeStageById: {},
        isGetCompleteStageById: false,
        errors: { ...state.errors, getcompleteStageById: actions.error },
      };

    case TypeActions.CREATE_COMPLETE_STAGE_REQUEST:
      return {
        ...state,
        isCreateCompleteStage: true,
        errors: { ...state.errors, createCompleteStage: "" },
      };
    case TypeActions.CREATE_COMPLETE_STAGE_SUCCESS:
      return {
        ...state,
        isCreateCompleteStage: false,
        errors: { ...state.errors, createCompleteStage: "" },
      };
    case TypeActions.CREATE_COMPLETE_STAGE_FAILED:
      return {
        ...state,
        isCreateCompleteStage: false,
        errors: { ...state.errors, createCompleteStage: actions.error },
      };

    case TypeActions.UPDATE_COMPLETE_STAGE_REQUEST:
      return {
        ...state,
        isUpdateCompleteStage: true,
        errors: { ...state.errors, updateCompleteStage: "" },
      };
    case TypeActions.UPDATE_COMPLETE_STAGE_SUCCESS:
      return {
        ...state,
        isUpdateCompleteStage: false,
        errors: { ...state.errors, updateCompleteStage: "" },
      };
    case TypeActions.UPDATE_COMPLETE_STAGE_FAILED:
      return {
        ...state,
        isUpdateCompleteStage: false,
        errors: { ...state.errors, updateCompleteStage: actions.error },
      };

    case TypeActions.DELETE_COMPLETE_STAGE_REQUEST:
      return {
        ...state,
        isDeleteCompleteStage: true,
        errors: { ...state.errors, deleteCompleteStage: "" },
      };
    case TypeActions.DELETE_COMPLETE_STAGE_SUCCESS:
      return {
        ...state,
        isDeleteCompleteStage: false,
        errors: { ...state.errors, deleteCompleteStage: "" },
      };
    case TypeActions.DELETE_COMPLETE_STAGE_FAILED:
      return {
        ...state,
        isDeleteCompleteStage: false,
        errors: { ...state.errors, deleteCompleteStage: actions.error },
      };

    case TypeActions.GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_REQUEST:
      return {
        ...state,
        // customerByBarcodeCompleteStage: {},
        isGetCustomerByBarcodeCompleteStage: true,
        errors: { ...state.errors, getCustomerByBarcodeCompleteStage: "" },
      };
    case TypeActions.GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_SUCCESS:
      return {
        ...state,
        customerByBarcodeCompleteStage: actions.data || {},
        isGetCustomerByBarcodeCompleteStage: false,
        errors: { ...state.errors, getCustomerByBarcodeCompleteStage: "" },
      };
    case TypeActions.GET_CUSTOMER_BY_BARCODE_COMPLETE_STAGE_FAILED:
      return {
        ...state,
        customerByBarcodeCompleteStage: {},
        isGetCustomerByBarcodeCompleteStage: false,
        errors: {
          ...state.errors,
          getCustomerByBarcodeCompleteStage: actions.error,
        },
      };

    case TypeActions.TRANSFER_QC_SP_REQUEST:
      return {
        ...state,
        isTransferQCSP: true,
        errors: { ...state.errors, transferQCSP: "" },
      };
    case TypeActions.TRANSFER_QC_SP_SUCCESS:
      return {
        ...state,
        isTransferQCSP: false,
        errors: { ...state.errors, transferQCSP: "" },
      };
    case TypeActions.TRANSFER_QC_SP_FAILED:
      return {
        ...state,
        isTransferQCSP: false,
        errors: { ...state.errors, transferQCSP: actions.error },
      };

    case TypeActions.GET_COMPLETE_PACKINGS_REQUEST:
      return {
        ...state,
        // completePackings: { results: [] },
        isGetCompletePackings: true,
        errors: { ...state.errors, getCompletePackings: "" },
      };
    case TypeActions.GET_COMPLETE_PACKINGS_SUCCESS:
      return {
        ...state,
        completePackings: actions.data || { results: [] },
        isGetCompletePackings: false,
        errors: { ...state.errors, getCompletePackings: "" },
      };
    case TypeActions.GET_COMPLETE_PACKINGS_FAILED:
      return {
        ...state,
        completePackings: { results: [] },
        isGetCompletePackings: false,
        errors: { ...state.errors, getCompletePackings: actions.error },
      };

    case TypeActions.GET_COMPLETE_PRODUCTS_ERROR_REQUEST:
      return {
        ...state,
        // completeProductsError: { results: [] },
        isGetCompleteProductsError: true,
        errors: { ...state.errors, getCompleteProductsError: "" },
      };
    case TypeActions.GET_COMPLETE_PRODUCTS_ERROR_SUCCESS:
      return {
        ...state,
        completeProductsError: actions.data || { results: [] },
        isGetCompleteProductsError: false,
        errors: { ...state.errors, getCompleteProductsError: "" },
      };
    case TypeActions.GET_COMPLETE_PRODUCTS_ERROR_FAILED:
      return {
        ...state,
        completeProductsError: { results: [] },
        isGetCompleteProductsError: false,
        errors: { ...state.errors, getCompleteProductsError: actions.error },
      };

    case TypeActions.GET_PRODUCTS_DELIVERING_REQUEST:
      return {
        ...state,
        // productsDelivering: { results: [] },
        isGetProductsDelivering: true,
        errors: { ...state.errors, productsDelivering: "" },
      };
    case TypeActions.GET_PRODUCTS_DELIVERING_SUCCESS:
      return {
        ...state,
        productsDelivering: actions.data || { results: [] },
        isGetProductsDelivering: false,
        errors: { ...state.errors, productsDelivering: "" },
      };
    case TypeActions.GET_PRODUCTS_DELIVERING_FAILED:
      return {
        ...state,
        productsDelivering: { results: [] },
        isGetProductsDelivering: false,
        errors: { ...state.errors, productsDelivering: actions.error },
      };

    case TypeActions.GET_PRODUCTS_DELIVERED_REQUEST:
      return {
        ...state,
        // productsDelivered: { results: [] },
        isGetProductsDelivered: true,
        errors: { ...state.errors, productsDelivered: "" },
      };
    case TypeActions.GET_PRODUCTS_DELIVERED_SUCCESS:
      return {
        ...state,
        productsDelivered: actions.data || { results: [] },
        isGetProductsDelivered: false,
        errors: { ...state.errors, productsDelivered: "" },
      };
    case TypeActions.GET_PRODUCTS_DELIVERED_FAILED:
      return {
        ...state,
        productsDelivered: { results: [] },
        isGetProductsDelivered: false,
        errors: { ...state.errors, productsDelivered: actions.error },
      };

    default:
      return { ...state };
  }
};

export default completeStageReducer;
