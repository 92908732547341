import _ from "lodash";
import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Services/ServiceBase";
import ServiceURL from "Services/ServiceURL";
import TypeActions from "../TypeActions";

export function* getAllExchangeHistory(data) {
	const url = ServiceURL.exchangeHistory + "?" + data.params;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_ALL_EXCHANGE_HISTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_ALL_EXCHANGE_HISTORY_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_ALL_EXCHANGE_HISTORY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}
export function* getExchangeHistoryById(data) {
	const url = ServiceURL.exchangeHistory + "/" + data.params + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_EXCHANGE_HISTORY_BY_ID_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_EXCHANGE_HISTORY_BY_ID_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_EXCHANGE_HISTORY_BY_ID_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* createExchangeHistory(data) {
	const url = ServiceURL.exchangeHistory;
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.CREATE_EXCHANGE_HISTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.CREATE_EXCHANGE_HISTORY_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.CREATE_EXCHANGE_HISTORY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}
export function* updateExchangeHistory(data) {
	const url = ServiceURL.exchangeHistory + "/" + data.params;
	const callback = data.callback;
	try {
		const res = yield call(PATCH, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.UPDATE_EXCHANGE_HISTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.UPDATE_EXCHANGE_HISTORY_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.UPDATE_EXCHANGE_HISTORY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}
export function* deleteExchangeHistory(data) {
	const url = ServiceURL.exchangeHistory + "/" + data.params;
	const callback = data.callback;
	try {
		const res = yield call(DELETE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.DELETE_EXCHANGE_HISTORY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.DELETE_EXCHANGE_HISTORY_SUCCESS,
			});

			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.DELETE_EXCHANGE_HISTORY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export default function* exchangeHistorySaga() {
	yield takeLatest(
		TypeActions.GET_ALL_EXCHANGE_HISTORY_REQUEST,
		getAllExchangeHistory
	);
	yield takeLatest(
		TypeActions.GET_EXCHANGE_HISTORY_BY_ID_REQUEST,
		getExchangeHistoryById
	);
	yield takeLatest(
		TypeActions.CREATE_EXCHANGE_HISTORY_REQUEST,
		createExchangeHistory
	);
	yield takeLatest(
		TypeActions.UPDATE_EXCHANGE_HISTORY_REQUEST,
		updateExchangeHistory
	);
	yield takeLatest(
		TypeActions.DELETE_EXCHANGE_HISTORY_REQUEST,
		deleteExchangeHistory
	);
}
