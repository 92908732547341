import _ from "lodash";
import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { DELETE, GET, PATCH, POST } from "Services/ServiceBase";
import ServiceURL from "Services/ServiceURL";
import TypeActions from "../TypeActions";

export function* getQualities(data) {
	const url = ServiceURL.transports + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_QUALITIES_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_QUALITIES_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_QUALITIES_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getQualityById(data) {
	const url = ServiceURL.transports + "/" + data.params + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_QUALITY_BY_ID_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_QUALITY_BY_ID_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_QUALITY_BY_ID_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* createQuality(data) {
	const url = ServiceURL.transports;
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.CREATE_QUALITY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.CREATE_QUALITY_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.CREATE_QUALITY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* updateQuality(data) {
	const url = ServiceURL.transports + "/" + data.params;
	const callback = data.callback;
	try {
		const res = yield call(PATCH, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.UPDATE_QUALITY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.UPDATE_QUALITY_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.UPDATE_QUALITY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}
export function* deleteQuality(data) {
	const url = ServiceURL.transports + "/" + data.params;
	const callback = data.callback;
	try {
		const res = yield call(DELETE, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.DELETE_QUALITY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.DELETE_QUALITY_SUCCESS,
			});

			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.DELETE_QUALITY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getCustomerByBarcodeQuality(data) {
	const url = ServiceURL.transports + "/1" + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* qcProductAchieved(data) {
	const url = ServiceURL.transports + "/qc/pass";
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.QC_PRODUCT_ACHIEVED_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.QC_PRODUCT_ACHIEVED_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.QC_PRODUCT_ACHIEVED_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* qcProductUnachieved(data) {
	const url = ServiceURL.transports + "/qc/fail";
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.QC_PRODUCT_ACHIEVED_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.QC_PRODUCT_UNACHIEVED_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.QC_PRODUCT_ACHIEVED_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* getProductsError(data) {
	const url = ServiceURL.transports + "?" + data.query;
	const callback = data.callback;
	try {
		const res = yield call(GET, url);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.GET_PRODUCTS_ERROR_QC_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.GET_PRODUCTS_ERROR_QC_SUCCESS,
				data: res.data,
			});
			!!callback?.success && callback.success(res.data);
		}
	} catch (error) {
		yield put({
			type: TypeActions.GET_PRODUCTS_ERROR_QC_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* qcCompleteProductsError(data) {
	const url = ServiceURL.transports + "/qc/coordination";
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export function* returnProducer(data) {
	const url = ServiceURL.transports + "/qc/coordination";
	const callback = data.callback;
	try {
		const res = yield call(POST, url, data.body);
		if (res.message && !_.isEmpty(res.message)) {
			yield put({
				type: TypeActions.RETURN_PRODUCER_FAILED,
				error: res?.error?.response?.data?.message,
			});
			!!callback?.failed &&
				callback.failed(res?.error?.response?.data?.message);
		} else {
			yield put({
				type: TypeActions.RETURN_PRODUCER_SUCCESS,
			});
			!!callback?.success && callback.success();
		}
	} catch (error) {
		yield put({
			type: TypeActions.RETURN_PRODUCER_FAILED,
			error: error?.response?.data?.message,
		});
		!!callback?.failed && callback.failed(error?.response?.data?.message);
	}
}

export default function* customerSaga() {
	yield takeLatest(TypeActions.GET_QUALITIES_REQUEST, getQualities);
	yield takeLatest(TypeActions.GET_QUALITY_BY_ID_REQUEST, getQualityById);
	yield takeLatest(TypeActions.CREATE_QUALITY_REQUEST, createQuality);
	yield takeLatest(TypeActions.UPDATE_QUALITY_REQUEST, updateQuality);
	yield takeLatest(TypeActions.DELETE_QUALITY_REQUEST, deleteQuality);
	yield takeLatest(
		TypeActions.GET_CUSTOMER_BY_BARCODE_QUALITY_REQUEST,
		getCustomerByBarcodeQuality
	);
	yield takeLatest(TypeActions.QC_PRODUCT_ACHIEVED_REQUEST, qcProductAchieved);
	yield takeLatest(
		TypeActions.QC_PRODUCT_UNACHIEVED_REQUEST,
		qcProductUnachieved
	);
	yield takeLatest(TypeActions.GET_PRODUCTS_ERROR_QC_REQUEST, getProductsError);
	yield takeLatest(
		TypeActions.QC_COMPLETE_PRODUCTS_ERROR_QC_REQUEST,
		qcCompleteProductsError
	);
	yield takeLatest(TypeActions.RETURN_PRODUCER_REQUEST, returnProducer);
}
