import TypeActions from "../TypeActions";

const initialState = {
  exchangeHistory: {
    results: [],
  },
  exchangeHistoryById: {},
  isGetExchangeHistory: false,
  isUpdateExchangeHistory: false,
  isCreateExchangeHistory: false,
  isDeleteExchangeHistory: false,
  errors: {
    getExchangeHistory: "",
    getExchangeHistoryById: "",
    updateExchangeHistory: "",
    createExchangeHistory: "",
    deleteExchangeHistory: "",
  },
};

export const exchangeHistoryReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_ALL_EXCHANGE_HISTORY_REQUEST:
      return {
        ...state,
        // exchangeHistory: { results: [] },
        isGetExchangeHistory: true,
        errors: { ...state.errors, getExchangeHistory: "" },
      };
    case TypeActions.GET_ALL_EXCHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        exchangeHistory: actions.data || { results: [] },
        isGetExchangeHistory: false,
        errors: { ...state.errors, getExchangeHistory: "" },
      };

    case TypeActions.GET_ALL_EXCHANGE_HISTORY_FAILED:
      return {
        ...state,
        exchangeHistory: { results: [] },
        isGetExchangeHistory: false,
        errors: { ...state.errors, getExchangeHistory: actions.error },
      };

    case TypeActions.GET_EXCHANGE_HISTORY_BY_ID_REQUEST:
      return {
        ...state,
        // exchangeHistoryById: {},
        isGetExchangeHistoryById: true,
        errors: { ...state.errors, getExchangeHistoryById: "" },
      };
    case TypeActions.GET_EXCHANGE_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        exchangeHistoryById: actions.data || {},
        isGetExchangeHistoryById: false,
        errors: { ...state.errors, getExchangeHistoryById: "" },
      };
    case TypeActions.GET_EXCHANGE_HISTORY_BY_ID_FAILED:
      return {
        ...state,
        exchangeHistoryById: {},
        isGetExchangeHistoryById: false,
        errors: { ...state.errors, getExchangeHistoryById: actions.error },
      };
    case TypeActions.CREATE_EXCHANGE_HISTORY_REQUEST:
      return {
        ...state,
        isCreateExchangeHistory: true,
        errors: { ...state.errors, createExchangeHistory: "" },
      };
    case TypeActions.CREATE_EXCHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        isCreateExchangeHistory: false,
        errors: { ...state.errors, createExchangeHistory: "" },
      };
    case TypeActions.CREATE_EXCHANGE_HISTORY_FAILED:
      return {
        ...state,
        isCreateExchangeHistory: false,
        errors: { ...state.errors, createExchangeHistory: actions.error },
      };

    case TypeActions.UPDATE_EXCHANGE_HISTORY_REQUEST:
      return {
        ...state,
        isUpdateExchangeHistory: true,
        errors: { ...state.errors, updateExchangeHistory: "" },
      };
    case TypeActions.UPDATE_EXCHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        isUpdateExchangeHistory: false,
        errors: { ...state.errors, updateExchangeHistory: "" },
      };
    case TypeActions.UPDATE_EXCHANGE_HISTORY_FAILED:
      return {
        ...state,
        isUpdateExchangeHistory: false,
        errors: { ...state.errors, updateExchangeHistory: actions.error },
      };

    case TypeActions.DELETE_EXCHANGE_HISTORY_REQUEST:
      return {
        ...state,
        isDeleteExchangeHistory: true,
        errors: { ...state.errors, deleteExchangeHistory: "" },
      };
    case TypeActions.DELETE_EXCHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        isDeleteExchangeHistory: false,
        errors: { ...state.errors, deleteExchangeHistory: "" },
      };
    case TypeActions.DELETE_EXCHANGE_HISTORY_FAILED:
      return {
        ...state,
        isDeleteExchangeHistory: false,
        errors: { ...state.errors, deleteExchangeHistory: actions.error },
      };
    default:
      return { ...state };
  }
};

export default exchangeHistoryReducer;
