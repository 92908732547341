import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  CardFooter,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { provincesActions } from 'Redux/Actions';
import queryString from 'query-string';
import {
  customerActions,
  orderActions,
  producerActions,
  productActions,
} from 'Redux/Actions';
import Select from 'react-select';
import CONSTANTS from 'constant';
import { Style } from './style';

const Filter = ({
  openFilter,
  setOpenFilter,
  handleFilter,
  filterValues,
  setFilterValues,
}) => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orderReducer);
  const { producers } = useSelector((state) => state.producerReducer);
  const { products } = useSelector((state) => state.productReducer);
  const [optionValues, setOptionValues] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });

  const [orderSearch, setOrderSearch] = useState('');
  const [producerSearch, setProducerSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');

  const [queryOrder, setQueryOrder] = useState({
    page: 1,
    limit: 10,
  });
  const [queryProducer, setQueryProducer] = useState({
    page: 1,
    limit: 10,
  });
  const [queryProduct, setQueryProduct] = useState({
    page: 1,
    limit: 10,
  });

  const clearFilter = () => {
    setOptionValues({
      orderId: null,
      producerId: null,
      productId: null,
    });
    setFilterValues({
      orderId: null,
      producerId: null,
      productId: null,
    });
    setOpenFilter(false);
    handleFilter({
      orderId: null,
      producerId: null,
      productId: null,
    });
  };

  const handleGetOrders = () => {
    if (orderSearch === '') {
      dispatch(orderActions.getOrders(queryString.stringify(queryOrder)));
    } else {
      dispatch(
        orderActions.getOrders(
          queryString.stringify({ ...queryOrder, name: orderSearch })
        )
      );
    }
  };
  const handleGetProducers = () => {
    if (orderSearch === '') {
      dispatch(
        producerActions.getProducers(queryString.stringify(queryProducer))
      );
    } else {
      dispatch(
        producerActions.getProducers(
          queryString.stringify({ ...queryProducer, name: producerSearch })
        )
      );
    }
  };
  const handleGetProducts = () => {
    if (orderSearch === '') {
      dispatch(productActions.getProducts(queryString.stringify(queryProduct)));
    } else {
      dispatch(
        productActions.getProducts(
          queryString.stringify({ ...queryProduct, name: productSearch })
        )
      );
    }
  };

  useEffect(() => {
    handleGetOrders();
  }, [orderSearch]);
  useEffect(() => {
    handleGetProducers();
  }, [producerSearch]);
  useEffect(() => {
    handleGetProducts();
  }, [productSearch]);

  useEffect(() => {
    openFilter && setOptionValues({ ...filterValues });
  }, [openFilter]);

  return (
    <Style>
      <Card className={`filter ${false} && "show"} transition-filter`}>
        <CardHeader>
          <span
            style={{ cursor: 'pointer' }}
            className="text-danger font-weight-bold"
            onClick={() => {
              // clearFilter();
              setOpenFilter(false);
            }}
          >
            Đóng
          </span>
        </CardHeader>
        {/* <hr className="my-3"/> */}
        <CardBody style={{ height: '78vh', overflowY: 'auto' }}>
          <FormGroup>
            <Label className="form-control-label text-sm">Chọn đơn hàng</Label>
            <Select
              placeholder="Nhập để tìm kiếm"
              isClearable={true}
              value={optionValues.orderId}
              onChange={(e) => {
                setOptionValues({ ...optionValues, orderId: e });
              }}
              options={orders.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setOrderSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">
              Chọn nhà gia công
            </Label>
            <Select
              placeholder="Nhập để tìm kiếm"
              isClearable={true}
              value={optionValues.producerId}
              onChange={(e) => {
                setOptionValues({
                  ...optionValues,
                  producerId: e,
                });
              }}
              options={producers.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setProducerSearch(value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label text-sm">Chọn sản phẩm</Label>
            <Select
              placeholder="Nhập để tìm kiếm"
              isClearable={true}
              value={optionValues.productId}
              onChange={(e) => {
                setOptionValues({ ...optionValues, productId: e });
              }}
              options={products.results.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onInputChange={(value) => {
                setProductSearch(value);
              }}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <button
            className="btn btn-secondary btn-md text-sm btn-block"
            onClick={clearFilter}
          >
            Xóa bộ lọc
          </button>
          <button
            className="btn btn-primary btn-md text-sm btn-block ml-0"
            onClick={() => {
              setFilterValues({ ...optionValues });
              handleFilter(optionValues);
            }}
          >
            Áp dụng bộ lọc
          </button>
        </CardFooter>
      </Card>
    </Style>
  );
};

export default Filter;
