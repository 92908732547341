import { AddSVG } from 'assets/svg';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactNotificationAlert from 'react-notification-alert';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import InputCustom from 'views/pages/components/InputCustom';
import { orderActions } from 'Redux/Actions';
import queryString from 'query-string';
import * as _ from 'lodash';
import { notify } from 'common';
import { measureSizeActions } from 'Redux/Actions';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';

const DialogFormMeasure = ({ open, toggle, currentOrders }) => {
  const notificationAlertRef = useRef();
  const dispatch = useDispatch();
  const [fileMeasure, setFileMeasure] = useState(null);
  const { orderById } = useSelector((state) => state.orderReducer);
  const { isUploadMeasureSize } = useSelector(
    (state) => state.measureSizeReducer
  );
  useEffect(() => {
    if (orderById)
      dispatch(
        orderActions.getOrderById(
          currentOrders,
          queryString.stringify({ populate: 'customerOrganizationId' })
        )
      );
  }, [currentOrders]);

  const handleAddFileMeasure = () => {
    if (currentOrders === '') {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Vui lòng chọn đơn hàng để tải tệp`
      );
      return;
    }
    if (_.isEmpty(fileMeasure)) return;
    const arrNameFile = fileMeasure.files[0].name.split('.');
    if (arrNameFile[arrNameFile.length - 1] === 'xlsx') {
      const params = `${currentOrders}`;
      dispatch(
        measureSizeActions.uploadMeasureSize(fileMeasure.files[0], params, {
          success: () => {
            notify(
              notificationAlertRef,
              'success',
              'Thông báo',
              `Tải tệp số đo lên thành công`
            );
            toggle();
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tải tệp số đo lên không thành công. Lỗi: ${mess}`
            );
          },
        })
      );
    } else {
      notify(
        notificationAlertRef,
        'danger',
        'Thông báo',
        `Vui lòng chọn đúng định dạng file exel!`
      );
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader>
          <p className="h3 text-uppercase">Nhập danh sách số đo</p>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={3}>
              <p className="text-sm font-weight-500">Tên đơn hàng</p>
            </Col>
            <Col xs={9}>
              <p className="text-sm">{orderById?.name}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <p className="text-sm font-weight-500">Tên chủ đầu tư</p>
            </Col>
            <Col xs={9}>
              <p className="text-sm">
                {orderById?.customerOrganizationId?.name}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="h5">
                Cập nhật danh sách kết quả đo cho các đơn vị, phòng ban
              </p>
            </Col>
          </Row>
          <div className="">
            {!isUploadMeasureSize && (
              <InputCustom
                type="file"
                id="fileMeasure"
                name="fileMeasure"
                // value={fileMeasure}
                onChange={(e) => setFileMeasure(e.target)}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button onClick={toggle}>Hủy bỏ</Button>
          <LoadingButtonCustom
            onClick={handleAddFileMeasure}
            color="primary"
            type="button"
            loading={isUploadMeasureSize}
          >
            Cập nhật
          </LoadingButtonCustom>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DialogFormMeasure;
