import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SimpleHeader from './components/Header';
import ModalWarningCustom from 'views/pages/components/ModalWarningCustom';
import { EditSVG, DeleteSVG } from 'assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from 'Redux/Actions';
import queryString from 'query-string';
import _ from 'lodash';
import { notify, checkRole } from 'common';
import FormCustomer from './FormCustomer';
import ReactNotificationAlert from 'react-notification-alert';
import { Link, useParams } from 'react-router-dom';
import { AddSVG } from 'assets/svg';

const CustomerManage = () => {
  const notificationAlertRef = useRef(null);
  const dispatch = useDispatch();
  const [customerById, setCustomerById] = useState(null);
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const [roleManage, setRoleManage] = useState(false);
  const [roleDelete, setRoleDelete] = useState(false);
  const { id } = useParams();

  const [isGetCustomers, setIsGetCustomers] = useState(false);
  const [customers, setCustomers] = useState({ results: [] });
  const [customerSearch, setCustomerSearch] = useState('');
  const [formModal, setFormModal] = useState(false);
  const [dataFormModal, setDataFormModal] = useState({});
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    level: customerById?.level ? customerById.level : 0,
    status: 'active',
    populate: 'childrentIds, parentId, provinceId',
  });

  const [idCustomerSelect, setIdCustomerSelect] = useState(null);
  const closeModal = () => {
    setFormModal(false);
    setDataFormModal({});
  };
  const boxAction = (cell, row, id, formatExtraData) => {
    return (
      <>
        {roleManage && (
          <>
            <button
              style={{
                padding: 0,
                border: 'none',
                marginRight: '.5rem',
                background: 'none',
              }}
              id={'add' + id}
              onClick={() => {
                setFormModal(true);
                setIsModalAdd(true);
                setCurrentParent(cell);
              }}
            >
              <AddSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'add' + id}>
              Thêm thông tin khách hàng
            </UncontrolledTooltip>
          </>
        )}
        {roleManage && (
          <>
            <button
              style={{
                padding: 0,
                border: 'none',
                marginRight: '.5rem',
                background: 'none',
              }}
              id={'edit' + id}
              onClick={() => {
                setFormModal(true);
                setIsModalAdd(false);
                setDataFormModal(row);
                setCurrentParent(cell);
              }}
            >
              <EditSVG />
            </button>
            <UncontrolledTooltip delay={1} placement="top" target={'edit' + id}>
              Cập nhật thông tin khách hàng
            </UncontrolledTooltip>
          </>
        )}
        {roleDelete && (
          <>
            <button
              id={'delete' + id}
              onClick={() => {
                setNotificationModal(true);
                setIdCustomerSelect(row.id);
              }}
              style={{ padding: 0, border: 'none', background: 'none' }}
            >
              <DeleteSVG />
            </button>
            <UncontrolledTooltip
              delay={1}
              placement="top"
              target={'delete' + id}
            >
              Xóa khách hàng
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };
  const [columns, setColumns] = useState([
    {
      dataField: 'code',
      text: 'Mã số',
      style: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'name',
      text: 'Tên đơn vị',
      formatter: (cell, row) => {
        return <Link to={`/customers/${row.id}`}>{cell}</Link>;
      },
    },
    {
      dataField: 'childrentIds',
      text: 'Chi nhánh/phòng ban',
      formatter: (cell) => {
        return cell.map((item, idx) =>
          item.childrentIds.length > 0 ? (
            <Link key={idx} to={`/customers/${item.id}`}>
              {item.name},{' '}
            </Link>
          ) : (
            <span>{item.name}, </span>
          )
        );
      },
    },
    {
      dataField: 'phone',
      text: 'Số điện thoại',
    },
    {
      dataField: 'contactPerson',
      text: 'Người liên hệ',
    },
    {
      dataField: 'address',
      text: 'Địa chỉ',
      style: {
        width: 300,
      },
      headerStyle: {
        maxWidth: 300,
      },
    },
    {
      dataField: 'id',
      text: 'Hành động',
      formatter: boxAction,
      style: { textAlign: 'center', width: 120 },
      headerAlign: 'center',
    },
  ]);

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: customers?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col>
          <p>
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > customers.totalResults
              ? !isNaN(customers?.totalResults)
                ? customers.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(customers?.totalResults) ? customers.totalResults : 0} bản
            ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleDelete = () => {
    dispatch(
      customerActions.deleteCustomer(idCustomerSelect, {
        success: () => {
          setNotificationModal(false);
          notify(
            notificationAlertRef,
            'success',
            'Thông báo',
            `Xóa khách hàng thành công!`
          );
          handleGetCustomers();
        },
        failed: (mess) => {
          notify(
            notificationAlertRef,
            'danger',
            'Thông báo',
            `Xóa tài khoản thất bại.Lỗi: ${mess}!`
          );
        },
      })
    );
  };

  const handleGetCustomers = () => {
    if (customerSearch === '') {
      dispatch(
        customerActions.getCustomers(
          !!id
            ? queryString.stringify({
                ...query,
                parentId: id,
                level: customerById ? customerById.level + 1 : 0,
              })
            : queryString.stringify({
                ...query,
                level: customerById ? customerById.level + 1 : 0,
              }),
          {
            success: (data) => {
              setCustomers(data);
              setIsGetCustomers(false);
            },
            failed: () => {
              setIsGetCustomers(false);
            },
          }
        )
      );
    } else {
      dispatch(
        customerActions.getCustomers(
          !!id
            ? queryString.stringify({
                ...query,
                parentId: id,
                level: customerById ? customerById.level + 1 : 0,
                name: customerSearch,
              })
            : queryString.stringify({
                ...query,
                level: customerById ? customerById.level + 1 : 0,
                name: customerSearch,
              }),
          {
            success: (data) => {
              setCustomers(data);
              setIsGetCustomers(false);
            },
            failed: () => {
              setIsGetCustomers(false);
            },
          }
        )
      );
    }
  };
  const handleGetDetailsCustomer = () => {
    if (id) {
      dispatch(
        customerActions.getCustomerById(
          id,
          queryString.stringify({ ...query, parentId: id }),
          {
            success: (data) => {
              setCustomerById(data);
              setQuery({ ...query, level: data.level + 1 });
            },
            failed: () => {
              setIsGetCustomers(false);
            },
          }
        )
      );
    } else {
      setQuery({ ...query, level: 0 });
    }
  };
  useEffect(() => {
    handleGetDetailsCustomer();
  }, [id]);
  useEffect(() => {
    setIsGetCustomers(true);
    handleGetCustomers();
  }, [query, id]);

  useEffect(() => {
    if (!_.isEmpty(currentAccount)) {
      if (
        checkRole(currentAccount, { roles: ['manage_customerOrganization'] })
      ) {
        setRoleManage(true);
      }
      if (
        checkRole(currentAccount, { roles: ['delete_customerOrganization'] })
      ) {
        setRoleDelete(true);
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    if (!roleManage && !roleDelete) {
      const index = columns.findIndex((item) => item.dataField === 'actions');
      if (index !== -1)
        setColumns([...columns.slice(0, index), ...columns.slice(index + 1)]);
    } else {
      setColumns([
        {
          dataField: 'code',
          text: 'Mã số',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'name',
          text: 'Tên đơn vị',
          formatter: (cell, row) => {
            return <Link to={`/customers/${row.id}`}>{cell}</Link>;
          },
        },
        {
          dataField: 'childrentIds',
          text: 'Chi nhánh/phòng ban',
          formatter: (cell) => {
            return cell.map((item, idx) =>
              item.childrentIds.length > 0 ? (
                <Link key={idx} to={`/customers/${item.id}`}>
                  {item.name},{' '}
                </Link>
              ) : (
                <span>{item.name}, </span>
              )
            );
          },
        },
        {
          dataField: 'phone',
          text: 'Số điện thoại',
          style: {
            textAlign: 'center',
          },
          headerStyle: {
            textAlign: 'center',
          },
        },
        {
          dataField: 'contactPerson',
          text: 'Người liên hệ',
        },
        {
          dataField: 'address',
          text: 'Địa chỉ',
          style: {
            width: 300,
          },
          headerStyle: {
            maxWidth: 300,
          },
        },
        {
          dataField: 'id',
          text: 'Hành động',
          formatter: boxAction,
          style: { textAlign: 'center', width: 120 },
          headerAlign: 'center',
        },
      ]);
    }
  }, [roleManage, roleDelete]);

  return (
    <>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      {formModal && (
        <FormCustomer
          handleGetCustomersManage={handleGetCustomers}
          isModalAdd={isModalAdd}
          formModal={formModal}
          setFormModal={setFormModal}
          customer={dataFormModal}
          listCustomers={customers.results}
          closeModal={closeModal}
          notify={notify}
          notificationAlertRef={notificationAlertRef}
          setNotificationModal={setNotificationModal}
          idParent={currentParent}
        />
      )}
      {notificationModal && (
        <ModalWarningCustom
          notificationModal={notificationModal}
          setNotificationModal={setNotificationModal}
          name="khách hàng"
          func={handleDelete}
        />
      )}
      <SimpleHeader
        name={customerById && 'Chi nhánh : ' + customerById.name}
        parentName="Quản lý"
        setFormModal={setFormModal}
        setIsModalAdd={setIsModalAdd}
        setCurrentParent={() => {
          setCurrentParent(id);
        }}
        roleManage={roleManage}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={customers.results}
                keyField="id"
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Row>
                      <Col>
                        <CardHeader>
                          <h3 className="mb-0">Danh sách công ty khách hàng</h3>
                        </CardHeader>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <CardHeader>
                          <div className="mb-0 d-flex align-items-center">
                            <p
                              className="mb-0"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              Hiển thị{' '}
                            </p>
                            {
                              <select
                                value={rowsPerPage}
                                name="datatable-basic_length"
                                aria-controls="datatable-basic"
                                className="form-control form-control-sm mx-2"
                                style={{ maxWidth: 60 }}
                                onChange={(e) =>
                                  onSizePerPageChange(e.target.value)
                                }
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            }{' '}
                            <p className="mb-0">dòng</p>
                          </div>
                        </CardHeader>
                      </Col>
                      <Col className="d-flex align-items-center mr-4 justify-content-end">
                        <Row style={{ width: '100%' }}>
                          <Col
                            md={6}
                            className="d-flex align-items-center justify-content-end"
                          >
                            <h4 className="mb-0">Tìm kiếm tên khách hàng</h4>
                          </Col>
                          <Col md={6} className="d-flex align-items-center">
                            <Input
                              id="search-by-name"
                              placeholder="Nhập tên"
                              type="text"
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  setPage(1);
                                  setQuery({ ...query, page: 1 });
                                  handleGetCustomers();
                                }
                              }}
                              value={customerSearch}
                              onChange={(e) => {
                                setCustomerSearch(e.target.value);
                              }}
                              className="py-0"
                              bsSize="sm"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={() => {
                        return (
                          <span className="font-weight-bold text-danger">
                            Không có dữ liệu!
                          </span>
                        );
                      }}
                      onTableChange={() => {
                        return <div className="spinner-border text-info" />;
                      }}
                      hover
                      remote
                      filter={filterFactory()}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CustomerManage;
