import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { provincesActions } from "Redux/Actions";
import queryString from "query-string";
import { orderActions } from "Redux/Actions";
import Select from "react-select";
import _ from "lodash";
import makeAnimated from "react-select/animated";
import InputCustom from "views/pages/components/InputCustom";
const animatedComponents = makeAnimated();

const Filter = ({
	handleClose,
	handleFilter,
	filterValues,
	setFilterValues,
	setQueryFilter,
	filterRequest,
	opentFilter,
}) => {
	const dispatch = useDispatch();
	const [orders, setOrders] = useState({ results: [] });
	const [queryOrders, setQueryOrders] = useState({
		page: 1,
		limit: 10,
	});
	const [orderSearch, setOrderSearch] = useState("");
	const [orderValues, setOrderValues] = useState([]);
	const [provinceValue, setProvinceValue] = useState(null);
	const [yearValue, setYearValue] = useState(null);
	const [monthValue, setMonthValue] = useState(null);
	const { provinces } = useSelector((state) => state.provincesReducer);
	const changeOptionFilter = (e) => {
		setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		dispatch(
			provincesActions.getProvinces(queryString.stringify({ limit: 100 }))
		);
	}, []);

	const resetFilter = () => {
		setOrderValues([]);
		setProvinceValue(null);
		setYearValue(null);
		setMonthValue(null);
		setFilterValues({ orderIds: [], year: "", month: "" });
		setQueryFilter({});
	};

	const handleGetOrders = () => {
		if (orderSearch === "") {
			dispatch(
				orderActions.getOrders(queryString.stringify(queryOrders), {
					success: (data) => {
						setOrders(data);
					},
				})
			);
		} else {
			dispatch(
				orderActions.getOrders(
					queryString.stringify({ ...queryOrders, name: orderSearch }),
					{
						success: (data) => {
							setOrders(data);
						},
					}
				)
			);
		}
	};
	useEffect(() => {
		setOrderValues(filterRequest.orderIds);
	}, [filterRequest]);
	useEffect(() => {
		handleGetOrders();
	}, [queryOrders, orderSearch]);
	const range = (start, stop, step) =>
		Array.from(
			{ length: (stop - start) / step + 1 },
			(_, i) => start + i * step
		);

	useEffect(() => {
		if (!_.isEmpty(filterRequest.year)) {
			setYearValue({ label: filterRequest.year, value: filterRequest.year });
		}
		if (!_.isEmpty(filterRequest.month)) {
			setMonthValue({
				label: parseInt(filterRequest.month) + 1,
				value: filterRequest.month,
			});
		}
	}, [opentFilter]);
	return (
		<>
			<Card className={`filter ${false} && "show"}`}>
				<CardHeader>
					<span
						style={{ cursor: "pointer" }}
						className="text-danger font-weight-bold"
						onClick={handleClose}
					>
						Đóng
					</span>
				</CardHeader>
				{/* <hr className="my-3"/> */}
				<CardBody>
					<FormGroup className="col p-0 mb-2">
						<Label
							className="form-control-label text-sm"
							htmlFor="example-text-input"
						>
							Chọn đơn hàng
						</Label>
						<Select
							isClearable={false}
							value={orderValues}
							placeholder="Chọn đơn hàng"
							className="select-muti"
							closeMenuOnSelect={false}
							components={animatedComponents}
							isMulti
							onChange={(e, remove) => {
								if (_.isEmpty(remove?.removedValue)) {
									setOrderValues([...e]);
									setFilterValues({
										...filterValues,
										orderIds: e,
									});
								} else {
									const tempOrderValues = orderValues.filter(
										(item) => item.value !== remove.removedValue.value
									);
									setOrderValues(tempOrderValues);
									const tempOrderIds = filterValues.orderIds.filter(
										(item) => item !== remove.removedValue.value
									);
									setFilterValues({
										...filterValues,
										orderIds: e,
									});
								}
							}}
							options={orders.results.map((item) => ({
								value: item.id + "",
								label: item.name + "",
							}))}
							onInputChange={(value) => {
								setOrderSearch(value);
							}}
						/>
					</FormGroup>
					<label className="form-control-label">Lọc theo năm</label>
					<Select
						className="mb-2"
						placeholder="Chọn năm"
						isClearable={true}
						value={yearValue}
						onChange={(e) => {
							setYearValue(e);
							setFilterValues({
								...filterValues,
								year: !!e ? e.value : null,
							});
						}}
						options={Array.apply(null, Array(5)).map((item, index) => ({
							label: new Date().getFullYear() - index + "",
							value: new Date().getFullYear() - index + "",
						}))}
					/>
					<label className="form-control-label">Lọc theo tháng</label>
					<Select
						placeholder="Chọn tháng"
						isClearable={true}
						value={monthValue}
						onChange={(e) => {
							setMonthValue(e);
							setFilterValues({
								...filterValues,
								month: !!e ? e.value : null,
							});
						}}
						options={Array.apply(null, Array(12)).map((item, index) => ({
							label: index + 1 + "",
							value: index + "",
						}))}
					/>
					{/* <div className="d-flex">
                <InputCustom
                  className="mr-2 max-height-input-custom"
                  placeholder="Chọn năm"
                  name="year"
                  value={filterValues.year}
                  type="select"
                  onChange={changeOptionFilter}
                >
                  {range(2010, 2022, 1).map((value) => {
                    return (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    );
                  })}
                </InputCustom>
                <InputCustom
                  className="max-height-input-custom"
                  min="1"
                  max="12"
                  placeholder="Chọn tháng"
                  name="month"
                  value={filterValues.month}
                  type="select"
                  onChange={changeOptionFilter}
                >
                  {range(1, 12, 1).map((value) => {
                    return (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    );
                  })}
                </InputCustom>
              </div> */}
					{/* <Select
								placeholder="Chọn năm"
								isClearable={true}
								value={yearValue}
								onChange={(e) => {
									setYearValue(e);
									setFilterValues({
										...filterValues,
										year: !!e ? e.value : null,
									});
								}}
								options={Array.apply(null, Array(5)).map((item, index) => ({
									label: new Date().getFullYear() - index,
									value: new Date().getFullYear() - index,
								}))}
							/> */}
				</CardBody>
				<CardFooter>
					<Button block={true} onClick={resetFilter}>
						Xóa bộ lọc
					</Button>
					<Button
						className=" ml-0"
						block={true}
						color="primary"
						onClick={() => {
							handleFilter();
						}}
					>
						Áp dụng bộ lọc
					</Button>
				</CardFooter>
			</Card>
		</>
	);
};

export default Filter;
