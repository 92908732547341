import React, { useState, useEffect, useRef } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Button,
  CardBody,
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { useHistory } from 'react-router';
import ReactNotificationAlert from 'react-notification-alert';
import _ from 'lodash';
import LoadingButtonCustom from 'views/pages/components/LoadingButtonCustom';
import { notify } from 'common';
import { Style } from '../style';
import Filter from './components/Filter';
import ModalCoordinator from './components/ModalCoordinator';
import { deliveryActions } from 'Redux/Actions';
import CONSTANTS from 'constant';
import { checkRole } from 'common';

const ReceiveGC = ({ location }) => {
  const { currentAccount } = useSelector((state) => state.accountReducer);

  const { deliveries } = useSelector((state) => state.deliveryReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterBy, setFilterBy] = useState({
    orderId: null,
    producerId: null,
    productId: null,
    no: null,
    day: null,
  });
  const [filterByName, setFilterByName] = useState({});
  const notificationAlertRef = useRef(null);
  const [firstCalled, setFirstCalled] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectItems, setSelectItems] = useState([]);
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
    barcode: '',
  });
  const [barcodes, setBarcodes] = useState([]);
  const [isOpenModalCoordinator, setIsOpenModalCoordinator] = useState(false);
  const [page, setPage] = useState(location?.state?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(location?.state?.page || 10);
  const [transportStatus, setTransportStatus] = useState(
    CONSTANTS.TRANSPORT_STATUS.INIT
  );
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    transportStatus: transportStatus,
    populate: 'customerOrgId, productId',
  });

  const [filterValues, setFilterValues] = useState({
    orderId: null,
    producerId: null,
    productId: null,
  });

  const columns = [
    {
      dataField: 'barcode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerCode: e.target.value })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerName',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerName: e.target.value })
            }
            placeholder="Tên khách hàng"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'barcode',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.barcode}
            onChange={(e) =>
              setSearchValues({
                ...searchValues,
                barcode: e.target.value,
              })
            }
            placeholder="Mã SP KH"
          />
        );
      },
      style: {
        textAlign: 'left',
      },
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Đơn vị/phòng ban',
    },
    {
      dataField: 'sendPerson',
      text: 'Người giao hàng',
    },
    {
      dataField: 'no',
      text: 'Số phiếu',
    },
    {
      dataField: 'sendDate',
      text: 'Ngày giao',
      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
      style: { textAlign: 'center' },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      dataField: 'amount',
      text: `Số lượng (${deliveries?.totalResults})`,
      style: { textAlign: 'center' },
      headerAlign: 'center',
      formatter: (cell) => {
        return 1;
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    selectColumnPosition: 'right',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const temp = [...selectItems, row.id];
        setBarcodes([...barcodes, row.barcode]);
        setSelectItems(temp);
      } else {
        let temp = [...selectItems];
        let tempBarcodes = [];
        let check = false;
        barcodes.forEach((item) => {
          if (item === row.barcode && !check) {
            check = true;
          } else {
            tempBarcodes.push(item);
          }
        });
        const index = selectItems.indexOf(row.id);
        if (index > -1) {
          temp.splice(index, 1);
        }
        setSelectItems(temp);
        setBarcodes(tempBarcodes);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const temp = rows.map((item) => item.id);
        setSelectItems(temp);
        setBarcodes(rows.map((item) => item.barcode));
      } else {
        setSelectItems([]);
        setBarcodes([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="text-md-right" style={{ verticalAlign: 'inherit' }}>
        Lựa chọn{' '}
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="d-flex justify-content-end">
        <input type={mode} {...rest} />
      </div>
    ),
    selected: selectItems,
  };

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };

  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: deliveries?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > deliveries.totalResults
              ? !isNaN(deliveries?.totalResults)
                ? deliveries.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(deliveries?.totalResults) ? deliveries.totalResults : 0} bản
            ghi
          </p>
        </Col>
      </>
    ),
  });

  const handleGetDeliveries = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    setSelectItems([]);
    dispatch(
      deliveryActions.getDeliveries(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setFirstCalled(true);
          },
        }
      )
    );
  };

  const handleFilter = (values) => {
    setFilterBy({ ...values });
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(values).forEach((key, index) => {
      if (!_.isEmpty(values[key])) {
        tempFilter[key] = values[key].value || values[key];
      }
    });

    Object.keys(tempSearch).forEach((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    setSelectItems([]);
    dispatch(
      deliveryActions.getDeliveries(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    Object.keys(filterValues).map((key, index) => {
      if (!_.isEmpty(filterValues[key])) {
        tempFilter[key] = filterValues[key].value;
      }
    });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    setSelectItems([]);
    dispatch(
      deliveryActions.getDeliveries(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    const tempFilter = {};
    Object.keys(filterBy).map((key, index) => {
      if (!_.isEmpty(filterBy[key])) {
        tempFilter[key] = filterBy[key].label;
      }
    });
    setFilterByName(tempFilter);
  }, [filterBy]);

  useEffect(() => {
    handleGetDeliveries();
  }, [query]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  return (
    <Style>
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col md="8">
                {_.isEmpty(filterByName) ? (
                  <></>
                ) : (
                  <>
                    <span className="font-weight-600 text-info">
                      Đang lọc theo :
                    </span>

                    {filterByName?.orderId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Đơn hàng: {filterByName?.orderId};
                      </span>
                    )}
                    {filterByName?.productId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Sản phẩm: {filterByName?.productId};
                      </span>
                    )}
                    {filterByName?.producerId && (
                      <span className="font-weight-600 text-info">
                        &ensp;Nhà gia công: {filterByName?.producerId};
                      </span>
                    )}
                    {filterBy?.no && (
                      <span className="font-weight-600 text-info">
                        &ensp;Mã số phiếu: {filterBy?.no};
                      </span>
                    )}
                  </>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" md="4">
                {checkRole(currentAccount, {
                  roles: ['manage_transportGN'],
                }) && (
                  <Button
                    onClick={() => {
                      history.push('/form-receive-gc', {
                        page,
                        rowsPerPage,
                        route: location.pathname,
                        type: 0, // Cho biet trang tiep nhan tu dau(0-tu nha gia cong, 1-tu khach hang)
                      });
                    }}
                    className="btn-neutral"
                    color="default"
                    size="md"
                  >
                    Tiếp nhận
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setOpenFilter(true);
                  }}
                  className="btn-neutral"
                  color="default"
                  size="md"
                >
                  Lọc hiển thị
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={deliveries.results}
                keyField="id"
                columns={columns}
                search
                bootstrap4
              >
                {(props) => (
                  <>
                    <CardHeader>
                      <Row>
                        <Col className="d-flex align-items-center" md="6">
                          <h3 className="mb-0">
                            Danh sách sản phẩm nhận từ nhà gia công
                          </h3>
                        </Col>
                        <Col className="d-flex justify-content-end" md="6">
                          {checkRole(currentAccount, {
                            roles: ['manage_transportGN'],
                          }) && (
                            <LoadingButtonCustom
                              outline={true}
                              size="md"
                              onClick={() => {
                                setIsOpenModalCoordinator(true);
                              }}
                              loading={false}
                              style={{ border: '1px solid #4385b1' }}
                            >
                              Điều phối
                            </LoadingButtonCustom>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <BootstrapTable
                        {...props.baseProps}
                        noDataIndication={() => {
                          return (
                            <span className="font-weight-bold text-danger">
                              Không có dữ liệu!
                            </span>
                          );
                        }}
                        onTableChange={() => {
                          return <div className="spinner-border text-info" />;
                        }}
                        filter={filterFactory()}
                        pagination={pagination}
                        bordered={false}
                        hover
                        remote
                        selectRow={
                          checkRole(currentAccount, {
                            roles: ['manage_transportGN'],
                          }) && selectRow
                        }
                      />
                    </CardBody>
                  </>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      {openFilter && (
        <Filter
          openFilter={openFilter}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setOpenFilter={setOpenFilter}
          handleFilter={handleFilter}
        />
      )}
      {isOpenModalCoordinator && (
        <ModalCoordinator
          isOpen={isOpenModalCoordinator}
          setIsOpen={setIsOpenModalCoordinator}
          selectItems={selectItems}
          setSelectItems={setSelectItems}
          barcodes={barcodes}
          setBarcodes={setBarcodes}
          notificationAlertRef={notificationAlertRef}
          handleGetDeliveries={handleGetDeliveries}
        />
      )}
    </Style>
  );
};

export default ReceiveGC;
