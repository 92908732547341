import React, { useEffect, useState, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import filterFactory from 'react-bootstrap-table2-filter';
import _ from 'lodash';
import { CardHeader, Row, Col, Input, Badge, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// import DialogUpdateResult from "./DialogUpdateResult";
import { useDispatch, useSelector } from 'react-redux';
import orderActions from 'Redux/Actions/orderActions';
import queryString from 'query-string';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import constant from 'constant';
import { notify } from 'common';
import ReactNotificationAlert from 'react-notification-alert';
import { checkRole } from 'common';
import moment from 'moment';
function TableData({
  currentOrders,
  listCustomerInOrderConvert,
  setListCustomerinOrderConvert,
  filter,
  setOpenFilter,
}) {
  const { currentAccount } = useSelector((state) => state.accountReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [firstCalled, setFirstCalled] = useState(false);
  const notificationAlertRef = useRef(null);
  const [searchValues, setSearchValues] = useState({
    customerCode: '',
    customerName: '',
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({
    page: page,
    limit: rowsPerPage,
    populate: 'customerOrgId.parentId,productTypeId',
    customerInOrderStatus: constant.CUSTOMER_IN_ORDER_STATUS.PendingAcept,
  });

  const { ordersConvert, isGetOrdersConvert } = useSelector(
    (state) => state.orderReducer
  );
  const handleGetOrdersConvert = () => {
    const payload = { ...query };
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getOrdersConvert(
        queryString.stringify({ ...payload, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
            setFirstCalled(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    handleGetOrdersConvert();
  }, [query]);

  const onSizePerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(1);
    setQuery({ ...query, page: 1, limit: value });
  };
  const pagination = paginationFactory({
    page: page,
    onPageChange: (value) => {
      setPage(value);
      setQuery({ ...query, page: value });
    },
    sizePerPage: rowsPerPage,
    totalSize: ordersConvert?.totalResults,
    showTotal: false,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
    sizePerPageRenderer: () => (
      <>
        <Col className="d-flex align-items-center">
          <div className="mb-0 d-flex align-items-center">
            <p className="mb-0" style={{ whiteSpace: 'nowrap' }}>
              Hiển thị{' '}
            </p>
            {
              <select
                value={rowsPerPage}
                name="datatable-basic_length"
                aria-controls="datatable-basic"
                className="form-control form-control-sm mx-2"
                style={{ maxWidth: 60 }}
                onChange={(e) => onSizePerPageChange(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            }{' '}
            <p className="mb-0">dòng.</p>
          </div>
          <p className="mb-0 ml-3">
            Hiển thị từ {(page - 1) * rowsPerPage + 1} đến{' '}
            {page * rowsPerPage > ordersConvert.totalResults
              ? !isNaN(ordersConvert?.totalResults)
                ? ordersConvert.totalResults
                : 0
              : page * rowsPerPage}{' '}
            trong số{' '}
            {!isNaN(ordersConvert?.totalResults)
              ? ordersConvert.totalResults
              : 0}{' '}
            bản ghi
          </p>
        </Col>
      </>
    ),
  });

  const selectRow = {
    mode: 'checkbox',
    // clickToSelect: true,
    selectColumnPosition: 'left',
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const data = [...listCustomerInOrderConvert, row.id];
        setListCustomerinOrderConvert(data);
      } else {
        let data = [...listCustomerInOrderConvert];
        const index = listCustomerInOrderConvert.indexOf(row.id);
        if (index > -1) {
          data.splice(index, 1); // 2nd parameter means remove one item only
        }
        setListCustomerinOrderConvert(data);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const data = rows.map((item) => item.id);
        setListCustomerinOrderConvert(data);
      } else {
        setListCustomerinOrderConvert([]);
      }
    },
    selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
      <div className="text-md-right" style={{ verticalAlign: 'inherit' }}>
        Lựa chọn{' '}
        <input
          type="checkbox"
          style={{ height: 'unset', verticalAlign: 'inherit' }}
          ref={(input) => {
            if (input) input.indeterminate = indeterminate;
          }}
          {...rest}
        />
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="d-flex justify-content-end">
        <input type={mode} {...rest} />
      </div>
    ),
  };
  const columns = [
    {
      dataField: 'customerInOrderStatus',
      text: 'Trạng thái',
      formatter: (cell) => {
        if (cell === constant.CUSTOMER_IN_ORDER_STATUS.Init)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-success" />
              <span className="status">Khởi tạo</span>
            </Badge>
          );
        else if (cell === constant.CUSTOMER_IN_ORDER_STATUS.PendingToSennd)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-info" />
              <span className="status">Chờ gửi duyệt</span>
            </Badge>
          );
        else if (cell === constant.CUSTOMER_IN_ORDER_STATUS.PendingAcept)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-info" />
              <span className="status">Chờ duyệt</span>
            </Badge>
          );
        else if (cell === constant.CUSTOMER_IN_ORDER_STATUS.Accept)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-primary" />
              <span className="status">Đã duyệt</span>
            </Badge>
          );
        else if (cell === constant.CUSTOMER_IN_ORDER_STATUS.Reject)
          return (
            <Badge className="badge-dot mr-4" color="">
              <i className="bg-danger" />
              <span className="status">Đã hủy</span>
            </Badge>
          );
        else return <>Chưa rõ</>;
      },
    },
    {
      dataField: 'customerCode',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerCode}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerCode: e.target.value })
            }
            placeholder="Mã khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 120,
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerName',
      text: '',
      headerFormatter: (column, colIndex) => {
        return (
          <Input
            key="input"
            type="search"
            className="border-bottom-search"
            value={searchValues.customerName}
            onChange={(e) =>
              setSearchValues({ ...searchValues, customerName: e.target.value })
            }
            placeholder="Tên khách hàng"
          />
        );
      },
      headerStyle: {
        minWidth: 120,
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'barcode',
      text: 'Mã SP KH',
      headerStyle: {
        minWidth: 150,
        // textAlign: "center",
      },
    },
    {
      dataField: 'productTypeId.name',
      text: 'Tên sản phẩm',
      headerStyle: {
        minWidth: 120,
      },
      style: {
        textAlign: 'left',
      },
    },
    {
      dataField: 'customerOrgId.parentId.name',
      text: 'Chi nhánh',
      headerStyle: {
        minWidth: 180,
        // textAlign: "center",
      },
    },
    {
      dataField: 'customerOrgId.name',
      text: 'Đơn vị/Phòng ban',
    },
    {
      dataField: 'updatedAt',
      text: 'Ngày cập nhật',
      style: { textAlign: 'center' },

      formatter: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : '';
      },
    },
    {
      dataField: '',
      text: '',
      headerFormatter: (column) => {
        return (
          <div className="customHeader">
            <tr>
              <td colSpan="4">Chỉ tiêu may thêm</td>
            </tr>

            <tr>
              <td style={{ minWidth: '5rem' }}>Số lượng</td>
              <td style={{ minWidth: '5rem' }}>Tên SP</td>
              <td style={{ minWidth: '5rem' }}>Đơn giá</td>
              <td style={{ minWidth: '5rem' }}>Thành tiền</td>
            </tr>
          </div>
        );
      },
      formatter: (cell, row) => {
        if (
          row.customerInOrderType === constant.CUSTOMER_IN_ORDER_TYPE.Addition
        )
          return (
            <>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row.quota}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.productTypeId?.name}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.price || row?.productTypeId?.price || 0}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.quota * row?.price || row?.productTypeId?.price || 0}
              </td>
            </>
          );
      },
    },
    {
      dataField: 'salary',

      headerFormatter: (column) => {
        return (
          <div className="customHeader">
            <tr>
              <td colSpan="4">Chỉ tiêu chuyển đổi</td>
            </tr>
            <tr>
              <td style={{ minWidth: '5rem' }}>Số lượng</td>
              <td style={{ minWidth: '5rem' }}>Tên SP</td>
              <td style={{ minWidth: '5rem' }}>Đơn giá</td>
              <td style={{ minWidth: '5rem' }}>Thành tiền</td>
            </tr>
          </div>
        );
      },
      formatter: (cell, row) => {
        if (row.customerInOrderType === constant.CUSTOMER_IN_ORDER_TYPE.Convert)
          return (
            <>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row.quota}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.productTypeId?.name}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.price || row?.productTypeId?.price || 0}
              </td>
              <td
                style={{
                  border: 'none',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                  textAlign: 'center',
                }}
              >
                {row?.quota * row?.price || row?.productTypeId?.price || 0}
              </td>
            </>
          );
      },
    },
  ];

  useEffect(() => {
    if (_.isEmpty(currentOrders)) {
      setQuery({
        page: 1,
        limit: rowsPerPage,
        populate: 'customerOrgId.parentId,productTypeId',
        customerInOrderStatus: constant.CUSTOMER_IN_ORDER_STATUS.PendingAcept,
      });
      return;
    }

    setQuery({
      page: 1,
      limit: rowsPerPage,
      orderId: currentOrders,
      populate: 'customerOrgId.parentId,productTypeId',
      customerInOrderStatus: constant.CUSTOMER_IN_ORDER_STATUS.PendingAcept,
    });
  }, [currentOrders]);

  const handleFilter = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getOrdersConvert(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {
            setOpenFilter(false);
          },
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Lỗi lọc: ${mess}!`
            );
          },
        }
      )
    );
  };

  useEffect(() => {
    firstCalled && handleFilter();
  }, [filter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      firstCalled && handleSearch();
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchValues]);

  const handleSearch = () => {
    const tempFilter = {};
    const tempSearch = { ...searchValues };
    !_.isEmpty(filter) &&
      Object.keys(filter).map((key, index) => {
        if (!_.isEmpty(filter[key])) {
          tempFilter[key] = filter[key].value;
        }
      });

    Object.keys(tempSearch).map((key, index) => {
      if (_.isEmpty(tempSearch[key])) {
        delete tempSearch[key];
      }
    });
    dispatch(
      orderActions.getOrdersConvert(
        queryString.stringify({ ...query, ...tempFilter, ...tempSearch }),
        {
          success: () => {},
          failed: (mess) => {
            notify(
              notificationAlertRef,
              'danger',
              'Thông báo',
              `Tìm kiếm lỗi: ${mess}!`
            );
          },
        }
      )
    );
  };

  return (
    <CardBody className="p-0 pb-3">
      <div className="rna-wrapper">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>
      <ToolkitProvider
        data={ordersConvert.results}
        keyField="id"
        columns={columns}
        search
      >
        {(props) =>
          !_.isEmpty(currentAccount) && (
            <>
              <BootstrapTable
                {...props.baseProps}
                noDataIndication={() => {
                  return (
                    <span className="font-weight-bold text-danger">
                      Không có dữ liệu!
                    </span>
                  );
                }}
                onTableChange={() => {
                  return <div className="spinner-border text-info" />;
                }}
                hover
                remote
                selectRow={selectRow}
                filter={filterFactory()}
                bootstrap4={true}
                pagination={pagination}
                bordered={false}
              />
            </>
          )
        }
      </ToolkitProvider>
    </CardBody>
  );
}

export default TableData;
